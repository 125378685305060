import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G34_Prior_Knowledge_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G34"} seriesCount={"S2"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon icon-big">
          <img
            src="/image/lesson-images/settings.svg"
            alt="settings"
            title="settings"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.B,</span>{" "}
        <span className="fw-700 text-nexus-green">Cause and Effect:</span>{" "}
        Listen for arguments to include the following science ideas:
      </section>

      <ul className="pl-30 circle-listing">
        <li>
          Heating or cooling a substance may cause changes that can be observed.
        </li>
        <li>
          Some changes caused by temperature can be undone while others cannot.
        </li>
        <li>
          Different kinds of materials exist and many of them can be either
          solid or liquid, depending on the temperature.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G34_Prior_Knowledge_S2;
