import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G26_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"G26"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G26_Navigate;
