import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P20"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">7.</span>
          <span className="number-text">
            <span className="fw-700">
              Advance Sensemaking of the Anchoring Phenomenon:
            </span>{" "}
            Remind students of the anchoring phenomenon: During the course of a
            day, the Sun, the Moon, and the stars move across the sky. Ask:
          </span>
        </div>
        <ul className="pl-45 mb-10 circle-listing">
          <li>What did we learn in this lesson?</li>
          <li>How can these ideas help us explain this phenomenon?</li>
          <li>Can we fully explain it? Why not?</li>
        </ul>
        <p className="pl-15">
          Allow time for students to share their ideas with a partner and then
          with the class.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon;
