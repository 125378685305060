import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G16_Make_Observations() {
  return (
    <IdGeneratingComponent guideLineCount={"G16"}>
      <section className="text-wrapping-right pb-10">
        <div className="icon icon-big">
          <img
            src="/image/lesson-images/settings.svg"
            alt="settings"
            title="settings"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="text-nexus-blue fw-700">
          Planning and Carrying Out Investigations,
        </span>{" "}
        <span className="text-nexus-orange fw-700">PS1.A,</span>{" "}
        <span className="text-nexus-green fw-700">Cause and Effect:</span>{" "}
        Students carry out an investigation using media to determine how
        lower/cooler temperatures affect the observable properties of different
        types of matter. In grade 2, students identify that cooler/lower
        temperatures (cause) can change some types of matter from liquid to
        solid (effect).
      </section>
      <section className="text-wrapping-right pb-20">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative_assessment_opportunity"
            title="formative_assessment_opportunity"
          ></img>
        </div>
        <span className="fw-700 text-nexus-blue">
          Formative Assessment Opportunity:
        </span>{" "}
        Assess students’ ability to plan and carry out an investigation to
        understand how temperature can cause the observable properties of some
        matter to change by reviewing student work in the How Does Temperature
        Affect Other Types of Matter? section of their science notebooks (pages
        5–10). Look for evidence that each student planned an investigation with
        their partner and used the evidence collected to understand that higher
        temperatures can cause some types of matter to change. Use this to
        inform future instruction and support students in meeting the targeted
        expectations.
      </section>

      <section className="guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            ELA Connection <span className="text-nexus-lightgrey">(A,B,C)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p>
            Students gather information from their investigation to answer
            questions. (W.2.8)
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G16_Make_Observations;
