const LessonData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How do objects change into other objects?",
    Lessons: [
      {
        LessonNumber: "Lesson 1",
        LessonName: "How can pieces of glass be reused?",
        ImageUrl: "/image/glassblowing-lessons.png",
        Lessonurl: "",
      },
      {
        LessonNumber: "Lesson 2",
        LessonName: "Why does glass change when it is in the oven?",
        ImageUrl: "/image/glassblowing-lessons.png",
        Lessonurl: "",
      },
      {
        LessonNumber: "Lesson 3",
        LessonName: "How does temperature affect other types of matter?",
        ImageUrl: "/image/glassblowing-lessons.png",
        Lessonurl: "/grade2/view-planning",
      },
      {
        LessonNumber: "Lesson 4",
        LessonName: "How do objects change into other objects?",
        ImageUrl: "/image/glassblowing-lessons.png",
        Lessonurl: "",
      },
    ],
  },
];

export default LessonData;
