import React from "react";

function T30_Earn_Words_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p>Find irreversible changes on the number line.</p>
          </div>
        </section>
    {/*     <div class="container d-flex justify-content-center">
          <div class="row">
            <input type="checkbox" id="card-1" />
            <label class="col-md-2 flash-card-container" for="card-1">
              <div class="card-flip">
                <div class="flash-card front d-flex align-items-center justify-content-center">
                  <div class="card-block">
                    <p className="fw-700">?</p>
                  </div>
                </div>

                <div class="flash-card back d-flex align-items-center justify-content-center">
                  <div class="card-block">
                    <p className="fw-700">Irreversible</p>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default T30_Earn_Words_S2;
