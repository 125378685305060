import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P21_Make_Observations() {
  return (
    <IdGeneratingComponent guideLineCount={"P21"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">4.</span>
          <span className="font-16">
            <span className="fw-700">Make Observations: {""}</span>
            Allow time for students to explore the effects of heating and
            cooling on the remaining types of matter in the simulation. Remind
            them to record their observations in the table in their science
            notebook individually (page 11).
          </span>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default P21_Make_Observations;
