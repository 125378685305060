const LessonAssessmentData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How can we explain the patterns we notice in the sky?",
    Assessments: [
      {
        AssessmentName: "Lesson 3, Science Notebook: Teacher Version",
        ImageUrl: "/image/notebook_tv.png",
        PdfUrl: "/pdf/g5_space_l3_answerkey.pdf",
      },
      {
        AssessmentName: "Lesson 3: Supporting All Learners",
        ImageUrl: "/image/rem_ext.png",
        PdfUrl: "/pdf/g5_space_l3_remed_enrich.pdf",
      },
      {
        AssessmentName:
          "Lesson 3, Summative Assessment: Modeling the Solar System",
        ImageUrl: "/image/summ_asses.png",
        PdfUrl: "",
      },
      {
        AssessmentName:
          "Lesson 3, Assessment Rubric: Modeling the Solar System",
        ImageUrl: "/image/assess_rubric.png",
        PdfUrl: "",
      },
      // {
      //   AssessmentName: "Science Notebook Teacher’s Version: Lesson 3, Part A",
      //   ImageUrl: "/image/notebook_tile.png",
      //   PdfUrl: "./pdf/g2_glass_notebook_parta_checkpoint.pdf",
      // },
      // {
      //   AssessmentName:
      //     "Science Notebook Teacher’s Version: Lesson 3,Checkpoint Part A",
      //   ImageUrl: "/image/notebook_tile.png",
      //   PdfUrl: "./pdf/g2_glass_notebook_partA_initialideas.pdf",
      // },
      // {
      //   AssessmentName:
      //     "Science Notebook Teacher’s Version: Lesson 3,Checkpoint Part B",
      //   ImageUrl: "/image/notebook_tile.png",
      //   PdfUrl: "./pdf/g2_glass_notebook_partB_ourplan.pdf",
      // },
      // {
      //   AssessmentName: "Science Notebook Teacher’s Version: Lesson 3, Part C",
      //   ImageUrl: "/image/notebook_tile.png",
      //   PdfUrl: "./pdf/g2_glass_notebook_partb_heatingmatter.pdf",
      // },
      // {
      //   AssessmentName: "Lesson 3 resource",
      //   ImageUrl: "/image/notebook_tile.png",
      //   PdfUrl: "./pdf/g2_glass_l3_lesson_resource_3_1.pdf",
      // },
    ],
  },
];

export default LessonAssessmentData;
