import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P2_Make_Observations_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P2"} seriesCount={"S1"}>
        <div className="mb-10 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">2.</span>
          <span className="number-text">
            <span className="fw-700">Make Observations:</span> Say, “I was
            thinking about how things move in space, and I came across two
            pictures of the stars. The pictures were taken in the same place but
            at different times of the year. I tried to explain what I was seeing
            using what we learned in Lesson 2, but I couldn’t! It made me wonder
            what else is happening in space.”
          </span>
        </div>
        <p className="pl-20 pb-7">
          Project the Summer and Winter Night Skies image. Ask:
        </p>
        <ul className="pl-45 mb-10 circle-listing">
          <li>What do you notice when you compare these pictures?</li>
        </ul>
        <section className="pl-20 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          Allow time for students to observe the pictures <br />
          and write their observations and questions in the T-chart in the
          Observe section of their science notebooks (page 27).
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default P2_Make_Observations_S1;
