import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P37_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"P37"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">6.</span>
        <span className="number-text">
          <span className="fw-700">Engage in Sensemaking:</span> Direct
          students' attention to the Initial Ideas Class Chart from Part A.
          Review their ideas. Ask:
        </span>
      </p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>Which ideas about the pole do you still agree with? Why?</li>
        <li>Which ideas about the glass do you now disagree with? Why?</li>
        <li>
          What new ideas did we figure out that can be used to describe what the
          pole is made of or why the glass keeps going back into the furnace?
        </li>
      </ul>
      <p className="pl-20">
        If students no longer agree with an idea on the chart, cross it out. If
        students are still unsure of an idea, mark it with a question mark. Add
        any new ideas to the chart in a different color.
      </p>
    </IdGeneratingComponent>
  );
}

export default P37_Engage_Sensemaking;
