import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

function Header(props) {
  const navigate = useNavigate();
  //const [scroll, setScroll] = useState(false);

  const SignOut = () => {
    sessionStorage.removeItem("isLoggedIn");
    return navigate("/login");
  };

  // useEffect(() => {
  //   if (scroll === true) document.body.style.overflow = "hidden";
  //   else if (scroll === false) document.body.style.overflow = "auto";
  // });

  return (
    <header className="d-flex align-items-center fixed-top header-container">
      <div className="container-fluid">
        <section className="header">
          <div className="navbar-brand">
            <Link to={"/"} className="home">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 7.093V2H17V4.093L20 7.093ZM24 13L12 1L0 13H3V23H10V18H14V23H21V13H24ZM19 21H16V16H8V21H5V10.74L12 3.828L19 10.818V21Z"
                  fill="white"
                />
              </svg>
              Home
            </Link>
          </div>
          <button
            className="navbar-toggler d-md-none collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            // onClick={() => nonscroll()}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
              </svg>
            </span>
          </button>
          <form className="d-flex header-search" role="search">
            <input
              className="form-control bg-transparent border-0 shadow-none"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button
              className="btn btn-outline-success border-0 bg-transparent d-flex align-items-center"
              type="submit"
              disabled
            >
              <img src="/image/search.svg" />
            </button>
          </form>
          <div className="dropdown text-end header-dropdown">
            {/* <a
              href="#"
              className="d-block link-dark text-decoration-none dropdown-toggle user-thumb"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/image/user-thumb.png"
                alt="user-thumb"
                width="32"
                height="32"
                className="rounded-circle"
              />
            </a>
            <ul className="dropdown-menu text-small">
               <li>
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              </li>
              <li>
                <a
                  //onClick={() => SignOut()}
                  className="dropdown-item cursor-pointer"
                >
                  Sign out
                </a>
              </li>
            </ul> */}
          </div>
        </section>
      </div>
    </header>
  );
}

export default Header;
