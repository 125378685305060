import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G12_Collect_Represent_and_Analyze_Data_S4() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G13"} seriesCount={"S1"}>
        {/*  <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G12_Collect_Represent_and_Analyze_Data_S4;
