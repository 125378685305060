import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P4_Investigate_S4() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S4"}>
        <div className="d-flex guidence-count pb-10">
          <span className="fw-700 pr-5 text-nexus-medium-blue">10.</span>
          <span className="number-text">
            Say, “It sounds like we have a few different ideas about which
            objects are moving and how. Do we have any evidence to support our
            ideas? What could we do to gather evidence to help us answer our
            question?” Prompt students to share their ideas with a partner.
          </span>
        </div>
        <p className="pl-25">
          After students share, say, “I heard some of you mention researching
          the solar system first. I found an infographic that I think could help
          us determine which of our ideas are shared by other scientists who
          study space.”
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P4_Investigate_S4;
