import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G21_Make_Observations() {
  return (
    <IdGeneratingComponent guideLineCount={"G21"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/science_and_engineerning_practices.svg"
            alt="science_and_engineerning_practices"
            title="science_and_engineerning_practices"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="text-nexus-blue fw-700">
          Planning and Carrying Out Investigations:
        </span>{" "}
        Students engage in this practice when they use the simulation
        independently to gather evidence to determine how temperature affects
        different types of matter.
      </section>
    </IdGeneratingComponent>
  );
}

export default G21_Make_Observations;
