import React from "react";

function PartC() {
  return (
    <>
      <div className="lesson3-parts parta">
        <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
          PART C: INVESTIGATE HIGHER AND LOWER TEMPERATURES
        </p>
        <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
            MATERIALS FOR PART C
          </h5>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                TEACHER
              </p>
              <ul className="pl-20 circle-listing">
                <li>
                  Chart paper<sup>‡</sup>
                </li>
                <li>
                  Digital Lesson 3*:
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>Temperature Simulation</li>
                  </ul>
                </li>
                <li>
                  Markers<sup>‡</sup>
                </li>
                <li>
                  1 Pair of scissors<sup>‡</sup>
                </li>
                <li>
                  1 Rubber band<sup>‡</sup>
                </li>
                <li>
                  1 Sheet of paper<sup>‡</sup>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                CLASS
              </p>
              <ul className="mb-20 pl-20 circle-listing">
                <li>
                  Number Line<sup>†</sup>
                </li>
              </ul>

              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                STUDENT
              </p>
              <ul className="mb-20 pl-20 circle-listing">
                <li>1 Science Notebook*</li>
                <li>
                  1 Device (tablet, computer, etc.)<sup>‡</sup>
                </li>
              </ul>
              <p className="font-13">*Accessible online</p>
              <p className="font-13">† Prepared by the teacher</p>
              <p className="font-13">‡ Needed but not supplied</p>
            </div>
          </div>
        </section>
        <h5 className="fw-700 mb-10 text-decoration-underline">PREPARATION</h5>
        <p className="fw-700 mb-7">
          Part <span className="text-nexus-blue">C</span>{" "}
          <span className="fw-400">(10 minutes total)</span>
        </p>
        <p className="mb-7">
          <span className="fw-700">Session 1</span> (5 minutes)
        </p>
        <ul className="mb-20 pl-20 number-listing">
          <li>Prepare to present Digital Lesson 3.</li>
          <li>
            If you are not using the digital experience, students will still
            need access to the Temperature Simulation used in this lesson.
            Obtain enough devices for students to access the simulation
            individually.
          </li>
          <li>Ensure that students have access to their science notebooks.</li>
        </ul>
        <p className="mb-7">
          <span className="fw-700">Session 2</span> (5 minutes)
        </p>
        <ul className="pl-20 number-listing">
          <li>Prepare to present Digital Lesson 3.</li>
          <li>
            <p className="mb-10">
              Create a number line on chart paper with 27 evenly spaced hash
              marks. Label the first hash mark “0.” Have markers available to
              add to the number line during the lesson.
            </p>
            <div className="row">
              <div className="col-12">
                <img src="./image/lesson3_image5.png" alt="" title="" />
              </div>
            </div>
          </li>
          <li>
            Have a pair of scissors, sheet of paper, and rubber band for a
            demonstration during the lesson.
          </li>
          <li>Ensure that students have access to their science notebooks.</li>
        </ul>
      </div>
    </>
  );
}

export default PartC;
