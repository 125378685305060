import React from "react";

function T4_Investigate_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Compare the patterns you identified with a partner.
            </p>
          </div>
        </section>
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>Which objects might be moving in the solar system?</li>
            <li>How do you think they are moving?</li>
          </ul>
        </div>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Complete Steps 2 and 3 of the Investigate section of your
              notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/g5_space_notebookl3_forpoc_6-15-234_page_09.png"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T4_Investigate_S2;
