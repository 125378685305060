import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G9_Engage_Sensemaking_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G9"} seriesCount={"S2"}>
      <p className="pb-7">
        Prioritize investigation ideas related to heating up different types of
        matter. If students do not express these ideas, ask:
      </p>
      <ul className="pl-30 circle-listing">
        <li>What could we do to see if our ideas about the pole are right?</li>
        <li>
          What could we do to see how different types of matter are affected by
          heat?
        </li>
        <li>How could we heat these different types of matter?</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G9_Engage_Sensemaking_S2;
