import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P11_Checkpoint() {
  return (
    <IdGeneratingComponent guideLineCount={"P11"}>
      <div className="d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">8.</span>
        <span className="number-text">
          <span className="fw-700">Checkpoint:</span> Prompt students to
          complete the checkpoint question in their science notebooks to
          formatively assess their understanding of observable properties (page
          6).
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P11_Checkpoint;
