import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G7_Earn_Words_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G7"} seriesCount={"S2"}>
        {/* <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G7_Earn_Words_S2;
