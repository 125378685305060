import React, { useState, useRef, useEffect, createElement } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { nanoid } from "nanoid";
//import Events from "./Events";
import EventsAlt from "./Events-alt";
import dateFormat, { masks } from "dateformat";
import datetimeDifference from "datetime-difference";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";
import CustomModal from "./CustomModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const Calendar = (props) => {
  const [defaultminute, setDefaultMinute] = useState();
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [description, setDescription] = useState("");
  const [eventDateDetails, setEventDateDetails] = useState([
    new Date(),
    new Date(),
  ]);
  const [calendarContentType, setCalendarContentType] = useState("event");
  const [state, setState] = useState({});
  const [currentEvents, setCurrentEvents] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const calendarRef = useRef(null);

  const handleCloseModal = () => {
    handleClose();
    setModal(false);
  };
  function handleClose() {
    setTitle("");
    setEventDateDetails([new Date(), new Date()]);
    setState({});
    setModal(false);
  }

  useEffect(() => {
    if (currentEvents)
      localStorage.setItem("events", JSON.stringify(currentEvents));
  }, [currentEvents]);

  useEffect(() => {
    let data = localStorage.getItem("events");
    if (data) setCurrentEvents(JSON.parse(data));
  }, []);

  function handleEventClick(clickInfo) {
    setState({ clickInfo, state: "update" });
    // set detail
    setTitle(clickInfo.event.title);
    setEventDateDetails([clickInfo.event.start, clickInfo.event.end]);
    if (clickInfo.event.id === "note") {
      setCalendarContentType("note");
      setDescription(clickInfo.event.extendedProps.description);
    } else {
      setCalendarContentType("event");
      setDescription("");
    }
    setModal(true);
    // if (
    //   confirm(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove();
    // }
  }

  function handleEvents(currentEvents) {
    setCurrentEvents(currentEvents);
  }

  function handleDateSelect(selectInfo) {
    selectInfo.view.calendar.unselect();
    setState({ selectInfo, state: "create" });
    // Open modal create
    setEventDateDetails([selectInfo.start, selectInfo.end]);
    setModal(true);

    // let calendarApi = selectInfo.view.calendar;

    // let title = prompt("Please enter a new title for your event");

    // if (title) {
    //   calendarApi.addEvent({
    //     id: nanoid(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   });
    // }
  }

  function renderEventContent(eventInfo) {
    let endTime = new Date(
      eventInfo.event.start.setMinutes(eventInfo.event.extendedProps.duration2)
    );
    let lastDate = endTime;
    if (eventInfo.event.end) lastDate = eventInfo.event.end;
    const result = datetimeDifference(eventInfo.event.start, lastDate);
    let eventTime = "";
    if (result.hours)
      eventTime += result.hours + (result.hours > 1 ? " hours " : " hour ");
    if (result.minutes !== 0) eventTime += result.minutes + " minutes ";
    /* if (!result.hours || !result.minutes)
      eventTime = "All day" */
    let eventTitle = <b>{eventInfo.event.title}</b>;
    let eventDescription = (
      <b>
        {eventInfo.event.extendedProps.description
          ? eventInfo.event.extendedProps.description
          : "Untitled"}
      </b>
    );
    let eventDetails = { eventTime };
    return (
      <div>
        <i
          className="calendar-i"
          style={{
            whiteSpace: "",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {eventInfo.event.id === "note" ? (
            eventDescription
          ) : (
            <b>
              {" "}
              {eventTitle} | {eventTime}
            </b>
          )}
          {/*           <br />{dateFormat(eventInfo.event.start, "h:MM TT")} - {dateFormat(lastDate, "h:MM TT")} |          {eventTime} */}
        </i>
      </div>
    );
  }
  function handleEventDrop(checkInfo) {
    // console.log(
    //   "dsfsd:",
    //   checkInfo.event.id == "note",
    //   checkInfo.view.type,
    //   checkInfo.view.type === "timeGridWeek",
    //   checkInfo.view.type === "timeGridDay"
    // );
    if (
      checkInfo.event.id == "note" &&
      (checkInfo.view.type === "timeGridWeek" ||
        checkInfo.view.type === "timeGridDay")
    ) {
      return;
    }
    setState({ checkInfo, state: "drop" });
    setConfirmModal(true);
  }
  function handleEventResize(checkInfo) {
    setState({ checkInfo, state: "resize" });
    setConfirmModal(true);
  }
  function handleEdit() {
    state.clickInfo.event.setStart(eventDateDetails[0]);
    state.clickInfo.event.setEnd(eventDateDetails[1]);
    if (state.clickInfo.event.extendedProps)
      state.clickInfo.event.setExtendedProp("description", description);
    state.clickInfo.event.mutate({
      standardProps: { title },
    });
    handleClose();
  }

  function handleSubmit() {
    const newEvent = {
      id: nanoid(),
      title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
    };
    let calendarApi = calendarRef.current.getApi();
    // let calendarApi = selectInfo.view.calendar
    calendarApi.addEvent(newEvent);
    let newData = currentEvents;
    newData.push(newEvent);
    setCurrentEvents(newData);
    handleClose();
  }
  function handleDelete() {
    if (state.clickInfo.event) {
      let eventSource = document.getElementById(state.clickInfo.event.id);
      if (state.clickInfo.event.id != "note") {
        eventSource.classList.remove("disabled");
        eventSource.classList.add("dragging");
      }
    }
    state.clickInfo.event.remove();

    handleClose();
  }

  function eventDragged(info) {
    let eventSource = document.getElementById(info.event.id);
    if (info.event.id != "note") {
      eventSource.classList.remove("dragging");
      eventSource.classList.add("disabled");
    }
    if (info.view.type === "dayGridMonth") {
      info.event.setEnd(null);
      return info;
    } else return info;
  }

  function eventDropped(event) {
    const trashEl = document.getElementById("dandd");
    let x1 = trashEl.offsetLeft;
    let x2 = trashEl.offsetLeft + trashEl.offsetWidth;
    let y1 = trashEl.offsetTop;
    let y2 = trashEl.offsetTop + trashEl.offsetHeight;
    if (
      event.jsEvent.pageX >= x1 &&
      event.jsEvent.pageX <= x2 &&
      event.jsEvent.pageY >= y1 &&
      event.jsEvent.pageY <= y2
    ) {
      let eventSource = document.getElementById(event.event.id);
      if (event.id != "note") {
        eventSource.classList.remove("disabled");
        eventSource.classList.add("dragging");
      }
      event.event.remove();
    }
  }

  let popUpTitle = "Add Event";
  if (calendarContentType === "note") {
    popUpTitle = "Notes";
  } else if (state === "update") {
    popUpTitle = "Update Event";
  }

  const ResetEvents = () => {
    if (calendarRef && calendarRef.current) {
      let removeEvents = calendarRef.current.calendar.getEvents();
      return removeEvents.forEach((event) => {
        let eventSource = document.getElementById(event.id);
        if (event.id != "note") {
          eventSource.classList.remove("disabled");
          eventSource.classList.add("dragging");
        }
        event.remove();
      });
    }
  };

  useEffect(() => {
    return ResetEvents;
  }, []);

  return (
    <div className="mr-5">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        events={props.changestate === 0 ? [] : []}
        customButtons={{
          myCustomButton: {
            text: "Export",
            icon: "shareIcon",
            /* click: function () {
              alert('Export the current calendar view!');
            }, */
          },
          ResetButton: {
            text: "Reset",
            icon: "resetIcon",
            click: function () {
              ResetEvents();
            },
          },
        }}
        headerToolbar={{
          left: "prev,today,next",
          center: "title",
          right:
            "ResetButton myCustomButton dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        buttonText={{
          today: "current",
          month: "month",
          week: "week",
          day: "day",
          listWeek: "list",
          export: "Export",
        }}
        editable={true}
        selectable={true}
        droppable={true}
        selectMirror={false}
        dayMaxEvents={true}
        eventReceive={eventDragged}
        select={handleDateSelect}
        dayCellContent={function (info) {
          if (info) {
            const element = createElement(
              "span",
              { id: "fc-day-span-" + info.date },
              info.dayNumberText
            );
            return element;
          }
        }}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventsSet={() => handleEvents(currentEvents)}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        eventDragStop={eventDropped}
      />
      <CustomModal
        title={popUpTitle}
        isOpen={modal}
        toggle={handleCloseModal}
        onCancel={handleCloseModal}
        onSubmit={state.clickInfo ? handleEdit : handleSubmit}
        submitText={state.clickInfo ? "Update" : "Save"}
        onDelete={state.clickInfo && handleDelete}
        deleteText="Delete"
      >
        {calendarContentType === "event" ? (
          <FormGroup>
            <Label htmlFor="exampleEmail">Title</Label>
            <Input
              type="text"
              name="title"
              placeholder="Event title goes here..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        ) : null}
        {calendarContentType === "event" ? (
          <FormGroup>
            <Label htmlFor="exampleEmail w-100">From - End</Label>
            {/* <DateRangePicker
            initialSettings={{
              locale: {
                format: "M/DD hh:mm A",
              },
              startDate: start,
              endDate: end,
              timePicker: true,
            }}
            onApply={(event, picker) => {
              setStart(new Date(picker.startDate));
              setEnd(new Date(picker.endDate));
            }}
          >
            <input className="form-control" type="text" />
          </DateRangePicker> */}
            <DateTimeRangePicker
              onChange={setEventDateDetails}
              value={eventDateDetails}
              className={"form-control"}
            />
          </FormGroup>
        ) : null}
        {calendarContentType === "note" ? (
          <FormGroup className="mb-0">
            {/* <Label htmlFor="exampleEmail">Enter Note</Label> */}
            <Input
              rows={7}
              type="textarea"
              name="description"
              placeholder="Add Note..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        ) : null}
      </CustomModal>
    </div>
  );
};

export default Calendar;
