import React, { useRef } from "react";
import videojs from "video.js";
import VideoPlayerComponent from "../../../../VideoPlayer/VideoPlayerComponent";

function T2_Make_Observations_S1() {
  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "/videos/lesson3-videos/nex_gr2_glassblowing_revisit_furnace_video.mp4",
        type: "video/mp4",
      },
    ],
  };

  function onPlay(e) {
    var players = [];

    // +++  Determine the available player IDs +++//
    for (var x = 0; x < Object.keys(videojs.players).length; x++) {
      // Assign the player name to setPlayer
      var setPlayer = Object.keys(videojs.players)[x];
      // Define the ready event for the player
      var playerData = videojs.getPlayer(setPlayer);
      if (playerData != undefined) {
        players.push(playerData);
      }
    }
    // Determine which player the event is coming from
    var id = e.id_;
    // Loop through the array of players
    for (var i = 0; i < players.length; i++) {
      // Get the player(s) that did not trigger the play event
      if (players[i].id() != id) {
        // Pause the other player(s)
        videojs.getPlayer(players[i].id()).pause();
      }
    }
  }

  const handlePlayerReady = (player) => {
    var playerId = player.player();
    playerId.on("waiting", () => {
      videojs.log("player is waiting");
    });

    playerId.on("play", (e) => {
      onPlay(player);
    });

    playerId.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-10">Watch the Revisiting the Furnace video.</p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9">
            <div className="mb-20 video-section">
              {/* <source
              src="/videos/lesson3-videos/nex_gr2_glassblowing_revisit_furnace_video.mp4"
              type="video/mp4"
            ></source> */}
              <VideoPlayerComponent
                options={videoJsOptions}
                onReady={handlePlayerReady}
              />
            </div>
          </div>
        </div>
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class-discussion"
              title="class-discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Share your observations with the class. Ask any new questions
              about what you see.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  What is something different that you notice about the glass in
                  this video?
                </li>
                <li>What questions do you have about what you see here?</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T2_Make_Observations_S1;
