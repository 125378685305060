import React, { useState, useEffect } from "react";
import PartA from "./PartA";
import PartB from "./PartB";
import PartC from "./PartC";
// import PartD from "./PartD";
// import LessonResources from "./LessonResources";

function Containerprep() {
  const [isActiveScroll, setIsActiveScroll] = useState(0);
  const [scrollhide, setScrollHide] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const sections = document.querySelectorAll("section[id]");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight * 0.1 &&
          scrollPosition < sectionTop + sectionHeight * 0.1
        ) {
          setIsActiveScroll(section.id);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div className="two-column-content">
        <aside className="table-of-content-menu">
          <nav className="sidebar card accordion-menu-listings">
            <ul className="nav flex-column" id="nav_accordion">
              <li className="level-one">
                <a
                  onClick={() => setScrollHide(3)}
                  className="nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse3"
                  aria-expanded="true"
                >
                  Lesson 3: Why do we see different stars in the summer than in
                  the winter?
                  <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse show" id="home-collapse3">
                  <ul className="mt-15 nav flex-column submenu btn-toggle-navl">
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "performance"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#performance"
                      >
                        Performance Expectations
                      </a>
                    </li>
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "elements"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#elements"
                      >
                        Elements Developed in This Lesson
                      </a>
                    </li>
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "lessonsummary"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#lessonsummary"
                      >
                        Lesson Summary
                      </a>
                    </li>
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "teacherbackground"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#teacherbackground"
                      >
                        Teacher Background
                      </a>
                    </li>
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "priorconceptions"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#priorconceptions"
                      >
                        Prior Conceptions
                      </a>
                    </li>
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "earnedword"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#earnedword"
                      >
                        Earned Words
                      </a>
                    </li>
                    <li className="level-three">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "materiallist"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#materiallist"
                      >
                        Materials List
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        className={
                          scrollhide === 1 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setScrollHide(1)}
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded={scrollhide === 1 ? "true" : "false"}
                      >
                        Materials and Preparation{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 1 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse1"
                      >
                        <ul className="nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "parta"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#parta"
                            >
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partb"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partb"
                            >
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partc"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partc"
                            >
                              Part C
                            </a>
                          </li>

                          {/* <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "supporting"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#supporting"
                            >
                              Supporting All Learners
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </aside>
        <div className="pr-15 pt-15 table-of-content">
          <section className="mb-20 lesson-banner">
            <img
              src="/image/g5-lesson3-image1.jpg"
              alt="WHY DO WE SEE DIFFERENT STARS IN THE SUMMER"
              title="WHY DO WE SEE DIFFERENT STARS IN THE SUMMER"
            ></img>
          </section>
          <h5 className="fw-700 text-nexus-white mb-20">
            <span className="gradient-title p-2 pr-40">
              3 sessions of 30 minutes
            </span>
          </h5>
          <section id="performance">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PERFORMANCE</span>{" "}
              <span className="fw-500">EXPECTATIONS</span>
            </h5>
            <p className="fw-700 mb-10">
              Lesson-Level Performance Expectations
            </p>
            <p className="mb-7">In this lesson, students will:</p>
            <ul className="mb-10 pl-30 circle-listing">
              <li>
                <span className="text-nexus-dark-blue">
                  Represent data in graphical displays
                </span>{" "}
                that reveals{" "}
                <span className="text-nexus-green">
                  patterns that can be used as evidence
                </span>{" "}
                to explain the seasonal changes in the number of daylight hours
                that result from{" "}
                <span className="text-nexus-orange">
                  Earth's revolution around the Sun.
                </span>{" "}
                (Part B)
              </li>
              <li>
                <span className="text-nexus-dark-blue">
                  Use models to describe and predict
                </span>{" "}
                <span className="text-nexus-green">patterns</span> caused by{" "}
                <span className="text-nexus-orange">Earth’s revolution</span>{" "}
                that are related to the{" "}
                <span className="text-nexus-orange">
                  different positions of the stars throughout the year.
                </span>{" "}
                (Part C)
              </li>
            </ul>
            <p className="fw-700 mb-10">
              Building Toward NGSS Performance Expectations
            </p>
            <ul className="pb-20 pl-30 circle-listing">
              <li>
                <span className="fw-700">5-ESS1-2: </span>Represent data in
                graphical displays to reveal patterns of daily changes in length
                and direction of shadows, day and night, and the seasonal
                appearance of some stars in the night sky.
              </li>
            </ul>
            <section className="lesson-banner">
              <img src="/image/g5-lesson3-image2.jpg" alt="" title=""></img>
            </section>
          </section>
          <hr></hr>
          <section id="elements">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">ELEMENTS</span>{" "}
              <span className="fw-500">DEVELOPED IN THIS LESSON</span>
            </h5>
            <p className="mb-10">
              When developing an element, students build on their previous
              knowledge and experience to make progress toward grade-band
              expectations for the element. These elements are what make up the
              Lesson-Level Performance Expectations.
            </p>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle background-nexus-orange w-25">
                      <p>Disciplinary Core Ideas</p>
                      <p className="fw-500">
                        Fundamental scientific ideas that form content
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-darkblue w-25">
                      <p>Science and Engineering Practices</p>
                      <p className="fw-500">
                        What students do to make sense of phenomena
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-green w-25">
                      <p>Crosscutting Concepts</p>
                      <p className="fw-500">
                        A way of linking the different domains of science
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nexus-orange">
                      <span className="fw-700">
                        ESS1.B: Earth and the Solar System:
                      </span>{" "}
                      The orbits of Earth around the sun{" "}
                      <span className="text-decoration-line-through">
                        and of the moon around Earth, together with the rotation
                        of Earth about an axis between its North and South
                        poles,
                      </span>{" "}
                      cause observable patterns. These include{" "}
                      <span className="text-decoration-line-through">
                        day and night; daily changes in the length and direction
                        of shadows;
                      </span>{" "}
                      and different positions of{" "}
                      <span className="text-decoration-line-through">
                        the sun, moon, and
                      </span>{" "}
                      stars at different times of the{" "}
                      <span className="text-decoration-line-through">day,</span>{" "}
                      month, and year.
                    </td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Developing and Using Models:
                      </span>{" "}
                      Develop and/or use models to describe and/or predict
                      phenomena.
                    </td>
                    <td className="text-nexus-green">
                      <span className="fw-700">Patterns:</span> Patterns of
                      change can be used to make predictions.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nexus-orange"></td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Analyzing and Interpreting Data:
                      </span>{" "}
                      Represent data in graphical displays (bar graphs,
                      pictographs, and/ or pie charts) to reveal patterns that
                      indicate relationships.
                    </td>
                    <td className="text-nexus-green">
                      <span className="fw-700">Patterns:</span> Patterns can be
                      used as evidence to support an explanation.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">
              * The parts of each element that are crossed out are not addressed
              in the lesson.
            </p>
          </section>
          <hr></hr>
          <section id="lessonsummary">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">LESSON</span>{" "}
              <span className="fw-500">SUMMARY</span>
            </h5>
            <p>
              In this lesson students conduct a series of investigations to
              determine the role of{" "}
              <span className="text-nexus-orange">Earth’s revolution</span>{" "}
              around the Sun in the seasonal{" "}
              <span className="text-nexus-green">patterns</span> that we observe
              in the sky. Students{" "}
              <span className="text-nexus-dark-blue">
                analyze and represent data in graphical displays
              </span>{" "}
              to identify <span className="text-nexus-green">patterns</span> in
              the{" "}
              <span className="text-nexus-orange">
                number of daylight hours at different times of the year.
              </span>{" "}
              In addition, students{" "}
              <span className="text-nexus-dark-blue">use models</span> to
              determine why we see{" "}
              <span className="text-nexus-orange">
                different stars in the summer than in the winter.
              </span>
            </p>
          </section>
          <hr></hr>
          <section id="teacherbackground">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">TEACHER</span>{" "}
              <span className="fw-500">BACKGROUND</span>
            </h5>
            <p className="mb-10">
              The movement of one space body around another is called
              revolution. Earth’s revolution around the Sun takes about 365
              days. Earth (and all other planets) revolve around the Sun in a
              counterclockwise direction. Earth’s revolution causes observable
              patterns related to seasonal changes such as the number of
              daylight hours, and the different stars and constellations that
              can be seen in the night sky throughout the year. This unit does
              not address the concepts of seasons and other seasonal changes
              because these science ideas are above grade band.
            </p>
            <p className="mb-10">
              Because of the constant pull of the Sun’s gravity on the planets,
              comets, asteroids, and other objects in the solar system, these
              objects move in orbit around the Sun. An orbit is a path that one
              object in space takes around another object. The concept of
              gravity in space is presented to help you better understand the
              concepts related to this lesson, but understanding the concept of
              gravity is above grade band and is not expected of students.
            </p>
            <p className="mb-20">
              Earth’s axis is an imaginary line that runs from top to bottom,
              from the North Pole to the South Pole. Earth is tilted at its
              axis, and this tilt doesn’t change. As Earth revolves around the
              Sun, different parts of Earth are tilted toward or away from the
              Sun. When one part is tilted toward the Sun, it experiences more
              hours of daylight (longer days). When the same part is tilted away
              from the Sun, it experiences fewer hours of daylight (shorter
              days).
            </p>
            <div className="row justify-content-center">
              <div className="col-12 col-md-7">
                <img
                  src="/image/g5-lesson3-image4.jpg"
                  alt="TEACHER BACKGROUND"
                  title="TEACHER BACKGROUND"
                ></img>
              </div>
            </div>
          </section>
          <hr></hr>
          <section id="priorconceptions">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PRIOR</span>{" "}
              <span className="fw-500">CONCEPTIONS</span>
            </h5>
            <p className="mb-10">
              Students may come to this lesson with preconceptions or
              misconceptions around the science ideas presented in this unit.
              Such ideas will be addressed as part of the student sensemaking
              process throughout this unit. Listed below are misconceptions you
              are likely to hear from your students in this lesson. The correct
              science idea follows in parenthesis.
            </p>
            <p className="mb-7">
              This lesson will address the following misconception:
            </p>
            <ul className="pl-30 circle-listing">
              <li>
                Stars and constellations appear in the same place in the sky
                throughout the year.{" "}
                <span className="text-i">
                  (Due to Earth’s revolution around the Sun, stars and
                  constellations appear in different places in the sky
                  throughout the year.)
                </span>
              </li>
            </ul>
          </section>
          <hr></hr>
          <section id="earnedword">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">EARNED</span>{" "}
              <span className="fw-500">WORDS</span>
            </h5>
            <p className="mb-10">
              A word is earned on the Word Wall when the class demonstrates a
              strong understanding of that key term. Until a word is earned,
              encourage students to use everyday language in discussions and
              work products. Opportunities to earn words are called out in the
              lesson procedure.
            </p>
            <p className="mb-7">
              By the end of this lesson, students will earn the following key
              terms for the Word Wall:
            </p>
            <div className="mb-20 d-flex justify-content-center">
              <p className="font-20 mr-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Axis
              </p>
              <p className="font-20 mr-20 fw-700 ">
                <span className="text-nexus-blue pr-10">✓</span> Orbit
              </p>
              <p className="font-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Revolution
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <img src="/image/g5-lesson3-image3.jpg" alt="" title=""></img>
              </div>
            </div>
          </section>
          <hr></hr>
          <section id="materiallist">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">MATERIALS</span>{" "}
              <span className="fw-500">LIST</span>
            </h5>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part A</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Summer and Winter Night Skies image</li>
                            <li>
                              Initial Ideas: Different Stars in the Winter and
                              the Summer Class Chart
                            </li>
                            <li>What Is In Our Solar System? interactive</li>
                          </ul>
                        </li>
                        <li>32 Devices (tablets, laptops, etc.)</li>
                        <li>32 Science Notebooks*</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part B</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3* :
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Earth’s Revolution animation</li>
                          </ul>
                        </li>
                        <li>32 Science Notebooks*</li>
                        {/* <li>32 Science Notebooks*</li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part C</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3* :
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Summer and Winter Night Skies image</li>
                            <li>
                              Initial Ideas: Different Stars in the Winter and
                              the Summer Class Chart (from Part A)
                            </li>
                            <li>
                              Notice and Wonder Class Chart (from Lesson 1)
                            </li>
                            <li>
                              Alternative Digital Investigation (optional)
                            </li>
                          </ul>
                        </li>
                        <li>1 Large styrene ball</li>
                        <li>1 Small styrene ball</li>
                        <li>
                          1 Stars Demonstration Model†
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>1 Night Sky Card Set</li>
                            <li>Tape‡</li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">*Accessible online</p>
            <p className="font-14">† Prepared by the teacher</p>
            <p className="font-14">‡ Needed but not supplied</p>
          </section>
          <hr></hr>
          <p className="fw-700 mb-20 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
            MATERIALS AND PREPARATION
          </p>
          <section id="parta">
            <PartA />
          </section>
          <hr></hr>
          <section id="partb">
            <PartB />
          </section>
          <hr></hr>
          <section id="partc">
            <PartC />
          </section>
        </div>
      </div>
    </>
  );
}

export default Containerprep;
