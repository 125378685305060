import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G3_Develop_Initial_Ideas_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G3"} seriesCount={"S2"}>
        <section className="text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative_assessment_opportunity"
              title="formative_assessment_opportunity"
            ></img>
          </div>
          <span className="fw-700">Formative Assessment Opportunity:</span> Use
          this discussion to gain insight into what students already know about
          Earth’s movement in the solar system. Listen for students to mention
          that different stars or constellations are visible in the summer than
          in the winter. At this point, students may hold misconceptions or
          incomplete ideas. These will be addressed as the lesson progresses.
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G3_Develop_Initial_Ideas_S2;
