import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P19_Engage_in_Sensemaking_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P19"} seriesCount={"S3"}>
        <div className="mb-10 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">6.</span>
          <span className="number-text">(continued)</span>
        </div>
        <p className="pl-20">
          Next, review the Initial Ideas: Different Stars in the Winter and the
          Summer Class Chart from Part A with the class. Invite students to
          share how their ideas about why we see different stars throughout the
          year have changed. Cross out or modify ideas on the chart as needed.
          Add any new ideas students share.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P19_Engage_in_Sensemaking_S3;
