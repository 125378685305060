import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P2_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P2"} seriesCount={"S2"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">3.</span>
        <span className="number-text">
          Tell students that you will replay the video. Ask them to observe what
          they notice about the objects that go into the furnace.
        </span>
      </div>
      <p className="pl-20 mb-10">Play the video a second time.</p>
      <p className="pl-20 mb-7">Next, ask:</p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          What are the two objects that go into the furnace?{" "}
          <i>(The blob of glass and a pole.)</i>
        </li>
        <li>
          Which object is affected by the very high temperature of the furnace?{" "}
          <i>(The blob of glass.)</i>
        </li>
        <li>
          Which object was not affected by the very high temperature of the
          furnace? <i>(The pole.)</i>
        </li>
        <li>What new questions do you have after watching this video?</li>
      </ul>
      <p className="pl-20">
        Listen for questions related to why the pole is unaffected by the
        extremely high temperature of the furnace.
      </p>
    </IdGeneratingComponent>
  );
}

export default P2_Make_Observations_S2;
