import React from "react";

function T10_Plan_Investigation_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>
          <div className="text">
            <p className="mb-20 mt-5 font-16">
              Share your investigation plan with the class. Listen to others'
              plans.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <div className="mb-10 d-flex align-items-center thumb">
                <div className="icon">
                  <img
                    src="/image/lesson-images/thumb_up.svg"
                    alt="thumb_up"
                    title="thumb_up"
                  ></img>
                </div>
                <div className="text">
                  Put your thumb up if you had a similar plan.
                </div>
              </div>
              <div className="d-flex align-items-center thumb">
                <div className="icon">
                  <img
                    src="/image/lesson-images/thumb_down.svg"
                    alt="thumb_down"
                    title="thumb_down"
                  ></img>
                </div>
                <div className="text">
                  Put your thumb down if you had a different plan.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T10_Plan_Investigation_S2;
