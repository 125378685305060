import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P7_Prior_Knowledge() {
  return (
    <IdGeneratingComponent guideLineCount={"P7"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">1.</span>
        <span className="number-text">
          <span className="fw-700">Connect to Prior Knowledge:</span> Hold up
          any object from the classroom, such as a mug or a book. Have students
          briefly review observable properties by describing the item you
          display. Students should describe:
        </span>
      </div>
      <ul className="pl-45 circle-listing">
        <li>Color</li>
        <li>Flexibility</li>
        <li>Texture</li>
        <li>Hardness</li>
        <li>Solid or liquid</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P7_Prior_Knowledge;
