import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P23_Analyze_Data() {
  return (
    <IdGeneratingComponent guideLineCount={"P23"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">6.</span>
          <span className="font-16">
            <span className="fw-700">Analyze Data: {""}</span>
            Have pairs use their findings to complete the Make Connections table
            and the Final Ideas prompts in their science notebooks (pages
            12–13).
          </span>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default P23_Analyze_Data;
