import React from "react";

function T7_Earn_Words_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  If your desk was the Sun and you were Earth, what motion could
                  you make to represent the ideas of orbit and revolution?
                </li>
                <li>
                  Can you describe your movement using the words “orbit” and
                  “revolution”?
                </li>
                <li>
                  How are these two words different? How are they related?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T7_Earn_Words_S2;
