import React from "react";
import { Link } from "react-router-dom";

function BreadcumbComponent_2({ Parent, Child, grandChild }) {
  if (Parent && Child && grandChild) {
    return (
      <>
        <span className="pr-5">
          <Link className="text-nexus-blue" to={"/grade5"}>
            Home
          </Link>{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 12L0 24L7.289 12L0 0L20 12Z" fill="#525252" />
          </svg>
        </span>
        <span className="pr-5">
          <Link className="text-nexus-blue" to={Parent.path}>
            {Parent.title}
          </Link>{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 12L0 24L7.289 12L0 0L20 12Z" fill="#525252" />{" "}
          </svg>
        </span>
        <span className="pr-5">
          <Link className="text-nexus-blue" to={Child.path}>
            {Child.title}
          </Link>{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 12L0 24L7.289 12L0 0L20 12Z" fill="#525252" />{" "}
          </svg>
        </span>
        <span>
          <Link
            className="text-nexus-grey"
            style={{ cursor: "default" }}
            to={grandChild.path}
          >
            {grandChild.title}
          </Link>
        </span>
      </>
    );
  } else if (Parent && Child) {
    return (
      <>
        <span className="pr-5">
          <Link className="text-nexus-blue" to={"/grade5"}>
            Home
          </Link>{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 12L0 24L7.289 12L0 0L20 12Z" fill="#525252" />
          </svg>
        </span>
        <span className="pr-5">
          <Link className="text-nexus-blue" to={Parent.path}>
            {Parent.title}
          </Link>{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 12L0 24L7.289 12L0 0L20 12Z" fill="#525252" />
          </svg>
        </span>
        <span>
          <Link
            className="text-nexus-grey"
            style={{ cursor: "default" }}
            to={Child.path}
          >
            {Child.title}
          </Link>
        </span>
      </>
    );
  } else if (Parent) {
    return (
      <>
        <span className="pr-5">
          <Link className="text-nexus-blue" to={"/grade5"}>
            Home
          </Link>{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 12L0 24L7.289 12L0 0L20 12Z" fill="#525252" />
          </svg>
        </span>
        <span className="pr-5">
          <Link
            className="text-nexus-grey"
            style={{ cursor: "default" }}
            to={Parent.path}
          >
            {Parent.title}
          </Link>{" "}
        </span>
      </>
    );
  } else {
    <span>
      <Link className="text-nexus-blue" to={"/grade5"}>
        Home
      </Link>{" "}
    </span>;
  }
}

export default BreadcumbComponent_2;
