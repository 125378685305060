import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P28_Connect_Ideas() {
  return (
    <IdGeneratingComponent guideLineCount={"P28"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">12.</span>
        <span className="number-text">
          <span className="fw-700">Connect Ideas:</span> Invite students to
          describe how the observable properties of each type of matter changed.
          Next to each type of matter on the number line, use students’ language
          to describe the change, such as “liquid,” “burned solid,” “flexible
          solid,” or “cooked solid.”
        </span>
      </div>
      <p className="pl-25 mb-7">
        Direct the class to look at the changes recorded on the number line.
        Ask:
      </p>
      <ul className="pl-55 circle-listing">
        <li>
          What happened to most types of matter when they were heated?
          <span className="text-i">
            {" "}
            (Their observable properties changed from solid to liquid.)
          </span>
        </li>
        <li>
          What happened to most types of matter when they were cooled?
          <span className="text-i">
            {" "}
            (Their observable properties changed from liquid back into solid.)
          </span>
        </li>

        <li>
          How were the egg and wood different from the other types of matter you
          tested?{" "}
          <span className="text-i">
            (Their observable properties changed when heated but the properties
            did not change back when cooled.)
          </span>
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P28_Connect_Ideas;
