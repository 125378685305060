import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P4_Investigate_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S2"}>
        <div className="d-flex guidence-count mb-10">
          <span className="fw-700 pr-5 text-nexus-medium-blue">8.</span>
          <span className="number-text">
            Allow time for students to compare the patterns they identified with
            a partner.
          </span>
        </div>
        <section className="pl-15 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="">
              Next, ask pairs to discuss which objects they think might be
              moving in the solar system and how. Prompt students to draw their
              ideas and explain their thinking in Steps 2 and 3 of the
              Investigate section of their science notebooks individually (page
              30).
            </p>
          </div>
        </section>
        {/*  <section className="text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>

        </section> */}
      </IdGeneratingComponent>
    </>
  );
}

export default P4_Investigate_S2;
