import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G17_Connect_to_Prior_Knowledge_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G17"} seriesCount={"S2"}>
        {/* <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G17_Connect_to_Prior_Knowledge_S2;
