import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G20_Prior_Knowledge_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G20"} seriesCount={"S1"}>
      <p>
        <span className="fw-700">Formative Assessment Opportunity:</span> Listen
        for students to describe the science idea that some types of matter
        changed from solid to liquid when heated and then back to solid when
        cooled.
      </p>
    </IdGeneratingComponent>
  );
}

export default G20_Prior_Knowledge_S1;
