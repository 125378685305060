import React from "react";

function T14_Make_Observations_S1() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className=" font-16">
              Find the Heating Matter data table in your science notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_06.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T14_Make_Observations_S1;
