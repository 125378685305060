import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P34_Prior_Knowledge_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P34"} seriesCount={"S2"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">2.</span>
        <span className="number-text">
          Invite some volunteers to share their arguments with the class. Ask:
        </span>
      </div>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          How did you describe the ways heating and cooling cause matter to
          change?
        </li>
        <li>What evidence did you use to support your claim?</li>
      </ul>
      <p className="pl-20">
        As a student shares their argument, engage the rest of the class by
        having them give a thumbs up or a thumbs down to show if they had
        something similar in their argument.
      </p>
    </IdGeneratingComponent>
  );
}

export default P34_Prior_Knowledge_S2;
