import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G4_Develop_Initial_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S3"}>
      <section className="text-wrapping-right">
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.A:</span>{" "}
        <div className="icon">
          <img
            src="/image/lesson-images/disciplinary_core_idea.svg"
            alt="disciplinary_core_idea"
            title="disciplinary_core_idea"
          ></img>
        </div>{" "}
        Listen for ideas related to different types of matter and their
        observable properties. Students will likely have ideas related to the
        need to reheat the glass to keep it flexible and the pole not melting
        because it is probably made of a different type of matter than the
        glass. It is OK if students do not make these connections immediately.
        These ideas will resurface more thoroughly later in the lesson.
      </section>
    </IdGeneratingComponent>
  );
}

export default G4_Develop_Initial_S3;
