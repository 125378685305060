import React, { useState } from "react";
import { useNavigate } from "react-router";

function HomePage(props) {
  const navigate = useNavigate();
  const [close, setclose] = useState(false);
  const [showpopup, setShowPopup] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [change, setChange] = useState(0);

  const ShowPopup = (_index) => {
    setShowPopup(true);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center landing-page">
          <div
            className="col-6 grades grade-2"
            onClick={() => {
              navigate("/grade2");
              props.setUpdateLesson(0);
            }}
          >
            <img
              className="cursor-pointer shadow-lg"
              src="/image/grade-2.png"
              alt="Grade 2"
              title="Grade 2"
            ></img>
          </div>
          <div
            className="col-6 grades grade-5"
            onClick={() => {
              navigate("/grade5");
              props.setUpdateLesson(0);
            }}
          >
            <img
              className="cursor-pointer shadow-lg"
              src="/image/grade-5.png"
              alt="Grade 5"
              title="Grade 5"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
