import React from "react";

function LessonResources() {
  return (
    <>
      <div>
        <div>
          <section id="observable">
            <p className="fw-700 mb-10 p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
              TEACHER RESOURCES
            </p>
            <p className="fw-700 mb-10">
              Lesson Resource 3.1: Observable Properties of Matter
            </p>
            <p className="mb-20">
              <span className="fw-700">Directions:</span> Describe each thing at
              the table.
            </p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <tbody>
                  <tr>
                    <td className="text-center align-middle fw-700">Color</td>
                    <td className="text-center align-middle">
                      <p className="mb-10">What color is it?</p>
                      <p>Is it see-through?</p>
                    </td>
                    <td className="text-center align-middle">
                      <img
                        className="w-50"
                        src="/image/lesson3_image6.png"
                        alt="What color is it? Is it see-through?"
                        title="What color is it? Is it see-through?"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle fw-700">State</td>
                    <td className="text-center align-middle">
                      <p className="mb-10">Is it a solid?</p>
                      <p>Is it a liquid?</p>
                    </td>
                    <td className="text-center align-middle">
                      <img
                        className="w-50"
                        src="/image/lesson3_image7.jpg"
                        alt="Is it a solid? Is it a liquid?"
                        title="Is it a solid? Is it a liquid?"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle fw-700">
                      Flexibility (only for solids)
                    </td>
                    <td className="text-center align-middle">Can it bend?</td>
                    <td className="text-center align-middle">
                      <img
                        className="w-50"
                        src="/image/lesson3_image8.jpg"
                        alt="Can it bend?"
                        title="Can it bend?"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle fw-700">Texture</td>
                    <td className="text-center align-middle">
                      <p className="mb-7">What does it feel like?</p>
                      <ul className="mb-20 pl-30 circle-listing">
                        <li>Rough</li>
                        <li>Smooth</li>
                        <li>Soft</li>
                        <li>Hard</li>
                        <li>Sticky</li>
                        <li>Wet</li>
                        <li>Bumpy</li>
                        <li>Squishy</li>
                        <li>Fluffy</li>
                      </ul>
                    </td>
                    <td className="text-center align-middle">
                      <img
                        className="w-50"
                        src="/image/lesson3_image9.jpg"
                        alt="What does it feel like?"
                        title="What does it feel like?"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle fw-700">
                      Hardness (only for solids)
                    </td>
                    <td className="text-center align-middle">
                      How hard is it?
                    </td>
                    <td className="text-center align-middle">
                      <img
                        className="w-50"
                        src="/image/lesson3_image10.jpg"
                        alt="How hard is it?"
                        title="How hard is it?"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section id="notebooka">
            <h5 className="fw-700 mt-10">
              Science Notebook Teacher’s Version: Lesson 3, Part A
            </h5>
            <h5 className="fw-700 mb-10">What is Happening in the Furnace?</h5>
            <p className="fw-700 mb-10 text-nexus-blue">INITIAL IDEAS</p>
            <p className="fw-600 mb-7">
              I think the glassblower kept putting the glass back into the
              furnace because…
            </p>
            <p className="mb-15 text-i">
              Accept all initial ideas. Note any students who have started to
              make the connection that the glassblower needs to keep the glass
              hot so that it stays flexible.
            </p>
            <p className="fw-600 mb-7">
              I think the pole did not melt in the furnace because…
            </p>
            <p className="mb-20 text-i">
              Accept all initial ideas. Note any students who have started to
              make the connection that the pole must be made of a different type
              of matter that does not change at the same temperature as glass.
            </p>
            <div className="mb-20 row justify-content-center">
              <div className="col-12 col-md-8">
                <img
                  src="/image/lesson3_image11.png"
                  alt="Glass blowing in a furnace"
                  title="Glass blowing in a furnace"
                />
              </div>
            </div>
          </section>
          <section id="notebookb">
            <h5 className="fw-700 mt-10">
              Science Notebook Teacher’s Version: Lesson 3, Part B
            </h5>
            <h5 className="fw-700 mb-10">
              How Does Temperature Affect Other Types of Matter?
            </h5>
            <p className="fw-700 mb-10 text-nexus-blue">OUR PLAN</p>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                We want to test these five objects:
              </span>
            </div>
            <p className="pl-20 mb-20 text-i">
              Students should select one object of each of the five types of
              matter: wax, wood, metal, plastic, and food.
            </p>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                We will heat these objects by:
              </span>
            </div>
            <div className="pl-20 mb-20 d-flex">
              <p className="mr-5">Placing them in hot water</p>
              <p className="text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Placing them under a clamp lamp
                </span>
              </p>
            </div>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">3.</span>
              <span className="number-text">
                After we heat the object, it will be safe for us to check:
              </span>
            </div>
            <div className="pl-20 mb-20 d-flex">
              <p className="mr-5">Hardness</p>
              <p className="pr-5 mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Color
                </span>
              </p>
              <p className="mr-5">Flexibility</p>
              <p className="mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Solid or liquid
                </span>
              </p>
            </div>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">4.</span>
              <span className="number-text">
                We will observe and record each object’s properties before and
                after testing the objects.
              </span>
            </div>
            <section className="mb-20 right-icon-text-group right-icon-text-group1">
              <div className="text">
                <p className="fw-700 pr-10 text-nexus-blue">CHECKPOINT</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/formative_assessment_opportunity.svg"
                  alt="formative-assessment-opportunity"
                  title="formative-assessment-opportunity"
                ></img>
              </div>
            </section>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Which is a property of ice cream?
              </span>
            </div>
            <div className="pl-20 mb-20">
              <p className="pr-5 mr-5 mb-10 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  a. Smooth
                </span>
              </p>
              <p className="mr-5 mb-10">b. Liquid</p>
              <p className="mr-5 mb-10">c. Rough</p>
              <p className="mr-5 mb-10">d. See-through</p>
            </div>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">Which is a property of a pen?</span>
            </div>
            <div className="pl-20 mb-20">
              <p className="mr-5 mb-10">a. Flexible</p>
              <p className="mr-5 mb-10">b. Sticky</p>
              <p className="mr-5 mb-10">c. Not hard</p>
              <p className="pr-5 mr-5 mb-10 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  d. Solid
                </span>
              </p>
            </div>
            <p className="fw-700 mb-10 text-nexus-blue">HEATING MATTER</p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle">Object</th>
                    <th className="text-center align-middle">Type of Matter</th>
                    <th className="text-center align-middle">
                      Observations{" "}
                      <span className="text-decoration-underline">Before</span>{" "}
                      Heating
                    </th>
                    <th className="text-center align-middle">
                      Observations{" "}
                      <span className="text-decoration-underline">After</span>{" "}
                      Heating
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle">Fork</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Plastic
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Sample: Solid, white, handle broken off, flexible, smooth,
                      pointy ends
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      No change
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Coin</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Metal
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Sample: Shiny, hard, penny, bronze, solid, and not
                      flexible
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      No change
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Block</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Wood
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Sample: Brown, solid, not flexible, hard, smooth,
                      square/cube, ends are pointy
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      No change
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Butter</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Food
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Sample: Yellow, flexible, not hard, sticky, smooth, and
                      square
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Melted into a liquid and is runny
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Crayon pieces</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Wax
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Sample: Blue, red, different shapes, not hard, not
                      flexible, smooth, and solid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Melted into a liquid, is runny, and the colors mixed a
                      little to make a purple color in some places.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="fw-700 mb-10 text-nexus-blue">ANALYZING MY DATA</p>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Heat affected the objects made of:
              </span>
            </div>
            <div className="pl-15 mb-20 d-flex">
              <p className="mr-5">Plastic</p>
              <p className="mr-5">Metal</p>
              <p className="pr-5 mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Food
                </span>
              </p>
              <p className="mr-5">Wood</p>
              <p className="mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Wax
                </span>
              </p>
            </div>
            <div className="mb-10 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                These objects changed from{" "}
                <span className="text-nexus-lightgrey">a solid</span> to{" "}
                <span className="text-nexus-lightgrey">a liquid.</span>
              </span>
            </div>
            <div className="mb-20 fw-600 d-flex guidence-count">
              <span className="number pr-5">3.</span>
              <span className="number-text">
                The{" "}
                <span className="text-nexus-lightgrey">
                  heat/high temperatures from the clamp lamp{" "}
                </span>{" "}
                caused these objects to change.{" "}
              </span>
            </div>
            <p className="fw-700 mb-10 text-nexus-blue">COOLING MATTER</p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle">Object</th>
                    <th className="text-center align-middle">
                      My Prediction{" "}
                      <span className="text-nexus-grey">
                        (What will happen to the matter when it is cooled?)
                      </span>
                    </th>
                    <th className="text-center align-middle">
                      Observations{" "}
                      <span className="text-decoration-underline">After</span>{" "}
                      Cooling
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle">Plastic fork</td>
                    <td
                      className="text-center align-middle text-nexus-lightgrey"
                      rowSpan={5}
                    >
                      Predictions will vary. Because these are predictions, do
                      not worry about incorrect ideas.
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      No change
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Metal coin</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      No change
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Wood block</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      No change
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Butter (food)</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Changed back into a solid
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">
                      Wax crayon pieces
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Changed back into a solid
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="fw-700 mb-10 text-nexus-blue">ANALYZING MY DATA</p>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Cooler temperature affected the objects made of:
              </span>
            </div>
            <div className="pl-15 mb-20 d-flex">
              <p className="mr-5">Plastic</p>
              <p className="mr-5">Metal</p>
              <p className="pr-5 mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Food
                </span>
              </p>
              <p className="mr-5">Wood</p>
              <p className="mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  Wax
                </span>
              </p>
            </div>
            <div className="mb-10 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                These objects changed from{" "}
                <span className="text-nexus-lightgrey">a liquid</span> to{" "}
                <span className="text-nexus-lightgrey">a solid.</span>
              </span>
            </div>
            <div className="mb-20 fw-600 d-flex guidence-count">
              <span className="number pr-5">3.</span>
              <span className="number-text">
                The{" "}
                <span className="text-nexus-lightgrey">
                  cooler temperatures
                </span>{" "}
                caused these objects to change.
              </span>
            </div>
          </section>
          <section id="notebookc">
            <h5 className="fw-700 mt-10">
              Science Notebook Teacher’s Version: Lesson 3, Part C
            </h5>
            <h5 className="fw-700 mb-10">
              How Do Higher and Lower Temperatures Affect Matter?
            </h5>
            <p className="fw-700 mb-10 text-nexus-blue">OBSERVE</p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle">Type of Matter</th>
                    <th className="text-center align-middle" colSpan={8}>
                      Observations at Each Temperature
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center align-middle"></th>
                    <th className="text-center align-middle">32˚F</th>
                    <th className="text-center align-middle">68˚F</th>
                    <th className="text-center align-middle">158˚F</th>
                    <th className="text-center align-middle">212˚F</th>
                    <th className="text-center align-middle">338˚F</th>
                    <th className="text-center align-middle">572˚F</th>
                    <th className="text-center align-middle">2,400˚F</th>
                    <th className="text-center align-middle">2,600˚F</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle">Glass</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Solid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Melts into flexible solid.
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Stays a flexible solid.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Plastic</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Solid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Melts into a liquid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Stays a liquid.
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Stays a liquid.
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Stays a liquid.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Wood</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Solid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Burns and changes into ash.
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Stays ash.
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Stays ash.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Metal</td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Solid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      NC
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Melts into a liquid.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Egg</td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Liquid
                    </td>
                    <td className="text-center align-middle text-nexus-lightgrey">
                      Cooks and changes to a solid.
                    </td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="fw-700 mb-10 text-nexus-blue">MAKE CONNECTIONS</p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle">Object</th>
                    <th className="text-center align-middle">
                      Changes Observed with Clamp Lamp
                    </th>
                    <th className="text-center align-middle">
                      Changes Observed with Simulation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle">Butter</td>
                    <td className="text-center align-middle">
                      Melts into a liquid at 100˚F
                    </td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Wax</td>
                    <td className="text-center align-middle">
                      Melts into a liquid at 100˚F
                    </td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Glass</td>
                    <td className="text-center align-middle">No change</td>
                    <td className="text-center align-middle">
                      Glass <span className="text-nexus-lightgrey">melts</span>{" "}
                      into a{" "}
                      <span className="text-nexus-lightgrey">
                        flexible solid
                      </span>{" "}
                      at <span className="text-nexus-lightgrey">2,400˚F</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Plastic</td>
                    <td className="text-center align-middle">No change</td>
                    <td className="text-center align-middle">
                      Plastic{" "}
                      <span className="text-nexus-lightgrey">melts</span> into a{" "}
                      <span className="text-nexus-lightgrey">liquid</span> at{" "}
                      <span className="text-nexus-lightgrey">338˚F.</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Wood</td>
                    <td className="text-center align-middle">No change</td>
                    <td className="text-center align-middle">
                      Wood <span className="text-nexus-lightgrey">burns</span>{" "}
                      into <span className="text-nexus-lightgrey">ash</span> at{" "}
                      <span className="text-nexus-lightgrey">572˚F.</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Metal</td>
                    <td className="text-center align-middle">No change</td>
                    <td className="text-center align-middle">
                      Metal <span className="text-nexus-lightgrey">melts</span>{" "}
                      into a{" "}
                      <span className="text-nexus-lightgrey">liquid</span> at{" "}
                      <span className="text-nexus-lightgrey">2,600˚F.</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">Egg</td>
                    <td className="text-center align-middle background-nexus-lightgrey"></td>
                    <td className="text-center align-middle">
                      Egg <span className="text-nexus-lightgrey">changed</span>{" "}
                      into a <span className="text-nexus-lightgrey">solid</span>{" "}
                      at <span className="text-nexus-lightgrey">158˚F</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="fw-700 mb-20 text-nexus-blue">FINAL IDEAS</p>
            <div className="mb-20 p-2 border">
              <p className="fw-700 text-center mb-10">Word Bank</p>
              <div className="d-flex justify-content-between">
                <p className="text-center flex-fill">heated</p>
                <p className="text-center flex-fill">solids</p>
                <p className="text-center flex-fill">liquids</p>
                <p className="text-center flex-fill">temperature</p>
                <p className="text-center flex-fill">change</p>
              </div>
            </div>
            <div className="mb-10 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                I saw that many types of matter became{" "}
                <span className="text-nexus-lightgrey">liquids</span> when the
                temperature was high enough.
              </span>
            </div>
            <div className="mb-10 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                When these types of matter cooled back down, I saw them change
                back into <span className="text-nexus-lightgrey">solids.</span>
              </span>
            </div>
            <div className="mb-10 fw-600 d-flex guidence-count">
              <span className="number pr-5">3.</span>
              <span className="number-text">
                The egg and wood were different. They could not change back
                after they were{" "}
                <span className="text-nexus-lightgrey">heated.</span>
              </span>
            </div>
            <div className="mb-20 fw-600 d-flex guidence-count">
              <span className="number pr-5">4.</span>
              <span className="number-text">
                I know that{" "}
                <span className="text-nexus-lightgrey">temperature</span> causes
                matter to <span className="text-nexus-lightgrey">change</span>{" "}
                in many different ways.
              </span>
            </div>
            <section className="mb-20 right-icon-text-group right-icon-text-group1">
              <div className="text">
                <p className="fw-700 pr-10 text-nexus-blue">CHECKPOINT</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/formative_assessment_opportunity.svg"
                  alt="formative-assessment-opportunity"
                  title="formative-assessment-opportunity"
                ></img>
              </div>
            </section>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Chocolate chips melt into a liquid when heated. What will happen
                when the liquid chocolate cools?
              </span>
            </div>
            <div className="pl-20 mb-20">
              <p className="mr-5 mb-10">a. The chocolate will burn.</p>
              <p className="mr-5 mb-10">b. The chocolate will stay a liquid.</p>
              <p className="pr-5 mr-5 mb-10 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  c. The chocolate will change back to a solid.
                </span>
              </p>
              <p className=" mr-5">d. The chocolate will disappear.</p>
            </div>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                A change to matter can always be undone.
              </span>
            </div>
            <div className="pl-20 mb-20 d-flex">
              <p className="mr-5">TRUE</p>
              <p className="pr-5 mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  FALSE
                </span>
              </p>
            </div>
            <h5 className="fw-700 mb-10">Can It Change Back?</h5>
            <p className="fw-700 mb-10 text-nexus-blue">MATCH</p>
            <p className="mb-10">
              Draw a line from each example to the type of change it shows.
            </p>
            <div className="mb-20 row">
              <div className="col-12 col-md-7">
                <img src="/image/lesson3_image12.jpg"></img>
              </div>
            </div>
            <p className="fw-700 mb-10 text-nexus-blue">
              MY ARGUMENT: HOW DOES TEMPERATURE AFFECT MATTER?
            </p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <tbody>
                  <tr>
                    <th className="text-center align-middle">Butter</th>
                    <td className="text-center align-middle">
                      Heating and cooling can cause matter to change. These
                      changes are sometimes{" "}
                      <span className="text-nexus-lightgrey">reversible</span>{" "}
                      and other times{" "}
                      <span className="text-nexus-lightgrey">
                        irreversible.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-center align-middle">My Evidence</th>
                    <td className="text-left align-middle">
                      <p className="mb-10">
                        Plastic, metal, wax, and butter change from{" "}
                        <span className="text-nexus-lightgrey">solid</span> to{" "}
                        <span className="text-nexus-lightgrey">liquid</span>{" "}
                        when heated.
                      </p>
                      <p className="mb-10">
                        These types of matter were cooled. This caused them to
                        change from{" "}
                        <span className="text-nexus-lightgrey">liquids</span>{" "}
                        back into{" "}
                        <span className="text-nexus-lightgrey">solids.</span>
                      </p>
                      <p className="mb-10">
                        This is an example of{" "}
                        <span className="text-nexus-lightgrey">reversible</span>{" "}
                        change.
                      </p>
                      <p className="mb-10">
                        I saw irreversible changes when the{" "}
                        <span className="text-nexus-lightgrey">egg</span> and{" "}
                        <span className="text-nexus-lightgrey">wood</span> were
                        heated.
                      </p>
                      <p className="mb-10">
                        High temperatures{" "}
                        <span className="text-nexus-lightgrey">caused</span> the
                        observable properties of matter to change. These changes
                        could not be undone.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <section className="mb-20 right-icon-text-group right-icon-text-group1">
              <div className="text">
                <p className="fw-700 pr-10 text-nexus-blue">CHECKPOINT</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/formative_assessment_opportunity.svg"
                  alt="formative-assessment-opportunity"
                  title="formative-assessment-opportunity"
                ></img>
              </div>
            </section>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                An ice cube melts. This is an irreversible change.
              </span>
            </div>
            <div className="pl-15 mb-20 d-flex">
              <p className="mr-5">TRUE</p>
              <p className="pr-5 mr-5 text-nexus-grey">
                <span className="border py-1 pl-10 pr-10 rounded-pill">
                  FALSE
                </span>
              </p>
            </div>
            <div className="mb-7 fw-600 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                How do you feel about your understanding of reversible and
                irreversible changes?
              </span>
            </div>
            <div className="mb-20 d-flex hand-symbol-checks">
              <div className="checks">
                <p className="w-100 mb-10">I feel unsure</p>
                <img src="/image/lesson-images/thumb_down.svg"></img>
              </div>
              <div className="checks">
                <p className="w-100 mb-10">I feel okay</p>
                <img src="/image/lesson-images/thumb_middle.svg"></img>
              </div>
              <div className="checks">
                <p className="w-100 mb-10">I feel great</p>
                <img src="/image/lesson-images/thumb_up.svg"></img>
              </div>
            </div>
            <p className="text-nexus-lightgrey">
              Use this self-assessment to touch base with students who are
              unsure about their understanding of reversible and irreversible
              changes. Remediate as needed.
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default LessonResources;
