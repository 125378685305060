import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P14_Make_Observations_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"P14"} seriesCount={"S3"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">13.</span>
        <span className="number-text">
          Continue playing the video. Encourage students to focus on the
          observable changes to each type of matter. Play the video a second
          time, if needed.
        </span>
      </div>
      <p className="pl-25">
        Direct students to record their observations in the “Observations{" "}
        <span className="text-decoration-underline">After</span> Heating” column
        of the data table. Next, ask them to answer the questions in the
        Analyzing My Data section individually (page 8).
      </p>
    </IdGeneratingComponent>
  );
}

export default P14_Make_Observations_S3;
