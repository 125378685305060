import React, { useState } from "react";
import Header from "./Common/Header";
import LeftNav from "./Menu/grade5/LeftNav";
import { useNavigate, Outlet, Route } from "react-router";
import Login from "./Authentication/Login";
import { useEffect } from "react";

function Root_5(props) {
  const navigate = useNavigate();

  const [menuclose, setMenuClose] = useState(false);
  //const [isauthenticated, setIsAuthenticated] = useState(isLoggedIn);

  // useEffect(() => {
  //   setIsAuthenticated(isLoggedIn);
  // }, []);

  // function isLoggedIn() {
  //   if (btoa(sessionStorage.getItem("isLoggedIn")) == "true") return true;
  //   else return navigate("/login");
  // }

  //  !isauthenticated ? (
  //   <Login />
  // ) : (
  return (
    <>
      <Header />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <LeftNav
              menuclose={menuclose}
              setMenuClose={setMenuClose}
              {...props}
            />
            <main
              className={
                menuclose === false
                  ? "col-md-9 ms-sm-auto col-lg-10 p-0"
                  : "col-md-9 ms-sm-auto col-lg-10 p-0 full-width"
              }
            >
              <section className="content">
                {window.location.pathname != "/dashboard" ? (
                  <>
                    <div className="mb-10 unit-name">
                      <h5 className="fw-700">
                        How can we explain the patterns we notice in the sky?
                      </h5>
                    </div>
                  </>
                ) : null}
                <div className="content-box">
                  <Outlet />
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </>
  );
  // );
}

export default Root_5;
