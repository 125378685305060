import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G15_Make_Predictions() {
  return (
    <IdGeneratingComponent guideLineCount={"G15"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon">
          <img
            src="/image/lesson-images/disciplinary_core_idea.svg"
            alt="disciplinary_core_idea"
            title="disciplinary_core_idea"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.A:</span> Listen for
        students to describe the following changes to the objects under the
        clamp lamp:
      </section>
      <ul className="pl-30 pb-10 circle-listing">
        <li>The butter and crayon pieces melted from a solid into a liquid.</li>
        <li>
          The coin, wood block, plastic fork’s observable properties did not
          change.
        </li>
      </ul>
      <p>
        As students begin to consider the effect of colder temperatures,
        encourage them to use their prior experiences with cooling or colder
        temperatures to help inform their predictions.
      </p>
    </IdGeneratingComponent>
  );
}

export default G15_Make_Predictions;
