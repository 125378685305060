import React from "react";

function T1_Connect_to_Prior_Knowledge() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What did we figure out about stars in the last lesson?</li>
                <li>
                  Why do some stars in the night sky appear larger than others?
                </li>
                <li>
                  What are some reasons that some stars appear brighter than
                  others?
                </li>
                <li>What did we decide to investigate next?</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T1_Connect_to_Prior_Knowledge;
