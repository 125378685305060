import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G4_Develop_Initial_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S2"}>
      <section className="text-wrapping-right">
        <span className="fw-700">Differentiation:</span> Provide opportunities
        for students to use multiple modalities such as writing and drawing to
        document their thinking. Students with limited written language skills
        may benefit from the opportunity to use oral language skills and
        gestures to describe their ideas.
      </section>
    </IdGeneratingComponent>
  );
}

export default G4_Develop_Initial_S2;
