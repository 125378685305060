import React from "react";

function PartB() {
  return (
    <>
      <div className="lesson3-parts parta">
        <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
          PART B: INVESTIGATE HEATING AND COOLING
        </p>
        <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
            MATERIALS FOR PART B
          </h5>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                TEACHER
              </p>
              <ul className="pl-20 circle-listing">
                <li>
                  Digital Lesson 3*:
                  <ul className="pt-5 pl-20 mb-5 circle-listing">
                    <li>
                      Heating and Cooling Different Types of Matter videos
                    </li>
                    <li>Our Plan Class Chart</li>
                  </ul>
                </li>
              </ul>
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                CLASS
              </p>
              <ul className="pl-20 circle-listing">
                <li>
                  Food objects
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>
                      1 TBSP Butter<sup>‡</sup>
                    </li>
                    <li>
                      5 Chocolate chips<sup>‡</sup>
                    </li>
                  </ul>
                </li>
                <li>
                  5 Lesson Resource 3:{" "}
                  <span className="text-i">
                    Observable Properties Checklist*
                  </span>
                </li>
                <li>
                  Metal objects
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>
                      3 Coins<sup>‡</sup>
                    </li>
                    <li>
                      3 Paper clips<sup>‡</sup>
                    </li>
                    <li>2 Washers</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <ul>
                <li>
                  Plastic objects
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>
                      3 Marker caps<sup>‡</sup>
                    </li>
                    <li>
                      2 Plastic forks<sup>‡</sup>
                    </li>
                  </ul>
                </li>
                <li>
                  Wax objects
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>2 Candles</li>
                    <li>
                      5 Crayons<sup>‡</sup>
                    </li>
                  </ul>
                </li>
                <li>
                  Wood objects
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>2 Craft sticks</li>
                    <li>
                      2 Pencils<sup>‡</sup>
                    </li>
                    <li>2 Wood blocks</li>
                  </ul>
                </li>
              </ul>
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                STUDENT
              </p>
              <ul className="mb-20 pl-20 circle-listing">
                <li>1 Science Notebook*</li>
              </ul>
              <p className="font-13">*Accessible online</p>
              <p className="font-13">† Prepared by the teacher</p>
              <p className="font-13">‡ Needed but not supplied</p>
            </div>
          </div>
        </section>
        <h5 className="fw-700 mb-10 text-decoration-underline">PREPARATION</h5>
        <p className="fw-700 mb-7">
          Part <span className="text-nexus-blue">B</span>{" "}
          <span className="fw-400">(20 minutes total)</span>
        </p>
        <p className="mb-7">
          <span className="fw-700">Session 1</span> (15 minutes)
        </p>
        <ul className="mb-10 pl-20 number-listing">
          <li>
            Make five copies of Lesson Resource 3:{" "}
            <span className="text-i">Observable Properties Checklist.</span>
          </li>
          <li>
            Gather items that are not supplied: a tablespoon of butter, five or
            more chocolate chips, a couple plastic forks, marker tops, crayons,
            paper clips, coins, and pencils. If possible, locate other items
            made of wood, plastic, food, wax, and metal for students to observe.
          </li>
          <li>
            Obtain the metal washers, candles, craft sticks, and wooden blocks
            from the kit.
          </li>
          <li>
            Set up five stations, each representing one type of matter. At each
            station, place one copy of Lesson Resource 3:{" "}
            <span className="text-i">Observable Properties Checklist</span> and
            all the samples of one type of matter. Number the stations so you
            can refer to them during the lesson without identifying the types of
            matter. A sample station setup follows:
            <ul className="pl-20 mt-10 circle-listing">
              <li>Station 1: Metal objects</li>
              <li>Station 2: Wax objects</li>
              <li>Station 3: Plastic objects</li>
              <li>Station 4: Wood objects</li>
              <li>Station 5: Food objects</li>
            </ul>
          </li>
          <li>Prepare to present Digital Lesson 3.</li>
          <li>Ensure that students have access to their science notebooks.</li>
        </ul>
        <p className="mb-7">
          <span className="fw-700">Session 2</span> (5 minutes)
        </p>
        <ul className="pl-20 number-listing">
          <li>Prepare to present Digital Lesson 3.</li>
          <li>Ensure that students have access to their science notebooks.</li>
        </ul>
      </div>
    </>
  );
}

export default PartB;
