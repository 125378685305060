import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G32_Checkpoint() {
  return (
    <IdGeneratingComponent guideLineCount={"G32"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative-assessment-opportunity"
            title="formative-assessment-opportunity"
          ></img>
        </div>
        <span className="fw-700">Formative Assessment:</span> Use this
        checkpoint to assess students’ understanding of the following science
        ideas before moving on:
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          Sometimes changes to matter are reversible and sometimes they are not.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G32_Checkpoint;
