import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P26_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"P26"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">9.</span>
        <span className="number-text">
          <span className="fw-700">Navigate to the Next Session:</span> Say,
          “We've figured out that heating and cooling different types of matter
          can cause changes to its observable properties. Sometimes these
          changes can be undone, but other times they cannot. If you remember,
          our unit-driving question is, “How do objects change into other
          objects.” Ask:
        </span>
      </p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          How does our new understanding about temperature help us answer this
          question?
        </li>
        <li>
          It seems like we are not completely sure about whether the changes to
          different types of matter can be undone or not. Should we investigate
          this a little more?
        </li>
      </ul>
      <p className="pl-20">
        After time for all students to share their ideas, say, “It sounds like
        most of us think we should analyze or look at the changes we observed in
        matter more closely. Let's do that in the next session.
      </p>
    </IdGeneratingComponent>
  );
}

export default P26_Navigate;
