import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G14_Make_Observations_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"G14"} seriesCount={"S3"}>
      <section className="text-wrapping-right mb-20">
        <div className="icon icon-big">
          <img
            src="/image/lesson-images/settings.svg"
            alt="settings"
            title="settings"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="text-nexus-blue fw-700">
          Planning and Carrying Out Investigations,
        </span>{" "}
        <span className="text-nexus-orange fw-700">PS1.A,</span>{" "}
        <span className="text-nexus-green fw-700">Cause and Effect:</span>{" "}
        Students carry out an investigation using media to determine how heat
        affects the observable properties of different types of matter. In grade
        2, students identify that heat/higher temperature (cause) can change
        some types of matter from solid to liquid (effect).
      </section>
      <section className="guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            ELA Connection <span className="text-nexus-lightgrey">(A,B,C)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p>
            Students gather information from their investigation to answer
            questions. (W.2.8)
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G14_Make_Observations_S3;
