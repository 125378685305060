import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P18_Make_Personal() {
  return (
    <IdGeneratingComponent guideLineCount={"P18"}>
      <p className="d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">17.</span>
        <span className="number-text">
          <span className="fw-700">Make Personal Connections:</span> Invite
          students to share experiences they have had with things melting into a
          liquid when heated and changing into a solid when cooled.
        </span>
      </p>
    </IdGeneratingComponent>
  );
}

export default P18_Make_Personal;
