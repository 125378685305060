import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G5_Gather_Evidence() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G5"}>
        <section className="mb-10 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/disciplinary_core_idea.svg"
              alt="disciplinary_core_idea"
              title="disciplinary_core_idea"
            ></img>
          </div>
          <span className="fw-700">NGSS Element:</span>{" "}
          <span className="text-nexus-orange fw-700">ESS1.B:</span> Students
          begin to develop an understanding of Earth’s revolution around the Sun
          as they put together their ideas from the models and the infographic.
        </section>
        <section className="mb-10 guidelines-blue-section">
          <div className="title">
            <p className="fw-700">
              ELA Connection{" "}
              <span className="text-nexus-lightgrey">(A,B,C)</span>
            </p>
          </div>
          <div className="p-2 text">
            <p>
              When they engage with the infographic, students draw on
              information from print sources to demonstrate the abililty to
              locate the answer to the question of which objects move in the
              solar system. (RI.5.7)
            </p>
          </div>
        </section>
        <section className="text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/differentiaton.svg"
              alt="differentiaton"
              title="differentiaton"
            ></img>
          </div>
          <span className="fw-700">Differentiation:</span> Some students may
          prefer to read and analyze this information individually. Others may
          benefit from having the infographic read aloud to them by another
          person or by a text-to-speech feature. Conduct this step using the
          option that best meets the needs of your students.
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G5_Gather_Evidence;
