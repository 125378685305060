import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G18_Collect_Data_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G18"} seriesCount={"S3"}>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>
          <span className="fw-700">Formative Assessment Opportunity:</span> Look
          for students to:
        </section>
        <ul className="pl-30 circle-listing">
          <li>Draw a model that includes Earth, the Sun, and other stars.</li>
          <li>
            Depict Earth’s revolution and indicate the stars that are visible
            from different places.
          </li>
          <li>
            Explain how Earth’s revolution causes us to see different stars in
            the sky throughout the year.
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G18_Collect_Data_S3;
