import React, { useEffect, useState } from "react";
import FormikQuill from "../../../../Common/FormikQuill";

function T4_Develop_Initial_S5() {
  const LessonData = {
    stt_4_5: "",
    stt_4_6: "",
  };

  const [data, setData] = useState(LessonData);
  const onBlur = () => {};

  useEffect(() => {
    var fetch_data = localStorage.getItem("Lesson_Data");

    if (fetch_data != null || undefined) {
      const Lesson_Data = JSON.parse(localStorage.getItem("Lesson_Data"));
      setData(Lesson_Data);
    } else {
      localStorage.setItem("Lesson_Data", JSON.stringify(data));
    }
  }, []);

  const UpdateText = (name, val) => {
    name == "stt_4_5" && setData({ ...data, ["stt_4_5"]: val });
    name == "stt_4_6" && setData({ ...data, ["stt_4_6"]: val });
  };

  useEffect(() => {
    localStorage.setItem("Lesson_Data", JSON.stringify(data));
  }, [data]);
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class-discussion"
              title="class-discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Think about these questions. Share your ideas with the class.
            </p>
            <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What ideas do you have about what the pole is made of?</li>
                <li>Why do you think this?</li>
              </ul>
            </div>
            <div>
              <img
                src="/image/lesson-images/Initial_Ideas_Class_Chart.svg"
                alt="Initial Ideas Class Chart"
                title="Initial Ideas Class Chart"
              />
            </div>
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle">
                      Our Ideas About the Glass
                    </th>
                    <th className="text-center align-middle">
                      Our Ideas About the Pole
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="w-50">
                      <div className="text-to-speech-section text-to-speech-section-full">
                        <div className="text-to-speech-box">
                          <div className="text-to-speech-section-box left border-0">
                            <div className="text-to-speech-box-content p-0">
                              <div className="notepad-outer">
                                <div className="notepad-content table-break-line">
                                  {["stt_4_5"].map((name) => (
                                    <FormikQuill
                                      displayTextBarLocation={"top"}
                                      id={"stt_4_5"}
                                      name={name}
                                      onChange={UpdateText}
                                      onBlur={onBlur}
                                      value={data["stt_4_5"]}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="w-50">
                      <div className="text-to-speech-section text-to-speech-section-full">
                        <div className="text-to-speech-box">
                          <div className="text-to-speech-section-box left border-0">
                            <div className="text-to-speech-box-content p-0">
                              <div className="notepad-outer">
                                <div className="notepad-content table-break-line">
                                  {["stt_4_6"].map((name) => (
                                    <FormikQuill
                                      displayTextBarLocation={"top"}
                                      id={"stt_4_6"}
                                      name={name}
                                      onChange={UpdateText}
                                      onBlur={onBlur}
                                      value={data["stt_4_6"]}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T4_Develop_Initial_S5;
