import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G14_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G14"} seriesCount={"S2"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/scaffolding.svg"
            alt="scaffolding"
            title="scaffolding"
          ></img>
        </div>
        <span className="fw-700">Scaffolding:</span>{" "}
        <span>
          The class may benefit from you modeling how to fill in the table. If
          needed, draw the table on the board or a sheet of chart paper. Ask
          students to share their observations and record their ideas on the
          board. Students should then copy the information in the table in their
          science notebooks.
        </span>
      </section>
    </IdGeneratingComponent>
  );
}

export default G14_Make_Observations_S2;
