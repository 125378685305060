const LessonData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How can we explain the patterns we notice in the sky?",
    Lessons: [
      {
        LessonNumber: "Lesson 1",
        LessonName: "What patterns do we notice in the sky? ",
        ImageUrl: "/image/gr5_l3_mod_tile_1.png",
        Lessonurl: "",
      },
      {
        LessonNumber: "Lesson 2",
        LessonName: "Why do some stars look bigger and brighter than others?",
        ImageUrl: "/image/gr5_l3_mod_tile_1.png",
        Lessonurl: "",
      },
      {
        LessonNumber: "Lesson 3",
        LessonName:
          "Why do we see different stars in the summer than in the winter?",
        ImageUrl: "/image/gr5_l3_mod_tile_1.png",
        Lessonurl: "/grade5/view-planning",
      },
      {
        LessonNumber: "Lesson 4",
        LessonName:
          "Where do the Sun, other stars, and the Moon go when we cannot see them?",
        ImageUrl: "/image/gr5_l3_mod_tile_1.png",
        Lessonurl: "",
      },
      {
        LessonNumber: "Lesson 5",
        LessonName: "How can we explain the patterns we notice in the sky?",
        ImageUrl: "/image/gr5_l3_mod_tile_1.png",
        Lessonurl: "",
      },
    ],
  },
];

export default LessonData;
