import React from "react";

function T12_Collect_Represent_and_Analyze_Data_S1() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="font-16">
              Complete the Collect Data and Represent Data sections of your
              notebook with your group.
            </p>
          </div>
        </section>
        <div className="row">
          <div className="col-6">
            <div className="shadow">
              <img
                src="/image/g5_space_notebookl3_forpoc_6-15-234_page_14.png"
                alt="g5_space_notebookl3_forpoc_6-15-234_page_14"
                title="g5_space_notebookl3_forpoc_6-15-234_page_14"
              ></img>
            </div>
          </div>
          <div className="col-6">
            <div className="shadow">
              <img
                src="/image/g5_space_notebookl3_forpoc_6-15-234_page_15.png"
                alt="g5_space_notebookl3_forpoc_6-15-234_page_15"
                title="g5_space_notebookl3_forpoc_6-15-234_page_15"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T12_Collect_Represent_and_Analyze_Data_S1;
