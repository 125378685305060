import HighlightPop from "./HighLight";
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { fromRange } from "xpath-range";

function generateId(guideLineCount, seriesCount) {
  if (guideLineCount && seriesCount) {
    return guideLineCount + "S" + seriesCount;
  } else if (guideLineCount) {
    return guideLineCount;
  }
}

function GetDataFromLocalStorage() {
  var data = JSON.parse(localStorage.getItem("userAnnotationsCreated"));
  if (data) return data;
  else return [];
}

const HighLightComponent = forwardRef((props, ref) => {
  const [userAnnotationsCreated, setUserAnnotationsCreated] = useState(
    GetDataFromLocalStorage
  );
  useImperativeHandle(ref, () => ({
    removePopUp() {
      if (popUpRef && popUpRef.current) popUpRef.current.hidePopover();
    },
  }));
  let uniqueId = null; //generateId(props.guideLineCount, props.seriesCount);
  const popUpRef = useRef(null);
  function getSelection() {
    if (window.getSelection) {
      return window.getSelection();
    }

    if (document.selection) {
      return document.selection;
    }

    return null;
  }

  function updateMark(xpath, typeDetails) {
    let _element;

    if (typeDetails.highlightId)
      _element = document.getElementById(typeDetails.highlightId);
    else if (uniqueId) _element = document.getElementById(uniqueId);

    if (_element) {
      var element = document.evaluate(
        xpath.start,
        _element,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (element != null) {
        const newItem = document.createElement("span");

        let _text = element.nodeValue;
        if (typeDetails.type === "comment") {
          newItem.innerHTML =
            _text.slice(0, xpath.startOffset) +
            `<span class="commentHighlighter font-16" title= ` +
            " " +
            typeDetails.commentDetails.replace(/[ ]/g, "\u00a0") +
            " " +
            `>` +
            _text.slice(xpath.startOffset, xpath.endOffset) +
            `</span>` +
            _text.slice(xpath.endOffset, _text.length);
        } else {
          newItem.innerHTML =
            _text.slice(0, xpath.startOffset) +
            `<span class="text-highlight font-16">` +
            _text.slice(xpath.startOffset, xpath.endOffset) +
            `</span >` +
            _text.slice(xpath.endOffset, _text.length);
        }
        element.parentNode?.replaceChild(newItem, element);
        return element;
      }
    }
  }

  useEffect(() => {
    let available = JSON.parse(localStorage.getItem("userAnnotationsCreated"));
    let filteredData = available; // []
    /* if (available)
            filteredData = available.filter(data => data.page === updatelesson) */
    if (filteredData && filteredData.length) {
      for (const element of filteredData) {
        updateMark(element, element);
      }
    }
  }, []);

  function promptTheData() {
    if (window.getSelection().rangeCount > 0) {
      let commentDetails = prompt("Please enter your comment");
      if (commentDetails != null) {
        onHighlightAction("comment", commentDetails);
      }
    }
  }

  const UnHighlightAction = () => {
    if (popUpRef && popUpRef.current) popUpRef.current.hidePopover();
    const selection = window.getSelection();
    const selectionData = window.getSelection().toString();
    if (selection && selection.anchorNode) {
      const selectedElement = selection.anchorNode.parentElement;
      if (selectedElement.classList.contains("text-highlight")) {
        selectedElement.classList.remove("text-highlight");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      } else if (selectedElement.classList.contains("commentHighlighter")) {
        selectedElement.classList.remove("commentHighlighter");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      } else if (
        selectedElement.classList.contains("text-highlight") &&
        selectedElement.classList.contains("commentHighlighter")
      ) {
        selectedElement.classList.remove("text-highlight");
        selectedElement.classList.remove("commentHighlighter");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      }
    }
  };

  function onHighlightAction(type, commentDetails) {
    if (popUpRef && popUpRef.current) popUpRef.current.hidePopover();

    let text = (getSelection() && getSelection().toString()) || "";
    if (!text || text === " ") {
      return;
    }
    function findAncestor(el, cls) {
      while ((el = el.parentElement) && !el.id);
      return el.id;
    }
    const selection = getSelection();
    if (selection.rangeCount > 0) {
      let content;
      let getId;
      const range = selection.getRangeAt(0);
      if (uniqueId) content = document.getElementById(uniqueId);
      else {
        getId = findAncestor(window.getSelection().anchorNode.parentNode);
        content = document.getElementById(getId);
      }
      let xpath = null;
      if (content) {
        xpath = fromRange(range, content);
      }
      let start;
      let end;
      if (getId) {
        start = "//*[@id='" + getId + "']/" + xpath.start;
        end = "//*[@id='" + getId + "']/" + xpath.end;
        xpath = { ...xpath, start, end };
      } else if (uniqueId) {
        start = "//*[@id='" + uniqueId + "']/" + xpath.start;
        end = "//*[@id='" + uniqueId + "']/" + xpath.end;
        xpath = { ...xpath, start, end };
      }

      if (xpath) {
        try {
          let highlightId;
          if (getId) {
            highlightId = getId;
          } else if (uniqueId) {
            highlightId = uniqueId;
          }
          let _userAnnotations = GetDataFromLocalStorage();
          let newDetails = {
            page: props.point,
            text,
            type: type,
            highlightId,
            ...xpath,
          };
          if (type === "comment") {
            newDetails.commentDetails = commentDetails;
          }
          _userAnnotations.push(newDetails);

          updateMark(xpath, newDetails);
          setUserAnnotationsCreated(_userAnnotations);
          localStorage.setItem(
            "userAnnotationsCreated",
            JSON.stringify(_userAnnotations)
          );
        } catch (e) {
          console.error("user highlight failed: ", e);
        }
      }
    }
  }

  return (
    <div id={uniqueId}>
      <HighlightPop
        ref={popUpRef}
        popoverItems={(itemClass) => (
          <React.Fragment>
            <span className={itemClass} onClick={promptTheData}>
              <img
                src="/image/Annotation.svg"
                width="20px"
                height="20px"
                style={{ padding: "0px 5px" }}
              />
              Annotate
            </span>
            <span
              className={itemClass}
              onClick={() => onHighlightAction("Highlight")}
            >
              <img
                src="/image/Highlighting.svg"
                width="20px"
                height="20px"
                style={{ padding: "0px 5px" }}
              />
              Highlight
            </span>
            <span className={itemClass} onClick={UnHighlightAction}>
              <img
                src="/image/highlight-remove.svg"
                width="20px"
                height="20px"
                style={{ padding: "0px 5px" }}
              />
              Remove
            </span>
            {/* <span
                            className={itemClass} onClick={() => onHighlightAction("remove")}>
                            Remove
                        </span> */}
          </React.Fragment>
        )}
      >
        {props.children}
      </HighlightPop>
    </div>
  );
});

export default HighLightComponent;
