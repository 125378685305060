import React from "react";

function T14_Make_Observations_S3() {
  return (
    <>
      <div className="mb-20 lesson-brief">
        <div className="row">
          <div className="col-12 col-md-6">
            <section className="icon-text-group">
              <div className="icon">
                <img
                  src="/image/lesson-images/notebook.svg"
                  alt="notebook"
                  title="notebook"
                ></img>
              </div>
              <div className="text">
                <p className="mb-10">Watch the Heating Matter video.</p>
                <p className="mb-10">
                  Record your observations in the "Type of Matter" and
                  "Observations{" "}
                  <span className="text-decoration-underline">Before</span>{" "}
                  Heating" columns of the data table.
                </p>
                <p className="">
                  Continue watching the video. What changes do you observe?
                </p>
              </div>
            </section>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-20 video-section">
              <video controls>
                <source
                  src="/videos/lesson3-videos/nex_gr2_glassblowing_heatingmatter_video.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9">
                <div className="mb-20 video-section">
                  <video controls>
                    <source
                      src="/videos/lesson3-videos/nex_gr2_glassblowing_heatingmatter_video.mp4"
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="lesson-brief">
        <div className="row">
          <div className="col-12 col-md-6">
            <section className="icon-text-group">
              <div className="icon">
                <img
                  src="/image/lesson-images/notebook.svg"
                  alt="notebook"
                  title="notebook"
                ></img>
              </div>
              <div className="text">
                <p className="mb-10 mt-5 font-16">
                  Record your observations in the “Observations{" "}
                  <span className="text-decoration-underline">After</span>{" "}
                  Heating” column of the Heating Matter data table.
                </p>
                <p className="mb-20">
                  Next, complete the Analyzing My Data section of your notebook.
                </p>
              </div>
            </section>
          </div>
          <div className="col-12 col-md-6">
            <div className="row justify-content-center">
              <div className="col-12 col-md-9">
                <div className="shadow">
                  <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_07.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T14_Make_Observations_S3;
