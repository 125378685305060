import React from "react";

function T5_Gather_Evidence() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Read the{" "}
              <span className="text-i">Movement in the Solar System</span>{" "}
              infographic.
            </p>
          </div>
        </section>
        <div className="mb-20 row">
          <div className="col-6">
            <div className="shadow">
              <img src="/image/g5_space_notebookl3_forpoc_6-15-234_page_10.png"></img>
            </div>
          </div>
          <div className="col-6">
            <div className="shadow">
              <img src="/image/gG5_space_notebookl3_forpoc_6-15-234_page_11.png"></img>
            </div>
          </div>
        </div>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p>
              Complete the Gather Evidence and Reflect sections of your science
              notebook.
            </p>
          </div>
        </section>
        <div className="row">
          <div className="col-6">
            <div className="shadow">
              <img src="/image/G5_Space_NotebookL3_ForPOC_6-15-23 (4)_Page_12.png"></img>
            </div>
          </div>
          <div className="col-6">
            <div className="shadow">
              <img src="/image/G5_Space_NotebookL3_ForPOC_6-15-23 (4)_Page_13.png"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T5_Gather_Evidence;
