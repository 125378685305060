import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P21_Navigate() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P21"}>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">8.</span>
          <span className="number-text">
            <span className="fw-700">Navigate to the Next Lesson:</span> Say,
            “We now know that the Sun doesn’t move in space, and we have gained
            a new understanding about the movement of Earth around the Sun. Do
            you think the Sun really moves across the sky? What about the stars
            and the Moon? Where do they go when we cannot see them? Should we
            gather evidence to answer these questions?”
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P21_Navigate;
