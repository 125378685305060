import React from "react";

function T36_Initial_Ideas() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Discuss these questions with a partner:
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What type of matter do you now think the pole made of?</li>
                <li>
                  How would you use evidence from your investigations to explain
                  why the pole didn't melt in the hot furnace?
                </li>
                <li>
                  Why did the glassblower keep putting the glass back into the
                  furnace?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T36_Initial_Ideas;
