import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P14_Earn_Words() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P14"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">9.</span>
          <span className="number-text">
            <span className="fw-700">Earn Words:</span> Explain that an
            imaginary line runs through the center of Earth from top (the North
            Pole) to bottom (the South Pole). This is known as the axis, and
            Earth is tilted at an angle at this line. Ask:
          </span>
        </div>
        <ul className="pl-45 mb-10 circle-listing">
          <li>If you were a planet, where would your axis be?</li>
        </ul>
        <p className="pl-20">
          Add the term “axis” to the Word Wall. Students have now earned this
          word and can begin to integrate it into their everyday language.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P14_Earn_Words;
