import React from "react";

function T27_Prior_Knowledge_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <p>
                With your class, use the number line to organize the data you
                collected in the last session.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T27_Prior_Knowledge_S2;
