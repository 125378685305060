import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G38_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"G38"}>
      {/*  <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G38_Navigate;
