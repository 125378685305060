import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G25_Checkpoint() {
  return (
    <IdGeneratingComponent guideLineCount={"G25"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative-assessment-opportunity"
            title="formative-assessment-opportunity"
          ></img>
        </div>
        <span className="fw-700">Formative Assessment:</span> Use this
        checkpoint to assess students' understanding of the following science
        ideas before moving on:
      </section>

      <ul className="pl-30 circle-listing">
        <li>
          Different kinds of matter exist and many of them can be either solid
          or liquid, depending on the temperature.
        </li>
        <li>
          Heating or cooling a substance may cause changes that can be observed.
          Sometimes these changes can be undone and sometimes they cannot.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G25_Checkpoint;
