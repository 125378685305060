import React from "react";

function PartA() {
  return (
    <>
      <div className="lesson3-parts parta">
        <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
          PART A: INITIAL IDEAS
        </p>
        <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
            MATERIALS FOR PART A
          </h5>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                TEACHER
              </p>
              <ul className="pl-20 circle-listing">
                <li>
                  Digital Lesson 3*:
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>Summer and Winter Night Skies image</li>
                    <li>
                      Initial Ideas: Different Stars in the Winter and the
                      Summer Class Chart
                    </li>
                    <li>What Is In Our Solar System? interactive</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                STUDENT
              </p>
              <ul className="mb-20 pl-20 circle-listing">
                <li>1 Device (tablet, laptop, etc.)</li>
                <li>
                  Digital Resource:
                  <ul className="pl-20 circle-listing">
                    <li>What Is in Our Solar System? interactive*</li>
                  </ul>
                </li>
                <li>1 Science Notebook*</li>
              </ul>
              <p className="font-13">
                <sup>*</sup> Accessible online
              </p>
              <p className="font-13">
                <sup>†</sup> Prepared by the teacher
              </p>
              <p className="font-13">
                <sup>‡</sup> Needed but not supplied
              </p>
            </div>
          </div>
        </section>
        <h5 className="fw-700 mb-10 text-decoration-underline">PREPARATION</h5>
        <p className="fw-700 mb-7">
          Part <span className="text-nexus-blue">A</span> (5 minutes)
        </p>
        <ul className="pl-20 number-listing">
          <li>Prepare to present Digital Lesson 3.</li>
          <li>Ensure that students have access to their science notebooks.</li>
          <li>
            If you are not using the student digital experience, students will
            still need to access the What Is In Our Solar System? interactive
            resource. Obtain enough devices for students to access the
            interactive individually. Alternatively, students can access the
            interactive in pairs or small groups, or you can present the
            resource for the entire class.
          </li>
        </ul>
      </div>
    </>
  );
}

export default PartA;
