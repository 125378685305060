import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G15_Checkpoint() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G15"}>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>{" "}
          <span className="fw-700">Formative Assessment Opportunity:</span> Use
          this checkpoint to assess students' understanding of the following
          science ideas before moving on:
        </section>
        <ul className="pl-30 circle-listing">
          <li>Earth is tilted at an angle that does not change.</li>
          <li>
            As Earth revolves around the Sun, the tilt causes one part of Earth
            to face toward or away from the Sun. This causes the change in
            daylight hours throughout the year.
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G15_Checkpoint;
