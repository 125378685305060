import React, { useState } from "react";
import ReadersData from "../../LessonCommon/grade2/ReadersData";
import Modal from "react-bootstrap/Modal";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.js";
import BreadcumbComponent from "../../Common/BreadcrumbComponent";
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

function Module6() {
  const [lgShow, setLgShow] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [showpopup, setShowPopup] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [updateindex, setUpdateIndex] = useState(0);

  const ShowPopup = (_index) => {
    setShowPopup(true);
    setUpdateIndex(_index);
  };
  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Physical Science | Unit 1",
              path: "/grade2/glassblowing",
            }}
            Child={{
              title: "Leveled Readers",
            }}
          />
        </h1>
      </div>
      <div className="list mt-10">
        <div className="lesson-module-list leveled-readers-list">
          <div className="row g-4 row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
            {ReadersData.map((x) => {
              return x.Readers.map((y, index) => {
                return (
                  <>
                    <div key={"reader" + y.id} className="col">
                      <div
                        key={y.id}
                        className="card h-100 lesson-module leveled-reader-module"
                      >
                        <div key={"main" + y.id} className="card-body p-3">
                          <div className="d-flex student-lesson-preview-actions leveled-reader-module-actions mb-20">
                            <button
                              type="button"
                              className={
                                y.pdfUrl === ""
                                  ? "btn mr-5 disabled"
                                  : "btn mr-5"
                              }
                              onClick={() => setLgShow(true)}
                            >
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M6.75 15.7761L0 9.29763L2.09325 7.14513L6.71025 11.5506L15.8678 2.22363L18 4.33638L6.75 15.7761Z"
                                    fill="#414141"
                                  ></path>
                                </svg>
                              </span>
                              Present
                            </button>
                            <button type="button" className="btn ml-5" disabled>
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18 16.5H0V1.5H18V16.5ZM12.75 15.75V4.5H0.75V15.75H12.75ZM13.5 15.75H17.25V2.25H0.75V3.75H13.5V15.75ZM9 11.25H8.25V8.81925L2.81925 14.25L2.25 13.6807L7.68075 8.25H5.25V7.5H9V11.25Z"
                                    fill="#414141"
                                  ></path>
                                </svg>
                              </span>
                              Assign
                            </button>
                          </div>
                          <span
                            onClick={
                              y.pdfUrl === "" ? null : () => ShowPopup(index)
                            }
                            className={
                              y.pdfUrl === "" ? "disabled" : "cursor-pointer"
                            }
                          >
                            <img src={y.ImagerUrl} />
                            {/* <div className="leveled-reader-module-text">
                          <p className="pt-50 text-center"></p>
                        </div> */}
                          </span>
                        </div>
                      </div>
                    </div>
                    {showpopup === true ? (
                      <Modal
                        className="modal-fullscreen"
                        show={showpopup}
                        fullscreen={fullscreen}
                        scrollable={true}
                        onHide={() => {
                          setShowPopup(false);
                        }}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="w-100">
                            <div className="d-flex justify-content-between">
                              <div className="">
                                {x.Readers[updateindex].ReaderName}
                              </div>
                              <div className="pr-20">
                                <button className="btn btn-primary" disabled>
                                  Spanish
                                </button>
                              </div>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Document
                            file={x.Readers[updateindex].pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            {Array.from(new Array(numPages), (el, index) => (
                              <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                              />
                            ))}{" "}
                          </Document>
                          <p>
                            Page {pageNumber} of {numPages}
                          </p>
                        </Modal.Body>
                      </Modal>
                    ) : null}
                  </>
                );
              });
            })}
          </div>
        </div>
      </div>
      <Modal
        fullscreen={fullscreen}
        show={lgShow}
        scrollable={true}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">On-grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Document
            file={"/pdf/G2_Glassblowing_EnergyWork_StudentReader.pdf"}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}{" "}
          </Document>
        </Modal.Body>
      </Modal>
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Readers
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Document
                file={"./pdf/G2_Glassblowing_EnergyWork_StudentReader.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}{" "}
              </Document>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Module6;
