import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P27_Prior_Knowledge_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P27"} seriesCount={"S2"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">11.</span>
        <span className="number-text">
          Prompt students to look back in their science notebooks at the Make
          Connections data table they filled in during the previous session
          (page 12). Ask:
        </span>
      </p>
      <ul className="pl-45 mb-10 circle-listing">
        <li>Which type of matter was changed by the lowest temperature?</li>
      </ul>
      <p className="pl-25">
        Record each type of matter at the appropriate place on the number line
        for the temperature at which it changed when heated (e.g., The egg
        changed at 158˚F, so it should be placed between 100 and 200).
      </p>
    </IdGeneratingComponent>
  );
}

export default P27_Prior_Knowledge_S2;
