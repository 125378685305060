import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P11_Develop_Initial_Ideas_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P11"} seriesCount={"S1"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">2.</span>
          <span className="number-text">
            <span className="fw-700">Develop Initial Ideas:</span> Say, “We now
            have a good understanding of where Earth is compared to the Sun. We
            also understand how Earth moves in the solar system.” Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>How do we experience the effects of Earth's revolution?</li>
          <li>
            Could Earth’s revolution be causing some of the patterns we
            identified in the video and card set in Lesson 1?
          </li>
          <li>What are some ways we could find out?</li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P11_Develop_Initial_Ideas_S1;
