import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G20_Prior_Knowledge_S4() {
  return (
    <IdGeneratingComponent guideLineCount={"G20"} seriesCount={"S4"}>
      <section className="guidelines-blue-section">
        <div className="title">
          <section className="right-icon-text-group">
            <div className="text">
              <p className="fw-700">Teacher Tip</p>
            </div>
            <div className="icon">
              <img
                src="/image/lesson-images/Pencil.svg"
                alt="Pencil"
                title="Pencil"
              ></img>
            </div>
          </section>
        </div>
        <div className="p-2 text">
          <p>
            Each time you select a temperature, be sure to click the play button
            to start the simulation. Wait for the effect. After a few seconds, a
            claw will come down and attempt to pick up the object. Once the claw
            goes away, the effect of that temperature on the selected material
            is complete. A new temperature can then be selected, or the
            simulation can be reset.
          </p>
        </div>
      </section>
      {/* <section className="mb-10 right-icon-text-group">
        <div className="text">
          <p className="fw-700">Teacher Tip</p>
        </div>
        <div className="icon">
          <img
            src="/image/lesson-images/Pencil.svg"
            alt="Pencil"
            title="Pencil"
          ></img>
        </div>
      </section>
      <p>
        Each time you select a temperature, be sure to click the play button to
        start the simulation. Wait for the effect. After a few seconds, a claw
        will come down and attempt to pick up the object. Once the claw goes
        away, the effect of that temperature on the selected material is
        complete. A new temperature can then be selected, or the simulation can
        be reset.
      </p> */}
    </IdGeneratingComponent>
  );
}

export default G20_Prior_Knowledge_S4;
