import React from "react";

function T6_Engage_in_Sensemaking() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What information do you think is important?</li>
                <li>
                  How do the ideas in the infographic match your ideas about how
                  objects move in space?
                </li>
                <li>What new information did you uncover?</li>
                <li>
                  Can the evidence from the models and the infographic help you
                  describe how objects move in the solar system?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T6_Engage_in_Sensemaking;
