import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P17_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"P17"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">16.</span>
        <span className="number-text">
          <span className="fw-700">Engage in Sensemaking:</span> Bring the class
          together to make sense of the evidence collected. Ask:
        </span>
      </div>
      <ul className="pl-55 circle-listing">
        <li>
          What objects did not change during this investigation?{" "}
          <span className="text-i">
            (Objects made from plastic, metal, and wood did not change.)
          </span>
        </li>
        <li>
          How did the wax and food items change when heated?{" "}
          <span className="text-i">(They melted and became liquids.)</span>
        </li>
        <li>
          What happened to the wax and food items when they were cooled?
          <span className="text-i">
            {" "}
            (They changed from liquids back to solids.)
          </span>
        </li>
        <li>
          What do you think would happen if we put those items back under the
          lamp?{" "}
          <span className="text-i">
            (The heat would cause the food and wax to melt again.)
          </span>
        </li>
        <li>
          Who can use the word “cause” or “effect” to describe why the wax and
          food items changed?{" "}
          <span className="text-i">
            (The temperature caused the wax and food items to change from solid
            to liquid or liquid to solid.)
          </span>
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P17_Engage_Sensemaking;
