import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P30_Earn_Words_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"P30"} seriesCount={"S3"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">15.</span>
          <span className="font-16">
            Prompt students to locate the Can It Change Back? section of their
            science notebooks and use their knowledge and newly earned words to
            identify whether each of the changes described is reversible or
            irreversible (page 15).
          </span>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default P30_Earn_Words_S3;
