import React from "react";

function PartD() {
  return (
    <>
      <div className="lesson3-parts parta">
        <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
          PART D: ADVANCE SENSEMAKING OF THE ANCHOR
        </p>
        <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
            MATERIALS FOR PART D
          </h5>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                TEACHER
              </p>
              <ul className="pl-20 circle-listing">
                <li>
                  Digital Lesson 3*:
                  <ul className="pt-5 pl-20 circle-listing">
                    <li>Initial Ideas Class Chart</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                STUDENT
              </p>
              <ul className="mb-20 pl-20 circle-listing">
                <li>1 Science Notebook*</li>
              </ul>
              <p className="font-13">*Accessible online</p>
              <p className="font-13">† Prepared by the teacher</p>
              <p className="font-13">‡ Needed but not supplied</p>
            </div>
          </div>
        </section>
        <h5 className="fw-700 mb-10 text-decoration-underline">PREPARATION</h5>
        <p className="fw-700 mb-7">
          Part <span className="text-nexus-blue">D</span>{" "}
          <span className="fw-400">(5 minutes)</span>
        </p>
        <ul className="pl-20 number-listing">
          <li>Prepare to present Digital Lesson 3.</li>
          <li>Ensure that students have access to their science notebooks.</li>
        </ul>
      </div>
    </>
  );
}

export default PartD;
