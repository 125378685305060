import React from "react";

function T19_Engage_in_Sensemaking_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15">
              Review the Initial Ideas section of your notebook from Part A.
            </p>
            <p className="">
              Revise your initial ideas based on what you learned in this
              lesson.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img
                src="/image/g5_space_notebookl3_forpoc_6-15-234_Page_07.png"
                alt="g5_space_notebookl3_forpoc_6-15-234_Page_07"
                title="g5_space_notebookl3_forpoc_6-15-234_Page_07"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T19_Engage_in_Sensemaking_S2;
