import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P20_Prior_Knowledge_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"P20"} seriesCount={"S3"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">3.</span>
        <span className="number-text">
          Prompt students to locate the Observations at Each Temperature data
          table in their science notebooks (page 11). Explain that students will
          use the simulation to gather evidence about the effect of temperature
          on different types of matter.
        </span>
      </div>
      <p className="pl-20">(continued)</p>
    </IdGeneratingComponent>
  );
}

export default P20_Prior_Knowledge_S3;
