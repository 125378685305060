import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.js";
import BreadcumbComponent from "../../Common/BreadcrumbComponent_2";
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

function Module4() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Earth and Space Science | Unit 1",
              path: "/grade5/glassblowing",
            }}
            Child={{
              title: "Standards",
            }}
          />
        </h1>
      </div>
      <div className="bordered-box">
        <Document
          file="/pdf/G5_Space_UnitStandards_POC_6-13-23.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              className={numPages === 1 ? "page-index-zero" : ""}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              height={
                document.getElementsByClassName("bordered-box")[0]
                  ?.clientWidth * 1.0 ?? 150
              }
            />
          ))}{" "}
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
    </>
  );
}

export default Module4;
