import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P4_Develop_Initial_S5() {
  return (
    <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S5"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">8.</span>
        <span className="number-text">
          Say, “We seem to have many ideas about why the glass kept going back
          into the furnace and why the pole didn’t melt. Let’s focus on the pole
          first. What ideas do you have about what the pole is made of? Why do
          you think this?”
        </span>
      </p>
      <p className="pl-20">Add these ideas to the Initial Ideas Class Chart.</p>
    </IdGeneratingComponent>
  );
}

export default P4_Develop_Initial_S5;
