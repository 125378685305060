import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P6_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"P6"}>
      <div className="d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">11.</span>
        <span className="number-text">
          <span className="fw-700">Navigate to the Next Session:</span> End the
          session by saying, “Today we figured out that we are not completely
          sure what the pole is made of, or why the glassblower keeps putting
          the glass back into the furnace. We have ideas, but we need evidence
          to support them. I’ll gather examples of different types of
          Glassblowing so we can begin to investigate how temperature affects
          them in our next session.”
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P6_Navigate;
