import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P10_Plan_Investigation_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P10"} seriesCount={"S2"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">7.</span>
        <span className="number-text">
          Invite pairs to discuss their plans with the class. Have students use
          the thumbs up/thumbs down strategy to share if they chose the same
          options for their investigation plan.
        </span>
      </p>
      <p className="pl-15">(continued)</p>
    </IdGeneratingComponent>
  );
}

export default P10_Plan_Investigation_S2;
