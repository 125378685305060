import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function T32_Checkpoint() {
  const [lgShow, setLgShow] = useState(false);
  return (
    <>
      <div className="lesson-brief">
        <div className="row mb-20">
          <div className="col-6">
            <section className="right-icon-text-group">
              <div className="checkpoints-cta">
                <button
                  disabled
                  type="button"
                  className="btn btn-primary background-nexus-blue"
                >
                  Assign
                </button>
              </div>
            </section>
          </div>
          <div className="col-6">
            <section className="right-icon-text-group justify-content-end">
              <div className="checkpoints-cta">
                <button
                  type="button"
                  onClick={() => setLgShow(true)}
                  className="btn btn-primary"
                >
                  Check Student Responses
                </button>
              </div>
            </section>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_16.png" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="sm-down modal-dialog-centered"
        className="modal-dialog-centered modal-dialog-scrollable"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Student Proficiency
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="/image/checkpoint-chart-1.png"
            title="Student Proficiency"
            alt="Student Proficiency"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default T32_Checkpoint;
