import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P13_Engage_in_Sensemaking() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P13"} seriesCount={"S2"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">8.</span>
          <span className="number-text">
            <span className="fw-700">Engage in Sensemaking:</span> Facilitate a
            class discussion in which students share their observations. Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>
            How is Earth positioned?{" "}
            <span className="text-i">(Earth is tilted.)</span>
          </li>
          <li>
            How might this tilt help explain why the hours of daylight change at
            certain times of the year?{" "}
            <span className="text-i">
              (When Earth tilts toward the Sun, the days are longer. When Earth
              tilts away from the Sun, the days are shorter)
            </span>
          </li>
          <li>
            (Project the digital resource) At what point in Earth’s revolution
            do you think North America experiences more hours of daylight? Fewer
            hours of daylight?
          </li>
          <li>
            Why might it be useful to know how many hours of daylight an area
            receives throughout the year?{" "}
            <span className="text-i">(Answers will vary.)</span>
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P13_Engage_in_Sensemaking;
