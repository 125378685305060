import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G4_Investigate_S4() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S4"}>
        {/* <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G4_Investigate_S4;
