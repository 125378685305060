import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P10_Plan_Investigation_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"P10"} seriesCount={"S3"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">7.</span>
        <span className="number-text">(continued)</span>
      </div>
      <p className="pl-20 mb-7">
        As a class, come to an agreement on an investigation plan that includes
        the following:
      </p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>Testing one item made from each type of matter.</li>
        <li>
          Placing the items under the clamp lamp because it is safer to work
          with than hot water.
        </li>
        <li>
          Observing the objects’ color and whether each is a solid or liquid
          after heating (these are the only properties it will be safe to
          observe without touching the objects).
        </li>
      </ul>
      <p className="pl-20">
        Record the class plan on the Our Plan Class Chart.
      </p>
    </IdGeneratingComponent>
  );
}

export default P10_Plan_Investigation_S3;
