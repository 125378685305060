const ModuleData = [
  {
    id: 0,
    UnitName: "Physical Science | Unit 1",
    ModuleName: "How do objects change into other objects?",
    ImagePath: "/image/g2_glass_mod_tile.png",
    url: "/grade2/glassblowing",
  },
  {
    id: 1,
    UnitName: "Life Science | Unit 1",
    ModuleName: "Where do plants live and what do they need to grow?",
    ImagePath: "/image/g2_life_mod_tile.png",
    url: "",
  },
  {
    id: 2,
    UnitName: "Physical Science | Unit 2",
    ModuleName: "How do we help solve the trash problem?",
    ImagePath: "/image/g2_eng_mod_tile.png",
    url: "",
  },
  {
    id: 3,
    UnitName: "Earth Science | Unit 1",
    ModuleName: "Where can we find water on Earth?",
    ImagePath: "/image/g2_life2_mod_tile.png",
    url: "",
  },
  {
    id: 4,
    UnitName: "Earth Science | Unit 2",
    ModuleName: "Why does land change over time?",
    ImagePath: "/image/g2_earth_mod_tile.png",
    url: "",
  },
  // {
  //   id: 5,
  //   ModuleName: "Module 6",
  //   ImagePath: "/image/card-img.png",
  //   url: "",
  // },
  // {
  //   id: 6,
  //   ModuleName: "Module 7",
  //   ImagePath: "/image/card-img.png",
  //   url: "",
  // },
  // {
  //   id: 7,
  //   ModuleName: "Module 8",
  //   ImagePath: "/image/card-img.png",
  //   url: "",
  // },
];

export default ModuleData;
