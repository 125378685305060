import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G20_Prior_Knowledge_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"G20"} seriesCount={"S3"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G20_Prior_Knowledge_S3;
