import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P7_Earn_Words_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P7"} seriesCount={"S1"}>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">13.</span>
          <span className="number-text">
            Say, "The infographic gave us two new words, 'revolution' and
            'orbit.' Can you describe what these terms mean in your own words?"
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P7_Earn_Words_S1;
