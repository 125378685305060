import React from "react";

function T4_Investigate_S4() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">Share your ideas with a partner.</p>
          </div>
        </section>
        <div className="p-3 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>Do we have any evidence to support our ideas?</li>
            <li>
              What could we do to gather evidence to help us answer our
              question?
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default T4_Investigate_S4;
