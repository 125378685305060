import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G19_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"G19"}>
      {/*   <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G19_Navigate;
