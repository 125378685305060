import React from "react";

function T10_Connect_to_Prior_Knowledge() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Describe what you figured out about how objects move in the solar
              system.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

export default T10_Connect_to_Prior_Knowledge;
