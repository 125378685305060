import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P16_Navigate() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P16"}>
        <div className="d-flex guidence-count pb-10">
          <span className="fw-700 pr-5 text-nexus-medium-blue">11.</span>
          <span className="number-text">
            <span className="fw-700">Navigate to the Next Session:</span> Say,
            “We just figured out why the days are longer and shorter throughout
            the year. I wonder what that has to do with the pictures of the
            stars we saw.”
          </span>
        </div>
        <p className="pl-20">
          Allow students to share their ideas. Then say, “In our next session,
          should we try to investigate that idea?”
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P16_Navigate;
