import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P3_Develop_Initial_Ideas_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P3"} seriesCount={"S2"}>
        <div className="d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">5.</span>
          <span className="number-text">
            Invite students to share their initial ideas about why we may see
            different stars in the winter than in the summer with the class. As
            students share, record their ideas on the Initial Ideas: Different
            Stars in the Winter and the Summer Class Chart.
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P3_Develop_Initial_Ideas_S2;
