import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G0_Title() {
  return (
    <IdGeneratingComponent guideLineCount={"G0"}>
      <p className="mb-7">This column:</p>
      <ul className="pl-30 circle-listing">
        <li>
          Supports teachers in developing understanding and fluency with NGSS.
        </li>
        <li>
          Supports students by building in point-of-use scaffolding and
          differentiation options.
        </li>
        <li>
          Provides actionable ways to help students correct misconceptions.
        </li>
        <li>Highlights math and ELA connections.</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G0_Title;
