import React, { useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import HomePage from "./Platform/HomePage";
import Root_2 from "./Components/Root_2";
import Dashboard from "./Components/Container/grade2/Dashboard";
import Module1 from "./Components/Modules/grade2/Module1";
import Module2 from "./Components//Modules/grade2/Module2";
import Module3 from "./Components/Modules/grade2/Module3";
import Module4 from "./Components/Modules/grade2/Module4";
import Module5 from "./Components/Modules/grade2/Module5";
import Module6 from "./Components/Modules/grade2/Module6";
import Module7 from "./Components/Modules/grade2/Module7";
import LessonProcedure from "./Components/Lessons/grade2/LessonProcedure";
import LessonPrep from "./Components/Lessons/grade2/LessonPrep";
import LessonResources from "./Components/Lessons/grade2/LessonResources";
import LessonAssessment from "./Components/Lessons/grade2/LessonAssessment";

import Root_5 from "./Components/Root_5";
import Dashboard_5 from "./Components/Container/grade5/Dashboard";
import Module1_5 from "./Components/Modules/grade5/Module1";
import Module2_5 from "./Components//Modules/grade5/Module2";
import Module3_5 from "./Components/Modules/grade5/Module3";
import Module4_5 from "./Components/Modules/grade5/Module4";
import Module5_5 from "./Components/Modules/grade5/Module5";
import Module6_5 from "./Components/Modules/grade5/Module6";
import Module7_5 from "./Components/Modules/grade5/Module7";
import LessonProcedure_5 from "./Components/Lessons/grade5/LessonProcedure";
import LessonPrep_5 from "./Components/Lessons/grade5/LessonPrep";
import LessonResources_5 from "./Components/Lessons/grade5/LessonResources";
import LessonAssessment_5 from "./Components/Lessons/grade5/LessonAssessment";

function App() {
  const [updatelesson, setUpdateLesson] = useState(0);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} /> */}
        <Route
          path=""
          element={
            <HomePage
              setUpdateLesson={setUpdateLesson}
              updatelesson={updatelesson}
            />
          }
        />
        <Route
          path="grade2"
          element={
            <Root_2
              setUpdateLesson={setUpdateLesson}
              updatelesson={updatelesson}
            />
          }
        >
          <Route index path="" element={<Dashboard />} />
          <Route path="glassblowing" element={<Module1 />} />
          <Route path="teacher" element={<Module2 />} />
          <Route path="pacing" element={<Module3 />} />
          <Route path="standards" element={<Module4 />} />
          <Route path="materials" element={<Module5 />} />
          <Route path="leveledreaders" element={<Module6 />} />
          <Route path="teacherresources" element={<Module7 />} />
          <Route
            path="view-lessonprocedure"
            element={
              <LessonProcedure
                setUpdateLesson={setUpdateLesson}
                updatelesson={updatelesson}
              />
            }
          />
          <Route path="view-planning" element={<LessonPrep />} />
          <Route path="view-assessment" element={<LessonAssessment />} />
          <Route path="view-lessonresources" element={<LessonResources />} />
        </Route>

        <Route
          path="grade5"
          element={
            <Root_5
              setUpdateLesson={setUpdateLesson}
              updatelesson={updatelesson}
            />
          }
        >
          <Route index path="" element={<Dashboard_5 />} />
          <Route path="glassblowing" element={<Module1_5 />} />
          <Route path="teacher" element={<Module2_5 />} />
          <Route path="pacing" element={<Module3_5 />} />
          <Route path="standards" element={<Module4_5 />} />
          <Route path="materials" element={<Module5_5 />} />
          <Route path="leveledreaders" element={<Module6_5 />} />
          <Route path="teacherresources" element={<Module7_5 />} />
          <Route
            path="view-lessonprocedure"
            element={
              <LessonProcedure_5
                setUpdateLesson={setUpdateLesson}
                updatelesson={updatelesson}
              />
            }
          />
          <Route path="view-planning" element={<LessonPrep_5 />} />
          <Route path="view-assessment" element={<LessonAssessment_5 />} />
          <Route path="view-lessonresources" element={<LessonResources_5 />} />
        </Route>
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} /* fallbackElement={<BigSpinner />}  */ />
    </div>
  );
}

export default App;
