const ReadersData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How can we explain the patterns we notice in the sky?",
    Readers: [
      {
        id: 1,
        ReaderName: "On-grade",
        ImagerUrl: "/image/ongrade_tile.png",
        pdfUrl: "/pdf/BBS_3D_SkyWatchers_Reader_OnGrade_2202.pdf",
      },
      {
        id: 2,
        ReaderName: "Below-grade",
        ImagerUrl: "/image/reader_below_tile.png",
        pdfUrl: "",
      },
      {
        id: 3,
        ReaderName: "Above-grade",
        ImagerUrl: "/image/reader_above_tile.png",
        pdfUrl: "",
      },
      {
        id: 4,
        ReaderName: "On-grade-Spanish",
        ImagerUrl: "/image/ongrade_sp_tile.png",
        pdfUrl: "",
      },
    ],
  },
];

export default ReadersData;
