import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P19_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"P19"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">18.</span>
        <span className="number-text">
          <span className="fw-700">Navigate to the Next Session:</span> Ask:
        </span>
      </div>
      <ul className="pl-50 mb-10 circle-listing">
        <li>
          What are some ways we could figure out if higher or lower temperatures
          affect the observable properties of wood, plastic, and metal?
        </li>
      </ul>
      <p className="pl-25">
        Allow students to share their ideas. Next, explain that they will
        investigate temperature more during the next session.
      </p>
    </IdGeneratingComponent>
  );
}

export default P19_Navigate;
