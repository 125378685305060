import React from "react";

function T4_Develop_Initial_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="">Record your thinking.</p>
          </div>
        </section>
        <div className="row">
          <div className="col-6">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_02.png" />
            </div>
          </div>
          <div className=" col-6">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_03.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T4_Develop_Initial_S2;
