import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
import HighLightComponent from "../../../../Common/HighLightComponent";

function T14_Make_Observations_S2() {
  return (
    <>
      <div className="mb-20 lesson-procedure-row pb-0 min-height-auto">
        <div className="lesson-procedure-colum min-height-auto lesson-procedure-colum-left">
          <div className="teacher-procedure-text">
            <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
              Procedure
            </h6>
            <HighLightComponent>
              <IdGeneratingComponent guideLineCount={"P14"} seriesCount={"S2"}>
                <p className="mb-10 d-flex guidence-count">
                  <span className="number fw-700 pr-5 text-nexus-medium-blue">
                    12.
                  </span>
                  <span className="number-text">
                    <span className="fw-700">Make Observations:</span> Open the
                    Heating and Cooling Different Types of Matter resource.
                    Click to play the Heating video. Pause the video after the
                    materials are all placed under the lamp. Provide time for
                    students to record their observations in the “Type of
                    Matter” and “Observation{" "}
                    <span className="text-decoration-underline">Before</span>{" "}
                    Heating” columns of the data table.
                  </span>
                </p>
              </IdGeneratingComponent>
            </HighLightComponent>
          </div>
        </div>
        <div className="lesson-procedure-colum min-height-auto lesson-procedure-center">
          <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
            Make Observations
          </h6>
          <div className="lesson-brief">
            <section className="icon-text-group">
              <div className="icon">
                <img
                  src="/image/lesson-images/notebook.svg"
                  alt="notebook"
                  title="notebook"
                ></img>
              </div>
              <div className="text">
                <p className="mb-15 mt-5 font-16">
                  Watch the Heating Matter video.
                </p>
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 col-lg-9">
                    <div className="mb-20 video-section">
                      <video controls>
                        <source
                          src="/videos/lesson3-videos/nex_gr2_glassblowing_heatingmatter_video.mp4"
                          type="video/mp4"
                        ></source>
                      </video>
                    </div>
                  </div>
                </div>
                <p>
                  Record your observations in the "Type of Matter" and
                  "Observations{" "}
                  <span className="text-decoration-underline">Before</span>{" "}
                  Heating" columns of the data table.
                </p>
              </div>
            </section>
          </div>
        </div>
        <div className="lesson-procedure-colum min-height-auto lesson-procedure-colum-right">
          <div className="teacher-guidelines-text">
            <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
              Guidance
            </h6>
            <HighLightComponent>
              <IdGeneratingComponent guideLineCount={"G14"} seriesCount={"S2"}>
                <section className="text-wrapping-right">
                  <div className="icon">
                    <img
                      src="/image/lesson-images/scaffolding.svg"
                      alt="scaffolding"
                      title="scaffolding"
                    ></img>
                  </div>
                  <span className="fw-700">Scaffolding:</span>{" "}
                  <span>
                    The class may benefit from you modeling how to fill in the
                    table. If needed, draw the table on the board or a sheet of
                    chart paper. Ask students to share their observations and
                    record their ideas on the board. Students should then copy
                    the information in the table in their science notebooks.
                  </span>
                </section>
              </IdGeneratingComponent>
            </HighLightComponent>
          </div>
        </div>
      </div>
      <div className="lesson-procedure-row">
        <div className="lesson-procedure-colum lesson-procedure-colum-left">
          <div className="teacher-procedure-text">
            {window.screen.width < 900 ? (
              <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
                Procedure
              </h6>
            ) : null}
            <HighLightComponent>
              <IdGeneratingComponent guideLineCount={"P14"} seriesCount={"S3"}>
                <p className="mb-10 d-flex guidence-count">
                  <span className="number pr-5 fw-700 text-nexus-medium-blue">
                    13.
                  </span>
                  <span className="number-text">
                    Continue playing the video. Encourage students to focus on
                    the observable changes to each type of matter. Play the
                    video a second time, if needed.
                  </span>
                </p>
                <p className="pl-20">
                  Direct students to record their observations in the
                  “Observations{" "}
                  <span className="text-decoration-underline">After</span>{" "}
                  Heating” column of the data table. Next, ask them to answer
                  the questions in the Analyzing My Data section individually
                  (page 8).
                </p>
              </IdGeneratingComponent>
            </HighLightComponent>
          </div>
        </div>
        <div className="lesson-procedure-colum lesson-procedure-center">
          {window.screen.width < 900 ? (
            <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
              Make Observations
            </h6>
          ) : null}
          <div className="lesson-brief">
            <p className="mb-20 pl-60">
              Continue watching the video. What changes do you observe?
            </p>
            <section className="icon-text-group mb-20">
              <div className="icon">
                <img
                  src="/image/lesson-images/notebook.svg"
                  alt="notebook"
                  title="notebook"
                ></img>
              </div>
              <div className="text">
                <p className="mb-10 mt-5 font-16">
                  Record your observations in the “Observations{" "}
                  <span className="text-decoration-underline">After</span>{" "}
                  Heating” column of the Heating Matter data table.
                </p>
                <p className="">
                  Next, complete the Analyzing My Data section of your notebook.
                </p>
              </div>
            </section>
            <div className="row justify-content-center">
              <div className="col-12 col-md-9">
                <div className="shadow">
                  <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_07.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lesson-procedure-colum lesson-procedure-colum-right">
          <div className="teacher-guidelines-text">
            {window.screen.width < 900 ? (
              <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
                Guidance
              </h6>
            ) : null}
            <HighLightComponent>
              <IdGeneratingComponent guideLineCount={"G14"} seriesCount={"S3"}>
                <section className="text-wrapping-right mb-20">
                  <div className="icon icon-big">
                    <img
                      src="/image/lesson-images/settings.svg"
                      alt="settings"
                      title="settings"
                    ></img>
                  </div>
                  <span className="fw-700">NGSS Element:</span>{" "}
                  <span className="text-nexus-blue fw-700">
                    Planning and Carrying Out Investigations,
                  </span>{" "}
                  <span className="text-nexus-orange fw-700">PS1.A,</span>{" "}
                  <span className="text-nexus-green fw-700">
                    Cause and Effect:
                  </span>{" "}
                  Students carry out an investigation using media to determine
                  how heat affects the observable properties of different types
                  of matter. In grade 2, students identify that heat/higher
                  temperature (cause) can change some types of matter from solid
                  to liquid (effect).
                </section>
                <section className="guidelines-blue-section">
                  <div className="title">
                    <p className="fw-700">
                      ELA Connection{" "}
                      <span className="text-nexus-lightgrey">(A,B,C)</span>
                    </p>
                  </div>
                  <div className="p-2 text">
                    <p>
                      Students gather information from their investigation to
                      answer questions. (W.2.8)
                    </p>
                  </div>
                </section>
              </IdGeneratingComponent>
            </HighLightComponent>
          </div>
        </div>
      </div>
    </>
  );
}

export default T14_Make_Observations_S2;
