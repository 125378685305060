import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P4_Develop_Initial_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S1"}>
      <div className="d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">5.</span>
        <span className="number-text">
          <span className="fw-700">Develop Initial Ideas:</span> Encourage
          students to think about why the glassblower kept putting the glass
          into the very hot furnace and why the pole did not melt like the glass
          did. After some time to think, ask students to share their ideas with
          a partner.
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P4_Develop_Initial_S1;
