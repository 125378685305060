import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P10_Connect_to_Prior_Knowledge() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P10"}>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">1.</span>
          <span className="number-text">
            <span className="fw-700">Connect to Prior Knowledge:</span> Prompt
            students to describe what they figured out in the last session about
            how objects move in the solar system. Encourage them to use the
            words they earned in their explanations.
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P10_Connect_to_Prior_Knowledge;
