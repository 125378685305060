import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G4_Develop_Initial_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S1"}>
      <section className="pb-7 text-wrapping-right">
        <span className="fw-700">Scaffolding:</span> Listen The following
        prompts may help students who struggle to come up with ideas:
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          Why do you think the glassblower kept putting the glass blob into the
          furnace?
        </li>
        <li>Why might they need to keep putting it in the hot furnace?</li>
        <li>
          Why do you think the pole did not melt in the furnace, but the pieces
          of glass did?
        </li>
        <li>
          What do you think the pole is made of? Could that have something to do
          with why it did not melt?
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G4_Develop_Initial_S1;
