import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P30_Earn_Words_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P30"} seriesCount={"S2"}>
      <div className="pb-5 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">14.</span>
        <span className="number-text">(continued)</span>
      </div>
      <p className="pl-25 mb-10">
        <span className="fw-700">Earn Words:</span> Explain that if a change
        cannot be undone, that is called an irreversible change. Invite students
        to make a connection to the word “irreversible.” Next, have them use the
        number line to describe which types of matter underwent irreversible
        changes <span className="text-i">(egg and wood).</span>
      </p>
      <p className="pl-25">
        Add the words “reversible” and “irreversible” to the Word Wall. Students
        have now earned these words and can begin to use them in their everyday
        language.
      </p>
    </IdGeneratingComponent>
  );
}

export default P30_Earn_Words_S2;
