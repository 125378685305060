import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G4_Develop_Initial_S5() {
  return (
    <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S5"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G4_Develop_Initial_S5;
