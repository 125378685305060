import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P29_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"P29"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">13.</span>
        <span className="number-text">
          <span className="fw-700">Engage in Sensemaking:</span> Hold up a piece
          of paper. Fold it in half. Ask:
        </span>
      </p>
      <ul className="pl-50 mb-10 circle-listing">
        <li>
          Can this change be undone?{" "}
          <span className="text-i">(Yes, just unfold the paper.)</span>
        </li>
      </ul>
      <p className="pl-25 mb-7">Cut the piece of paper in half. Ask:</p>
      <ul className="pl-50 mb-10 circle-listing">
        <li>
          Can this change be undone?{" "}
          <span className="text-i">
            (No, the pieces won’t stick back together without something to hold
            them together.)
          </span>
        </li>
      </ul>
      <p className="mb-10 pl-25">
        Repeat the demonstration with a rubber band. Stretch the band and ask if
        the change can be undone.{" "}
        <span className="text-i">
          (Yes, stop stretching it.) Cut the rubber band and ask if that change
          can be undone. (No, the pieces cannot come back together.)
        </span>
      </p>
      <p className="mb-7 pl-25">
        Finally, have students consider the broken drinking glass from earlier
        in the unit. Ask:
      </p>
      <ul className="pl-50 circle-listing">
        <li>
          Once the glass is broken, can that change be undone?{" "}
          <span className="text-i">
            (No, the pieces cannot be put back together to make the drinking
            glass. The pieces could be heated in the furnace and used to make a
            new drinking glass, but the original glass could be put back
            together exactly how it was.)
          </span>
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P29_Engage_Sensemaking;
