import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G28_Connect_Ideas() {
  return (
    <IdGeneratingComponent guideLineCount={"G28"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative_assessment_opportunity"
            title="formative_assessment_opportunity"
          ></img>
        </div>
        <span className="fw-700">Formative Assessment Opportunity:</span> Listen
        for students to describe the science ideas developed during the last
        session related to:
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          Different kinds of matter exist and many of them can be a solid of
          liquid depending on the temperature.
        </li>
        <li>
          Heating and cooling a substance may cause changes to an object’s
          properties that can be observed.
        </li>
        <li>Some changes can be undone while others cannot.</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G28_Connect_Ideas;
