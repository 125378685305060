import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G12_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"G12"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/science_and_engineerning_practices.svg"
            alt="science_and_engineerning_practices"
            title="science_and_engineerning_practices"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-blue fw-700">
          Planning and Carrying Out Investigations:
        </span>{" "}
        Students engage in this practice when they decide what to include in
        their investigation in the previous step and then sharing their ideas
        with the class.
      </section>
    </IdGeneratingComponent>
  );
}

export default G12_Navigate;
