import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P18_Collect_Data_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P18"} seriesCount={"S2"}>
        <div className="d-flex guidence-count pb-10">
          <span className="fw-700 pr-5 text-nexus-medium-blue">4.</span>
          <span className="number-text">
            <span className="">
              <section className="align-items-center icon-text-group">
                <div className="">
                  <div className="text-wrapping-left">
                    <p>
                      Have students complete the Observe section of their
                      science notebooks (page 39). After some time to document
                      what they saw, have them compare their observations with a
                      partner.
                      <div className="pt-5 pr-5 icon">
                        <img
                          src="/image/lesson-images/notebook.svg"
                          alt="notebook"
                          title="notebook"
                        ></img>
                      </div>
                    </p>
                  </div>
                </div>
              </section>
            </span>
          </span>
        </div>
        <p className="text pl-20">(continued)</p>
      </IdGeneratingComponent>
    </>
  );
}

export default P18_Collect_Data_S2;
