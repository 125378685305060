import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P11_Develop_Initial_Ideas_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P11"} seriesCount={"S2"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">3.</span>
          <span className="number-text">
            Encourage students to think about their experiences with the Sun.
            Ask:
          </span>
        </div>
        <ul className="pl-45 pb-10 circle-listing">
          <li>How do you experience the Sun on Earth every day?</li>
          <li>What effects does the Sun have on Earth every day?</li>
          <li>
            Do any of these effects seem to change throughout the year? Why do
            you think that?
          </li>
        </ul>
        <p className="pl-20">Have students share their ideas with a partner.</p>
      </IdGeneratingComponent>
    </>
  );
}

export default P11_Develop_Initial_Ideas_S2;
