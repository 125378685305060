import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P10_Plan_Investigation_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P10"} seriesCount={"S1"}>
      <section className="text-wrapping-right pb-10">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">6.</span>
          <span className="font-16">
            <span className="fw-700">Plan an Investigation:</span> Remind
            students that during the last session the class decided to
            investigate the question, “How does temperature affect other of
            matter?”
          </span>
        </div>
      </section>
      <p className="pl-20">
        Have students work with a partner to complete the Our Plan section of
        their notebooks (page 5).
      </p>
    </IdGeneratingComponent>
  );
}

export default P10_Plan_Investigation_S1;
