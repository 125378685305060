import React, { useState, useRef } from "react";
import Containerprep from "../../TeacherPreparation/grade5/Lesson-3/Containerprep";
import { fromRange } from "xpath-range";
import { v4 as uuidv4 } from "uuid";
import BreadcumbComponent from "../../Common/BreadcrumbComponent_2";
import HighLightComponent from "../../Common/HighLightComponent";
import { useEffect } from "react";

function GetDataFromLocalStorage() {
  var data = JSON.parse(localStorage.getItem("userAnnotationsCreated"));
  if (data) return data;
  else return [];
}

function LessonPrep() {
  const popUp = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [userAnnotationsCreated, setUserAnnotationsCreated] = useState(
    GetDataFromLocalStorage
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function promptTheData() {
    if (window.getSelection().rangeCount > 0) {
      let commentDetails = prompt("Please enter your comment");
      if (commentDetails != null) {
        onHighlightAction("comment", commentDetails);
      }
    }
  }

  const UnHighlightAction = () => {
    if (popUp && popUp.current) popUp.current.removePopUp();
    const selection = window.getSelection();
    const selectionData = window.getSelection().toString();
    if (selection && selection.anchorNode) {
      const selectedElement = selection.anchorNode.parentElement;
      if (selectedElement.classList.contains("text-highlight")) {
        selectedElement.classList.remove("text-highlight");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      } else if (selectedElement.classList.contains("commentHighlighter")) {
        selectedElement.classList.remove("commentHighlighter");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      } else if (
        selectedElement.classList.contains("text-highlight") &&
        selectedElement.classList.contains("commentHighlighter")
      ) {
        selectedElement.classList.remove("text-highlight");
        selectedElement.classList.remove("commentHighlighter");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      }
    }
  };

  function updateMark(xpath, typeDetails, uniqueId) {
    let _element;
    if (uniqueId) _element = document.getElementById(uniqueId);
    else if (xpath.highlightId)
      _element = document.getElementById(xpath.highlightId);
    if (_element) {
      var element = document.evaluate(
        xpath.start,
        _element,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (element != null) {
        const newItem = document.createElement("span");

        let _text = element.nodeValue;
        if (typeDetails.type === "comment") {
          newItem.innerHTML =
            _text.slice(0, xpath.startOffset) +
            `<span className="commentHighlighter font-16" title= ` +
            " " +
            typeDetails.commentDetails.replace(/[ ]/g, "\u00a0") +
            " " +
            `>` +
            _text.slice(xpath.startOffset, xpath.endOffset) +
            `</span>` +
            _text.slice(xpath.endOffset, _text.length);
        } else {
          newItem.innerHTML =
            _text.slice(0, xpath.startOffset) +
            `<span className="text-highlight font-16">` +
            _text.slice(xpath.startOffset, xpath.endOffset) +
            `</span>` +
            _text.slice(xpath.endOffset, _text.length);
        }
        element.parentNode?.replaceChild(newItem, element);
        return element;
      }
    }
  }

  useEffect(() => {
    let available = JSON.parse(localStorage.getItem("userAnnotationsCreated"));
    let filteredData = available; // []
    /* if (available)
        filteredData = available.filter(data => data.page === updatelesson) */
    if (filteredData && filteredData.length) {
      for (const element of filteredData) {
        updateMark(element, element);
      }
    }
  }, []);

  function onHighlightAction(type, commentDetails) {
    if (popUp && popUp.current) popUp.current.removePopUp();
    let text = (getSelection() && getSelection().toString()) || "";
    if (!text || text === " ") {
      return;
    }
    function findAncestor(el, cls) {
      while ((el = el.parentElement) && !el.id);
      return el.id;
    }
    const selection = getSelection();
    const range = selection.getRangeAt(0);
    let uniqueId = findAncestor(window.getSelection().anchorNode.parentNode);
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const content = document.getElementById(uniqueId);
      let xpath = null;
      if (content) {
        xpath = fromRange(range, content);
      }
      let start = "//*[@id='" + uniqueId + "']/" + xpath.start;
      let end = "//*[@id='" + uniqueId + "']/" + xpath.end;
      xpath = { ...xpath, start, end };

      if (xpath) {
        try {
          const highlightId = uniqueId;
          let _userAnnotations = GetDataFromLocalStorage();
          let newDetails = {
            page: "point",
            text,
            type: type,
            highlightId,
            ...xpath,
          };
          if (type === "comment") {
            newDetails.commentDetails = commentDetails;
          }
          _userAnnotations.push(newDetails);

          updateMark(xpath, newDetails, uniqueId);
          setUserAnnotationsCreated(_userAnnotations);
          localStorage.setItem(
            "userAnnotationsCreated",
            JSON.stringify(_userAnnotations)
          );
        } catch (e) {
          console.error("user highlight failed: ", e);
        }
      }
    }
  }

  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Earth and Space Science | Unit 1",
              path: "/grade5/glassblowing",
            }}
            Child={{
              title: "Lesson 3 - Planning",
            }}
          />
        </h1>
      </div>
      <section className="teacher-guide">
        <div className="bordered-box pdf-reader">
          <div className="pdf-reader-actions">
            <button className="btn mr-10" onClick={() => promptTheData()}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.25 5.99992C11.648 5.99992 12 6.35192 12 6.74992C12 7.16392 11.664 7.49992 11.25 7.49992C9.745 7.49992 3.5 7.49992 3.5 7.49992V19.4999H20.5V10.7499C20.5 10.3359 20.836 9.99992 21.25 9.99992C21.664 9.99992 22 10.3359 22 10.7499V19.9999C22 20.6209 21.478 20.9999 21 20.9999H3C2.52 20.9999 2 20.6209 2 19.9999V6.99992C2 6.51892 2.38 5.99992 3 5.99992H11.25ZM9.239 12.5259C8.194 15.5289 8.001 15.9759 8.001 16.3659C8.001 16.8069 8.386 16.9919 8.628 16.9919C8.9 16.9919 9.736 16.6909 12.457 15.7429L9.239 12.5259ZM10.127 11.6369L13.347 14.8569L21.755 6.45692C21.918 6.29392 22 6.07992 22 5.86492C22 5.65192 21.918 5.43792 21.755 5.27392C21.175 4.69592 20.297 3.81692 19.716 3.23792C19.553 3.07492 19.339 2.99292 19.125 2.99292C18.912 2.99292 18.697 3.07492 18.533 3.23792L10.127 11.6369Z"
                    fill="#1E293B"
                  />
                </svg>
              </span>
              Annotate
            </button>
            <button
              className="btn ml-10"
              onClick={() => onHighlightAction("Highlight")}
            >
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 20.25C19 19.848 18.644 19.5 18.25 19.5C15.689 19.5 6.311 19.5 3.75 19.5C3.356 19.5 3 19.848 3 20.25C3 20.652 3.356 21 3.75 21H18.25C18.644 21 19 20.652 19 20.25ZM6.977 13.167C5.643 17.083 5.497 17.399 5.497 17.754C5.497 18.281 5.957 18.503 6.246 18.503C6.598 18.503 6.914 18.366 10.82 17.01L6.977 13.167ZM8.037 12.106L11.883 15.952L20.707 7.138C20.902 6.943 21 6.687 21 6.431C21 6.176 20.902 5.92 20.707 5.725C20.015 5.034 18.965 3.984 18.272 3.293C18.077 3.098 17.821 3 17.565 3C17.311 3 17.055 3.098 16.859 3.293L8.037 12.106Z"
                    fill="#1E293B"
                  />
                </svg>
              </span>
              Highlight
            </button>
            <button className="btn ml-10" onClick={() => UnHighlightAction()}>
              <span className="icon">
                <svg
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.662 22L0.293 16.635C0.098 16.44 0 16.185 0 15.928C0 15.672 0.098 15.416 0.293 15.221L15.222 0.293C15.417 0.099 15.673 0 15.929 0C16.184 0 16.441 0.099 16.636 0.293L23.707 7.366C23.903 7.561 24 7.817 24 8.074C24 8.33 23.903 8.585 23.707 8.781L12.491 20H18.005V22H5.662ZM9.319 20L3.833 14.514L2.414 15.928L6.49 20H9.319ZM9.775 8.571L5.247 13.099L10.905 18.758L15.432 14.228L9.775 8.571Z"
                    fill="#1E293B"
                  />
                </svg>
              </span>
              Remove
            </button>
          </div>
          <HighLightComponent ref={popUp}>
            <Containerprep />
          </HighLightComponent>
        </div>
      </section>
    </>
  );
}

export default LessonPrep;
