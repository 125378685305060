import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P0_Title() {
  return (
    <IdGeneratingComponent guideLineCount={"P0"}>
      <p>
        This column includes step-by-step instructions for facilitating each
        section of the lesson. Guiding questions and anticipated responses are
        provided.
      </p>
    </IdGeneratingComponent>
  );
}

export default P0_Title;
