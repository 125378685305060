import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G17_Connect_to_Prior_Knowledge_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G17"} seriesCount={"S1"}>
        <section className="mb-7 text-wrapping-right">
          <span className="fw-700">Formative Assessment Opportunity:</span>{" "}
          Listen for students to explain the following ideas:
        </section>
        <ul className="pl-30 circle-listing">
          <li>Earth is tilted at an angle at its axis.</li>
          <li>Earth revolves around the Sun.</li>
          <li>
            As Earth revolves, the parts that are tilted toward the Sun have
            longer days and the parts that are tilted away from the Sun have
            shorter days.
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G17_Connect_to_Prior_Knowledge_S1;
