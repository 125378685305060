import React from "react";

function T0_Title() {
  return (
    <>
      <div className="lesson-intro">
        <div className="lesson-intro-image">
          <img src="/image/lesson-3-title.png"></img>
        </div>
        <h2 className="lesson-intro-title">Lesson 3</h2>
      </div>
    </>
  );
}

export default T0_Title;
