import React from "react";

function T27_Prior_Knowledge_S1() {
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-15 mt-5 font-16">Your teacher made a number line.</p>
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              With your class, skip-count by 100 to label the number line.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

export default T27_Prior_Knowledge_S1;
