import React from "react";

function T36_Initial_Ideas() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Find the Initial Ideas section of your notebook.
            </p>
            <p>Reflect on your ideas from the start of this lesson.</p>
          </div>
        </section>
      </div>
    </>
  );
}

export default T36_Initial_Ideas;
