import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P19_Engage_in_Sensemaking_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P19"} seriesCount={"S2"}>
        <section className="mb-10 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="d-flex guidence-count">
            <span className="number pr-5 fw-700 text-nexus-medium-blue">
              6.
            </span>
            <span className="number-text">
              Have students review the Initial Ideas section in Part A of their
              notebooks (page 28) and make changes, deletions, or additions as
              needed to reflect what they learned in this lesson.
            </span>
          </div>
        </section>
        <p className=" text pl-20">(continued)</p>
      </IdGeneratingComponent>
    </>
  );
}

export default P19_Engage_in_Sensemaking_S2;
