import React from "react";

function T20_Prior_Knowledge_S1() {
  return (
    <>
      <div className="lesson-brief">
        <div className="p-3 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>
              What have we figured out about how temperature affects different
              types of matter?
            </li>
            <li>What did we decide we still need to figure out?</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default T20_Prior_Knowledge_S1;
