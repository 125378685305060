import React from "react";

function T3_Develop_Initial_Ideas_S1() {
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-20">
          Look at the images in the Initial Ideas section of your notebook.
        </p>
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>What do you think is happening?</li>
            <li>Can you try to explain the differences?</li>
          </ul>
        </div>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <ul className="pl-20 circle-listing">
              <li>Record your ideas in your notebook.</li>
            </ul>
          </div>
        </section>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class-discussion"
              title="class-discussion"
            ></img>
          </div>
          <div className="text">
            <ul className="pl-20 circle-listing">
              <li>Next, share your ideas with a partner.</li>
            </ul>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/g5_space_notebookl3_forpoc_6-15-234_Page_07.png"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T3_Develop_Initial_Ideas_S1;
