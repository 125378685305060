import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P12_Collect_Represent_and_Analyze_Data_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P12"} seriesCount={"S1"}>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="d-flex guidence-count">
            <span className="fw-700 pr-5 text-nexus-medium-blue">5.</span>
            <span className="number-text">
              <span className="fw-700">
                Collect, Represent and Analyze Data:
              </span>{" "}
              Arrange students in groups of four. Explain that they will work
              together to:
            </span>
          </div>
        </section>
        <ul className="pl-45 mb-10 circle-listing">
          <li>
            Calculate the total hours of daylight for day 15 of each month
            (Collect Data, page 35).
          </li>
          <li>
            Create a graph to visually represent those calculations (Represent
            Data, page 36).
          </li>
          <li>
            Look for patterns in their data and discuss them with their group.
          </li>
        </ul>
        <p className="mb-10 pl-20">
          Allow ample time for groups to complete the Collect Data and Represent
          Data sections of their notebooks (pages 35–36).
        </p>
        <p className="pl-20">(continued)</p>
      </IdGeneratingComponent>
    </>
  );
}

export default P12_Collect_Represent_and_Analyze_Data_S1;
