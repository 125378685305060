import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P27_Prior_Knowledge_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P27"} seriesCount={"S1"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">10.</span>
        <span className="number-text">
          <span className="fw-700">Connect to Prior Knowledge:</span> Display
          the number line you prepared. Point out the number “0.” Explain that
          the class will use this number line to review the types of matter they
          investigated and organize them by the temperatures at which they
          changed.
        </span>
      </p>
      <p className="pl-25 mb-10">
        Ask students to skip-count aloud by 100s from 0 to 1,000 while you label
        each mark on the number line.
      </p>
      <p className="pl-25">
        Next, point out that the class investigated temperatures higher than
        1,000˚F. Model how to skip-count by 100s from 1,000 to 2,600, labelling
        the remaining marks on the number line as you do.
      </p>
    </IdGeneratingComponent>
  );
}

export default P27_Prior_Knowledge_S1;
