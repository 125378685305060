import React, { useEffect, useState } from "react";
import PartA from "../../../TeacherPreparation/grade2/Lesson-3/PartA";
import PartB from "../../../TeacherPreparation/grade2/Lesson-3/PartB";
import PartC from "../../../TeacherPreparation/grade2/Lesson-3/PartC";
import PartD from "../../../TeacherPreparation/grade2/Lesson-3/PartD";
import LessonResources from "../../../TeacherPreparation/grade2/Lesson-3/LessonResources";

import P1_Prior_knowledge from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P1_Prior_Knowledge";
import P2_Make_Observations_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P2_Make_Observations_S1";
import P2_Make_Observations_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P2_Make_Observations_S2";
import P3_Make_Personal from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P3_Make_Personal";
import P4_Develop_Initial_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P4_Develop_Initial_S1";
import P4_Develop_Initial_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P4_Develop_Initial_S2";
import P4_Develop_Initial_S3 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P4_Develop_Initial_S3";
import P4_Develop_Initial_S4 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P4_Develop_Initial_S4";
import P4_Develop_Initial_S5 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P4_Develop_Initial_S5";
import P5_Ask_Questions_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P5_Ask_Questions_S1";
import P5_Ask_Questions_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P5_Ask_Questions_S2";
import P6_Navigate from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P6_Navigate";

import P7_Prior_Knowledge from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P7_Prior_Knowledge";
import P8_Make_Observations_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P8_Make_Observations_S1";
import P8_Make_Observations_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P8_Make_Observations_S2";
import P9_Engage_Sensemaking_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P9_Engage_Sensemaking_S1";
import P9_Engage_Sensemaking_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P9_Engage_Sensemaking_S2";
import P10_Plan_Investigation_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P10_Plan_Investigation_S1";
import P10_Plan_Investigation_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P10_Plan_Investigation_S2";
import P10_Plan_Investigation_S3 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P10_Plan_Investigation_S3";
import P11_Checkpoint from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P11_Checkpoint";
import P12_Navigate from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P12_Navigate";

import P13_Prior_Knowledge from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P13_Prior_Knowledge";
import P14_Make_Observations_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P14_Make_Observations_S1";
import P14_Make_Observations_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P14_Make_Observations_S2";
import P14_Make_Observations_S3 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P14_Make_Observations_S3";
import P15_Make_Predictions from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P15_Make_Predictions";
import P16_Make_Observations from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P16_Make_Observations";
import P17_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P17_Engage_Sensemaking";
import P18_Make_Personal from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P18_Make_Personal";
import P19_Navigate from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P19_Navigate";

import P20_Prior_Knowledge_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S1";
import P20_Prior_Knowledge_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S2";
import P20_Prior_Knowledge_S3 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S3";
import P20_Prior_Knowledge_S4 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S4";
import P21_Make_Observations from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P21_Make_Observations";

import P22_Make_Comparisons from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P22_Make_Comparisons";
import P23_Analyze_Data from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P23_Analyze_Data";
import P24_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P24_Engage_Sensemaking";
import P25_Checkpoint from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P25_Checkpoint";
import P26_Navigate from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P26_Navigate";

import P27_Prior_Knowledge_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P27_Prior_Knowledge_S1";
import P27_Prior_Knowledge_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P27_Prior_Knowledge_S2";
import P28_Connect_Ideas from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P28_Connect_Ideas";
import P29_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P29_Engage_Sensemaking";
import P30_Earn_Words_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P30_Earn_Words_S1";
import P30_Earn_Words_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P30_Earn_Words_S2";
import P30_Earn_Words_S3 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P30_Earn_Words_S3";
import P31_Engage_Argument_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P31_Engage_Argument_S1";
import P31_Engage_Argument_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P31_Engage_Argument_S2";
import P32_Checkpoint from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P32_Checkpoint";
import P33_Navigate from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P33_Navigate";

import P34_Prior_Knowledge_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P34_Prior_Knowledge_S1";
import P34_Prior_Knowledge_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P34_Prior_Knowledge_S2";
import P35_Anchoring_Phenomenon from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P35_Anchoring_Phenomenon";
import P36_Initial_Ideas_S1 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P36_Initial_Ideas_S1";
import P36_Initial_Ideas_S2 from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P36_Initial_Ideas_S2";
import P37_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P37_Engage_Sensemaking";
import P38_Navigate from "../../../LessonLevel/grade2/TeacherProcedure/Lesson-p3/P38_Navigate";

import G1_Prior_knowledge from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G1_Prior_Knowledge";
import G2_Make_Observations_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G2_Make_Observations_S1";
import G2_Make_Observations_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G2_Make_Observations_S2";
import G3_Make_Personal from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G3_Make_Personal";
import G4_Develop_Initial_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S1";
import G4_Develop_Initial_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S2";
import G4_Develop_Initial_S3 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S3";
import G4_Develop_Initial_S4 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S4";
import G4_Develop_Initial_S5 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S5";
import G5_Ask_Questions_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G5_Ask_Questions_S1";
import G5_Ask_Questions_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G5_Ask_Questions_S2";
import G6_Navigate from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G6_Navigate";

import G7_Prior_Knowledge from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G7_Prior_Knowledge";
import G8_Make_Observations_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G8_Make_Observations_S1";
import G8_Make_Observations_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G8_Make_Observations_S2";
import G9_Engage_Sensemaking_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G9_Engage_Sensemaking_S1";
import G9_Engage_Sensemaking_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G9_Engage_Sensemaking_S2";
import G10_Plan_Investigation_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G10_Plan_Investigation_S1";
import G10_Plan_Investigation_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G10_Plan_Investigation_S2";
import G10_Plan_Investigation_S3 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G10_Plan_Investigation_S3";
import G11_Checkpoint from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G11_Checkpoint";
import G12_Navigate from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G12_Navigate";

import G13_Prior_Knowledge from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G13_Prior_Knowledge";
import G14_Make_Observations_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G14_Make_Observations_S1";
import G14_Make_Observations_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G14_Make_Observations_S2";
import G14_Make_Observations_S3 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G14_Make_Observations_S3";
import G15_Make_Predictions from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G15_Make_Predictions";
import G16_Make_Observations from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G16_Make_Observations";
import G17_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G17_Engage_Sensemaking";
import G18_Make_Personal from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G18_Make_Personal";
import G19_Navigate from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G19_Navigate";

import G20_Prior_Knowledge_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S1";
import G20_Prior_Knowledge_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S2";
import G20_Prior_Knowledge_S3 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S3";
import G20_Prior_Knowledge_S4 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S4";
import G21_Make_Observations from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G21_Make_Observations";

import G22_Make_Comparisons from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G22_Make_Comparisons";
import G23_Analyze_Data from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G23_Analyze_Data";
import G24_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G24_Engage_Sensemaking";
import G25_Checkpoint from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G25_Checkpoint";
import G26_Navigate from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G26_Navigate";

import G27_Prior_Knowledge_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G27_Prior_Knowledge_S1";
import G27_Prior_Knowledge_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G27_Prior_Knowledge_S2";
import G28_Connect_Ideas from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G28_Connect_Ideas";
import G29_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G29_Engage_Sensemaking";
import G30_Earn_Words_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G30_Earn_Words_S1";
import G30_Earn_Words_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G30_Earn_Words_S2";
import G30_Earn_Words_S3 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G30_Earn_Words_S3";
import G31_Engage_Argument_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G31_Engage_Argument_S1";
import G31_Engage_Argument_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G31_Engage_Argument_S2";
import G32_Checkpoint from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G32_Checkpoint";
import G33_Navigate from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G33_Navigate";

import G34_Prior_Knowledge_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G34_Prior_Knowledge_S1";
import G34_Prior_Knowledge_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G34_Prior_Knowledge_S2";
import G35_Anchoring_Phenomenon from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G35_Anchoring_Phenomenon";
import G36_Initial_Ideas_S1 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G36_Initial_Ideas_S1";
import G36_Initial_Ideas_S2 from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G36_Initial_Ideas_S2";
import G37_Engage_Sensemaking from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G37_Engage_Sensemaking";
import G38_Navigate from "../../../LessonLevel/grade2/TeacherGuideLines/Lesson-g3/G38_Navigate";

function ContainerGuide({ sections }) {
  const [isActiveScroll, setIsActiveScroll] = useState(0);
  const [scrollhide, setScrollHide] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const sections = document.querySelectorAll("section[id]");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          scrollPosition >= sectionTop - sectionHeight * 0.2 &&
          scrollPosition < sectionTop + sectionHeight * 0.2
        ) {
          setIsActiveScroll(section.id);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="two-column-content">
        <aside className="table-of-content-menu">
          <nav className="sidebar card accordion-menu-listings">
            <ul className="nav flex-column" id="nav_accordion">
              <li className="level-one opacity-75">
                <a
                  className="nav-link"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Lesson 1 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse" id="home-collapse">
                  <ul className="nav flex-column submenu btn-toggle-navl">
                    <li className="level-two">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse"
                        aria-expanded="false"
                      >
                        How does temperature affect other types of matter?{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#performance">
                              Performance Expectations
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#elements">
                              Elements Developed in This Lesson
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#lessonsummary">
                              Lesson Summary
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#teacherbackground">
                              Teacher Background
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#priorconceptions">
                              Prior Conceptions
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#earnedwords">
                              Earned Words
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#materiallist">
                              Materials list
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded="false"
                      >
                        Procedure & Guidance{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse1">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#parta">
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partb">
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partc">
                              Part C
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partd">
                              Part D
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-three">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded="false"
                      >
                        Supporting All Learners
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse1">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#parta">
                              Supporting All Learners
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-four">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded="false"
                      >
                        Teacher Resources
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse1">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Lesson Resource 3.1: Observable Properties of
                              Matter
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              A
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              B
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              C
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="level-one opacity-75">
                <a
                  className="nav-link"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Lesson 2 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse" id="home-collapse">
                  <ul className="nav flex-column submenu btn-toggle-navl">
                    <li className="level-two">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse"
                        aria-expanded="false"
                      >
                        How does temperature affect other types of matter?{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#performance">
                              Performance Expectations
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#elements">
                              Elements developed in the lesson
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#lessonsummary">
                              Lesson Summary
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#teacherbackground">
                              Teacher Background
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#priorconceptions">
                              Prior Conceptions
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#earnedwords">
                              Earned Words
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#materiallist">
                              Material list
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded="false"
                      >
                        Procedure and Teacher Guidance{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse1">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#parta">
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partb">
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partc">
                              Part C
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partd">
                              Part D
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Supporting All Learners
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Lesson Resource 3.1 : Observable Properties of
                              Matter
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              A
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              B
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              C
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="level-one">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse3"
                  aria-expanded="false"
                  onClick={() => setScrollHide(4)}
                >
                  Lesson 3 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse show" id="home-collapse3">
                  <ul className="mt-15 nav flex-column submenu btn-toggle-navl">
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          scrollhide === 0 ? "nav-link active" : "nav-link"
                        }
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse"
                        aria-expanded={scrollhide === 0 ? "true" : "false"}
                      >
                        How does temperature affect other types of matter?{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 0 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse"
                      >
                        <ul className="nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "performance"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#performance"
                            >
                              Performance Expectations
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "elements"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#elements"
                            >
                              Elements Developed in This Lesson
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "lessonsummary"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#lessonsummary"
                            >
                              Lesson Summary
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "teacherbackground"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#teacherbackground"
                            >
                              Teacher Background
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "priorconceptions"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#priorconceptions"
                            >
                              Prior Conceptions
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "earnedwords"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#earnedwords"
                            >
                              Earned Words
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "materiallist"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#materiallist"
                            >
                              Materials List
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        className={
                          scrollhide === 1 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setScrollHide(1)}
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded={scrollhide === 1 ? "true" : "false"}
                      >
                        Procedure & Guidance{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 1 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse1"
                      >
                        <ul className="nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "parta"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#parta"
                            >
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partb"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partb"
                            >
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partc"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partc"
                            >
                              Part C
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partd"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partd"
                            >
                              Part D
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(2)}
                        className={
                          isActiveScroll === "supporting"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#supporting"
                      >
                        Supporting All Learners
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        className={
                          scrollhide === 3 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setScrollHide(3)}
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse3"
                        aria-expanded={scrollhide === 3 ? "true" : "false"}
                      >
                        Teacher Resources
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 3 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse3"
                      >
                        <ul className="nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "observable"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#observable"
                            >
                              Lesson Resource 3.1: Observable Properties of
                              Matter
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "notebooka"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#notebooka"
                            >
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              A
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "notebookb"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#notebookb"
                            >
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              B
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "notebookc"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#notebookc"
                            >
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              C
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="level-one opacity-75">
                <a
                  className="nav-link"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Lesson 4 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse" id="home-collapse">
                  <ul className="nav flex-column submenu btn-toggle-navl">
                    <li className="level-two">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse"
                        aria-expanded="false"
                      >
                        How does temperature affect other types of matter?{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#performance">
                              Performance Expectations
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#elements">
                              Elements developed in the lesson
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#lessonsummary">
                              Lesson Summary
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#teacherbackground">
                              Teacher Background
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#priorconceptions">
                              Prior Conceptions
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#earnedwords">
                              Earned Words
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Material list
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        className="nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded="false"
                      >
                        Procedure and Teacher Guidance{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div className="collapse" id="dashboard-collapse1">
                        <ul className="ml-15 nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a className="nav-link" href="#parta">
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partb">
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partc">
                              Part C
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#partd">
                              Part D
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Supporting All Learners
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Lesson Resource 3.1 : Observable Properties of
                              Matter
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              A
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              B
                            </a>
                          </li>
                          <li className="level-three">
                            <a className="nav-link" href="#">
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              C
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </aside>
        <div className="pr-15 pt-15 table-of-content">
          <section id="initial-page" className="mb-20 lesson-banner">
            <img
              src="/image/lesson3_image2.jpg"
              alt="HOW DOES TEMPERATURE AFFECT OTHER TYPES OF MATTER?"
              title="HOW DOES TEMPERATURE AFFECT OTHER TYPES OF MATTER?"
            ></img>
          </section>
          <h5 className="fw-700 text-nexus-white mb-20">
            <span className="gradient-title p-2 pr-40">
              6 sessions of 30 minutes
            </span>
          </h5>
          <section id="performance">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PERFORMANCE</span>{" "}
              <span className="fw-500">EXPECTATIONS</span>
            </h5>
            <p className="fw-700 mb-10">
              Lesson-Level Performance Expectations
            </p>
            <p className="mb-7">In this lesson, students will:</p>
            <ul className="pl-30 circle-listing">
              <li>
                <span className="text-nexus-dark-blue">
                  Plan and conduct an investigation to produce data
                </span>{" "}
                on how a{" "}
                <span className="text-nexus-orange">change in temperature</span>{" "}
                can <span className="text-nexus-green">cause</span> the{" "}
                <span className="text-nexus-orange">
                  observable properties of some types of matter to change.
                </span>{" "}
                (Part B)
              </li>
              <li>
                <span className="text-nexus-dark-blue">
                  Make observations to collect and compare data to describe the
                </span>{" "}
                <span className="text-nexus-green">effect</span>{" "}
                <span className="text-nexus-dark-blue">
                  {" "}
                  of temperature on the
                </span>{" "}
                <span className="text-nexus-orange">
                  observable properties of different types of matter.
                </span>{" "}
                (Part C)
              </li>
              <li>
                <span className="text-nexus-dark-blue">
                  Construct an evidence-based argument
                </span>{" "}
                that describes how the{" "}
                <span className="text-nexus-orange">changes to matter</span>{" "}
                <span className="text-nexus-green">caused</span>{" "}
                <span className="text-nexus-orange">
                  by heating and cooling can sometimes be reversed.
                </span>{" "}
                (Part C)
              </li>
            </ul>
          </section>
          <hr></hr>
          <div id="towards">
            <p className="fw-700 mb-10">
              Building Toward NGSS Performance Expectations
            </p>
            <p className="mb-7">
              The NGSS Performance Expectations set learning goals for what
              students should be able to do by the end of a grade band. This
              lesson builds toward the following Performance Expectations:
            </p>
            <ul className="mb-20 pl-30 circle-listing">
              <li>
                <span className="fw-700">2-PS1-1:</span> Plan and conduct an
                investigation to describe and classify different kinds of
                materials by their observable properties.
              </li>
              <li>
                <span className="fw-700">2-PS1-4:</span> Construct an argument
                with evidence that some changes caused by heating or cooling can
                be reversed and some cannot.
              </li>
            </ul>
            <section className="lesson-banner">
              <img
                src="/image/lesson3_image3.png"
                alt="Building Toward NGSS Performance Expectations"
                title="Building Toward NGSS Performance Expectations"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="elements">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">ELEMENTS</span>{" "}
              <span className="fw-500">DEVELOPED IN THIS LESSON</span>
            </h5>
            <p className="mb-10">
              When developing an element, students build on their previous
              knowledge and experience to make progress toward grade-band
              expectations for the element. These elements are what make up the
              Lesson-Level Performance Expectations.
            </p>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle background-nexus-orange w-25">
                      <p>Disciplinary Core Ideas</p>
                      <p className="fw-500">
                        Fundamental scientific ideas that form content
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-darkblue w-25">
                      <p>Science and Engineering Practices</p>
                      <p className="fw-500">
                        What students do to make sense of phenomena
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-green w-25">
                      <p>Crosscutting Concepts</p>
                      <p className="fw-500">
                        A way of linking the different domains of science
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nexus-orange">
                      <span className="fw-700">
                        PS1.A: Structure and Properties of Matter:
                      </span>{" "}
                      Different kinds of matter exist and many of them can be
                      either solid or liquid, depending on temperature. Matter
                      can be described and classified by its observable
                      properties.
                    </td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Planning and Carrying Out Investigations:
                      </span>{" "}
                      Plan and conduct an investigation collaboratively to
                      produce data to serve as the basis for evidence to answer
                      a question.
                    </td>
                    <td className="text-nexus-green">
                      <span className="fw-700">Cause and Effect:</span> Events
                      have causes that generate observable patterns.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nexus-orange">
                      <span className="fw-700">PS1.B: Chemical Reactions:</span>{" "}
                      Heating or cooling a substance may cause changes that can
                      be observed. Sometimes these changes are reversible and
                      sometimes they are not.
                    </td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Planning and Carrying Out Investigations:
                      </span>{" "}
                      Make observations (firsthand or from media){" "}
                      <span className="text-decoration-line-through">
                        and/or make measurements
                      </span>{" "}
                      to collect data that can be used to make comparisons.
                    </td>
                    <td className="text-nexus-green"></td>
                  </tr>
                  <tr>
                    <td className="text-nexus-orange"></td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Engaging in Argument from Evidence:
                      </span>{" "}
                      Construct an argument with evidence to support a claim.
                    </td>
                    <td className="text-nexus-green"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">
              * The parts of each element that are crossed out are not addressed
              in the lesson.
            </p>
          </section>
          <hr></hr>
          <section id="lessonsummary">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">LESSON</span>{" "}
              <span className="fw-500">SUMMARY</span>
            </h5>
            <p>
              This lesson is driven by students’ questions related to their
              ideas about the furnace, including why the glassblower keeps
              putting the glass back into the furnace and why the pole doesn’t
              melt like the pieces of glass do. Students gather evidence to help
              answer these questions when they engage in investigations that
              focus on why{" "}
              <span className="text-nexus-orange">
                some types of matter change when heated or cooled.
              </span>{" "}
              They{" "}
              <span className="text-nexus-dark-blue">
                use their observations as evidence to construct an argument
              </span>{" "}
              to explain the <span className="text-nexus-green">effect</span> of
              temperature on objects made of different types of matter and
              whether or not these{" "}
              <span className="text-nexus-orange">changes can be undone.</span>
            </p>
          </section>
          <hr></hr>
          <section id="teacherbackground">
            <div className="row">
              <div className="col-12 col-md-10 mb-10">
                <h5 className="fw-700 mb-10 text-decoration-underline">
                  <span className="text-nexus-blue">TEACHER</span>{" "}
                  <span className="fw-500">BACKGROUND</span>
                </h5>
                <p className="mb-10">
                  Nearly all matter has a temperature at which it changes. The
                  most common change is a change in state. (Students are not
                  expected to use or learn the term “state” in this unit.) Many
                  types of matter can be solid, liquid, or gas at different
                  temperatures. In many instances, matter at high temperatures
                  is liquid or gas, and matter at cooler temperatures is solid.
                  In some instances, a higher temperature may result in a more
                  flexible solid, such as glass and melted cheese. Students will
                  explore and identify only liquids and solids in this unit.
                  They will progress in their understanding of matter in grade 5
                  when they learn about gases.
                </p>
              </div>
              <div className="col-12 col-md-2 mb-10">
                <img
                  src="/image/lesson3_image4.jpg"
                  alt="TEACHER BACKGROUND"
                  title="TEACHER BACKGROUND"
                ></img>
              </div>
            </div>
            <p className="mb-10">
              Some of the changes that occur can be reversed. Think of water,
              for example. It can be cooled from a liquid into solid ice, warmed
              back up into a liquid, and cooled again into ice. Many examples of
              melting are reversible, including melting chocolate, butter, gold,
              and ice cream, although these substances may not return to their
              original form or shape when the change is reversed. Other changes
              caused by temperature are irreversible, including burning a
              marshmallow, cooking an egg, or popping popcorn.
            </p>
            <p>
              Changes to some items, including glass, can be confusing. When
              glass breaks, it is considered an irreversible change because
              those pieces cannot be put back together into their original form.
              On the other hand, the heating of glass is usually considered a
              reversible process because the pieces can be melted together again
              and again and shaped into any object.
            </p>
          </section>
          <hr></hr>
          <section id="priorconceptions">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PRIOR</span>{" "}
              <span className="fw-500">CONCEPTIONS</span>
            </h5>
            <p className="mb-10">
              Students may come to this lesson with preconceptions or
              misconceptions around the science ideas presented in this unit.
              Such ideas will be addressed as part of the student sensemaking
              process throughout this unit. Listed below are misconceptions you
              are likely to hear from your students in this lesson. The correct
              science idea follows in parenthesis.
            </p>
            <p className="mb-7">
              This lesson will address the following misconceptions:
            </p>
            <ul className="pl-30 circle-listing">
              <li>
                Solids are matter, but liquids are not.{" "}
                <span className="text-i">
                  (All things, big and small, are made of matter. This includes
                  all solids and liquids. Some students may know that gas is
                  also made of matter, but this is a grade 5 idea.)
                </span>
              </li>
              <li>
                When matter changes from solid to liquid, it becomes a new type
                of matter.{" "}
                <span className="text-i">
                  (When matter changes from solid to liquid or liquid to solid,
                  its observable properties change, but the type of matter
                  remains the same [e.g., When solid ice warms and changes to
                  liquid water, it is still water].)
                </span>
              </li>
              <li>
                All changes to matter are permanent.{" "}
                <span className="text-i">
                  (Most matter can undergo change. Some changes cannot be
                  undone, like frying an egg or burning a piece of paper. Other
                  changes can be undone, such as melting an ice cube and
                  refreezing it or melting gold and reshaping it into a new
                  piece of jewelry.)
                </span>
              </li>
              <li>
                All changes to matter can be undone.{" "}
                <span className="text-i">
                  (Most matter can undergo change. Some changes cannot be
                  undone, like frying an egg or burning a piece of paper. Other
                  changes can be undone, such as melting an ice cube and
                  refreezing it or melting gold and reshaping it into a new
                  piece of jewelry.)
                </span>
              </li>
            </ul>
          </section>
          <hr></hr>
          <section id="earnedwords">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">EARNED</span>{" "}
              <span className="fw-500">WORDS</span>
            </h5>
            <p className="mb-10">
              A word is earned on the Word Wall when the class demonstrates a
              strong understanding of that key term. Until a word is earned,
              encourage students to use everyday language in discussions and
              work products. Opportunities to earn words are called out in the
              lesson procedure.
            </p>
            <p className="mb-10">
              By the end of this lesson, students will earn the following key
              terms for the Word Wall:
            </p>
            <div className="d-flex justify-content-center">
              <p className="font-20 mr-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Irreversible
              </p>
              <p className="font-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Reversible
              </p>
            </div>
          </section>
          <hr></hr>
          <section id="materiallist">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">MATERIALS</span>{" "}
              <span className="fw-500">LIST</span>
            </h5>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part A</th>
                    <th className="text-center align-middle w-50">Part B</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="mb-20 pl-30 circle-listing">
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Revisiting the Furnace video</li>
                            <li>Initial Ideas Class Chart</li>
                            <li>Testing Our Ideas Class Chart</li>
                          </ul>
                        </li>
                        <li>32 Science Notebooks*</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="mb-20 pl-30 circle-listing">
                        <li>
                          5 Lesson Resource 3.1:{" "}
                          <span className="text-i">
                            Observable Properties Checklist<sup>*</sup>
                          </span>
                        </li>
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              Heating and Cooling Different Types of Matter
                              videos
                            </li>
                            <li>Our Plan Class Chart</li>
                          </ul>
                        </li>
                        <li>
                          Food objects:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              1 TBSP Butter<sup>‡</sup>
                            </li>
                            <li>
                              5 Chocolate chips<sup>‡</sup>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Metal objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              3 Coins<sup>‡</sup>
                            </li>
                            <li>
                              3 Paper clips<sup>‡</sup>
                            </li>
                            <li>2 Washers</li>
                          </ul>
                        </li>
                        <li>
                          Plastic objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              3 Marker caps<sup>‡</sup>
                            </li>
                            <li>
                              2 Plastic forks<sup>‡</sup>
                            </li>
                          </ul>
                        </li>
                        <li>32 Science notebooks*</li>
                        <li>
                          Wax objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>2 Candles</li>
                            <li>
                              5 Crayons<sup>‡</sup>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Wood objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>2 Craft sticks</li>
                            <li>
                              2 Pencils<sup>‡</sup>
                            </li>
                            <li>2 Wood blocks</li>
                          </ul>
                        </li>
                        {/* <li>32 Science Notebooks*</li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part C</th>
                    <th className="text-center align-middle w-50">Part D</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          32 Devices (tablets, laptops, etc.)<sup>‡</sup>
                        </li>
                        <li>
                          Chart paper<sup>‡</sup>
                        </li>
                        <li>
                          Number Line<sup>†</sup>
                        </li>
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Temperature Simulation</li>
                          </ul>
                        </li>
                        <li>
                          Markers<sup>‡</sup>
                        </li>
                        <li>32 Science notebooks*</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Initial Ideas Class Chart (from Part A)</li>
                          </ul>
                        </li>
                        <li>
                          1 Pair of scissors<sup>‡</sup>
                        </li>
                        <li>
                          1 Rubber band<sup>‡</sup>
                        </li>
                        <li>32 Science notebooks*</li>
                        <li>
                          1 Sheet of paper<sup>‡</sup>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">*Accessible online</p>
            <p className="font-14">† Prepared by the teacher</p>
            <p className="font-14">‡ Needed but not supplied</p>
          </section>
          <hr></hr>
          <section className="mb-20 lesson-banner">
            <img
              src="/image/lesson3_image1.jpg"
              alt="PROCEDURE AND TEACHER GUIDANCE"
              title="PROCEDURE AND TEACHER GUIDANCE"
            ></img>
          </section>
          <section id="parta">
            <PartA />
          </section>
          <div className="table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P1_Prior_knowledge />
                  </td>
                  <td>
                    <G1_Prior_knowledge />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P2_Make_Observations_S1 />
                  </td>
                  <td>
                    <G2_Make_Observations_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P2_Make_Observations_S2 />
                  </td>
                  <td>
                    <G2_Make_Observations_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P3_Make_Personal />
                  </td>
                  <td>
                    <G3_Make_Personal />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Develop_Initial_S1 />
                  </td>
                  <td>
                    <G4_Develop_Initial_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Develop_Initial_S2 />
                  </td>
                  <td>
                    <G4_Develop_Initial_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Develop_Initial_S3 />
                  </td>
                  <td>
                    <G4_Develop_Initial_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Develop_Initial_S4 />
                  </td>
                  <td>
                    <G4_Develop_Initial_S4 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Develop_Initial_S5 />
                  </td>
                  <td>
                    <G4_Develop_Initial_S5 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P5_Ask_Questions_S1 />
                  </td>
                  <td>
                    <G5_Ask_Questions_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P5_Ask_Questions_S2 />
                  </td>
                  <td>
                    <G5_Ask_Questions_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P6_Navigate />
                  </td>
                  <td>
                    <G6_Navigate />
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="partb">
            <PartB />
          </section>
          <div className="table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P7_Prior_Knowledge />
                  </td>

                  <td>
                    <G7_Prior_Knowledge />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P8_Make_Observations_S1 />
                  </td>

                  <td>
                    <G8_Make_Observations_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P8_Make_Observations_S2 />
                  </td>

                  <td>
                    <G8_Make_Observations_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P9_Engage_Sensemaking_S1 />
                  </td>

                  <td>
                    <G9_Engage_Sensemaking_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P9_Engage_Sensemaking_S2 />
                  </td>

                  <td>
                    <G9_Engage_Sensemaking_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P10_Plan_Investigation_S1 />
                  </td>

                  <td>
                    <G10_Plan_Investigation_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P10_Plan_Investigation_S2 />
                  </td>

                  <td>
                    <G10_Plan_Investigation_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P10_Plan_Investigation_S3 />
                  </td>

                  <td>
                    <G10_Plan_Investigation_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P11_Checkpoint />
                  </td>

                  <td>
                    <G11_Checkpoint />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P12_Navigate />
                  </td>

                  <td>
                    <G12_Navigate />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P13_Prior_Knowledge />
                  </td>

                  <td>
                    <G13_Prior_Knowledge />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P14_Make_Observations_S1 />
                  </td>
                  <td>
                    <G14_Make_Observations_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P14_Make_Observations_S2 />
                  </td>
                  <td>
                    <G14_Make_Observations_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P14_Make_Observations_S3 />
                  </td>
                  <td>
                    <G14_Make_Observations_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P15_Make_Predictions />
                  </td>

                  <td>
                    <G15_Make_Predictions />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P16_Make_Observations />
                  </td>

                  <td>
                    <G16_Make_Observations />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P17_Engage_Sensemaking />
                  </td>

                  <td>
                    <G17_Engage_Sensemaking />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P18_Make_Personal />
                  </td>

                  <td>
                    <G18_Make_Personal />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P19_Navigate />
                  </td>

                  <td>
                    <G19_Navigate />
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="partc">
            <PartC />
          </section>
          <div className="mb-10 table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P20_Prior_Knowledge_S1 />
                  </td>
                  <td>
                    <G20_Prior_Knowledge_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P20_Prior_Knowledge_S2 />
                  </td>

                  <td>
                    <G20_Prior_Knowledge_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P20_Prior_Knowledge_S3 />
                  </td>

                  <td>
                    <G20_Prior_Knowledge_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P20_Prior_Knowledge_S4 />
                  </td>

                  <td>
                    <G20_Prior_Knowledge_S4 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P21_Make_Observations />
                  </td>

                  <td>
                    <G21_Make_Observations />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P22_Make_Comparisons />
                  </td>

                  <td>
                    <G22_Make_Comparisons />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P23_Analyze_Data />
                  </td>

                  <td>
                    <G23_Analyze_Data />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P24_Engage_Sensemaking />
                  </td>

                  <td>
                    <G24_Engage_Sensemaking />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P25_Checkpoint />
                  </td>

                  <td>
                    <G25_Checkpoint />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P26_Navigate />
                  </td>

                  <td>
                    <G26_Navigate />
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P27_Prior_Knowledge_S1 />
                  </td>

                  <td>
                    <G27_Prior_Knowledge_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P27_Prior_Knowledge_S2 />
                  </td>

                  <td>
                    <G27_Prior_Knowledge_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P28_Connect_Ideas />
                  </td>

                  <td>
                    <G28_Connect_Ideas />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P29_Engage_Sensemaking />
                  </td>

                  <td>
                    <G29_Engage_Sensemaking />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P30_Earn_Words_S1 />
                  </td>

                  <td>
                    <G30_Earn_Words_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P30_Earn_Words_S2 />
                  </td>

                  <td>
                    <G30_Earn_Words_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P30_Earn_Words_S3 />
                  </td>

                  <td>
                    <G30_Earn_Words_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P31_Engage_Argument_S1 />
                  </td>

                  <td>
                    <G31_Engage_Argument_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P31_Engage_Argument_S2 />
                  </td>

                  <td>
                    <G31_Engage_Argument_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P32_Checkpoint />
                  </td>

                  <td>
                    <G32_Checkpoint />
                  </td>
                </tr>
                {/* <tr>
                    <td>
                      <P33_Navigate />
                    </td>
                    <td>
                      <G33_Navigate />
                    </td>
                  </tr> */}
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="partd">
            <PartD />
          </section>
          <div className="table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P34_Prior_Knowledge_S1 />
                  </td>
                  <td>
                    <G34_Prior_Knowledge_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P34_Prior_Knowledge_S2 />
                  </td>
                  <td>
                    <G34_Prior_Knowledge_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P35_Anchoring_Phenomenon />
                  </td>
                  <td>
                    <G35_Anchoring_Phenomenon />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P36_Initial_Ideas_S1 />
                  </td>
                  <td>
                    <G36_Initial_Ideas_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P36_Initial_Ideas_S2 />
                  </td>
                  <td>
                    <G36_Initial_Ideas_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P37_Engage_Sensemaking />
                  </td>
                  <td>
                    <G37_Engage_Sensemaking />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P38_Navigate />
                  </td>
                  <td>
                    <G38_Navigate />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
            <img
              src="/image/lesson-images/session_break.svg"
              alt="session_break"
              title="session_break"
            ></img>
          </section>
          <hr></hr>
          <section id="supporting">
            <p className="fw-700 mb-10 p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
              SUPPORTING ALL LEARNERS
            </p>
            <p className="mb-10">
              After completing this lesson, consider the following options for
              remediation or extension:
            </p>
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="background-nexus-blue align-middle">
                      REMEDIATION
                    </th>
                    <th className="background-nexus-blue align-middle">
                      EXTENSION
                    </th>
                  </tr>
                </thead>
                <tbody className="light-blue-section">
                  <tr>
                    <td className="fw-700">
                      Reversible and Irreversible Changes
                    </td>
                    <td className="fw-700">What Is Lava?</td>
                  </tr>
                  <tr>
                    <td>
                      If students struggle to identify the relationship between
                      temperature and changes the observable properties of
                      matter, use the following activity:
                    </td>
                    <td>
                      If students express high interest in or strong
                      understanding of the lesson’s concepts, consider providing
                      the following activity:
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">1.</span>
                        <span className="number-text">
                          Show students pictures of different materials that are
                          liquid or solid at room temperature but that are
                          easily changed by heating or cooling (e.g., egg, wood,
                          popcorn, ice cream, cake batter, gold, water, etc.).
                          Have students sort the materials into two groups:
                          solid and liquid.
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">1.</span>
                        <span className="number-text">
                          Invite students to research lava to find out what it
                          is made of, how it is made, and what happens when it
                          is cooled.
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">2.</span>
                        <span className="number-text">
                          Use pictures or videos to demonstrate what happens
                          when each material is heated (e.g., bubbles form in
                          some liquids, some solids turn to liquids, some solids
                          burn up, and other liquids turn to solid).
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">2.</span>
                        <span className="number-text">
                          Encourage students to compare the creation of lava to
                          what they explored in this lesson.
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">3.</span>
                        <span className="number-text">
                          Encourage students to identify what caused these
                          changes to the different types of matter.
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">3.</span>
                        <span className="number-text">
                          Invite students to share their research with the
                          class. Their presentations should focus on how
                          extremely hot temperatures changed the
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">4.</span>
                        <span className="number-text">
                          Prompt students to sort the materials again based on
                          what happened when the materials were heated.
                        </span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">5.</span>
                        <span className="number-text">
                          Use pictures or videos to demonstrate what happens
                          when each material is cooled. Prompt students to
                          identify what caused these changes to the different
                          types of matter.
                        </span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mb-10 d-flex guidence-count">
                        <span className="number pr-5">6.</span>
                        <span className="number-text">
                          Have students sort the materials into two new groups:
                          if the changes can be undone (reversible) or cannot be
                          undone (irreversible).
                        </span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <hr></hr>
          <LessonResources />
        </div>
      </div>
    </>
  );
}

export default ContainerGuide;
