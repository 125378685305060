import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P2_Make_Observations_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P2"} seriesCount={"S3"}>
        <div className="mb-10 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">3.</span>
          <span className="number-text">(continued)</span>
        </div>
        <p className="pl-20 mb-7">Next, ask:</p>
        <ul className="pl-45 mb-10 circle-listing">
          <li>
            What question could we ask about these pictures that will help us
            figure out a reason for the different stars we see?
          </li>
        </ul>
        <p className="pl-20">
          Allow time for students to share their ideas with the class.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P2_Make_Observations_S3;
