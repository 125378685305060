import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G9_Navigation() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G9"}>
        {/* <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G9_Navigation;
