import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G17_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"G17"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon">
          <img
            src="/image/lesson-images/misconceptions.svg"
            alt="misconceptions"
            title="misconceptions"
          ></img>
        </div>
        <span className="fw-700">Misconceptions:</span> The purpose of this
        discussion is for students to begin to develop an understanding that
        higher temperatures can cause some types of matter to change from solid
        to liquid. At this point, students may still hold the following
        misconceptions:
      </section>

      <ul className="pl-30 pb-10 circle-listing">
        <li>Solids are matter, but liquids are not.</li>
        <li>
          When matter changes from solid to liquid it becomes a new type of
          matter.
        </li>
      </ul>
      <p>
        If needed, address these misconceptions by asking students to think
        about water. Even when water freezes into ice, it is still water. When
        the ice melts, it is still water.
      </p>
    </IdGeneratingComponent>
  );
}

export default G17_Engage_Sensemaking;
