import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P30_Earn_Words_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P30"} seriesCount={"S1"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">14.</span>
        <span className="number-text">
          <span className="fw-700">Earn Words:</span> Explain that a change that
          can be undone is called a reversible change. Invite students to make a
          connection to the word “reversible.” Then, have them use the number
          line to describe which types of matter underwent a reversible change{" "}
          <span className="text-i">
            (food, wax, glass, plastic, and metal).
          </span>
        </span>
      </p>
      <p className="pl-25">(continued)</p>
    </IdGeneratingComponent>
  );
}

export default P30_Earn_Words_S1;
