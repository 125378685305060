import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P35_Anchoring_Phenomenon() {
  return (
    <IdGeneratingComponent guideLineCount={"P35"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">3.</span>
        <span className="number-text">
          <span className="fw-700">Connect to the Anchoring Phenomenon:</span>{" "}
          <span>Deepen the discussion by asking:</span>
        </span>
      </p>
      <ul className="pl-45 circle-listing">
        <li>
          How does what we learned about temperature and the changes it can
          cause relate to our anchoring phenomenon?
        </li>
        <li>
          How does what we learned about reversible and irreversible changes
          help us in our everyday lives?
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P35_Anchoring_Phenomenon;
