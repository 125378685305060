import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G35_Anchoring_Phenomenon() {
  return (
    <IdGeneratingComponent guideLineCount={"G35"}>
      <p className="mb-7">
        The purpose of this discussion is to connect the science ideas they
        developed back to the anchoring phenomenon. Listen for students to make
        the following connections:
      </p>
      <ul className="pl-30 circle-listing">
        <li>
          The glassblower needed the high temperature of the furnace to melt the
          pieces of glass.
        </li>
        <li>
          Melting the pieces made the glass more flexible. The glassblower could
          then shape the glass into something new.
        </li>
        <li>
          Breaking glass is an irreversible change because the pieces cannot be
          put back together.
        </li>
        <li>
          Melting glass is a reversible change because the glass can be heated
          again and again.
        </li>
        <li>
          We can use this information about temperature and matter to change
          matter in ways to better help us.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G35_Anchoring_Phenomenon;
