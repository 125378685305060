import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Modal from "react-bootstrap/Modal";

function T17_Connect_to_Prior_Knowledge_S2() {
  const [lgShow, setLgShow] = useState(false);
  const [updatestate, setUpdatedState] = useState(0);

  const setSelectedPage = (index) => {
    setUpdatedState(index);
  };
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  Do you think what we have learned so far helps us better
                  understand what is happening in these pictures? Why or why
                  not?
                </li>
                <li>
                  Do we have enough evidence to confidently and accurately
                  explain what we see?
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="p-3 dashed-border-nexus-blue light-blue-section">
          <div className="col-12 col-md-12">
            <div className="shadow">
              <img
                src="/image/sum-night.png"
                alt="summer-and-winter-night-skies"
                title="summer-and-winter-night-skies"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T17_Connect_to_Prior_Knowledge_S2;
