import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G10_Connect_to_Prior_Knowledge() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G10"}>
        <p className="mb-7">
          <span className="fw-700">Scaffolding:</span> Use the following prompts
          to help students review the last session:
        </p>
        <ul className="pl-30 circle-listing">
          <li>What did we discover in our last session?</li>
          <li>What words did we earn?</li>
          <li>What do those words mean?</li>
          <li>Which objects move in the solar system? How do they move?</li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G10_Connect_to_Prior_Knowledge;
