import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G36_Initial_Ideas_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G36"} seriesCount={"S1"}>
      <p>
        <span className="fw-700">Scaffolding:</span> If time permits, consider
        having students use a different color to cross out ideas they no longer
        agree with and record any new ideas to explain these events.
      </p>
    </IdGeneratingComponent>
  );
}

export default G36_Initial_Ideas_S1;
