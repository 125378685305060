import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G33_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"G33"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G33_Navigate;
