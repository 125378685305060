import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

function T21_Make_Observations() {
  const [showiframe, setShowIframe] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  const PopupShow = () => {
    setShowIframe(true);
  };
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Explore the effects of heating and cooling on the other types of
              matter.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="mb-20 shadow">
              <img
                src="/image/lesson-images/temperature_simulations.png"
                alt="Temperature Simulations"
                title="Temperature Simulations"
              ></img>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div onClick={() => PopupShow()} className="btn btn-primary d-flex">
            <span className="pr-10">Play Simulation</span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0.799988C5.99998 0.799988 0.799988 5.99999 0.799988 12.5C0.799988 19 5.99998 24.2 12.5 24.2C19 24.2 24.2 19 24.2 12.5C24.2 5.99999 19 0.799988 12.5 0.799988ZM9.29998 17.6V7.39999L18.1 12.5L9.29998 17.6Z"
                stroke="white"
                stroke-width="1.2"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
          {showiframe === true ? (
            <Modal
              className="modal-fullscreen"
              show={showiframe}
              fullscreen={fullscreen}
              onHide={() => {
                setShowIframe(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Temperature Simulation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <iframe
                  src="http://msmstudio.pl/CBS/temperature/"
                  width="1510"
                  className="d-flex h-100"
                  frameborder="0"
                  allowfullscreen
                  webkitallowfullscreen
                  mozallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                ></iframe>
              </Modal.Body>
            </Modal>
          ) : null}
        </div>
        <p className="mt-30 font-16">
          Record your observations in the data table.
        </p>
      </div>
    </>
  );
}

export default T21_Make_Observations;
