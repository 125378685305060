import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P3_Develop_Initial_Ideas_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P3"} seriesCount={"S1"}>
        <div className="mb-7 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">4.</span>
          <span className="number-text">
            <span className="fw-700">Develop Initial Ideas:</span> Direct
            students’ attention back to the images and ask:
          </span>
        </div>
        <ul className="pl-45 pb-15 circle-listing">
          <li>What do you think is happening?</li>
          <li>Can you try to explain the differences?</li>
        </ul>
        <section className="pl-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="">
              Have students record their ideas in the Initial Ideas section of
              their science notebooks and then share them with a partner (page
              30).
            </p>
          </div>
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default P3_Develop_Initial_Ideas_S1;
