import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P32_Checkpoint() {
  return (
    <IdGeneratingComponent guideLineCount={"P32"}>
      <div className="d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">18.</span>
        <span className="number-text">
          <span className="fw-700">Checkpoint:</span> Prompt students to answer
          the checkpoint questions in their science notebooks to formatively
          assess their understanding of reversible and irreversible changes
          (page 17).
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P32_Checkpoint;
