import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G22_Make_Comparisons() {
  return (
    <IdGeneratingComponent guideLineCount={"G22"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon icon-big">
          <img
            src="/image/lesson-images/discipliary_crosscutting.svg"
            alt="discipliary_crosscutting"
            title="discipliary_crosscutting"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.B,</span>{" "}
        <span className="text-nexus-green fw-700">Cause and Effect:</span> The
        simulation helps students identify patterns that result from a change in
        temperature. Students use these relationships to further develop the
        following science ideas:
      </section>
      <ul className="pl-30 pb-10 circle-listing">
        <li>
          Heating and cooling a substance may cause changes that can be
          observed.
        </li>
        <li>
          Different kinds of materials exist and many of them can be either
          solid or liquid, depending on the temperature.
        </li>
        <li>Some changes can be undone while others cannot.</li>
      </ul>
      <p className="pb-7">
        <span className="fw-700">Scaffolding:</span> Provide the following
        prompts to help students identify cause-and-effect relationships:
      </p>
      <ul className="pl-30 circle-listing">
        <li>
          Was each object affected by the same temperature? Why do you think
          that is?
        </li>
        <li>What happened when you cooled the different types of matter?</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G22_Make_Comparisons;
