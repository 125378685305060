import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G12_Collect_Represent_and_Analyze_Data_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G12"} seriesCount={"S1"}>
        <p className="mb-7">
          <span className="fw-700">Differentiation:</span> As needed, review the
          steps for making a bar graph:
        </p>
        <ul className="pl-30 mb-10 circle-listing">
          <li>Title the graph so people know what the data is about.</li>
          <li>
            Label both axes. The horizontal axis (x-axis) shows the month. The
            vertical axis (y-axis) shows the number of hours.
          </li>
          <li>Decide on a scale for each axis and add it to the label.</li>
          <li>Graph the data.</li>
        </ul>
        <section className="mb-20 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>
          <span className="fw-700">Formative Assessment Opportunity:</span> Look
          for students to represent the data they calculated for the number of
          daylight hours throughout the year graphically to display the pattern
          that daylight hours increase until of June, when they begin to
          decrease.
        </section>
        <section className="guidelines-blue-section">
          <div className="title">
            <p className="fw-700">
              Math Connection{" "}
              <span className="text-nexus-light-blue">( x ÷ = )</span>
            </p>
          </div>
          <div className="p-2 text">
            <p>
              Students analyze the relationship between sunrise and sunset to
              find the average hours of daylight. They represent and interpret
              data when they create a graph to reveal a pattern. (5.0A.3,5.MD.2)
            </p>
          </div>
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G12_Collect_Represent_and_Analyze_Data_S1;
