import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P4_Develop_Initial_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S2"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">6.</span>
          <span className="number-text">
            After sharing with a partner, have students record their thinking in
            the Initial Ideas section of their science notebooks.
          </span>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default P4_Develop_Initial_S2;
