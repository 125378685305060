import React from "react";

function T9_Engage_Sensemaking_S2() {
  return (
    <>
      <div className="lesson-brief">
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>
              How could we test whether the pole is made of ________ instead of
              another type of matter?
            </li>
          </ul>
        </div>
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">Share your ideas with your class.</p>
          </div>
        </section>
      </div>
    </>
  );
}

export default T9_Engage_Sensemaking_S2;
