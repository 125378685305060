import React, { useState } from "react";
import LessonAssessmentData from "../../LessonCommon/grade5/LessonAssessmentData";
import Modal from "react-bootstrap/Modal";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.js";
import BreadcumbComponent from "../../Common/BreadcrumbComponent_2";
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

function LessonAssessment() {
  const [showpopup, setShowPopup] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [updateindex, setUpdateIndex] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const ShowPopup = (_index) => {
    setShowPopup(true);
    setUpdateIndex(_index);
  };

  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Earth and Space Science | Unit 1",
              path: "/grade5/glassblowing",
            }}
            Child={{
              title: "Lesson 3 - Assessment",
            }}
          />
        </h1>
      </div>
      <section className="mt-20 home-modules-list lesson-module-list teacher-resources-listing">
        <div className="row g-4 row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
          {LessonAssessmentData.map((x) => {
            return x.Assessments.map((y, index) => {
              return (
                <>
                  {y.PdfUrl === "" ? (
                    <>
                      <div
                        // onClick={() => ShowPopup(index)}
                        className="col opacity-50"
                      >
                        <div className="card h-100 module lesson-module teacher-resources-module">
                          <div className="teacher-resources-image">
                            <img src={y.ImageUrl} />
                          </div>
                          <div className="card-body">
                            <h6 className="fw-700 lesson-module-title teacher-resources-module-title">
                              {y.AssessmentName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col">
                        <div
                          onClick={() => ShowPopup(index)}
                          className="card h-100 module lesson-module teacher-resources-module cursor-pointer"
                        >
                          <div className="teacher-resources-image">
                            <img src={y.ImageUrl} />
                          </div>
                          <div className="card-body">
                            <h6 className="fw-700 lesson-module-title teacher-resources-module-title">
                              {y.AssessmentName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showpopup === true ? (
                    <Modal
                      className="modal-fullscreen"
                      show={showpopup}
                      scrollable={true}
                      fullscreen={fullscreen}
                      onHide={() => {
                        setShowPopup(false);
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {x.Assessments[updateindex].AssessmentName}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Document
                          file={x.Assessments[updateindex].PdfUrl}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page
                              className={
                                numPages === 1 ? "page-index-zero" : ""
                              }
                              height={
                                document.getElementsByClassName(
                                  "lesson-module-list"
                                )[0]?.clientWidth * 0.5 ?? 150
                              }
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          ))}{" "}
                        </Document>
                        <p>
                          Page {pageNumber} of {numPages}
                        </p>
                      </Modal.Body>
                    </Modal>
                  ) : null}
                </>
              );
            });
          })}
        </div>
      </section>
    </>
  );
}

export default LessonAssessment;
