import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G4_Investigate_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S2"}>
        <p className="pb-7">
          <span className="fw-700">Differentiation:</span> Use the following
          prompts to get partners started or continue discussing their ideas:
        </p>
        <ul className="pl-30 circle-listing">
          <li>
            What do you see in the models that may help you figure out which
            object is moving?
          </li>
          <li>
            How could the patterns you identified help you think about what is
            moving?
          </li>
          <li>Does it make sense for the Sun or the planets to move? Why?</li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G4_Investigate_S2;
