import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P31_Engage_Argument_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P31"} seriesCount={"S1"}>
      <div className="d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">16.</span>
        <span className="number-text">
          Encourage students to share and discuss their matches with a partner.
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P31_Engage_Argument_S1;
