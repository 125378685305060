import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P12_Collect_Represent_and_Analyze_Data_S4() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P13"} seriesCount={"S1"}>
        <div className="d-flex guidence-count pb-10">
          <span className="fw-700 pr-5 text-nexus-medium-blue">7.</span>
          <span className="number-text">
            Project the Earth’s Revolution animation for the class. Ask students
            to look carefully as you click through the four pictures—December,
            March, June, and September—to see the revolution of Earth throughout
            the year.
          </span>
        </div>
        <p className="pl-15">
          Do this a few times, and then ask students to share their observations
          with a partner.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P12_Collect_Represent_and_Analyze_Data_S4;
