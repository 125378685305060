import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P2_Make_Observations_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P2"} seriesCount={"S2"}>
        <div className="mb-10 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">3.</span>
          <span className="number-text">
            Have students share their observations and questions with a partner.
          </span>
        </div>
        <p className="pl-20 text">(continued)</p>
      </IdGeneratingComponent>
    </>
  );
}

export default P2_Make_Observations_S2;
