import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G23_Analyze_Data() {
  return (
    <IdGeneratingComponent guideLineCount={"G23"}>
      <section className="text-wrapping-right pb-10">
        <div className="icon">
          <img
            src="/image/lesson-images/crosscutting-concepts.svg"
            alt="crosscutting-concepts"
            title="crosscutting-concepts"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="fw-700 text-nexus-green">Cause and Effect:</span>{" "}
        Students use data from their investigations to develop the understanding
        that heating and cooling events cause observable patterns in the changes
        to matter.
      </section>
      <section className="text-wrapping-right pb-10">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative_assessment_opportunity"
            title="formative_assessment_opportunity"
          ></img>
        </div>
        <span className="fw-700">Formative Assessment Opportunity:</span> Assess
        students’ ability to make observations to collect and compare data to
        describe the effect of temperature on the observable properties of
        different types of matter by reviewing their work in the How Do Higher
        and Lower Temperatures Affect Matter section of their science notebooks
        (pages 11–13). Look for evidence that students made accurate
        observations from the videos and use their data as evidence to describe
        how and why the matter changes. Use this to inform future instruction
        and support students in meeting the targeted expectations.
      </section>
      <p>
        <span className="fw-700">Nature of Science:</span> Science searches for
        cause-and-effect relationships to explain natural events. As students
        analyze their data, they use the patterns that result from these
        relationships to determine how temperature affects different types of
        matter.
      </p>
    </IdGeneratingComponent>
  );
}

export default G23_Analyze_Data;
