const LessonAssessmentData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How do objects change into other objects?",
    Assessments: [
      {
        AssessmentName: "Lesson 3 Notebook: Teacher Version",
        ImageUrl: "/image/notebook_tv.png",
        PdfUrl: "/pdf/g2_glass_l3_answer_key.pdf",
      },
      {
        AssessmentName: "Lesson 3: Supporting All Learners",
        ImageUrl: "/image/rem_ext.png",
        PdfUrl: "/pdf/g2_glass_l3_rem_ext.pdf",
      },
      {
        AssessmentName:
          "Lesson 3, Summative Assessment: Heating and Cooling Matter",
        ImageUrl: "/image/summ_asses.png",
        PdfUrl: "",
      },
      {
        AssessmentName:
          "Lesson 3, Assessment Rubric: Heating and Cooling Matter",
        ImageUrl: "/image/assess_rubric.png",
        PdfUrl: "",
      },
    ],
  },
];

export default LessonAssessmentData;
