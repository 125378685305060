import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G10_Plan_Investigation_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G10"} seriesCount={"S2"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G10_Plan_Investigation_S2;
