import React from "react";

function T8_Make_Observations_S2() {
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-10">
          Think about how the items at each station were grouped.
        </p>
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Share your ideas with your partner. Do you agree with each other?
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  I agree that these items are grouped together because
                  ___________.
                </li>
                <li>
                  I disagree with your idea about how these items are grouped. I
                  think they are grouped together because _____________.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T8_Make_Observations_S2;
