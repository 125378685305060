import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G8_Make_Observations_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G8"} seriesCount={"S1"}>
      <p>
        <span className="text-number">
          <span className="fw-700">Formative Assessment Opportunity:</span> As
          you move around the room, listen for students to use the prompts from
          Lesson Resource 3 to describe the observable properties of the objects
          at each station. They should describe the hardness, flexibility,
          texture, and color of the objects and whether each is a solid or
          liquid.
        </span>
      </p>
    </IdGeneratingComponent>
  );
}

export default G8_Make_Observations_S1;
