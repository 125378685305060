import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G10_Plan_Investigation_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G10"} seriesCount={"S1"}>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/scaffolding.svg"
            alt="scaffolding"
            title="scaffolding"
          ></img>
        </div>
        <span className="fw-700">Scaffolding:</span> Use the following prompts
        to assist pairs that struggle to plan an investigation:
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          Does each object you selected represent a different type of matter?
          What can you do to change that?
        </li>
        <li>
          Would it be safer to use a bowl of very hot water or a lamp that gives
          off heat when it is turned on?
        </li>
        <li>
          Is it safe to touch objects that have been heated up? Which properties
          can we observe without touching the objects?
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G10_Plan_Investigation_S1;
