import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G11_Checkpoint() {
  return (
    <IdGeneratingComponent guideLineCount={"G11"}>
      <section className="text-wrapping-right pb-7">
        <span className="fw-700">Formative Assessment:</span>{" "}
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative-assessment-opportunity"
            title="formative-assessment-opportunity"
          ></img>
        </div>
        Use this checkpoint to assess students' understanding of the following
        science idea before moving on:
      </section>
      <ul className="pl-30 circle-listing">
        <li>Matter can be described by its observable properties.</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G11_Checkpoint;
