import React from "react";

function T30_Earn_Words_S3() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Complete the Can It Change Back? section of your notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_14.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T30_Earn_Words_S3;
