import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G9_Engage_Sensemaking_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G9"} seriesCount={"S1"}>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative-assessment-opportunity"
            title="formative-assessment-opportunity"
          ></img>
        </div>
        <span className="fw-700">Formative Assessment Opportunity:</span>{" "}
        Students <br />
        should be able to:
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          Identify that the materials at each station were grouped by type of
          matter (food, wax, wood, glass, and metal).
        </li>
        <li>
          Explain that they knew the items were made of the same type of matter
          because the observable properties of the objects at each station are
          similar.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G9_Engage_Sensemaking_S1;
