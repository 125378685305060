import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G27_Prior_Knowledge_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G27"} seriesCount={"S1"}>
      <section className="guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            Math Connection{" "}
            <span className="text-nexus-lightgrey">(x ÷ =)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p>
            Students can count within 1,000 and practice skip-counting by 100s
            (2.NBT.2)
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G27_Prior_Knowledge_S1;
