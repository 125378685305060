import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P24_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"P24"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">7.</span>
        <p className="number-text">
          <span className="fw-700">Engage in Sensemaking:</span> Hold a class
          discussion for students to make sense of the data they collected. Ask:
        </p>
      </div>
      <ul className="pl-45 circle-listing">
        <li>
          What caused each type of matter to change?{" "}
          <span className="text-i">(Different temperatures)</span>
        </li>
        <li>
          How would you describe the way each type of matter changed when it was
          heated?{" "}
          <span className="text-i">
            (Most matter changed from a solid to a liquid. The egg was cooked
            and changed from a liquid to a solid. The wood burned.)
          </span>
        </li>
        <li>
          What happened when each type of matter was cooled?{" "}
          <span className="text-i">
            (Most types of matter changed back into a solid. The wood and egg
            could not change back.)
          </span>
        </li>
        <li>
          Why do you think the wood and egg could not change back to what they
          were before they were heated?{" "}
          <span className="text-i">
            (Answers will vary, but students should be able to describe that the
            temperature caused the observable properties in these types of
            matter to change permanently.)
          </span>
        </li>
        <li>
          What would happen if we heated the different types of matter, like
          plastic, a second time?{" "}
          <span className="text-i">
            (The objects that melted would melt again.)
          </span>
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P24_Engage_Sensemaking;
