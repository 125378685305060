import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G20_Prior_Knowledge_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G20"} seriesCount={"S2"}>
      <section className="guidelines-blue-section">
        <div className="title">
          <section className="right-icon-text-group">
            <div className="text">
              <p className="fw-700">Teacher Tip</p>
            </div>
            <div className="icon">
              <img
                src="/image/lesson-images/Pencil.svg"
                alt="Pencil"
                title="Pencil"
              ></img>
            </div>
          </section>
        </div>
        <div className="p-2 text">
          <p className="pb-7">
            This may be students' first time using a simulation or model for
            their investigations. If so, explain the purpose:
          </p>
          <ul className="pl-30 circle-listing">
            <li>
              Scientists use models like this simulation to represent processes
              and objects that are too difficult to study firsthead. This
              includes things that are very small, far away, or unsafe.
            </li>
          </ul>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G20_Prior_Knowledge_S2;
