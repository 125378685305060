import React, { useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "@mui/material";

function T12_Collect_Represent_and_Analyze_Data_S4() {
  const carouselRef = useRef(null);
  const [reset, setReset] = useState(0);
  const [lgShow, setLgShow] = useState(false);
  const [updatestate, setUpdatedState] = useState(0);

  let CarouselImage = [
    {
      id: 0,
      ImageUrl: "/image/december.png",
    },
    {
      id: 1,
      ImageUrl: "/image/march.png",
    },
    {
      id: 2,
      ImageUrl: "/image/june.png",
    },
    {
      id: 3,
      ImageUrl: "/image/september.png",
    },
  ];

  const setSelectedPage = (index) => {
    setUpdatedState(index);
    setReset(index);
  };

  const Reset = () => {
    setReset(0);
  };

  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Share your observations with a partner.
            </p>
          </div>
        </section>
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <p className="text-center mb-20 fw-700 ">Earth’s Revolution</p>
          <div className="carousel-img position-relative">
            <Carousel
              ref={carouselRef}
              showArrows={true}
              selectedItem={reset}
              renderArrowPrev={(onClickHandler) => onClickHandler}
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <>
                    <Tooltip title="Next" placement="bottom">
                      <span
                        className="carousel-right-arrow position-absolute"
                        onClick={onClickHandler}
                      >
                        <svg
                          width="18"
                          height="20"
                          viewBox="0 0 19 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 12L-4.76837e-07 24V0L19 12Z"
                            fill="#f9f9f9"
                          />
                        </svg>
                      </span>
                    </Tooltip>
                  </>
                )
              }
              showStatus={false}
              showThumbs={false}
              onChange={setSelectedPage}
            >
              {CarouselImage.map((x) => {
                return (
                  <div key={x.id}>
                    <img src={x.ImageUrl} />
                  </div>
                );
              })}
            </Carousel>
            {reset != 0 ? (
              <>
                <Tooltip title="Refresh" placement="top">
                  <span
                    onClick={() => Reset()}
                    className="carousel-reset-arrow position-absolute"
                  >
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.885 3.51028C14.268 -1.10772 6.829 -1.16572 2.129 3.31528L0 1.05728V8.99528H7.484L5.418 6.80428C8.238 4.09828 12.715 4.12828 15.491 6.90428C19.832 11.2453 17.228 19.1953 10 19.1953V23.9953C13.708 23.9953 16.614 22.7513 18.885 20.4803C23.571 15.7943 23.571 8.19628 18.885 3.51028Z"
                        fill="#F9F9F9"
                      />
                    </svg>
                  </span>
                </Tooltip>
              </>
            ) : null}
            <Tooltip title="Enlarge View" placement="top">
              <span
                onClick={() => setLgShow(true)}
                className="plus-icon position-absolute"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_27_2)">
                    <path
                      d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_27_2">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </Tooltip>
            <Modal
              size="xl modal-dialog-centered"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="carousel-img position-relative">
                  <Carousel
                    ref={carouselRef}
                    showArrows={true}
                    selectedItem={reset}
                    renderArrowPrev={(onClickHandler) => onClickHandler}
                    renderArrowNext={(onClickHandler, hasNext) =>
                      hasNext && (
                        <>
                          <Tooltip title="Next" placement="bottom">
                            <span
                              className="carousel-right-arrow position-absolute"
                              onClick={onClickHandler}
                            >
                              <svg
                                width="18"
                                height="20"
                                viewBox="0 0 19 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 12L-4.76837e-07 24V0L19 12Z"
                                  fill="#f9f9f9"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                        </>
                      )
                    }
                    showStatus={false}
                    showThumbs={false}
                    onChange={setSelectedPage}
                  >
                    {CarouselImage.map((x) => {
                      return (
                        <div key={x.id}>
                          <img src={x.ImageUrl} />
                        </div>
                      );
                    })}
                  </Carousel>
                  {reset != 0 ? (
                    <>
                      <Tooltip title="Refresh" placement="top">
                        <span
                          onClick={() => Reset()}
                          className="carousel-reset-arrow position-absolute"
                        >
                          <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.885 3.51028C14.268 -1.10772 6.829 -1.16572 2.129 3.31528L0 1.05728V8.99528H7.484L5.418 6.80428C8.238 4.09828 12.715 4.12828 15.491 6.90428C19.832 11.2453 17.228 19.1953 10 19.1953V23.9953C13.708 23.9953 16.614 22.7513 18.885 20.4803C23.571 15.7943 23.571 8.19628 18.885 3.51028Z"
                              fill="#F9F9F9"
                            />
                          </svg>
                        </span>
                      </Tooltip>
                    </>
                  ) : null}
                </div>
                {/* <img src={CarouselImage[updatestate].ImageUrl} /> */}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default T12_Collect_Represent_and_Analyze_Data_S4;
