import React, { useEffect, useState, useRef } from "react";
import HighLightComponent from "../../Common/HighLightComponent";
import LessonRender from "../../LessonLevel/grade5/LessonsRender";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import BreadcumbComponent from "../../Common/BreadcrumbComponent_2";
import { fromRange } from "xpath-range";

function GetDataFromLocalStorage() {
  var data = JSON.parse(localStorage.getItem("userAnnotationsCreated"));
  if (data) return data;
  else return [];
}
function LessonProcedure(props) {
  // const [props.updatelesson, props.setUpdateLesson] = useState(0);
  const divRef = useRef(null);
  const popUp = useRef(null);
  const popUp1 = useRef(null);
  const [showpopup, setShowPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullScreen] = useState(true);
  const location = useLocation();
  const [userAnnotationsCreated, setUserAnnotationsCreated] = useState(
    GetDataFromLocalStorage
  );
  const GotoPrev = () => {
    props.setUpdateLesson(props.updatelesson - 1);
  };
  const GotoNext = () => {
    props.setUpdateLesson(props.updatelesson + 1);
  };

  useEffect(() => {
    if (props.updatelesson) {
      const Modalbody = document.getElementById("modal-body-popup");

      if (Modalbody) Modalbody.scrollIntoView(true);
      else window.scrollTo(0, 0);
    }
  }, [props.updatelesson]);

  useEffect(() => {
    const pathData = window.location.href;
    let url = pathData.split("#");
    if (url.length > 1) props.setUpdateLesson(parseInt(url[url.length - 1]));
  }, [location]);
  const LessonIndex = LessonRender.map((x) => x.Topics.map((y) => y.id));
  const PopupShow = () => {
    setShowPopup(true);
    setShow(!show);
  };

  function promptTheData() {
    if (window.getSelection().rangeCount > 0) {
      let commentDetails = prompt("Please enter your comment");
      if (commentDetails != null) {
        onHighlightAction("comment", commentDetails);
      }
    }
  }

  function updateMark(xpath, typeDetails, uniqueId) {
    let _element;
    if (uniqueId) _element = document.getElementById(uniqueId);
    else if (xpath.highlightId)
      _element = document.getElementById(xpath.highlightId);
    if (_element) {
      var element = document.evaluate(
        xpath.start,
        _element,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (element != null) {
        const newItem = document.createElement("span");

        let _text = element.nodeValue;
        if (typeDetails.type === "comment") {
          newItem.innerHTML =
            _text.slice(0, xpath.startOffset) +
            `<span class="commentHighlighter font-16" title= ` +
            " " +
            typeDetails.commentDetails.replace(/[ ]/g, "\u00a0") +
            " " +
            `>` +
            _text.slice(xpath.startOffset, xpath.endOffset) +
            `</span>` +
            _text.slice(xpath.endOffset, _text.length);
        } else {
          newItem.innerHTML =
            _text.slice(0, xpath.startOffset) +
            `<span class="text-highlight font-16">` +
            _text.slice(xpath.startOffset, xpath.endOffset) +
            `</span>` +
            _text.slice(xpath.endOffset, _text.length);
        }
        element.parentNode?.replaceChild(newItem, element);
        return element;
      }
    }
  }

  const UnHighlightAction = () => {
    if (popUp && popUp.current) popUp.current.removePopUp();
    if (popUp1 && popUp1.current) popUp1.current.removePopUp();
    const selection = window.getSelection();
    const selectionData = window.getSelection().toString();
    if (selection && selection.anchorNode) {
      const selectedElement = selection.anchorNode.parentElement;
      if (selectedElement.classList.contains("text-highlight")) {
        selectedElement.classList.remove("text-highlight");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      } else if (selectedElement.classList.contains("commentHighlighter")) {
        selectedElement.classList.remove("commentHighlighter");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      } else if (
        selectedElement.classList.contains("text-highlight") &&
        selectedElement.classList.contains("commentHighlighter")
      ) {
        selectedElement.classList.remove("text-highlight");
        selectedElement.classList.remove("commentHighlighter");
        let DataRemove = GetDataFromLocalStorage();
        let _index = DataRemove.findIndex((data, index) => {
          if (data.text === selectionData) return index.toString();
        });
        DataRemove.splice(_index, 1);
        localStorage.setItem(
          "userAnnotationsCreated",
          JSON.stringify(DataRemove)
        );
      }
    }
  };

  useEffect(() => {
    let available = JSON.parse(localStorage.getItem("userAnnotationsCreated"));
    let filteredData = available; // []
    /* if (available)
        filteredData = available.filter(data => data.page === updatelesson) */
    if (filteredData && filteredData.length) {
      for (const element of filteredData) {
        updateMark(element, element);
      }
    }
  }, [props.updatelesson]);

  function onHighlightAction(type, commentDetails) {
    if (popUp && popUp.current) popUp.current.removePopUp();
    if (popUp1 && popUp1.current) popUp1.current.removePopUp();
    let text = (getSelection() && getSelection().toString()) || "";
    if (!text || text === " ") {
      return;
    }
    function findAncestor(el, cls) {
      while ((el = el.parentElement) && !el.id);
      return el.id;
    }
    const selection = getSelection();
    const range = selection.getRangeAt(0);
    let uniqueId = findAncestor(window.getSelection().anchorNode.parentNode);
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const content = document.getElementById(uniqueId);
      let xpath = null;
      if (content) {
        xpath = fromRange(range, content);
      }
      let start = "//*[@id='" + uniqueId + "']/" + xpath.start;
      let end = "//*[@id='" + uniqueId + "']/" + xpath.end;
      xpath = { ...xpath, start, end };
      if (xpath) {
        try {
          const highlightId = uniqueId;
          let _userAnnotations = GetDataFromLocalStorage();
          let newDetails = {
            page: "point",
            text,
            type: type,
            highlightId,
            ...xpath,
          };
          if (type === "comment") {
            newDetails.commentDetails = commentDetails;
          }
          _userAnnotations.push(newDetails);
          updateMark(xpath, newDetails, uniqueId);
          setUserAnnotationsCreated(_userAnnotations);
          localStorage.setItem(
            "userAnnotationsCreated",
            JSON.stringify(_userAnnotations)
          );
        } catch (e) {
          console.error("user highlight failed: ", e);
        }
      }
    }
  }

  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Earth and Space Science | Unit 1",
              path: "/grade5/glassblowing",
            }}
            Child={{
              title: "Lesson 3 - Procedure & Guidance",
            }}
          />
        </h1>
      </div>
      <section className="lesson-procedure">
        <div className="bordered-box pdf-reader">
          <div className="mb-10 student-lesson-preview">
            <div className="bookmark-image">
              <img src="/image/bookmark.png"></img>
            </div>
            <div className="student-lesson-preview-right ">
              <div className="pdf-reader-actions border-0 student-lesson-preview-actions">
                <button className="btn mr-10" onClick={() => promptTheData()}>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.25 5.99992C11.648 5.99992 12 6.35192 12 6.74992C12 7.16392 11.664 7.49992 11.25 7.49992C9.745 7.49992 3.5 7.49992 3.5 7.49992V19.4999H20.5V10.7499C20.5 10.3359 20.836 9.99992 21.25 9.99992C21.664 9.99992 22 10.3359 22 10.7499V19.9999C22 20.6209 21.478 20.9999 21 20.9999H3C2.52 20.9999 2 20.6209 2 19.9999V6.99992C2 6.51892 2.38 5.99992 3 5.99992H11.25ZM9.239 12.5259C8.194 15.5289 8.001 15.9759 8.001 16.3659C8.001 16.8069 8.386 16.9919 8.628 16.9919C8.9 16.9919 9.736 16.6909 12.457 15.7429L9.239 12.5259ZM10.127 11.6369L13.347 14.8569L21.755 6.45692C21.918 6.29392 22 6.07992 22 5.86492C22 5.65192 21.918 5.43792 21.755 5.27392C21.175 4.69592 20.297 3.81692 19.716 3.23792C19.553 3.07492 19.339 2.99292 19.125 2.99292C18.912 2.99292 18.697 3.07492 18.533 3.23792L10.127 11.6369Z"
                        fill="#1E293B"
                      />
                    </svg>
                  </span>
                  Annotate
                </button>
                <button
                  className="btn mr-10"
                  onClick={() => onHighlightAction("Highlight")}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19 20.25C19 19.848 18.644 19.5 18.25 19.5C15.689 19.5 6.311 19.5 3.75 19.5C3.356 19.5 3 19.848 3 20.25C3 20.652 3.356 21 3.75 21H18.25C18.644 21 19 20.652 19 20.25ZM6.977 13.167C5.643 17.083 5.497 17.399 5.497 17.754C5.497 18.281 5.957 18.503 6.246 18.503C6.598 18.503 6.914 18.366 10.82 17.01L6.977 13.167ZM8.037 12.106L11.883 15.952L20.707 7.138C20.902 6.943 21 6.687 21 6.431C21 6.176 20.902 5.92 20.707 5.725C20.015 5.034 18.965 3.984 18.272 3.293C18.077 3.098 17.821 3 17.565 3C17.311 3 17.055 3.098 16.859 3.293L8.037 12.106Z"
                        fill="#1E293B"
                      />
                    </svg>
                  </span>
                  Highlight
                </button>
                <button
                  className="btn mr-10"
                  onClick={() => UnHighlightAction()}
                >
                  <span className="icon">
                    <svg
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.662 22L0.293 16.635C0.098 16.44 0 16.185 0 15.928C0 15.672 0.098 15.416 0.293 15.221L15.222 0.293C15.417 0.099 15.673 0 15.929 0C16.184 0 16.441 0.099 16.636 0.293L23.707 7.366C23.903 7.561 24 7.817 24 8.074C24 8.33 23.903 8.585 23.707 8.781L12.491 20H18.005V22H5.662ZM9.319 20L3.833 14.514L2.414 15.928L6.49 20H9.319ZM9.775 8.571L5.247 13.099L10.905 18.758L15.432 14.228L9.775 8.571Z"
                        fill="#1E293B"
                      />
                    </svg>
                  </span>
                  Remove
                </button>
                <button className="btn mr-5" disabled>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M6.75 15.7761L0 9.29763L2.09325 7.14513L6.71025 11.5506L15.8678 2.22363L18 4.33638L6.75 15.7761Z"
                        fill="#414141"
                      />
                    </svg>
                  </span>
                  Assign
                </button>
                <button
                  onClick={() => PopupShow()}
                  id="popup"
                  className="btn ml-5"
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18 16.5H0V1.5H18V16.5ZM12.75 15.75V4.5H0.75V15.75H12.75ZM13.5 15.75H17.25V2.25H0.75V3.75H13.5V15.75ZM9 11.25H8.25V8.81925L2.81925 14.25L2.25 13.6807L7.68075 8.25H5.25V7.5H9V11.25Z"
                        fill="#414141"
                      />
                    </svg>
                  </span>
                  Present
                </button>
              </div>
              <div className="d-flex justify-content-end lesson-procedure-navigation">
                <div
                  className={
                    props.updatelesson === 0
                      ? "mr-5 navigation-arrow disabled left cursor-pointer"
                      : "mr-5 navigation-arrow left cursor-pointer"
                  }
                >
                  <Tooltip title="Prev" placement="top">
                    <svg
                      onClick={() => GotoPrev()}
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z" />
                    </svg>
                  </Tooltip>
                </div>
                <div
                  className={
                    props.updatelesson < LessonIndex[0].length - 1
                      ? // props.updatelesson < 2

                        "ml-5 navigation-arrow right cursor-pointer"
                      : "ml-5 navigation-arrow right disabled cursor-pointer"
                  }
                >
                  <Tooltip title="Next" placement="top">
                    <svg
                      onClick={() => GotoNext()}
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.568 18.005l-1.414-1.415 4.574-4.59-4.574-4.579 1.414-1.416 5.988 5.995-5.988 6.005z" />
                    </svg>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          {showpopup === false ? (
            <>
              <div className="lesson-procedure-row">
                <div className="lesson-procedure-colum lesson-procedure-colum-left">
                  <div className="teacher-procedure-text">
                    <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
                      Procedure
                    </h6>
                    <HighLightComponent ref={popUp}>
                      {LessonRender.map((x) => {
                        if (
                          x.Topics[props.updatelesson].id === props.updatelesson
                        ) {
                          return x.Topics[props.updatelesson].PTopic;
                        }
                      })}
                    </HighLightComponent>
                  </div>
                </div>
                <div className="lesson-procedure-colum lesson-procedure-center">
                  {LessonRender.map((x, key) => {
                    if (
                      x.Topics[props.updatelesson].id === props.updatelesson
                    ) {
                      return (
                        <h6
                          className="text-center mb-15 procedure-side-heading background-nexus-blue"
                          key={key}
                        >
                          {x.Topics[props.updatelesson].TopicName}
                        </h6>
                      );
                    }
                  })}
                  {LessonRender.map((x) => {
                    if (
                      x.Topics[props.updatelesson].id === props.updatelesson
                    ) {
                      return x.Topics[props.updatelesson].Topic;
                    }
                  })}
                </div>
                <div className="lesson-procedure-colum lesson-procedure-colum-right">
                  <div className="teacher-guidelines-text">
                    <h6 className="text-center mb-15 procedure-side-heading background-nexus-blue">
                      Teacher Guidance
                    </h6>
                    <HighLightComponent ref={popUp1}>
                      {LessonRender.map((x) => {
                        if (
                          x.Topics[props.updatelesson].id === props.updatelesson
                        ) {
                          return x.Topics[props.updatelesson].GTopic;
                        }
                      })}
                    </HighLightComponent>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Modal
              className="present-modal"
              fullscreen={fullscreen}
              scrollable={true}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={show}
              onHide={() => {
                setShow(false);

                setShowPopup(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="w-100">
                  <div className="bookmark-image">
                    <img src="/image/bookmark.png"></img>
                  </div>
                  {/* <div className="title">
                    {LessonRender.map((x) => {
                      if (
                        x.Topics[props.updatelesson].id === props.updatelesson
                      ) {
                        return x.Topics[props.updatelesson].TopicName;
                      }
                    })}
                  </div> */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  ref={divRef}
                  id="modal-body-popup"
                  className="row justify-content-center lesson-modal-body"
                >
                  <div className="col-12 col-md-8 col-lg-6">
                    {LessonRender.map((x) => {
                      if (
                        x.Topics[props.updatelesson].id === props.updatelesson
                      ) {
                        return x.Topics[props.updatelesson].Topic;
                      }
                    })}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="row w-100 justify-content-center lesson-modal-footer">
                  <div className="col-12 col-md-8 col-lg-6">
                    <div className="d-flex justify-content-end lesson-procedure-navigation">
                      <div
                        className={
                          props.updatelesson === 0
                            ? "mr-5 navigation-arrow disabled left cursor-pointer"
                            : "mr-5 navigation-arrow left cursor-pointer"
                        }
                      >
                        <svg
                          onClick={() => {
                            GotoPrev();
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z" />
                        </svg>
                      </div>

                      <div
                        className={
                          props.updatelesson < LessonIndex[0].length - 1
                            ? "ml-5 navigation-arrow right cursor-pointer"
                            : "ml-5 navigation-arrow right disabled cursor-pointer"
                        }
                      >
                        <svg
                          onClick={() => {
                            GotoNext();
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.568 18.005l-1.414-1.415 4.574-4.59-4.574-4.579 1.414-1.416 5.988 5.995-5.988 6.005z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </section>
    </>
  );
}

export default LessonProcedure;
