import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P17_Connect_to_Prior_Knowledge_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P17"} seriesCount={"S1"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">1.</span>
          <span className="number-text">
            <span className="fw-700">Connect to Prior Knowledge:</span> Draw
            attention to the new words on the Word Wall: revolution, orbit,
            axis. Ask students to remember what they have learned about Earth so
            far and what they decided to investigate next. Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>What have we discovered about Earth so far?</li>
          <li>How does that affect us?</li>
          <li>What did we decide to investigate next?</li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P17_Connect_to_Prior_Knowledge_S1;
