import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P6_Engage_in_Sensemaking() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P6"}>
        <div className="mb-7 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">12.</span>
          <span className="number-text">
            <span className="fw-700">Engage in Sensemaking:</span> Have students
            share the evidence they gathered from the infographic about how
            objects move in the solar system. Ask:
          </span>
        </div>
        <ul className="pl-55 circle-listing">
          <li>
            What information from this infographic do you think is important?
          </li>
          <li>
            How do the ideas in the infographic match your ideas about how
            objects move in space?
          </li>
          <li>What new information did you uncover?</li>
          <li>
            Can you use evidence from the models and the infographic to help you
            describe how objects move in the solar system?
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P6_Engage_in_Sensemaking;
