import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P12_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"P12"}>
      <div className="d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">9.</span>
        <span className="number-text">
          <span className="fw-700">Navigate to the Next Session:</span> Tell the
          class that they have developed a good plan to test how temperature
          affects different types of matter. Explain that during the next
          session, they will carry out an investigation to see what happens when
          different types of matter are heated.
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P12_Navigate;
