import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G14_Earn_Words() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G14"}>
        {/*  <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G14_Earn_Words;
