import T0_Title from "./Lessons/Lesson-3/T0_Title";
import T1_Connect_to_Prior_Knowledge from "./Lessons/Lesson-3/T1_Connect_to_Prior_Knowledge";
import T2_Make_Observations_S1 from "./Lessons/Lesson-3/T2_Make_Observations_S1";
import T2_Make_Observations_S2 from "./Lessons/Lesson-3/T2_Make_Observations_S2";
import T2_Make_Observations_S3 from "./Lessons/Lesson-3/T2_Make_Observations_S3";
import T3_Develop_Initial_Ideas_S1 from "./Lessons/Lesson-3/T3_Develop_Initial_Ideas_S1";
import T3_Develop_Initial_Ideas_S2 from "./Lessons/Lesson-3/T3_Develop_Initial_Ideas_S2";
import T3_Develop_Initial_Ideas_S3 from "./Lessons/Lesson-3/T3_Develop_Initial_Ideas_S3";
import T4_Investigate_S1 from "./Lessons/Lesson-3/T4_Investigate_S1";
import T4_Investigate_S2 from "./Lessons/Lesson-3/T4_Investigate_S2";
import T4_Investigate_S3 from "./Lessons/Lesson-3/T4_Investigate_S3";
import T4_Investigate_S4 from "./Lessons/Lesson-3/T4_Investigate_S4";
import T5_Gather_Evidence from "./Lessons/Lesson-3/T5_Gather_Evidence";
import T6_Engage_in_Sensemaking from "./Lessons/Lesson-3/T6_Engage_in_Sensemaking";
import T7_Earn_Words_S1 from "./Lessons/Lesson-3/T7_Earn_Words_S1";
import T7_Earn_Words_S2 from "./Lessons/Lesson-3/T7_Earn_Words_S2";
import T8_Checkpoint from "./Lessons/Lesson-3/T8_Checkpoint";
import T9_Navigation from "./Lessons/Lesson-3/T9_Navigation";
import T10_Connect_to_Prior_Knowledge from "./Lessons/Lesson-3/T10_Connect_to_Prior_Knowledge";
import T11_Develop_Initial_Ideas_S1 from "./Lessons/Lesson-3/T11_Develop_Initial_Ideas_S1";
import T11_Develop_Initial_Ideas_S2 from "./Lessons/Lesson-3/T11_Develop_Initial_Ideas_S2";
import T11_Develop_Initial_Ideas_S3 from "./Lessons/Lesson-3/T11_Develop_Initial_Ideas_S3";
import T12_Collect_Represent_and_Analyze_Data_S1 from "./Lessons/Lesson-3/T12_Collect_Represent_and_Analyze_Data_S1";
import T12_Collect_Represent_and_Analyze_Data_S2 from "./Lessons/Lesson-3/T12_Collect_Represent_and_Analyze_Data_S2";
import T12_Collect_Represent_and_Analyze_Data_S3 from "./Lessons/Lesson-3/T12_Collect_Represent_and_Analyze_Data_S3";
import T12_Collect_Represent_and_Analyze_Data_S4 from "./Lessons/Lesson-3/T12_Collect_Represent_and_Analyze_Data_S4";
import T13_Engage_in_Sensemaking from "./Lessons/Lesson-3/T13_Engage_in_Sensemaking";
import T14_Earn_Words from "./Lessons/Lesson-3/T14_Earn_Words";
import T15_Checkpoint from "./Lessons/Lesson-3/T15_Checkpoint";
import T16_Navigate from "./Lessons/Lesson-3/T16_Navigate";
import T17_Connect_to_Prior_Knowledge_S1 from "./Lessons/Lesson-3/T17_Connect_to_Prior_Knowledge_S1";
import T17_Connect_to_Prior_Knowledge_S2 from "./Lessons/Lesson-3/T17_Connect_to_Prior_Knowledge_S2";
import T18_Collect_Data_S1 from "./Lessons/Lesson-3/T18_Collect_Data_S1";
import T18_Collect_Data_S2 from "./Lessons/Lesson-3/T18_Collect_Data_S2";
import T18_Collect_Data_S3 from "./Lessons/Lesson-3/T18_Collect_Data_S3";
import T19_Engage_in_Sensemaking_S1 from "./Lessons/Lesson-3/T19_Engage_in_Sensemaking_S1";
import T19_Engage_in_Sensemaking_S2 from "./Lessons/Lesson-3/T19_Engage_in_Sensemaking_S2";
import T19_Engage_in_Sensemaking_S3 from "./Lessons/Lesson-3/T19_Engage_in_Sensemaking_S3";
import T20_Advance_Sensemaking_of_the_Anchoring_Phenomenon from "./Lessons/Lesson-3/T20_Advance_Sensemaking_of_the_Anchoring_Phenomenon";
import T21_Navigate from "./Lessons/Lesson-3/T21_Navigate";

import G0_Title from "./TeacherGuideLines/Lesson-g3/G0_Title";
import G1_Connect_to_Prior_Knowledge from "./TeacherGuideLines/Lesson-g3/G1_Connect_to_Prior_Knowledge";
import G2_Make_Observations_S1 from "./TeacherGuideLines/Lesson-g3/G2_Make_Observations_S1";
import G2_Make_Observations_S2 from "./TeacherGuideLines/Lesson-g3/G2_Make_Observations_S2";
import G2_Make_Observations_S3 from "./TeacherGuideLines/Lesson-g3/G2_Make_Observations_S3";
import G3_Develop_Initial_Ideas_S1 from "./TeacherGuideLines/Lesson-g3/G3_Develop_Initial_Ideas_S1";
import G3_Develop_Initial_Ideas_S2 from "./TeacherGuideLines/Lesson-g3/G3_Develop_Initial_Ideas_S2";
import G3_Develop_Initial_Ideas_S3 from "./TeacherGuideLines/Lesson-g3/G3_Develop_Initial_Ideas_S3";
import G4_Investigate_S1 from "./TeacherGuideLines/Lesson-g3/G4_Investigate_S1";
import G4_Investigate_S2 from "./TeacherGuideLines/Lesson-g3/G4_Investigate_S2";
import G4_Investigate_S3 from "./TeacherGuideLines/Lesson-g3/G4_Investigate_S3";
import G4_Investigate_S4 from "./TeacherGuideLines/Lesson-g3/G4_Investigate_S4";
import G5_Gather_Evidence from "./TeacherGuideLines/Lesson-g3/G5_Gather_Evidence";
import G6_Engage_in_Sensemaking from "./TeacherGuideLines/Lesson-g3/G6_Engage_in_Sensemaking";
import G7_Earn_Words_S1 from "./TeacherGuideLines/Lesson-g3/G7_Earn_Words_S1";
import G7_Earn_Words_S2 from "./TeacherGuideLines/Lesson-g3/G7_Earn_Words_S2";
import G8_Checkpoint from "./TeacherGuideLines/Lesson-g3/G8_Checkpoint";
import G9_Navigation from "./TeacherGuideLines/Lesson-g3/G9_Navigation";
import G10_Connect_to_Prior_Knowledge from "./TeacherGuideLines/Lesson-g3/G10_Connect_to_Prior_Knowledge";
import G11_Develop_Initial_Ideas_S1 from "./TeacherGuideLines/Lesson-g3/G11_Develop_Initial_Ideas_S1";
import G11_Develop_Initial_Ideas_S2 from "./TeacherGuideLines/Lesson-g3/G11_Develop_Initial_Ideas_S2";
import G11_Develop_Initial_Ideas_S3 from "./TeacherGuideLines/Lesson-g3/G11_Develop_Initial_Ideas_S3";
import G12_Collect_Represent_and_Analyze_Data_S1 from "./TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S1";
import G12_Collect_Represent_and_Analyze_Data_S2 from "./TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S2";
import G12_Collect_Represent_and_Analyze_Data_S3 from "./TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S3";
import G12_Collect_Represent_and_Analyze_Data_S4 from "./TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S4";
import G13_Engage_in_Sensemaking from "./TeacherGuideLines/Lesson-g3/G13_Engage_in_Sensemaking";
import G14_Earn_Words from "./TeacherGuideLines/Lesson-g3/G14_Earn_Words";
import G15_Checkpoint from "./TeacherGuideLines/Lesson-g3/G15_Checkpoint";
import G16_Navigate from "./TeacherGuideLines/Lesson-g3/G16_Navigate";
import G17_Connect_to_Prior_Knowledge_S1 from "./TeacherGuideLines/Lesson-g3/G17_Connect_to_Prior_Knowledge_S1";
import G17_Connect_to_Prior_Knowledge_S2 from "./TeacherGuideLines/Lesson-g3/G17_Connect_to_Prior_Knowledge_S2";
import G18_Collect_Data_S1 from "./TeacherGuideLines/Lesson-g3/G18_Collect_Data_S1";
import G18_Collect_Data_S2 from "./TeacherGuideLines/Lesson-g3/G18_Collect_Data_S2";
import G18_Collect_Data_S3 from "./TeacherGuideLines/Lesson-g3/G18_Collect_Data_S3";
import G19_Engage_in_Sensemaking_S1 from "./TeacherGuideLines/Lesson-g3/G19_Engage_in_Sensemaking_S1";
import G19_Engage_in_Sensemaking_S2 from "./TeacherGuideLines/Lesson-g3/G19_Engage_in_Sensemaking_S2";
import G19_Engage_in_Sensemaking_S3 from "./TeacherGuideLines/Lesson-g3/G19_Engage_in_Sensemaking_S3";
import G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon from "./TeacherGuideLines/Lesson-g3/G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon";
import G21_Navigate from "./TeacherGuideLines/Lesson-g3/G21_Navigate";

import P0_Title from "./TeacherProcedure/Lesson-p3/P0_Title";
import P1_Connect_to_Prior_Knowledge from "./TeacherProcedure/Lesson-p3/P1_Connect_to_Prior_Knowledge";
import P2_Make_Observations_S1 from "./TeacherProcedure/Lesson-p3/P2_Make_Observations_S1";
import P2_Make_Observations_S2 from "./TeacherProcedure/Lesson-p3/P2_Make_Observations_S2";
import P2_Make_Observations_S3 from "./TeacherProcedure/Lesson-p3/P2_Make_Observations_S3";
import P3_Develop_Initial_Ideas_S1 from "./TeacherProcedure/Lesson-p3/P3_Develop_Initial_Ideas_S1";
import P3_Develop_Initial_Ideas_S2 from "./TeacherProcedure/Lesson-p3/P3_Develop_Initial_Ideas_S2";
import P3_Develop_Initial_Ideas_S3 from "./TeacherProcedure/Lesson-p3/P3_Develop_Initial_Ideas_S3";
import P4_Investigate_S1 from "./TeacherProcedure/Lesson-p3/P4_Investigate_S1";
import P4_Investigate_S2 from "./TeacherProcedure/Lesson-p3/P4_Investigate_S2";
import P4_Investigate_S3 from "./TeacherProcedure/Lesson-p3/P4_Investigate_S3";
import P4_Investigate_S4 from "./TeacherProcedure/Lesson-p3/P4_Investigate_S4";
import P5_Gather_Evidence from "./TeacherProcedure/Lesson-p3/P5_Gather_Evidence";
import P6_Engage_in_Sensemaking from "./TeacherProcedure/Lesson-p3/P6_Engage_in_Sensemaking";
import P7_Earn_Words_S1 from "./TeacherProcedure/Lesson-p3/P7_Earn_Words_S1";
import P7_Earn_Words_S2 from "./TeacherProcedure/Lesson-p3/P7_Earn_Words_S2";
import P8_Checkpoint from "./TeacherProcedure/Lesson-p3/P8_Checkpoint";
import P9_Navigation from "./TeacherProcedure/Lesson-p3/P9_Navigation";
import P10_Connect_to_Prior_Knowledge from "./TeacherProcedure/Lesson-p3/P10_Connect_to_Prior_Knowledge";
import P11_Develop_Initial_Ideas_S1 from "./TeacherProcedure/Lesson-p3/P11_Develop_Initial_Ideas_S1";
import P11_Develop_Initial_Ideas_S2 from "./TeacherProcedure/Lesson-p3/P11_Develop_Initial_Ideas_S2";
import P11_Develop_Initial_Ideas_S3 from "./TeacherProcedure/Lesson-p3/P11_Develop_Initial_Ideas_S3";
import P12_Collect_Represent_and_Analyze_Data_S1 from "./TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S1";
import P12_Collect_Represent_and_Analyze_Data_S2 from "./TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S2";
import P12_Collect_Represent_and_Analyze_Data_S3 from "./TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S3";
import P12_Collect_Represent_and_Analyze_Data_S4 from "./TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S4";
import P13_Engage_in_Sensemaking from "./TeacherProcedure/Lesson-p3/P13_Engage_in_Sensemaking";
import P14_Earn_Words from "./TeacherProcedure/Lesson-p3/P14_Earn_Words";
import P15_Checkpoint from "./TeacherProcedure/Lesson-p3/P15_Checkpoint";
import P16_Navigate from "./TeacherProcedure/Lesson-p3/P16_Navigate";
import P17_Connect_to_Prior_Knowledge_S1 from "./TeacherProcedure/Lesson-p3/P17_Connect_to_Prior_Knowledge_S1";
import P17_Connect_to_Prior_Knowledge_S2 from "./TeacherProcedure/Lesson-p3/P17_Connect_to_Prior_Knowledge_S2";
import P18_Collect_Data_S1 from "./TeacherProcedure/Lesson-p3/P18_Collect_Data_S1";
import P18_Collect_Data_S2 from "./TeacherProcedure/Lesson-p3/P18_Collect_Data_S2";
import P18_Collect_Data_S3 from "./TeacherProcedure/Lesson-p3/P18_Collect_Data_S3";
import P19_Engage_in_Sensemaking_S1 from "./TeacherProcedure/Lesson-p3/P19_Engage_in_Sensemaking_S1";
import P19_Engage_in_Sensemaking_S2 from "./TeacherProcedure/Lesson-p3/P19_Engage_in_Sensemaking_S2";
import P19_Engage_in_Sensemaking_S3 from "./TeacherProcedure/Lesson-p3/P19_Engage_in_Sensemaking_S3";
import P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon from "./TeacherProcedure/Lesson-p3/P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon";
import P21_Navigate from "./TeacherProcedure/Lesson-p3/P21_Navigate";

const LessonRender = [
  {
    ModuleName: "How can we explain the patterns we notice in the sky?",
    LessonName: "Lesson 3",
    Topics: [
      {
        id: 0,
        TopicName:
          "Why do we see different stars in the summer than in the winter?",
        Topic: <T0_Title />,
        PTopic: <P0_Title />,
        GTopic: <G0_Title />,
        url: "/view/LessonProcedure/Lesson1",
      },
      {
        id: 1,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T1_Connect_to_Prior_Knowledge />,
        PTopic: <P1_Connect_to_Prior_Knowledge />,
        GTopic: <G1_Connect_to_Prior_Knowledge />,
        url: "/view/LessonProcedure/Lesson1",
      },
      {
        id: 2,
        TopicName: "Make Observations",
        Topic: <T2_Make_Observations_S1 />,
        PTopic: <P2_Make_Observations_S1 />,
        GTopic: <G2_Make_Observations_S1 />,
        url: "/view/LessonProcedure/Lesson2",
      },
      {
        id: 3,
        TopicName: "Make Observations",
        Topic: <T2_Make_Observations_S2 />,
        PTopic: <P2_Make_Observations_S2 />,
        GTopic: <G2_Make_Observations_S2 />,
        url: "/view/LessonProcedure/Lesson2",
      },
      {
        id: 4,
        TopicName: "Make Observations",
        Topic: <T2_Make_Observations_S3 />,
        PTopic: <P2_Make_Observations_S3 />,
        GTopic: <G2_Make_Observations_S3 />,
        url: "/view/LessonProcedure/Lesson3",
      },
      {
        id: 5,
        TopicName: "Develop Initial Ideas",
        Topic: <T3_Develop_Initial_Ideas_S1 />,
        PTopic: <P3_Develop_Initial_Ideas_S1 />,
        GTopic: <G3_Develop_Initial_Ideas_S1 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 6,
        TopicName: "Develop Initial Ideas",
        Topic: <T3_Develop_Initial_Ideas_S2 />,
        PTopic: <P3_Develop_Initial_Ideas_S2 />,
        GTopic: <G3_Develop_Initial_Ideas_S2 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 7,
        TopicName: "Develop Initial Ideas",
        Topic: <T3_Develop_Initial_Ideas_S3 />,
        PTopic: <P3_Develop_Initial_Ideas_S3 />,
        GTopic: <G3_Develop_Initial_Ideas_S3 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 8,
        TopicName: "Investigate",
        Topic: <T4_Investigate_S1 />,
        PTopic: <P4_Investigate_S1 />,
        GTopic: <G4_Investigate_S1 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 9,
        TopicName: "Investigate",
        Topic: <T4_Investigate_S2 />,
        PTopic: <P4_Investigate_S2 />,
        GTopic: <G4_Investigate_S2 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 10,
        TopicName: "Investigate",
        Topic: <T4_Investigate_S3 />,
        PTopic: <P4_Investigate_S3 />,
        GTopic: <G4_Investigate_S3 />,
        url: "/view/LessonProcedure/Lesson5",
      },
      {
        id: 11,
        TopicName: "Investigate",
        Topic: <T4_Investigate_S4 />,
        PTopic: <P4_Investigate_S4 />,
        GTopic: <G4_Investigate_S4 />,
        url: "/view/LessonProcedure/Lesson5",
      },
      {
        id: 12,
        TopicName: "Gather Evidence",
        Topic: <T5_Gather_Evidence />,
        PTopic: <P5_Gather_Evidence />,
        GTopic: <G5_Gather_Evidence />,
        url: "/view/LessonProcedure/Lesson6",
      },
      {
        id: 13,
        TopicName: "Engage in Sensemaking ",
        Topic: <T6_Engage_in_Sensemaking />,
        PTopic: <P6_Engage_in_Sensemaking />,
        GTopic: <G6_Engage_in_Sensemaking />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 14,
        TopicName: "Earn Words",
        Topic: <T7_Earn_Words_S1 />,
        PTopic: <P7_Earn_Words_S1 />,
        GTopic: <G7_Earn_Words_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 15,
        TopicName: "Earn Words",
        Topic: <T7_Earn_Words_S2 />,
        PTopic: <P7_Earn_Words_S2 />,
        GTopic: <G7_Earn_Words_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 16,
        TopicName: "Checkpoint",
        Topic: <T8_Checkpoint />,
        PTopic: <P8_Checkpoint />,
        GTopic: <G8_Checkpoint />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 17,
        TopicName: "Navigation to the Next Session",
        Topic: <T9_Navigation />,
        PTopic: <P9_Navigation />,
        GTopic: <G9_Navigation />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 18,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T10_Connect_to_Prior_Knowledge />,
        PTopic: <P10_Connect_to_Prior_Knowledge />,
        GTopic: <G10_Connect_to_Prior_Knowledge />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 19,
        TopicName: "Develop Initial Ideas",
        Topic: <T11_Develop_Initial_Ideas_S1 />,
        PTopic: <P11_Develop_Initial_Ideas_S1 />,
        GTopic: <G11_Develop_Initial_Ideas_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 20,
        TopicName: "Develop Initial Ideas",
        Topic: <T11_Develop_Initial_Ideas_S2 />,
        PTopic: <P11_Develop_Initial_Ideas_S2 />,
        GTopic: <G11_Develop_Initial_Ideas_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 21,
        TopicName: "Develop Initial Ideas",
        Topic: <T11_Develop_Initial_Ideas_S3 />,
        PTopic: <P11_Develop_Initial_Ideas_S3 />,
        GTopic: <G11_Develop_Initial_Ideas_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 22,
        TopicName: "Collect, Represent and Analyze Data",
        Topic: <T12_Collect_Represent_and_Analyze_Data_S1 />,
        PTopic: <P12_Collect_Represent_and_Analyze_Data_S1 />,
        GTopic: <G12_Collect_Represent_and_Analyze_Data_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 23,
        TopicName: "Collect, Represent and Analyze Data",
        Topic: <T12_Collect_Represent_and_Analyze_Data_S2 />,
        PTopic: <P12_Collect_Represent_and_Analyze_Data_S2 />,
        GTopic: <G12_Collect_Represent_and_Analyze_Data_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 24,
        TopicName: "Collect, Represent and Analyze Data",
        Topic: <T12_Collect_Represent_and_Analyze_Data_S3 />,
        PTopic: <P12_Collect_Represent_and_Analyze_Data_S3 />,
        GTopic: <G12_Collect_Represent_and_Analyze_Data_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 25,
        TopicName: "Collect, Represent and Analyze Data",
        Topic: <T12_Collect_Represent_and_Analyze_Data_S4 />,
        PTopic: <P12_Collect_Represent_and_Analyze_Data_S4 />,
        GTopic: <G12_Collect_Represent_and_Analyze_Data_S4 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 26,
        TopicName: "Engage in Sensemaking",
        Topic: <T13_Engage_in_Sensemaking />,
        PTopic: <P13_Engage_in_Sensemaking />,
        GTopic: <G13_Engage_in_Sensemaking />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 27,
        TopicName: "Earn Words",
        Topic: <T14_Earn_Words />,
        PTopic: <P14_Earn_Words />,
        GTopic: <G14_Earn_Words />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 28,
        TopicName: "Checkpoint",
        Topic: <T15_Checkpoint />,
        PTopic: <P15_Checkpoint />,
        GTopic: <G15_Checkpoint />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 29,
        TopicName: "Navigation to the Next Session",
        Topic: <T16_Navigate />,
        PTopic: <P16_Navigate />,
        GTopic: <G16_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 30,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T17_Connect_to_Prior_Knowledge_S1 />,
        PTopic: <P17_Connect_to_Prior_Knowledge_S1 />,
        GTopic: <G17_Connect_to_Prior_Knowledge_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 31,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T17_Connect_to_Prior_Knowledge_S2 />,
        PTopic: <P17_Connect_to_Prior_Knowledge_S2 />,
        GTopic: <G17_Connect_to_Prior_Knowledge_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 32,
        TopicName: "Collect Data",
        Topic: <T18_Collect_Data_S1 />,
        PTopic: <P18_Collect_Data_S1 />,
        GTopic: <G18_Collect_Data_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 33,
        TopicName: "Collect Data",
        Topic: <T18_Collect_Data_S2 />,
        PTopic: <P18_Collect_Data_S2 />,
        GTopic: <G18_Collect_Data_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 34,
        TopicName: "Collect Data",
        Topic: <T18_Collect_Data_S3 />,
        PTopic: <P18_Collect_Data_S3 />,
        GTopic: <G18_Collect_Data_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 35,
        TopicName: "Engage in Sensemaking",
        Topic: <T19_Engage_in_Sensemaking_S1 />,
        PTopic: <P19_Engage_in_Sensemaking_S1 />,
        GTopic: <G19_Engage_in_Sensemaking_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 36,
        TopicName: "Engage in Sensemaking",
        Topic: <T19_Engage_in_Sensemaking_S2 />,
        PTopic: <P19_Engage_in_Sensemaking_S2 />,
        GTopic: <G19_Engage_in_Sensemaking_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 37,
        TopicName: "Engage in Sensemaking",
        Topic: <T19_Engage_in_Sensemaking_S3 />,
        PTopic: <P19_Engage_in_Sensemaking_S3 />,
        GTopic: <G19_Engage_in_Sensemaking_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 38,
        TopicName: "Advance Sensemaking of the Anchoring Phenomenon",
        Topic: <T20_Advance_Sensemaking_of_the_Anchoring_Phenomenon />,
        PTopic: <P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon />,
        GTopic: <G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 39,
        TopicName: "Navigate to the Next Lesson",
        Topic: <T21_Navigate />,
        PTopic: <P21_Navigate />,
        GTopic: <G21_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
    ],
  },
];

export default LessonRender;
