import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P8_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P8"} seriesCount={"S2"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">3.</span>
        <span className="number-text">
          Ask students to return to their seats, and then prompt them to think
          individually about how the items were grouped among the stations.
          Invite them to share their ideas with a partner using the following
          prompts:
        </span>
      </div>
      <ul className="pl-45 circle-listing">
        <li>
          I agree that these items are grouped together because ____________.
        </li>
        <li>
          I disagree with your idea about how these items are grouped. I think
          they are grouped together because ____________.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P8_Make_Observations_S2;
