import React from "react";

function T11_Develop_Initial_Ideas_S3() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  What are some ways we could gather evidence to determine if
                  this really happens?
                </li>
                <li>
                  Why might it be important for us to know if the amount of
                  daylight changes throughout the year?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T11_Develop_Initial_Ideas_S3;
