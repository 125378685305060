import React from "react";

function T24_Engage_Sensemaking() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Discuss your data with the class.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What caused each type of matter to change?</li>
                <li>
                  How would you describe the way each type of matter changed
                  when it was heated?
                </li>
                <li>What happened when each type of matter was cooled?</li>
                <li>
                  Why do you think the wood and egg could not change back to
                  what they were before they were heated?
                </li>
                <li>
                  What would happen if we heated the different types of matter,
                  like plastic, a second time?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T24_Engage_Sensemaking;
