import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P1_Connect_to_Prior_Knowledge() {
  return (
    <>
      <>
        <IdGeneratingComponent guideLineCount={"P1"}>
          <div className="mb-7 d-flex guidence-count">
            <span className="number pr-5 fw-700 text-nexus-medium-blue">
              1.
            </span>
            <span className="number-text">
              <span className="fw-700">Connect to Prior Knowledge:</span> Review
              the science ideas students figured out about the stars in the
              previous lesson. Ask:
            </span>
          </div>
          <ul className="pl-45 circle-listing">
            <li>What did we figure out about stars in the last lesson?</li>
            <li>
              Why do some stars in the night sky appear larger than others?
            </li>
            <li>
              What are some reasons that some stars appear brighter than others?
            </li>
            <li>What did we decide to investigate next?</li>
          </ul>
        </IdGeneratingComponent>
      </>
    </>
  );
}

export default P1_Connect_to_Prior_Knowledge;
