import React, { useState } from "react";
import LessonResourcesData from "../../LessonCommon/grade5/LessonResourcesData";
import Modal from "react-bootstrap/Modal";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.js";
import BreadcumbComponent from "../../Common/BreadcrumbComponent_2";
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

function LessonResources() {
  const [filtershow, setFilterShow] = useState(false);

  const [showpopup, setShowPopup] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [updateindex, setUpdateIndex] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const ShowPopup = (_index) => {
    setShowPopup(true);
    setUpdateIndex(_index);
  };

  const FilterOpen = () => {
    // setFilterShow(!filtershow);
  };

  const ClearFilter = () => {
    var checkboxes = document.getElementsByTagName("input"),
      val = null;
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == "checkbox") {
        if (val === null) val = checkboxes[i].checked;
        checkboxes[i].checked = null;
      }
    }
  };

  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Earth and Space Science | Unit 1",
              path: "/grade5/glassblowing",
            }}
            Child={{
              title: "Lesson 3 - Lesson Resources",
            }}
          />
        </h1>
      </div>
      <section className="bordered-box filter">
        <span className="cursor-pointer" onClick={() => FilterOpen()}>
          {filtershow === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="51"
              height="25"
              viewBox="0 0 51 25"
              fill="none"
            >
              <path
                d="M1 0L10 15.094V21L14 24V15.094L23 0H1ZM19.479 2L16.498 7H7.502L4.521 2H19.479Z"
                fill="#4978BC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39 14.5858L43.2929 10.2929C43.6834 9.90237 44.3166 9.90237 44.7071 10.2929C45.0976 10.6834 45.0976 11.3166 44.7071 11.7071L39.7071 16.7071C39.3166 17.0976 38.6834 17.0976 38.2929 16.7071L33.2929 11.7071C32.9024 11.3166 32.9024 10.6834 33.2929 10.2929C33.6834 9.90237 34.3166 9.90237 34.7071 10.2929L39 14.5858Z"
                fill="#4978BC"
              />
            </svg>
          ) : (
            <svg
              width="51"
              height="25"
              viewBox="0 0 51 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 0L10 15.094V21L14 24V15.094L23 0H1ZM19.479 2L16.498 7H7.502L4.521 2H19.479Z"
                fill="#4978BC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39 11.4142L34.7071 15.7071C34.3166 16.0976 33.6834 16.0976 33.2929 15.7071C32.9024 15.3166 32.9024 14.6834 33.2929 14.2929L38.2929 9.29289C38.6834 8.90237 39.3166 8.90237 39.7071 9.29289L44.7071 14.2929C45.0976 14.6834 45.0976 15.3166 44.7071 15.7071C44.3166 16.0976 43.6834 16.0976 43.2929 15.7071L39 11.4142Z"
                fill="#4978BC"
              />
            </svg>
          )}
        </span>
        <div className={filtershow === true ? "open" : "filter-forms"}>
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="page-title mb-10 filter-title">
                <h5 className="fw-600 pb-10">Categories</h5>
              </div>
              <div className="filter-form">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="TeacherPresentationSlide"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="TeacherPresentationSlide"
                  >
                    Teacher Presentation Slide
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="TeacherReference"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="TeacherReference"
                  >
                    Teacher Reference
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Video1"
                  />
                  <label className="form-check-label" htmlFor="Video1">
                    Video
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="page-title mb-10 filter-title">
                <h5 className="fw-600 pb-10">Media Type</h5>
              </div>
              <div className="filter-form">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="PDF"
                  />
                  <label className="form-check-label" htmlFor="PDF">
                    PDF
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Powerpoint"
                  />
                  <label className="form-check-label" htmlFor="Powerpoint">
                    Powerpoint
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Video2"
                  />
                  <label className="form-check-label" htmlFor="Video2">
                    Video
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="WordDocument"
                  />
                  <label className="form-check-label" htmlFor="WordDocument">
                    Word Document
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="page-title mb-10 filter-title">
                <h5 className="fw-600 pb-10">Subject</h5>
              </div>
              <div className="filter-form">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="PhysicalScience"
                  />
                  <label className="form-check-label" htmlFor="PhysicalScience">
                    Physical Science
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="page-title mb-10 filter-title">
                <h5 className="fw-600 pb-10">Level</h5>
              </div>
              <div className="filter-form">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="TeacherPresentationSlide"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="TeacherPresentationSlide"
                  >
                    Teacher Presentation Slide
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="TeacherReference"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="TeacherReference"
                  >
                    Teacher Reference
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Video3"
                  />
                  <label className="form-check-label" htmlFor="Video3">
                    Video
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex clear-filter">
            <button className="btn" onClick={() => ClearFilter()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clipPath="url(#clip0_17_6610)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.2465 17.25L0.21975 13.2262C0.0735 13.08 0 12.8888 0 12.696C0 12.504 0.0735 12.312 0.21975 12.1658L11.4165 0.96975C11.5627 0.82425 11.7548 0.75 11.9467 0.75C12.138 0.75 12.3307 0.82425 12.477 0.96975L17.7803 6.2745C17.9272 6.42075 18 6.61275 18 6.8055C18 6.9975 17.9272 7.18875 17.7803 7.33575L9.36825 15.75H13.5037V17.25H4.2465ZM6.98925 15.75L2.87475 11.6355L1.8105 12.696L4.8675 15.75H6.98925ZM11.943 2.56425L3.93525 10.5743L8.17875 14.8185L16.1857 6.807L11.943 2.56425Z"
                    fill="#555555"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_6610">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Clear All
            </button>
          </div>
        </div>
      </section>
      <section className="mt-20 home-modules-list lesson-module-list teacher-resources-listing">
        <div className="row g-4 row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
          {LessonResourcesData.map((x) => {
            return x.Resources.map((y, index) => {
              return (
                <>
                  {y.PdfUrl === "" ? (
                    <>
                      <div className="col opacity-50">
                        <div className="card h-100 module lesson-module teacher-resources-module">
                          <div className="teacher-resources-image">
                            <img src={y.ImageUrl} />
                          </div>
                          <div className="card-body">
                            <h6 className="fw-700 lesson-module-title teacher-resources-module-title">
                              {y.ResourcesName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col">
                        <div
                          onClick={() => ShowPopup(index)}
                          className="card h-100 module lesson-module teacher-resources-module cursor-pointer"
                        >
                          <div className="teacher-resources-image">
                            <img src={y.ImageUrl} />
                          </div>
                          <div className="card-body">
                            <h6 className="fw-700 lesson-module-title teacher-resources-module-title">
                              {y.ResourcesName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {showpopup === true ? (
                    <Modal
                      className="modal-fullscreen"
                      show={showpopup}
                      scrollable={true}
                      fullscreen={fullscreen}
                      onHide={() => {
                        setShowPopup(false);
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {x.Resources[updateindex].ResourcesName}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {x.Resources[updateindex].type == "pdf" ? (
                          <>
                            <Document
                              file={x.Resources[updateindex].PdfUrl}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                  className={
                                    numPages === 1 ? "page-index-zero" : ""
                                  }
                                  height={
                                    document.getElementsByClassName(
                                      "bordered-box"
                                    )[0]?.clientWidth * 0.5 ?? 150
                                  }
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                />
                              ))}{" "}
                            </Document>
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                          </>
                        ) : null}
                        {x.Resources[updateindex].type == "video" ? (
                          <>
                            <div className="row h-100 justify-content-center align-items-center">
                              <div className="col-12 col-md-8">
                                <div className="video-section">
                                  <video controls>
                                    <source
                                      src={x.Resources[updateindex].PdfUrl}
                                      type="video/mp4"
                                    ></source>
                                  </video>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {x.Resources[updateindex].type == "image" ? (
                          <>
                            <img
                              width={"100%"}
                              height={"100%"}
                              src={x.Resources[updateindex].PdfUrl}
                            />
                          </>
                        ) : null}
                      </Modal.Body>
                    </Modal>
                  ) : null}
                </>
              );
            });
          })}
        </div>
      </section>
    </>
  );
}

export default LessonResources;
