import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G30_Earn_Words_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G30"} seriesCount={"S1"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G30_Earn_Words_S1;
