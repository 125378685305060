import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P36_Initial_Ideas() {
  return (
    <IdGeneratingComponent guideLineCount={"P36"} seriesCount={"S2"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">5.</span>
        <span className="number-text">
          Prompt students to discuss the following questions with a partner:
        </span>
      </p>
      <ul className="pl-45 circle-listing">
        <li>What type of matter do you now think the pole made of?</li>
        <li>
          How would you use evidence from your investigations to explain why the
          pole didn't melt in the hot furnace?
        </li>
        <li>
          Why did the glassblower keep putting the glass back into the furnace?
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P36_Initial_Ideas;
