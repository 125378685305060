import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P4_Investigate_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S1"}>
        <div className="mb-10 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">7.</span>
          <span className="number-text">
            <span className="fw-700">Investigate:</span> Say, "I found different
            models that may help us investigate what is moving in the solar
            system. We can analyze these models to see if we can find any
            patterns among them."
          </span>
        </div>
        <section className="pl-15 mb-10 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="">
              Prompt students to access the What Is In Our Solar System?
              interactive.
            </p>
          </div>
        </section>
        <p className="pl-15">
          Explain that they should work individually to explore the models and
          complete Step 1 of the Investigate section of their science notebooks
          (page 29).
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P4_Investigate_S1;
