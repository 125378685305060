import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G11_Develop_Initial_Ideas_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G11"} seriesCount={"S1"}>
        <p>
          <span className="fw-700">Scaffolding:</span> Refer to the Initial
          Ideas Class Chart from Lesson 1 if students need a visual of the
          patterns they identified in the anchoring phenomenon.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default G11_Develop_Initial_Ideas_S1;
