import React, { useEffect, useId, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Calendar from "../../Calender/Calender";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.js";
import BreadcumbComponent from "../../Common/BreadcrumbComponent";
import { Draggable } from "@fullcalendar/interaction";
import { Tooltip } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

function generateDate(date, hour, minutes) {
  let dd = new Date();
  dd.setDate(date);
  dd.setHours(hour);
  dd.setMinutes(minutes);
  return dd;
}
function Module3() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded, setLoaded] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [changestate, setChangeState] = useState(0);
  const [ispreActive, setIsPreActive] = useState(0);
  const [isaltactive, setIsAltActive] = useState(1);

  const PrePacing = () => {
    setChangeState(0);
    setIsPreActive(0);
    setIsAltActive(1);
  };
  const AltPacing = () => {
    setChangeState(1);
    setIsPreActive(1);
    setIsAltActive(0);
  };

  useEffect(() => {
    let draggableEl = document.getElementById("elements");
    let draggableEl2 = document.getElementById("elements2");
    if (draggableEl) {
      let draggable = new Draggable(draggableEl, {
        itemSelector: ".dragging",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let duration2 = eventEl.getAttribute("duration")
            ? eventEl.getAttribute("duration")
            : 30;
          let date = eventEl.getAttribute("date")
            ? eventEl.getAttribute("date")
            : 30;
          let hours = eventEl.getAttribute("hours")
            ? eventEl.getAttribute("hours")
            : 30;
          let minutes = eventEl.getAttribute("minutes")
            ? eventEl.getAttribute("minutes")
            : 30;
          let id = eventEl.getAttribute("id");

          let data = {
            title: title,
            id: id,
            startTime: generateDate(date, 10, "00"),
            /* end: generateDate(date, hours, minutes + duration).setUTCHours(23, 59, 59, 999), */
            duration2: id === "note" ? null : duration2,
            allDay: id === "note",
            backgroundColor: id === "note" ? "#f57e20" : "#98ca3e",
            description: "",
            //editable: id !== "note",
            type: id === "note" ? "note" : "event",
            //durationEditable: id !== "note"
          };
          return data;
        },
      });
      return () => draggable.destroy();
    } else if (draggableEl2) {
      let draggable = new Draggable(draggableEl2, {
        itemSelector: ".dragging1",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let duration2 = eventEl.getAttribute("duration")
            ? eventEl.getAttribute("duration")
            : 20;
          let date = eventEl.getAttribute("date")
            ? eventEl.getAttribute("date")
            : 20;
          let hours = eventEl.getAttribute("hours")
            ? eventEl.getAttribute("hours")
            : 20;
          let minutes = eventEl.getAttribute("minutes")
            ? eventEl.getAttribute("minutes")
            : 20;
          let id = eventEl.getAttribute("id");

          let data = {
            title: title,
            id: id,
            startTime: generateDate(date, 10, "00"),
            /* end: generateDate(date, hours, minutes + duration).setUTCHours(23, 59, 59, 999), */
            duration2: id === "note" ? null : duration2,
            allDay: id === "note",
            backgroundColor: id === "note" ? "#f57e20" : "#98ca3e",
            description: "",
            //editable: id !== "note",
            type: id === "note" ? "note" : "event",
            //durationEditable: id !== "note"
          };
          return data;
        },
      });
      return () => draggable.destroy();
    }
  }, [changestate]);

  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Physical Science | Unit 1",
              path: "/grade2/glassblowing",
            }}
            Child={{
              title: "Pacing Guide",
            }}
          />
        </h1>
      </div>
      <section className="d-flex justify-content-center tabs mb-20">
        <div className="tab-box">
          <button
            onClick={() => PrePacing()}
            className={ispreActive === 0 ? "btn active" : "mr-5 btn"}
          >
            Recommended Pacing Guide
          </button>
          <button
            onClick={() => AltPacing()}
            className={isaltactive === 0 ? "btn active" : "ml-5 btn"}
          >
            Alternate Pacing Guide
          </button>
        </div>
      </section>
      {changestate === 0 ? (
        <div id="elements">
          <p className="mb-10">
            Use the recommended pacing guide or drag and drop each session into
            the calendar to customize your learning.
          </p>
          {/*   <h4 className="mb-5">How Do Objects Change Into Other Objects?</h4> */}
          <div className="row d-flex justify-content-between mb-3">
            <div className="col-12 col-lg-4">
              <b>Total Sessions:</b> 14
            </div>

            <div className="col-12 col-lg-4 text-end">
              <b>Total Time:</b> 420 minutes
            </div>
          </div>
          <div className="mb-15 table-responsive pacing-guide-table-responsive">
            <Tooltip title="Drag and drop" placement="top">
              <div className="pacing-guide-list-table">
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={1}
                  duration={"30"}
                  date={3}
                  hours={10}
                  minutes={30}
                  title="Lesson 1, Part A"
                >
                  <p className="text-center fw-700 p-1 guide-title">
                    Lesson 1, Part A
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={2}
                  duration={"30"}
                  date={4}
                  hours={10}
                  minutes={30}
                  title="Lesson 1, Part B"
                >
                  <p className="text-center fw-700 p-1 guide-title">
                    Lesson 1, Part B
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={3}
                  duration={"30"}
                  date={5}
                  hours={11}
                  minutes={30}
                  title="Lesson 2, Part A"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part A
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={4}
                  duration={"30"}
                  date={6}
                  hours={12}
                  minutes={30}
                  title="Lesson 2, Part B"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part B
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={5}
                  duration={"30"}
                  date={7}
                  hours={12}
                  minutes={30}
                  title="Lesson 2, Part B (continued)"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part B <span className="fw-400">(continued)</span>
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>

                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={6}
                  duration={"30"}
                  date={10}
                  hours={10}
                  minutes={30}
                  title="Lesson 2, Part C"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part C
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>

                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={7}
                  duration={"30"}
                  date={11}
                  hours={10}
                  minutes={30}
                  title="Lesson 3, Part A"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 3,&nbsp;Part A
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={8}
                  duration={"30"}
                  date={12}
                  hours={11}
                  minutes={30}
                  title="Lesson 3, Part B"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 3, Part B
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={9}
                  duration={"30"}
                  date={13}
                  hours={12}
                  minutes={30}
                  title="Lesson 3, Part B (continued)"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 3, Part B <span className="fw-400">(continued)</span>
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={10}
                  duration={"30"}
                  date={14}
                  hours={12}
                  minutes={30}
                  title="Lesson 3, Part C"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 3, Part C
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={11}
                  duration={"30"}
                  date={17}
                  hours={10}
                  minutes={30}
                  title="Lesson 3, Part C (continued)"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 3, Part C <span className="fw-400">(continued)</span>
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={12}
                  duration={"30"}
                  date={18}
                  hours={10}
                  minutes={30}
                  title="Lesson 3, Part D"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 3, Part D
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={13}
                  duration={"30"}
                  date={19}
                  hours={11}
                  minutes={30}
                  title="Lesson 4, Part A"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 4, Part A
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={14}
                  duration={"30"}
                  date={20}
                  hours={12}
                  minutes={30}
                  title="Lesson 4, Part B"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 4,&nbsp;Part B
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging"
                  id={14}
                  duration={"30"}
                  date={20}
                  hours={12}
                  minutes={30}
                  title="Lesson 4, Part B"
                ></div>
              </div>
            </Tooltip>
          </div>
          <div id="dandd">
            <div className="col-12 col-lg-12 text-center">
              <b id="note" className="addNoteButton dragging">
                <Tooltip title="Drag and drop a note" placement="top">
                  <img className="w-auto" src="/image/noteIcon.png" />
                </Tooltip>
              </b>
            </div>
            <p className="mb-20 text-i">My Pacing Guide</p>
            <div className="calender-container">
              <Calendar
                changestate={changestate}
                setChangeState={setChangeState}
              />
            </div>
          </div>
        </div>
      ) : (
        <div id="elements2">
          <p className="mb-20">
            This unit has been thoughtfully modified to retain the most critical
            portions of the unit. This alternative approach saves time but
            maintains the unit’s key investigation and sensemaking
            opportunities. Use the recommended pacing guide or drag and drop
            each section into the calendar to customize your learning.
          </p>
          {/*    <h4 className="mb-5">How Do Objects Change Into Other Objects?</h4> */}
          <div className="row d-flex justify-content-between mb-3">
            <div className="col-12 col-lg-4">
              <b>Total Sessions:</b> 12
            </div>

            <div className="col-12 col-lg-4 text-end">
              <b>Total Time:</b> 260 minutes
            </div>
          </div>
          <div className="mb-15 table-responsive pacing-guide-table-responsive">
            <Tooltip title="Drag and drop" placement="top">
              <div className="pacing-guide-list-table">
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={15}
                  duration={"20"}
                  date={3}
                  hours={10}
                  minutes={20}
                  title="Lesson 1, Part A"
                >
                  <p className="text-center fw-700 p-1 guide-title">
                    Lesson 1, Part A
                  </p>
                  <p className="text-center p-1 guide-duration">20 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={16}
                  duration={"25"}
                  date={4}
                  hours={10}
                  minutes={25}
                  title="Lesson 1, Part B"
                >
                  <p className="text-center fw-700 p-1 guide-title">
                    Lesson 1, Part B
                  </p>
                  <p className="text-center p-1 guide-duration">25 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={17}
                  duration={"30"}
                  date={5}
                  hours={11}
                  minutes={30}
                  title="Lesson 2, Part A"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part A
                  </p>
                  <p className="text-center p-1 guide-duration">30 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={18}
                  duration={"20"}
                  date={6}
                  hours={12}
                  minutes={20}
                  title="Lesson 2, Part B"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part B
                  </p>
                  <p className="text-center p-1 guide-duration">20 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={19}
                  duration={"20"}
                  date={7}
                  hours={12}
                  minutes={20}
                  title="Lesson 2, Part B (continued)"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 2, Part B <span className="fw-400">(continued)</span>
                  </p>
                  <p className="text-center p-1 guide-duration">20 minutes</p>
                </div>
                <div>
                  <div
                    className="p-0 pacing-guide-lesson-list dragging1"
                    id={20}
                    duration={"15"}
                    date={10}
                    hours={10}
                    minutes={15}
                    title="Lesson 2, Part C"
                  >
                    <p className="p-1 text-center fw-700 guide-title">
                      Lesson 2, Part C
                    </p>
                    <p className="text-center p-1 guide-duration">15 minutes</p>
                  </div>
                </div>
                <div>
                  <div
                    className="p-0 pacing-guide-lesson-list dragging1"
                    id={21}
                    duration={"20"}
                    date={11}
                    hours={10}
                    minutes={20}
                    title="Lesson 3, Part A"
                  >
                    <p className="p-1 text-center fw-700 guide-title">
                      Lesson 3,&nbsp;Part A
                    </p>
                    <p className="text-center p-1 guide-duration">20 minutes</p>
                  </div>
                </div>
                <div>
                  <div
                    className="p-0 pacing-guide-lesson-list dragging1"
                    id={22}
                    duration={"20"}
                    date={12}
                    hours={11}
                    minutes={20}
                    title="Lesson 3, Part B (continued)"
                  >
                    <p className="p-1 text-center fw-700 guide-title">
                      Lesson 3, Part B{" "}
                      <span className="fw-400">(continued)</span>
                    </p>
                    <p className="text-center p-1 guide-duration">20 minutes</p>
                  </div>
                </div>
                <div>
                  <div
                    className="p-0 pacing-guide-lesson-list dragging1"
                    id={23}
                    duration={"25"}
                    date={13}
                    hours={12}
                    minutes={25}
                    title="Lesson 3, Part C"
                  >
                    <p className="p-1 text-center fw-700 guide-title">
                      Lesson 3, Part C
                    </p>
                    <p className="text-center p-1 guide-duration">25 minutes</p>
                  </div>
                </div>
                <div>
                  <div
                    className="p-0 pacing-guide-lesson-list dragging1"
                    id={24}
                    duration={"20"}
                    date={14}
                    hours={13}
                    minutes={20}
                    title="Lesson 3, Part C (continued)"
                  >
                    <p className="p-1 text-center fw-700 guide-title">
                      Lesson 3, Part C{" "}
                      <span className="fw-400">(continued)</span>
                    </p>
                    <p className="text-center p-1 guide-duration">20 minutes</p>
                  </div>
                  <div
                    className="p-0 pacing-guide-lesson-list dragging1"
                    id={25}
                    duration={"10"}
                    date={15}
                    hours={14}
                    minutes={10}
                    title="Lesson 3, Part D"
                  >
                    <p className="p-1 text-center fw-700 guide-title">
                      Lesson 3, Part D
                    </p>
                    <p className="text-center p-1 guide-duration">10 minutes</p>
                  </div>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={26}
                  duration={"20"}
                  date={16}
                  hours={15}
                  minutes={20}
                  title="Lesson 4, Part A"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 4, Part A
                  </p>
                  <p className="text-center p-1 guide-duration">20 minutes</p>
                </div>
                <div
                  className="p-0 pacing-guide-lesson-list dragging1"
                  id={27}
                  duration={"15"}
                  date={17}
                  hours={16}
                  minutes={15}
                  title="Lesson 4, Part B"
                >
                  <p className="p-1 text-center fw-700 guide-title">
                    Lesson 4, Part B
                  </p>
                  <p className="text-center p-1 guide-duration">15 minutes</p>
                </div>
              </div>
            </Tooltip>
          </div>
          <div id="dandd">
            <div className="col-12 col-lg-12 text-center">
              <b id="note" className="addNoteButton dragging1">
                <Tooltip title="Drag and drop a note" placement="top">
                  <img className="w-auto" src="/image/noteIcon.png" />
                </Tooltip>
              </b>
            </div>
            <div
              className="calender-container"
              style={{ height: "400px", overflow: "scroll" }}
            >
              <Calendar
                changestate={changestate}
                setChangeState={setChangeState}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Module3;
