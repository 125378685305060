import React from "react";

function T34_Prior_Knowledge_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-20 mt-5 font-16">
              Next, share your argument with the class.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <div className="mb-10 d-flex align-items-center thumb">
                <div className="icon">
                  <img
                    src="/image/lesson-images/thumb_up.svg"
                    alt="thumb_up"
                    title="thumb_up"
                  ></img>
                </div>
                <div className="text">
                  Put your thumb up if you had something similar in your
                  argument.
                </div>
              </div>
              <div className="d-flex align-items-center thumb">
                <div className="icon">
                  <img
                    src="/image/lesson-images/thumb_down.svg"
                    alt="thumb_down"
                    title="thumb_down"
                  ></img>
                </div>
                <div className="text">
                  Put your thumb down if you had something different in your
                  argument.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T34_Prior_Knowledge_S2;
