import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P22_Make_Comparisons() {
  return (
    <IdGeneratingComponent guideLineCount={"P22"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">5.</span>
        <span className="number-text">
          <span className="fw-700">Make Comparisons: </span>
          <span>
            Prompt students to compare their observations with a partner. Move
            around the room, and listen for students to describe the following
            patterns that result from the cause-and-effect relationships:
          </span>
        </span>
      </p>
      <ul className="pl-45 circle-listing">
        <li>
          Higher temperatures can cause many types of matter to melt into
          liquids or flexible solids.
        </li>
        <li>
          The temperature caused some types of matter (wood, egg) to change in
          other ways.
        </li>
        <li>
          Lower temperatures can cause liquids and flexible solids to change
          back to a solid.
        </li>
        <li>
          Lower temperatures did not cause the wood or egg to change back to
          what they were.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P22_Make_Comparisons;
