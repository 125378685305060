import React from "react";

function T9_Navigation() {
  return (
    <>
      <div className="lesson-brief">
        <section className="pt-40 pb-40 lesson-break w-75 mx-auto">
          <img
            src="/image/lesson-images/session_break.svg"
            alt="session_break"
            title="session_break"
          ></img>
        </section>
      </div>
    </>
  );
}

export default T9_Navigation;
