import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P14_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P14"} seriesCount={"S2"}>
      <div className="d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">12.</span>
        <span className="number-text">
          <span className="fw-700">Make Observations:</span> Open the Heating
          and Cooling Different Types of Matter resource. Click to play the
          Heating video. Pause the video after the materials are all placed
          under the lamp. Provide time for students to record their observations
          in the “Type of Matter” and “Observation{" "}
          <span className="text-decoration-underline">Before</span> Heating”
          columns of the data table.
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P14_Make_Observations_S2;
