import React from "react";

function T35_Anchoring_Phenomenon() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  How does what we learned about temperature and the changes it
                  can cause relate to our anchoring phenomenon?
                </li>
                <li>
                  How does what we learned about reversible and irreversible
                  changes help us in our everyday lives?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T35_Anchoring_Phenomenon;
