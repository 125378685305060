import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G21_Navigate() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G21"}>
        <section className="guidelines-blue-section">
          <div className="title">
            <section className="right-icon-text-group">
              <div className="text">
                <p className="fw-700">Teacher Tip</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/Pencil.svg"
                  alt="Pencil"
                  title="Pencil"
                ></img>
              </div>
            </section>
          </div>
          <div className="p-2 text">
            <p>
              If your students had questions about the model in Step 4,
              incorporate them into the discussion in this step as you decide
              what to investigate in the next lesson.
            </p>
          </div>
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G21_Navigate;
