import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G7_Prior_Knowledge() {
  return (
    <IdGeneratingComponent guideLineCount={"G7"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/disciplinary_core_idea.svg"
            alt="disciplinary_core_idea"
            title="disciplinary_core_idea"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.A:</span> As students
        identify a property, ask them to describe what that property is (e.g.,
        flexibility describes how bendable an object is while texture describes
        how the object feels).
      </section>
    </IdGeneratingComponent>
  );
}

export default G7_Prior_Knowledge;
