import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G20"}>
        <section className="text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>
          <span className="fw-700">Formative Assessment Opportunity:</span>{" "}
          Listen for students to mention that we can explain some of the
          patterns we see in the sky, like the change in daylight hours, or that
          different stars are visible at different times of the year, because
          they are a result of Earth’s revolution around the Sun. We cannot yet
          explain some other patterns, like day and night or the Sun, the Moon,
          and the stars moving across the sky.
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon;
