import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P36_Initial_Ideas_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P36"} seriesCount={"S1"}>
      <section className="text-wrapping-right pb-10">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">4.</span>
          <span className="font-16">
            <span className="fw-700">Reflect on Initial Ideas: </span>
            Remind students that at the beginning of the lesson, they observed
            that the pole didn' t melt and that the glassblower kept putting the
            glass back into the furnace.
          </span>
        </div>
      </section>
      <p className="pl-20">
        Have students locate the Initial Ideas section of Lesson 3 in their
        science notebooks (pages 3-4). Prompt them to reflect on the initial
        ideas they recorded about these observations.
      </p>
    </IdGeneratingComponent>
  );
}

export default P36_Initial_Ideas_S1;
