import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import Modal from "react-bootstrap/Modal";

function PartC() {
  const [lgShow, setLgShow] = useState(false);
  return (
    <>
      <div className="lesson3-parts parta">
        <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
          PART C: INVESTIGATE: ONE EFFECT OF EARTH’S REVOLUTION
        </p>
        <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
            MATERIALS FOR PART C
          </h5>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                TEACHER
              </p>
              <ul className="pl-20 circle-listing">
                <li>
                  Digital Lesson 3*:
                  <ul className="pt-5 pl-20 mb-5 circle-listing">
                    <li>Summer and Winter Night Skies image</li>
                    <li>
                      Initial Ideas: Different Stars in the Winter and the
                      Summer Class Chart
                    </li>
                    <li>Notice and Wonder Class Chart</li>
                    <li>Alternative Digital Investigation (optional)</li>
                  </ul>
                </li>
                <li>1 Large styrene ball</li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <ul>
                <li>1 Small styrene ball</li>
                <li>
                  1 Stars Demonstration Model<sup>†</sup>
                  <ul className="pt-5 pl-20 mb-5 circle-listing">
                    <li>1 Night Sky Card Set</li>
                    <li>
                      Tape<sup>‡</sup>
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                STUDENT
              </p>
              <ul className="mb-20 pl-20 circle-listing">
                <li>1 Science Notebook*</li>
              </ul>
              <p className="font-13">
                <sup>*</sup> Accessible online
              </p>
              <p className="font-13">
                <sup>‡</sup> Prepared by the teacher
              </p>
              <p className="font-13">
                <sup>‡</sup> Needed but not supplied
              </p>
            </div>
          </div>
        </section>
        <h5 className="fw-700 mb-10 text-decoration-underline">PREPARATION</h5>
        <p className="fw-700 mb-7">
          Part <span className="text-nexus-blue">C</span> (10 minutes)
        </p>
        <ul className="mb-20 pl-20 number-listing">
          <li>Prepare to present Digital Lesson 3.</li>
          <li>
            <p className="mb-7">
              Set up a Stars Demonstration Model. Tape together the cards from
              the Night Sky Set provided in the kit with the graphics facing in.
              Set the model on a table where the class can gather around and see
              inside. If you do not have a large enough space, consider
              projecting the model using a document camera.
            </p>
            <section className="icon-text-group align-items-center">
              <div
                onClick={() => setLgShow(true)}
                className="icon cursor-pointer"
              >
                <Tooltip title="Video Alternative" placement="bottom">
                  <img src="./image/lab_video_option.png" alt="" title="" />
                </Tooltip>
              </div>
              <div className="text">
                If you are unable to conduct this hands-on demonstration, a
                digital alternative is available within Digital Lesson 3.
              </div>
            </section>
          </li>
          <li>
            Place the large styrene ball from the kit in the center of the
            model. Figure 3.2 shows a completed model.
          </li>
          <li>
            Mark a dot on one side on the small styrene ball from the kit, as
            shown in Figure 3.3.
          </li>
          <li>
            <p className="mb-15">
              Ensure that students have access to their science notebooks.
            </p>
            <div className="row">
              <div className="col-12 col-md-6">
                <img
                  src="/image/Figure_3_2_02.png"
                  alt="Completed Stars Demonstration Model"
                  title="Completed Stars Demonstration Model"
                />
                <p>
                  <span className="fw-700">Figure 3.2:</span> Completed Stars
                  Demonstration Model
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="/image/Figure_3_2_01.png"
                  alt="Small styrene ball with dot"
                  title="Small styrene ball with dot"
                />
                <p>
                  <span className="fw-700">Figure 3.3:</span> Small styrene ball
                  with dot
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        size="lg modal-dialog-centered"
        className="modal-dialog-centered modal-dialog-scrollable"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Stars Demonstration Model
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-20 video-section">
            <video controls>
              <source
                src="/videos/lesson3-videos/gr5_l3_space_stars_demonstration_model_video.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PartC;
