import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G5_Ask_Questions_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G5"} seriesCount={"S1"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G5_Ask_Questions_S1;
