import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P20_Prior_Knowledge_S4() {
  return (
    <IdGeneratingComponent guideLineCount={"P20"} seriesCount={"S4"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">3.</span>
        <span className="number-text">(continued)</span>
      </div>
      <p className="pl-20 mb-7">
        Use the following steps to model how to use the simulation. As you do,
        model recording the class’s observations for glass while students follow
        along in their notebooks.
      </p>
      <ul className="pl-45 pb-10 circle-listing numeric-listing">
        <li>
          Be sure that glass is still selected. Point out the different types of
          matter, the furnace, and the slider.
        </li>
        <li>
          Point out that the glass is in the furnace. Ask students whether it is
          solid or liquid.
        </li>
        <li>
          Draw attention to the temperature on the left side of the furnace.
          Move the slider one click to the right to increase the temperature.
          Click the play button at the bottom of the screen. Wait for any effect
          to occur. Once the claw comes down, picks up the material, and goes
          away, the simulation is complete.
        </li>
        <li>
          Ask students what they observe. Record their observations on the chart
          on the board. If they do not see a change, write “NC,” which means “no
          change.”
        </li>
        <li>
          Drag the slider to the next temperature. Click to play, watch what
          happens, and record what students see.
        </li>
        <li>
          Keep raising the temperature and recording observations until all
          temperatures have been simulated. For each temperature, record the
          change students describe.{" "}
          <span className="text-i">
            (e.g., The glass marbles melt into a flexible solid at 2,400°F and
            stay that way at 2,600°F.)
          </span>
        </li>
        <li>
          Move the slider to the left to cool the matter. Click play. Ask
          students what they notice about the observable properties of the glass
          now. Record their observations on the model chart on the board?
        </li>
        <li>Reset the simulation.</li>
      </ul>
      <p className="pl-20">
        After modeling, answer any questions students may have about using the
        simulation.
      </p>
    </IdGeneratingComponent>
  );
}

export default P20_Prior_Knowledge_S4;
