import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P13_Prior_Knowledge() {
  return (
    <IdGeneratingComponent guideLineCount={"P13"}>
      <div className="d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">10.</span>
        <span className="number-text">
          <span className="fw-700">Connect to Prior Knowledge:</span> Invite
          students to briefly review the investigation plan they created on the
          Our Plan Class Chart to test how temperature affects different types
          of matter.
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P13_Prior_Knowledge;
