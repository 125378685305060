import dateFormat, { masks } from "dateformat";
const now = new Date();

const Events = [
  // { title: "All Day Event", start: getDate("YEAR-MONTH-01") },
  {
    title: "Lesson 1, Part A",
    start: getDate("YEAR-MONTH-08T12:00:00"),
    end: getDate("YEAR-MONTH-08T12:20:00"),
  },
  {
    title: "Lesson 1, Part B",
    start: getDate("YEAR-MONTH-09T12:00:00"),
    end: getDate("YEAR-MONTH-09T12:25:00"),
  },
  {
    title: "Lesson 2, Part A",
    start: getDate("YEAR-MONTH-10T12:00:00"),
    end: getDate("YEAR-MONTH-10T12:30:00"),
  },
  {
    title: "Lesson 2, Part B",
    start: getDate("YEAR-MONTH-11T12:00:00"),
    end: getDate("YEAR-MONTH-11T12:20:00"),
  },
  {
    title: "Lesson 2, Part B (continued)",
    start: getDate("YEAR-MONTH-11T12:00:00"),
    end: getDate("YEAR-MONTH-11T12:20:00"),
  },
  {
    title: "Lesson 2, Part C",
    start: getDate("YEAR-MONTH-12T12:00:00"),
    end: getDate("YEAR-MONTH-12T12:15:00"),
  },
  {
    title: "Lesson 3, Part B",
    start: getDate("YEAR-MONTH-13T12:00:00"),
    end: getDate("YEAR-MONTH-13T12:20:00"),
  },
  {
    title: "Lesson 3, Part B (continued)",
    start: getDate("YEAR-MONTH-14T12:00:00"),
    end: getDate("YEAR-MONTH-14T12:20:00"),
  },
  {
    title: "Lesson 3, Part C",
    start: getDate("YEAR-MONTH-15T12:00:00"),
    end: getDate("YEAR-MONTH-15T12:25:00"),
  },
  {
    title: "Lesson 3, Part C (continued)",
    start: getDate("YEAR-MONTH-15T12:00:00"),
    end: getDate("YEAR-MONTH-15T12:20:00"),
  },
  {
    title: "Lesson 3, Part D",
    start: getDate("YEAR-MONTH-16T12:00:00"),
    end: getDate("YEAR-MONTH-16T12:10:00"),
  },
  {
    title: "Lesson 4, Part A",
    start: getDate("YEAR-MONTH-17T12:00:00"),
    end: getDate("YEAR-MONTH-17T12:20:00"),
  },
  {
    title: "Lesson 4, Part B",
    start: getDate("YEAR-MONTH-18T12:00:00"),
    end: getDate("YEAR-MONTH-18T12:15:00"),
  },
];

function getDate(dayString) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
}

export default Events;
