import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function T2_Make_Observations_S3() {
  const [lgShow, setLgShow] = useState(false);
  const [updatestate, setUpdatedState] = useState(0);

  const ImageURl = [
    {
      original: "/image/summer-and-winter-night-skies.png",
      originalTitle: "summer-and-winter-night-skies",
    },
  ];

  const setSelectedPage = (index) => {
    setUpdatedState(index);
  };

  return (
    <>
      <div className="lesson-brief">
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <section className="icon-text-group">
            <div className="icon">
              <img
                src="/image/lesson-images/class_discussion.svg"
                alt="class_discussion"
                title="class_discussion"
              ></img>
            </div>
            <div className="text">
              <p className="mt-5 font-16">
                What question could we ask about these pictures that will help
                us figure out a reason for the different stars we see?
              </p>
            </div>
          </section>
        </div>
        <div className="p-2 row justify-content-center">
          {/* <div className="col-12 col-md-9">
            <div className="shadow">
              <img
                src="/image/summer-and-winter-night-skies.png"
                alt="summer-and-winter-night-skies"
                title="summer-and-winter-night-skies"
              ></img>
            </div>
          </div> */}
          <div className="carousel-img position-relative">
            <div>
              <img
                src="/image/summer-and-winter-night-skies.png"
                alt="summer-and-winter-night-skies"
                title="summer-and-winter-night-skies"
              ></img>
            </div>
            <Tooltip title="Enlarge View" placement="top">
              <span
                onClick={() => setLgShow(true)}
                className="plus-icon position-absolute"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_27_2)">
                    <path
                      d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_27_2">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </Tooltip>
            <Modal
              size="xl modal-dialog-centered"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={true}
                  useBrowserFullscreen={false}
                  items={ImageURl}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default T2_Make_Observations_S3;
