import React from "react";

function T18_Collect_Data_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Complete the Observe section of your notebook.
            </p>
          </div>
        </section>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Compare your observations with a partner.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/g5_space_notebookl3_forpoc_6-15-234_page_18.png"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T18_Collect_Data_S2;
