const ModuleData = [
  {
    id: 0,
    UnitName: "Life Science | Unit 1",
    ModuleName: "How do plants increase in size?",
    ImagePath: "/image/gr5_l1_mod_tile.png",
    url: "",
  },
  {
    id: 1,
    UnitName: "Life Science | Unit 2",
    ModuleName:
      "How can communities protect the environment from new organisms?",
    ImagePath: "/image/gr5_l5_mod_tile.png",
    url: "",
  },
  {
    id: 2,
    UnitName: "Physical Science | Unit 1",
    ModuleName: "What happens to matter when substances are combined?",
    ImagePath: "/image/gr5_l4_mod_tile.png",
    url: "",
  },
  {
    id: 3,
    UnitName: "Physical Science | Unit 2",
    ModuleName: "What happens to the food animals eat?",
    ImagePath: "/image/gr5_l2_mod_tile.png",
    url: "",
  },
  {
    id: 4,
    UnitName: "Earth and Space Science | Unit 1",
    ModuleName: "How can we explain the patterns we notice in the sky?",

    ImagePath: "/image/gr5_l3_mod_tile.png",
    url: "/grade5/glassblowing",
  },
  // {
  //   id: 5,
  //   ModuleName: "Module 6",
  //   ImagePath: "/image/card-img.png",
  //   url: "",
  // },
  // {
  //   id: 6,
  //   ModuleName: "Module 7",
  //   ImagePath: "/image/card-img.png",
  //   url: "",
  // },
  // {
  //   id: 7,
  //   ModuleName: "Module 8",
  //   ImagePath: "/image/card-img.png",
  //   url: "",
  // },
];

export default ModuleData;
