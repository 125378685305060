const UnitResourcesData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How can we explain the patterns we notice in the sky?",
    UnitResources: [
      {
        UnitResourcesName: "Stars Demonstration Model",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/gr5_l3_space_stars_demonstration_model_video.mp4",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Observe",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_observe.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Initial Ideas",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_initial_ideas.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Gather Evidence",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_gather_evidence.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Reflect",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_reflect.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Investigate",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_investigate.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part B – Observe",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_partb_observe.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part B – Analyze Data",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_analyze_data.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part B – Checkpoint",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_checkpoint.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Movement in the Solar System",
        type: "image",
        ImageUrl: "/image/POC_tiles_image.png",
        PdfUrl: "/image/gr5_space_l3_image_solarsystem_infographic.png",
      },
      {
        UnitResourcesName: "Lesson 3 – Solar System Model",
        type: "image",
        ImageUrl: "/image/POC_tiles_image.png",
        PdfUrl: "/image/g5_space_l3_image_solarsystem.png",
      },
      {
        UnitResourcesName: "Lesson 3, Science Notebook: Teacher Version",
        type: "pdf",
        ImageUrl: "/image/notebook_tv.png",
        PdfUrl: "/pdf/g5_space_l3_answerkey.pdf",
      },
      {
        UnitResourcesName: "Lesson 3: Supporting All Learners",
        type: "pdf",
        ImageUrl: "/image/rem_ext.png",
        PdfUrl: "/pdf/g5_space_l3_remed_enrich.pdf",
      },
      {
        UnitResourcesName: "Lesson 1, Part A – Observe",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 2, Part A – Explore",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 4, Part C – Investigate",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName:
          "Lesson 3, Summative Assessment: Modeling the Solar System",
        type: "pdf",
        ImageUrl: "/image/summ_asses.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName:
          "Lesson 3, Assessment Rubric: Modeling the Solar System",
        type: "pdf",
        ImageUrl: "/image/assess_rubric.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 4 – Stars in the Sky",
        type: "pdf",
        ImageUrl: "/image/interactive_tile.png",
        PdfUrl: "",
      },
    ],
  },
];

export default UnitResourcesData;
