import React from "react";

function T7_Earn_Words_S1() {
  return (
    <>
      <div className="lesson-brief">
        <div className="container d-flex justify-content-center">
          <div className="row">
            <div className="col">
              <input type="checkbox" id="card-1" />
              <label className="col-md-2 flash-card-container" for="card-1">
                <div className="card-flip">
                  <div className="flash-card front d-flex align-items-center justify-content-center">
                    <div className="card-block">
                      <p className="fw-900">?</p>
                    </div>
                  </div>
                  <div className="flash-card back d-flex align-items-center justify-content-center">
                    <div className="card-block">
                      <p className="fw-900">revolution</p>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="col">
              <input type="checkbox" id="card-2" />
              <label className="col-md-2 flash-card-container" for="card-2">
                <div className="card-flip">
                  <div className="flash-card front d-flex align-items-center justify-content-center">
                    <div className="card-block">
                      <p className="fw-900">?</p>
                    </div>
                  </div>

                  <div className="flash-card back d-flex align-items-center justify-content-center">
                    <div className="card-block">
                      <p className="fw-900">orbit</p>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T7_Earn_Words_S1;
