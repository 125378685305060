import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P8_Make_Observations_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P8"} seriesCount={"S1"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">2.</span>
        <span className="number-text">
          <span className="fw-700">Make Observations:</span> Point out the five
          stations around the room. Explain that students will visit each
          station and discuss their observations of each item’s observable
          properties with each other.
        </span>
      </p>
      <p className="pl-20">
        Divide the class into five groups and assign each group a starting
        station. Direct groups to discuss the observable properties of the items
        at their first station. After a few minutes, prompt groups to rotate to
        the next station. Repeat this process until each group has visited all
        five stations.
      </p>
    </IdGeneratingComponent>
  );
}

export default P8_Make_Observations_S1;
