import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P15_Checkpoint() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P15"}>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">10.</span>
          <span className="number-text">
            <span className="">
              <section className="align-items-center icon-text-group">
                <div className="">
                  <div className="text-wrapping-left">
                    <p>
                      <span className="fw-700">Checkpoint:</span> Instruct
                      students to complete the checkpoint in their science
                      notebooks (page 38).
                      <div className="pt-5 pr-5 icon">
                        <img
                          src="/image/lesson-images/notebook.svg"
                          alt="notebook"
                          title="notebook"
                        ></img>
                      </div>
                    </p>
                  </div>
                </div>
              </section>
            </span>
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P15_Checkpoint;
