import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P38_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"P38"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">7.</span>
        <span className="number-text">
          <span className="fw-700">Navigate to the Next Lesson:</span> Say, “We
          have figured out many things about matter. We know that many objects
          made of matter can be taken apart and built into something new. We
          also figured out that temperature can cause matter to change.
          Sometimes these changes are reversible and sometimes they are not. Did
          I miss anything?”
        </span>
      </p>
      <p className="pl-15 mb-7">
        Invite students to share any additional insights. Ask:
      </p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          Can we use the science ideas that we figured out about matter and
          temperature to explain how pieces of glass can be changed into a
          colorful pitcher?
        </li>
        <li>
          Do we have enough evidence to answer the question, “How do objects
          change into other objects?”
        </li>
      </ul>
      <p className="pl-15">
        Invite students to share their ideas. Next, say, “It sounds like we have
        enough evidence to explain both our unit-driving question and the
        anchoring phenomenon. Let’s begin our final explanations of these in the
        next lesson.”
      </p>
    </IdGeneratingComponent>
  );
}

export default P38_Navigate;
