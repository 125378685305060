import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G2_Make_Observations_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G1"} seriesCount={"S1"}>
      {/*  <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G2_Make_Observations_S1;
