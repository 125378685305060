import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G13_Prior_Knowledge() {
  return (
    <IdGeneratingComponent guideLineCount={"G13"}>
      {/*  <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G13_Prior_Knowledge;
