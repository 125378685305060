import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G8_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G8"} seriesCount={"S2"}>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/scaffolding.svg"
            alt="scaffolding"
            title="scaffolding"
          ></img>
        </div>
        <span className="fw-700">Scaffolding:</span> If pairs need help
        beginning or continuing their conversation, ask the following questions:
      </section>
      <ul className="pl-30 mb-20 circle-listing">
        <li>
          What patterns or similarities did you notice among the items at each
          station?
        </li>
        <li>How do you think the objects were sorted?</li>
        <li>Why do you think that?</li>
      </ul>
      <section className="guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            ELA Connection <span className="text-nexus-lightgrey">(A,B,C)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p>
            By providing the scaffolded prompts, students can build on their
            partner’s ideas by linking their comments to their partner’s remarks
            (SL.2.1.b)
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G8_Make_Observations_S2;
