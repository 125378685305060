import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G8_Checkpoint() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G8"}>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>
          <span className="fw-700">Formative Assessment Opportunity:</span> Use
          this checkpoint to assess students' understanding of the following
          science ideas before moving on:
        </section>
        <ul className="pl-30 mb-10 circle-listing">
          <li>
            Revolution is the action or movement of one object around another.
          </li>
          <li>An orbit is the path the moving object follows.</li>
        </ul>
        <p>
          Ensure that students understand the distinction and relationship
          between these terms.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default G8_Checkpoint;
