import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G31_Engage_Argument_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G31"} seriesCount={"S1"}>
      <p className="pb-7">
        <span className="fw-700">Differentiation:</span> Use the following
        prompts for pairs who have differing answers and cannot come to an
        agreement:
      </p>
      <ul className="pl-30 pb-10 circle-listing">
        <li>
          Does a change happen to the matter that is so different that it cannot
          be reversed or undone?
        </li>
        <li>Why do you think this?</li>
      </ul>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/misconceptions.svg"
            alt="misconceptions"
            title="misconceptions"
          ></img>
        </div>
        <div className="text">
          <p>
            <span className="fw-700">Misconceptions:</span> Listen for students
            to express either of the following misconceptions during their
            discussion:
          </p>
        </div>
      </section>
      <ul className="pl-30 pb-10 circle-listing">
        <li>Changes to matter can never be undone.</li>
        <li>All changes to matter can be undone.</li>
      </ul>
      <p className="">
        If you hear either of these misconceptions, address them using a balloon
        as an example. Discuss with students whether blowing into a balloon is
        change that can be undone, and why. Then ask if bursting or popping a
        balloon can be reversed.
      </p>
    </IdGeneratingComponent>
  );
}

export default G31_Engage_Argument_S1;
