import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LessonData from "../../LessonCommon/grade2/LessonData";
import BreadcumbComponent from "../../Common/BreadcrumbComponent";
function Module1(props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Physical Science | Unit 1",
              path: "/grade2/glassblowing",
            }}
          />
        </h1>
      </div>
      <div className="list mt-10">
        <div className="home-modules-list lesson-module-list">
          <div className="row g-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            {LessonData.map((x) => {
              {
                return x.Lessons.map((x, key) => {
                  return (
                    // <div className="col" key={key}>
                    //   <div className="card border-0 h-100 bg-transparent lesson-module">
                    //     <div className="card-body bg-transparent p-0 lesson-module-short-text">
                    //       <a
                    //         className={
                    //           x.Lessonurl === "" ? "" : "cursor-pointer"
                    //         }
                    //         onClick={() =>
                    //           x.Lessonurl === "" ? null : navigate(x.Lessonurl)
                    //         }
                    //       >
                    //         <img src={x.ImageUrl} />
                    //       </a>
                    //     </div>
                    //   </div>
                    // </div>
                    <div className="col" key={key}>
                      {x.Lessonurl === "" ? (
                        <>
                          <div className="card module h-100 opacity-50">
                            <img
                              src={x.ImageUrl}
                              className="card-img-top"
                              alt="..."
                            />
                            <div className="card-body">
                              {x.LessonNumber + ": " + x.LessonName}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <Link to={x.Lessonurl} className="cursor-pointer">
                            <div className="card module h-100">
                              <img
                                src={x.ImageUrl}
                                className="card-img-top"
                                alt="..."
                              />
                              <div className="card-body">
                                {x.LessonNumber + ": " + x.LessonName}
                              </div>
                            </div>
                          </Link>
                        </>
                      )}
                    </div>
                  );
                });
              }
            })}
            {/* <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson 2</h6>
                  <p>Description line 1</p>
                </div>
                <button className="fw-700 p-2 lesson-module-link">
                  View Lesson
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson 3</h6>
                  <p>Description line 1</p>
                </div>
                <Link
                  to={"/view/LessonProcedure"}
                  className="fw-700 p-2 lesson-module-link"
                >
                  View Lesson
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson 4</h6>
                  <p>Description line 1</p>
                </div>
                <button className="fw-700 p-2 lesson-module-link">
                  View Lesson
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson 5</h6>
                  <p>Description line 1</p>
                </div>
                <button className="fw-700 p-2 lesson-module-link">
                  View Lesson
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson 6</h6>
                  <p>Description line 1</p>
                </div>
                <button className="fw-700 p-2 lesson-module-link">
                  View Lesson
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson 7</h6>
                  <p>Description line 1</p>
                </div>
                <button className="fw-700 p-2 lesson-module-link">
                  View Lesson
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module">
                <div className="card-body p-3 lesson-module-short-text">
                  <h6 className="fw-700 lesson-module-title mb-7">Lesson </h6>
                  <p>Description line 1</p>
                </div>
                <button className="fw-700 p-2 lesson-module-link">
                  View Lesson
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Module1;
