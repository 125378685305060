import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G19_Engage_in_Sensemaking_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G18"} seriesCount={"S1"}>
        <section className="text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/discipliary_crosscutting.svg"
              alt="discipliary_crosscutting"
              title="discipliary_crosscutting"
            ></img>
          </div>
          <span className="fw-700">NGSS Elements:</span>{" "}
          <span className="fw-700 text-nexus-green">Patterns,</span>{" "}
          <span className="fw-700 text-nexus-orange">ESS1.B:</span> As students
          make sense of their observations, they apply what they know about
          Earth’s revolution to explain the observable pattern that the stars we
          can view from Earth change throughout the year.
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G19_Engage_in_Sensemaking_S1;
