import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G1_Prior_Knowledge() {
  return (
    <IdGeneratingComponent guideLineCount={"G1"}>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/formative_assessment_opportunity.svg"
            alt="formative-assessment-opportunity"
            title="formative-assessment-opportunity"
          ></img>
        </div>
        <span className="fw-700">Formative Assessment Opportunity:</span> Listen
        for students to share ideas and science words they learned in the
        previous lesson, including:
      </section>
      <ul className="pl-30 circle-listing">
        <li>Earned words: furnace, temperature, melt</li>
        <li>
          Objects made of pieces can often be taken apart and used to build
          something new.
        </li>
        <li>
          The furnace is extremely hot and causes the glass to melt and its
          observable properties to change.
        </li>
        <li>
          Glass changes from a solid to a flexible solid when the temperature is
          high enough.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G1_Prior_Knowledge;
