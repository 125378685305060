import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P5_Ask_Questions_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P5"} seriesCount={"S1"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">9.</span>
        <span className="number-text">
          <span className="fw-700">Ask Questions:</span> As a class, develop a
          question students can investigate to determine if their initial ideas
          are correct.
        </span>
      </div>
      <p className="pl-20 mb-7">Ask:</p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>What are we trying to figure out?</li>
        <li>
          What question could we investigate to figure out how different types
          of matter are affected by temperature?
        </li>
        <li>
          How will this help us figure out what type of matter the pole is made
          from and why it didn't melt?”
        </li>
      </ul>
      <p className="pl-20">
        As a class, arrive at a question similar to, “How does temperature
        affect other types of matter?
      </p>
    </IdGeneratingComponent>
  );
}

export default P5_Ask_Questions_S1;
