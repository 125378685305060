import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G3_Develop_Initial_Ideas_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G3"} seriesCount={"S1"}>
        {/* <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G3_Develop_Initial_Ideas_S1;
