import React from "react";

function T16_Make_Observations() {
  return (
    <>
      <div className="mb-20 lesson-brief">
        <p className="mb-10">Watch the Cooling Matter video.</p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9">
            <div className="mb-20 video-section">
              <video controls>
                <source
                  src="/videos/lesson3-videos/nex_gr2_glassblowing_coolingmatter_video.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </div>
        </div>
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Record your observations in the “Observations{" "}
              <span className="text-decoration-underline">After</span> Cooling”
              column of the data table.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_08.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="lesson-brief">
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="font-16">
              Complete the Analyzing My Data section of your notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_09.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T16_Make_Observations;
