import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P18_Collect_Data_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P18"} seriesCount={"S3"}>
        <section className="mb-10 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="mb-5 d-flex guidence-count">
            <span className="number pr-5 fw-700 text-nexus-medium-blue">
              4.
            </span>
            <span className="number-text">(continued)</span>
          </div>
        </section>
        <p className="pl-20">
          Next, have students answer the questions in the Explain section
          independently (page 40).
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P18_Collect_Data_S3;
