import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P4_Develop_Initial_S3() {
  return (
    <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S3"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">7.</span>
        <span className="number-text">
          Invite students to share their ideas with the class about why the
          glass is put back into the furnace again and again.
        </span>
      </div>
      <p className="pl-20 mb-7">Ask:</p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          What ideas do you have about why the glassblower kept placing the
          glass back in the furnace?
        </li>
        <li>How can you explain what you saw?</li>
      </ul>
      <p className="pl-20 pb-10">
        Record students’ ideas in the “Our Ideas About the Glass” column of the
        Initial Ideas Class Chart.
      </p>
      <p className="pl-20">(continued)</p>
    </IdGeneratingComponent>
  );
}

export default P4_Develop_Initial_S3;
