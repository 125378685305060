import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P25_Checkpoint() {
  return (
    <IdGeneratingComponent guideLineCount={"P25"}>
      <p className="d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">8.</span>
        <span className="number-text">
          <span className="fw-700">Checkpoint:</span> Prompt students to answer
          the checkpoint questions in their science notebooks to formatively
          assess their understanding of the effect of temperature on different
          types of matter (page 14).
        </span>
      </p>
    </IdGeneratingComponent>
  );
}

export default P25_Checkpoint;
