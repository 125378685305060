import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P3_Develop_Initial_Ideas_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P3"} seriesCount={"S3"}>
        <div className="d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">6.</span>
          <span className="number-text">
            Say, “I hear lots of ideas! It sounds like we think something is
            moving in our solar system, but we’re not entirely sure what or how.
            Should we investigate that first?”
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P3_Develop_Initial_Ideas_S3;
