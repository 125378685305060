import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P2_Make_Observations_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P2"} seriesCount={"S1"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">2.</span>
        <span className="number-text">
          <span className="fw-700">Make Observations:</span> Play the Revisiting
          the Furnace video for the class. Prompt students to share their
          observations and ask any new questions they have about what they
          observe.
        </span>
      </div>
      <p className="pl-20 mb-7">
        <span className="number-text">Ask:</span>
      </p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          What is something different that you notice about the glass in this
          video?
        </li>
        <li>What questions do you have about what you see here?</li>
      </ul>
      <p className="pl-20">
        Listen for and highlight observations and questions related to the glass
        being put back into the furnace again and again.
      </p>
    </IdGeneratingComponent>
  );
}

export default P2_Make_Observations_S1;
