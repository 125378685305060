import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G11_Develop_Initial_Ideas_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G11"} seriesCount={"S2"}>
        <p className="mb-7">
          <span className="fw-700">Scaffolding:</span> Listen for students to
          share the idea that some days the Sun is out longer than other days.
          If students don’t mention something similar, use the following
          questions to guide their thinking:
        </p>
        <ul className="pl-30 circle-listing">
          <li>
            Think about when you go to school in the morning. Is the Sun always
            out?
          </li>
          <li>
            Think about winter and summer. What’s different about the Sun?
          </li>
          <li>Does the day always seem the same?</li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G11_Develop_Initial_Ideas_S2;
