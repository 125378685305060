import React from "react";

function T15_Make_Predictions() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-20 mt-5 font-16">
              Talk with your classmates. Share your ideas.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What changes did you observe in the video?</li>
                <li>
                  What do you think will happen to these types of matter once
                  they are removed from the heat and cool?
                </li>
                <li>
                  Predict what will happen if these types of matter are placed
                  in colder temperatures.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Find the Cooling Matter data table in your notebook. Fill in the
              “My Prediction” column.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_08.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T15_Make_Predictions;
