import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G2_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G2"} seriesCount={"S2"}>
      {/*  <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G2_Make_Observations_S2;
