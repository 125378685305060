import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P9_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"P9"} seriesCount={"S2"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number fw-700 text-nexus-medium-blue pr-5">5.</span>
        <span className="number-text">
          Guide the discussion toward testing how these materials are affected
          by heat. Say, “During the last session, we predicted that the pole is
          made of [type of matter], but we do not have any evidence to support
          our idea. When this happens to scientists, they plan and carry out
          investigations to test their predictions and gather evidence.” Ask:
        </span>
      </div>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          How could we test whether the pole is made of [type of matter] instead
          of another type of matter?
        </li>
      </ul>
      <p className="pl-20">
        Invite students to share their investigation ideas with the class.
      </p>
    </IdGeneratingComponent>
  );
}

export default P9_Engage_Sensemaking;
