import React from "react";

function T19_Navigate() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <p>
                How could we investigate if higher or lower temperatures affect
                the observable properties of wood, plastic, and metal?
              </p>
            </div>
          </div>
        </section>
        <section className="pt-40 lesson-break w-75 mx-auto">
          <img
            src="/image/lesson-images/session_break.svg"
            alt="session_break"
            title="session_break"
          ></img>
        </section>
      </div>
    </>
  );
}

export default T19_Navigate;
