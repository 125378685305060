import React, { useEffect } from "react";
import FormikQuill from "../../../../Common/FormikQuill";
import CanvasDraw from "../../../../Common/CanvasComponent";
import { useState } from "react";

function T37_Engage_Sensemaking(props) {
  const LessonData = {
    stt_4_1: "",
    stt_4_2: "",
  };

  const [data, setData] = useState(LessonData);
  const onBlur = () => {};

  useEffect(() => {
    var fetch_data = localStorage.getItem("Lesson_Data");

    if (fetch_data != null || undefined) {
      const Lesson_Data = JSON.parse(localStorage.getItem("Lesson_Data"));
      setData(Lesson_Data);
    } else {
      localStorage.setItem("Lesson_Data", JSON.stringify(data));
    }
  }, []);

  const UpdateText = (name, val) => {
    name == "stt_4_1" && setData({ ...data, ["stt_4_1"]: val });
    name == "stt_4_2" && setData({ ...data, ["stt_4_2"]: val });
  };

  useEffect(() => {
    localStorage.setItem("Lesson_Data", JSON.stringify(data));
  }, [data]);

  // const IdeasAboutleft = () => {
  //   setChangeMode(false);
  // };
  // const IdeasAboutright = () => {
  //   setChangeMode(true);
  // };

  return (
    <>
      <div className="lesson-brief">
        <p className="fw-700 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue mb-20">
          Initial Ideas Class Chart
        </p>
        {/* <section className="d-flex justify-content-center tabs mb-20">
          <div className="d-flex tab-box">
            <button
              onClick={() => IdeasAboutleft()}
              className={changeMode === false ? "btn active" : "mr-5 btn"}
            >
              Our Ideas About the Glass
            </button>
            <button
              onClick={() => IdeasAboutright()}
              className={changeMode === true ? "btn active" : "ml-5 btn"}
            >
              Our Ideas About the Pole
            </button>
          </div>
        </section>
        {changeMode === false ? (
          <>
            <p className="text-center mb-10 fw-700">Glass</p>
            <div className="mb-20 text-to-speech-section text-to-speech-section-full">
              <div className="text-to-speech-box">
                <div className="text-to-speech-section-box left border-0">
                  <div className="text-to-speech-box-content p-0">
                    <div className="notepad-outer">
                      <div className="notepad-content table-break-line">
                        {["notepad_1_1"].map((name) => (
                          <FormikQuill
                            displayTextBarLocation={"top"}
                            id={"notepad_1_1"}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={handleChange}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="drawing-section mb-20">
              <CanvasDraw
                canvasImageFileId={"canvasstr_6_1"}
                seq_nbr={1}
                img_seq_nbr={1}
              />
            </div>
          </>
        ) : (
          <>
            <p className="text-center mb-10 fw-700">Pole</p>
            <div className="mb-20 interesting-phenomenon-notes">
              <div className="text-to-speech-section text-to-speech-section-full">
                <div className="text-to-speech-box">
                  <div className="text-to-speech-section-box left border-0">
                    <div className="text-to-speech-box-content p-0">
                      <div className="notepad-content table-break-line">
                        {["notepad_1_2"].map((name) => (
                          <FormikQuill
                            displayTextBarLocation={"top"}
                            id={"notepad_1_2"}
                            value={handleChange1}
                            onChange={onChange1}
                            onBlur={onBlur}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="drawing-section mb-20">
              <CanvasDraw
                canvasImageFileId={"canvasstr_6_2"}
                seq_nbr={2}
                img_seq_nbr={2}
              />
            </div>
          </>
        )} */}

        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>{" "}
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  Which ideas about the pole do you still agree with? Why?{" "}
                </li>
                <li>
                  Which ideas about the glass do you now disagree with? Why?{" "}
                </li>
                <li>
                  What new ideas did we figure out that can be used to describe
                  what the pole is made of or why the glass keeps going back
                  into the furnace?{" "}
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div>
          <img
            src="/image/lesson-images/Initial_Ideas_Class_Chart.svg"
            alt="Initial Ideas Class Chart"
            title="Initial Ideas Class Chart"
          />
        </div>
        <div className="table-responsive">
          <table className="table table-bordered m-0">
            <thead className="table-light">
              <tr>
                <th className="text-center align-middle">
                  Our Ideas About the Glass
                </th>
                <th className="text-center align-middle">
                  Our Ideas About the Pole
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-50">
                  <div className="text-to-speech-section text-to-speech-section-full">
                    <div className="text-to-speech-box">
                      <div className="text-to-speech-section-box left border-0">
                        <div className="text-to-speech-box-content p-0">
                          <div className="notepad-outer">
                            <div className="notepad-content table-break-line">
                              {["stt_4_1"].map((name) => (
                                <FormikQuill
                                  displayTextBarLocation={"top"}
                                  id={"stt_4_1"}
                                  name={name}
                                  onChange={UpdateText}
                                  onBlur={onBlur}
                                  value={data["stt_4_1"]}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="w-50">
                  <div className="text-to-speech-section text-to-speech-section-full">
                    <div className="text-to-speech-box">
                      <div className="text-to-speech-section-box left border-0">
                        <div className="text-to-speech-box-content p-0">
                          <div className="notepad-outer">
                            <div className="notepad-content table-break-line">
                              {["stt_4_2"].map((name) => (
                                <FormikQuill
                                  displayTextBarLocation={"top"}
                                  id={"stt_4_2"}
                                  name={name}
                                  onChange={UpdateText}
                                  onBlur={onBlur}
                                  value={data["stt_4_2"]}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default T37_Engage_Sensemaking;
