import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G7_Earn_Words_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G7"} seriesCount={"S1"}>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/scaffolding.svg"
              alt="scaffolding"
              title="scaffolding"
            ></img>
          </div>
          <span className="fw-700">Scaffolding:</span> Students' definitions are
          likely to be similar to the following:
        </section>
        <ul className="pl-30 mb-10 circle-listing">
          <li>
            <span className="fw-700">Revolution:</span> The action of one object
            moving around another object in one complete path.
          </li>
          <li>
            <span className="fw-700">Orbit:</span> The path an object follows
            around another object.
          </li>
        </ul>
        <p>
          If students struggle to use the context from infographic to define
          these terms, provide the definitions above. Next, use the large and
          small styrene balls provided in the kit to demonstrate revolution and
          orbit. Hold still the big styrene ball in one hand and move the small
          styrene ball around the big one as you explain.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default G7_Earn_Words_S1;
