import React from "react";

function T17_Engage_Sensemaking() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Think about the evidence you collected. Discuss these questions
              with the class:
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What objects did not change?</li>
                <li>How did the wax and food items change when heated?</li>
                <li>
                  What happened to the wax and food items when they were cooled?
                </li>
                <li>
                  What do you think would happen if we put those items back
                  under the lamp?
                </li>
                <li>
                  Who can use the word “cause” or “effect” to describe why the
                  wax and food items changed?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T17_Engage_Sensemaking;
