import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function LeftNav(props) {
  const [isactive, setIsActive] = useState();
  const [ischildactive, setIsChildActive] = useState(0);
  const [istopicactive, setISTopicActive] = useState(props.updatelesson);
  const location = useLocation();
  const navigate = useNavigate();
  function getNearestValue(num, arr) {
    let curr = arr[0].PageIndex;
    for (let index = 0; index < arr.length; index++) {
      if (arr[index].PageIndex - num <= 0) {
        curr = arr[index].PageIndex;
      }
    }
    return curr;
  }

  useEffect(() => {
    if (isactive) {
      window.scrollTo(0, 0);
    }
  }, [isactive]);

  useEffect(() => {
    if (props.updatelesson < 39) {
      setIsChildActive(3);
    }
    if (props.updatelesson < 30) {
      setIsChildActive(2);
    }
    if (props.updatelesson < 18) {
      setIsChildActive(1);
    }
    if (props.updatelesson < 1) {
      setIsChildActive(0);
    }
  }, [props.updatelesson]);

  let DashboardMenu = [
    {
      NavName: "Grade 5",
      id: 1,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_4322)">
            <path
              d="M18 10.031V3.608L11.964 0L6 3.569V10.068L0 13.292V20.508L6.136 24L12 20.607L17.864 24L24 20.508V13.331L18 10.031ZM16.857 10.067L12.536 12.451V7.495L16.857 4.956V10.067ZM11.962 1.357L16.234 3.953L11.966 6.462L7.79 3.908L11.962 1.357ZM11.393 7.491V12.451L7.143 10.03V4.896L11.393 7.491ZM5.563 22.333L1.142 19.794V14.618L5.563 17.213V22.333ZM1.79 13.631L6.568 11.101L10.805 13.518L6.137 16.185L1.79 13.631ZM6.707 17.218L11.429 14.521V19.577L6.707 22.334V17.218ZM17.293 22.333L12.571 19.576V14.46L17.293 17.214V22.333ZM13.219 13.472L17.466 11.082L22.235 13.676L17.868 16.185L13.219 13.472ZM22.857 19.795L18.436 22.334V17.218L22.857 14.68V19.795Z"
              fill="#E4E9F2"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_4322">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5",
    },
    {
      NavName: "Grade Book",
      id: 2,
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
          <g clipPath="url(#clip0_801_10)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 24.5312H5C3.343 24.5312 2 23.1882 2 21.5312V3.53125C2 1.87425 3.343 0.53125 5 0.53125H22V24.5312ZM20 20.5312H5.495C4.12 20.5312 4.12 22.5312 5.495 22.5312H20V20.5312ZM20 2.53125H5V18.5312H20V2.53125ZM17 5.53125V8.53125H8V5.53125H17Z"
              fill="#E2E8F0"
            />
          </g>
          <defs>
            <clipPath id="clip0_801_10">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.53125)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "#",
    },
    {
      NavName: "Professional Learning",
      id: 3,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_3861)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.4999 18.0513C13.9149 16.9972 16.1239 16.2053 17.9999 16.0513V22.5303C16.2609 22.7933 14.2449 23.6343 12.4999 24.5303V18.0513ZM11.4999 18.0513C10.0849 16.9972 7.87589 16.2053 5.99989 16.0513V22.5303C7.73889 22.7933 9.75489 23.6343 11.4999 24.5303V18.0513ZM4.99989 20.9683C2.95089 20.2943 2.00389 19.5312 2.00389 19.5312L1.99989 17.5063C1.99189 15.3793 2.08789 14.1622 4.64789 13.5972C7.45289 12.9782 10.4469 12.2803 8.88889 10.0763C4.98789 4.55325 8.07989 0.53125 11.9999 0.53125C15.9209 0.53125 18.9959 4.52225 15.1099 10.0763C13.5809 12.2612 16.4859 12.9642 19.3519 13.5972C21.9219 14.1652 22.0089 15.3883 21.9989 17.5312L21.9959 19.5312C21.9959 19.5312 21.0489 20.2943 18.9999 20.9683V14.9653L17.9179 15.0542C15.8639 15.2232 13.5579 16.0562 11.9999 17.1822C10.4409 16.0562 8.13689 15.2232 6.08189 15.0542L4.99989 14.9653V20.9683Z"
              fill="#E4E9F2"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_3861">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.53125)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "#",
    },
  ];

  let ModuleMenu = [
    {
      NavName: "Back to Units",
      id: 4,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_4322)">
            <path
              d="M18 10.031V3.608L11.964 0L6 3.569V10.068L0 13.292V20.508L6.136 24L12 20.607L17.864 24L24 20.508V13.331L18 10.031ZM16.857 10.067L12.536 12.451V7.495L16.857 4.956V10.067ZM11.962 1.357L16.234 3.953L11.966 6.462L7.79 3.908L11.962 1.357ZM11.393 7.491V12.451L7.143 10.03V4.896L11.393 7.491ZM5.563 22.333L1.142 19.794V14.618L5.563 17.213V22.333ZM1.79 13.631L6.568 11.101L10.805 13.518L6.137 16.185L1.79 13.631ZM6.707 17.218L11.429 14.521V19.577L6.707 22.334V17.218ZM17.293 22.333L12.571 19.576V14.46L17.293 17.214V22.333ZM13.219 13.472L17.466 11.082L22.235 13.676L17.868 16.185L13.219 13.472ZM22.857 19.795L18.436 22.334V17.218L22.857 14.68V19.795Z"
              fill="#E4E9F2"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_4322">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5",
    },
    {
      NavName: "Lessons",
      id: 5,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.514 7.14137C3.197 7.32037 3 7.66037 3 8.02837V16.9784C3 17.3484 3.197 17.6864 3.514 17.8654C5.111 18.7664 9.97 21.5044 11.519 22.3774C11.671 22.4624 11.838 22.5054 12.006 22.5054C12.17 22.5054 12.334 22.4644 12.483 22.3824C14.032 21.5274 18.873 18.8594 20.477 17.9744C20.8 17.7974 21 17.4554 21 17.0834V8.02837C21 7.66037 20.803 7.32037 20.485 7.14137C18.89 6.24237 14.041 3.50937 12.486 2.63337C12.335 2.54837 12.167 2.50537 12 2.50537C11.832 2.50537 11.665 2.54837 11.514 2.63337C9.959 3.50937 5.109 6.24237 3.514 7.14137ZM19.5 9.25637V16.7814L12.75 20.5034V12.9254L19.5 9.25637ZM4.5 16.6814V9.22337L11.25 12.9734V20.4844L4.5 16.6814ZM5.236 7.91237L12 4.09937L18.801 7.93337L12 11.6494L5.236 7.91237Z"
            fill="#4978BC"
          />
        </svg>
      ),
      url: "/grade5/glassblowing",
    },
    {
      NavName: "Teacher's Guide",
      id: 6,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <g clipPath="url(#clip0_447_12)">
            <path
              d="M22 0.53125H5C3.343 0.53125 2 1.87425 2 3.53125V21.5312C2 23.1882 3.343 24.5312 5 24.5312H22V4.53125H18V12.5312L16 10.5312L14 12.5312V4.53125H5.495C4.12 4.53125 4.12 2.53125 5.495 2.53125H22V0.53125Z"
              fill="#DBDCDE"
            />
          </g>
          <defs>
            <clipPath id="clip0_447_12">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.53125)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5/teacher",
    },
    {
      NavName: "Pacing Guide",
      id: 7,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 19.5312H16V15.5312H20V19.5312ZM14 9.53125H10V13.5312H14V9.53125ZM20 9.53125H16V13.5312H20V9.53125ZM8 15.5312H4V19.5312H8V15.5312ZM24 1.53125V23.5312H0V1.53125H24ZM22 7.53125H2V21.5312H22V7.53125ZM14 15.5312H10V19.5312H14V15.5312ZM8 9.53125H4V13.5312H8V9.53125Z"
            fill="#DBDCDE"
          />
        </svg>
      ),
      url: "/grade5/pacing",
    },
    {
      NavName: "Standards",
      id: 8,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.53162 3.46802C10.7165 2.19852 13.402 2.22071 15.5664 3.52614C17.7095 4.85816 19.012 7.23543 18.9998 9.79269C18.95 12.3332 17.5533 14.7213 15.8075 16.5673C14.7998 17.6377 13.6726 18.5841 12.4488 19.3873C12.3228 19.4602 12.1847 19.509 12.0415 19.5312C11.9036 19.5254 11.7693 19.4846 11.6508 19.4127C9.78243 18.2058 8.14333 16.6653 6.81233 14.8652C5.69859 13.3626 5.06583 11.5473 5 9.66567C4.99855 7.1035 6.34676 4.73752 8.53162 3.46802ZM9.79416 10.726C10.1617 11.6321 11.0292 12.2231 11.9916 12.2231C12.6221 12.2276 13.2282 11.9751 13.6748 11.5217C14.1214 11.0684 14.3715 10.4519 14.3692 9.80963C14.3726 8.82929 13.7955 7.94356 12.9073 7.56602C12.0191 7.18848 10.995 7.3936 10.3133 8.0856C9.63159 8.7776 9.42664 9.81997 9.79416 10.726Z"
            fill="#DBDCDE"
          />
          <ellipse
            opacity="0.4"
            cx="12"
            cy="21.5312"
            rx="5"
            ry="1"
            fill="#DBDCDE"
          />
        </svg>
      ),
      url: "/grade5/standards",
    },
    {
      NavName: "Materials",
      id: 9,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_4033)">
            <path
              d="M12.0001 24.0847L23.4284 19.7311L17.1284 17.3311L23.4284 14.9311L17.1284 12.5311L23.4284 10.1311L17.1284 7.73114L23.4284 5.33114L12.0001 0.977539L0.571777 5.33114L6.87178 7.73114L0.571777 10.1311L6.87178 12.5311L0.571777 14.9311L6.87178 17.3311L0.571777 19.7311L12.0001 24.0847ZM3.26842 5.33114L12.0001 2.00474L20.7318 5.33114L15.7801 7.21754L12.0001 8.65754L8.2201 7.21754L3.26842 5.33114ZM3.26842 10.1311L8.2201 8.24474L12.0001 9.68474L15.7801 8.24474L20.7318 10.1311L15.7801 12.0175L12.0001 13.4575L8.2201 12.0175L3.26842 10.1311ZM3.26842 14.9311L8.2201 13.0447L12.0001 14.4847L15.7801 13.0447L20.7318 14.9311L15.7801 16.8175L12.0001 18.2575L8.2201 16.8175L3.26842 14.9311ZM15.7801 17.8447L20.7318 19.7311L12.0001 23.0575L3.26842 19.7311L8.2201 17.8447L12.0001 19.2847L15.7801 17.8447Z"
              fill="#DBDCDE"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_4033">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.53125)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5/materials",
    },
    {
      NavName: "Leveled Readers",
      id: 10,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_3861)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.4999 18.0513C13.9149 16.9972 16.1239 16.2053 17.9999 16.0513V22.5303C16.2609 22.7933 14.2449 23.6343 12.4999 24.5303V18.0513ZM11.4999 18.0513C10.0849 16.9972 7.87589 16.2053 5.99989 16.0513V22.5303C7.73889 22.7933 9.75489 23.6343 11.4999 24.5303V18.0513ZM4.99989 20.9683C2.95089 20.2943 2.00389 19.5312 2.00389 19.5312L1.99989 17.5063C1.99189 15.3793 2.08789 14.1622 4.64789 13.5972C7.45289 12.9782 10.4469 12.2803 8.88889 10.0763C4.98789 4.55325 8.07989 0.53125 11.9999 0.53125C15.9209 0.53125 18.9959 4.52225 15.1099 10.0763C13.5809 12.2612 16.4859 12.9642 19.3519 13.5972C21.9219 14.1652 22.0089 15.3883 21.9989 17.5312L21.9959 19.5312C21.9959 19.5312 21.0489 20.2943 18.9999 20.9683V14.9653L17.9179 15.0542C15.8639 15.2232 13.5579 16.0562 11.9999 17.1822C10.4409 16.0562 8.13689 15.2232 6.08189 15.0542L4.99989 14.9653V20.9683Z"
              fill="#E4E9F2"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_3861">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.53125)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5/leveledreaders",
    },
    {
      NavName: "Unit Resources",
      id: 11,
      icon: (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8069 8.15462L20.1845 7.07453C19.6579 6.16061 18.491 5.84533 17.5758 6.36972V6.36972C17.1401 6.62635 16.6203 6.69916 16.131 6.57209C15.6416 6.44503 15.2228 6.12852 14.9671 5.69238C14.8026 5.41516 14.7141 5.0994 14.7108 4.77705V4.77705C14.7256 4.26023 14.5307 3.75941 14.1703 3.38868C13.8099 3.01794 13.3148 2.80887 12.7977 2.80908H11.5437C11.0372 2.80908 10.5516 3.01092 10.1942 3.36995C9.83693 3.72898 9.63742 4.2156 9.63985 4.72213V4.72213C9.62484 5.76793 8.77272 6.60782 7.72681 6.60771C7.40446 6.60436 7.0887 6.51595 6.81148 6.35142V6.35142C5.89631 5.82702 4.72936 6.1423 4.20278 7.05622L3.53459 8.15462C3.00865 9.0674 3.31964 10.2336 4.23024 10.7633V10.7633C4.82215 11.105 5.18678 11.7366 5.18678 12.4201C5.18678 13.1035 4.82215 13.7351 4.23024 14.0768V14.0768C3.3208 14.6029 3.00947 15.7663 3.53459 16.6764V16.6764L4.16617 17.7656C4.41289 18.2108 4.82685 18.5393 5.31644 18.6785C5.80603 18.8176 6.33088 18.7559 6.77487 18.507V18.507C7.21132 18.2524 7.73143 18.1826 8.21958 18.3132C8.70774 18.4438 9.12348 18.7641 9.37441 19.2027C9.53894 19.4799 9.62735 19.7957 9.6307 20.118V20.118C9.6307 21.1746 10.4872 22.0311 11.5437 22.0311H12.7977C13.8507 22.0311 14.7058 21.1801 14.7108 20.1272V20.1272C14.7083 19.619 14.9091 19.131 15.2684 18.7717C15.6277 18.4124 16.1157 18.2117 16.6238 18.2141C16.9454 18.2227 17.2599 18.3108 17.5392 18.4704V18.4704C18.4519 18.9963 19.6182 18.6854 20.1479 17.7748V17.7748L20.8069 16.6764C21.062 16.2385 21.132 15.717 21.0014 15.2274C20.8709 14.7377 20.5505 14.3204 20.1112 14.0677V14.0677C19.672 13.815 19.3516 13.3976 19.2211 12.9079C19.0905 12.4183 19.1605 11.8968 19.4156 11.459C19.5815 11.1693 19.8216 10.9292 20.1112 10.7633V10.7633C21.0164 10.2339 21.3267 9.0745 20.8069 8.16377V8.16377V8.15462Z"
            stroke="#DBDCDE"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            cx="12.1752"
            cy="12.4203"
            rx="2.63615"
            ry="2.63616"
            stroke="#DBDCDE"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      url: "/grade5/teacherresources",
    },
  ];

  let LessonMenu = [
    {
      NavName: "Back to Lessons",
      id: 12,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_4322)">
            <path
              d="M18 10.031V3.608L11.964 0L6 3.569V10.068L0 13.292V20.508L6.136 24L12 20.607L17.864 24L24 20.508V13.331L18 10.031ZM16.857 10.067L12.536 12.451V7.495L16.857 4.956V10.067ZM11.962 1.357L16.234 3.953L11.966 6.462L7.79 3.908L11.962 1.357ZM11.393 7.491V12.451L7.143 10.03V4.896L11.393 7.491ZM5.563 22.333L1.142 19.794V14.618L5.563 17.213V22.333ZM1.79 13.631L6.568 11.101L10.805 13.518L6.137 16.185L1.79 13.631ZM6.707 17.218L11.429 14.521V19.577L6.707 22.334V17.218ZM17.293 22.333L12.571 19.576V14.46L17.293 17.214V22.333ZM13.219 13.472L17.466 11.082L22.235 13.676L17.868 16.185L13.219 13.472ZM22.857 19.795L18.436 22.334V17.218L22.857 14.68V19.795Z"
              fill="#E4E9F2"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_4322">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5/glassblowing",
    },
    {
      NavName: "Planning",
      id: 13,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.4993 4.14185L19.9843 3.91779C19.9219 3.87432 19.8533 3.8405 19.7809 3.81748C19.4996 3.73029 19.1499 3.64732 18.8246 3.64685C18.5122 3.65602 18.203 3.71212 17.9073 3.81326C17.758 3.86079 17.6276 3.95448 17.5349 4.08083C17.4423 4.20719 17.3921 4.35969 17.3916 4.51638L17.3809 17.9568L18.5621 20.7623C18.5849 20.8166 18.6233 20.8629 18.6723 20.8954C18.7213 20.928 18.7789 20.9453 18.8377 20.9453C18.8966 20.9453 18.9541 20.928 19.0032 20.8954C19.0522 20.8629 19.0905 20.8166 19.1134 20.7623L20.2965 17.9578L20.3059 4.89185L20.9743 4.99076V9.50013C20.9743 9.62172 21.0226 9.73832 21.1086 9.8243C21.1945 9.91027 21.3111 9.95857 21.4327 9.95857C21.5543 9.95857 21.6709 9.91027 21.7569 9.8243C21.8429 9.73832 21.8912 9.62172 21.8912 9.50013V4.59373C21.8908 4.48397 21.851 4.378 21.7791 4.29508C21.7072 4.21215 21.6079 4.15779 21.4993 4.14185ZM19.3655 9.28826C19.3677 9.35874 19.3556 9.42892 19.3301 9.49466C19.3045 9.56039 19.2661 9.62033 19.217 9.67093C19.1679 9.72153 19.1091 9.76175 19.0442 9.78922C18.9792 9.81669 18.9094 9.83084 18.8389 9.83084C18.7684 9.83084 18.6986 9.81669 18.6337 9.78922C18.5687 9.76175 18.5099 9.72153 18.4608 9.67093C18.4117 9.62033 18.3733 9.56039 18.3477 9.49466C18.3222 9.42892 18.3102 9.35874 18.3123 9.28826V5.16326C18.3102 5.09278 18.3222 5.02259 18.3477 4.95686C18.3733 4.89112 18.4117 4.83118 18.4608 4.78058C18.5099 4.72999 18.5687 4.68976 18.6337 4.66229C18.6986 4.63482 18.7684 4.62067 18.8389 4.62067C18.9094 4.62067 18.9792 4.63482 19.0442 4.66229C19.1091 4.68976 19.1679 4.72999 19.217 4.78058C19.2661 4.83118 19.3045 4.89112 19.3301 4.95686C19.3556 5.02259 19.3677 5.09278 19.3655 5.16326V9.28826Z"
            fill="#DBDCDE"
          />
          <path
            d="M11.0371 13.5764C11.0371 13.9944 11.2031 14.3953 11.4987 14.6909C11.7942 14.9865 12.195 15.1527 12.613 15.1528H16.2773V12H12.613C12.195 12.0001 11.7942 12.1663 11.4987 12.4619C11.2031 12.7575 11.0371 13.1584 11.0371 13.5764ZM13.2257 13.5764C13.2259 13.6937 13.1913 13.8085 13.1262 13.9061C13.0612 14.0037 12.9686 14.0799 12.8603 14.1249C12.752 14.1699 12.6327 14.1818 12.5176 14.159C12.4025 14.1363 12.2968 14.0799 12.2138 13.997C12.1308 13.9141 12.0742 13.8084 12.0512 13.6934C12.0283 13.5783 12.04 13.459 12.0848 13.3506C12.1297 13.2422 12.2057 13.1496 12.3032 13.0844C12.4007 13.0192 12.5154 12.9844 12.6327 12.9844C12.7898 12.9845 12.9404 13.0469 13.0516 13.1579C13.1627 13.2688 13.2253 13.4193 13.2257 13.5764Z"
            fill="#DBDCDE"
          />
          <path
            d="M10.1198 13.5764C10.1206 12.9154 10.3835 12.2816 10.8509 11.8142C11.3183 11.3468 11.9521 11.0839 12.6131 11.0831H15.6305V6.18751C15.6303 6.09261 15.5926 6.00164 15.5254 5.93458C15.4583 5.86752 15.3672 5.82985 15.2723 5.82985H4.75124C4.73999 5.82985 3.58921 5.79563 3.58921 5.01235C3.58921 4.30594 4.56562 4.26798 4.7864 4.26798L13.3223 4.25907C13.4172 4.25895 13.5081 4.22122 13.5751 4.15418C13.6421 4.08713 13.6799 3.99623 13.68 3.90141V3.41251C13.68 3.31753 13.6423 3.22644 13.5751 3.15927C13.5079 3.09211 13.4168 3.05438 13.3219 3.05438H4.50093C3.67452 3.05438 2.11031 3.5536 2.11031 5.44173V18.413C2.10656 18.4566 2.02546 19.4888 2.70515 20.2303C3.14062 20.7047 3.77156 20.9456 4.58015 20.9456H15.2676C15.3626 20.9456 15.4537 20.9079 15.5209 20.8407C15.588 20.7736 15.6258 20.6825 15.6258 20.5875V16.0697H12.6131C11.9521 16.069 11.3183 15.806 10.8509 15.3386C10.3835 14.8712 10.1206 14.2374 10.1198 13.5764Z"
            fill="#DBDCDE"
          />
        </svg>
      ),
      url: "/grade5/view-planning",
    },
    {
      NavName: "Procedure & Guidance",
      id: 14,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <g clipPath="url(#clip0_17_6968)">
            <path
              d="M4 15.4898V0.300781H8V9.92978C6.365 11.4998 5.007 13.3878 4 15.4898ZM12.709 5.80078L13.75 8.42578C7.875 10.9888 4 17.4258 4 24.3008H8C8 19.0818 11.438 13.5508 15.333 12.3008L16.417 14.9258L20 8.14478L12.709 5.80078Z"
              fill="#E4E9F2"
            />
          </g>

          <defs>
            <clipPath id="clip0_17_6968">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.300781)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      // url: "/view-lessonprocedure",
      Parts: [
        {
          id: 1,
          LessonName: "Lesson 3",
          PartName: "Part A",
          Topics: [
            {
              id: 1,
              TopicName: "Connect to Prior Knowledge",
              PageIndex: 1,
            },
            {
              id: 2,
              TopicName: "Make Observations",
              PageIndex: 2,
            },
            {
              id: 3,
              TopicName: "Develop Initial Ideas",
              PageIndex: 5,
            },
            {
              id: 4,
              TopicName: "Investigate",
              PageIndex: 8,
            },
            {
              id: 5,
              TopicName: "Gather Evidence",
              PageIndex: 12,
            },
            {
              id: 6,
              TopicName: "Engage in Sensemaking",
              PageIndex: 13,
            },
            {
              id: 7,
              TopicName: "Earn Words",
              PageIndex: 14,
            },
            {
              id: 8,
              TopicName: "Checkpoint",
              PageIndex: 16,
            },
            {
              id: 9,
              TopicName: "Navigate to the Next Session",
              PageIndex: 17,
            },
          ],
        },

        {
          id: 2,
          PartName: "Part B",

          Topics: [
            {
              id: 10,
              TopicName: "Connect to Prior Knowledge",
              PageIndex: 18,
            },
            {
              id: 11,
              TopicName: "Develop Initial Ideas",
              PageIndex: 19,
            },
            {
              id: 12,
              TopicName: "Collect, Represent and Analyze Data",
              PageIndex: 22,
            },
            {
              id: 13,
              TopicName: "Engage in Sensemaking",
              PageIndex: 26,
            },
            {
              id: 14,
              TopicName: "Earn Words",
              PageIndex: 27,
            },
            {
              id: 15,
              TopicName: "Checkpoint",
              PageIndex: 28,
            },
            {
              id: 16,
              TopicName: "Navigate to the Next Session",
              PageIndex: 29,
            },
          ],
        },
        {
          id: 3,
          PartName: "Part C",

          Topics: [
            {
              id: 17,
              TopicName: "Connect to Prior Knowledge",
              PageIndex: 30,
            },
            {
              id: 18,
              TopicName: "Collect Data",
              PageIndex: 32,
            },
            {
              id: 19,
              TopicName: "Engage in Sensemaking",
              PageIndex: 35,
            },
            {
              id: 20,
              TopicName: "Advance Sensemaking of the Anchoring Phenomenon",
              PageIndex: 38,
            },
            {
              id: 21,
              TopicName: "Navigate to the Next Lesson",
              PageIndex: 39,
            },
          ],
        },
      ],
    },
    {
      NavName: "Assessment",
      id: 15,
      icon: (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.9 0H16H6H1.1C0.5 0 0 0.5 0 1.1V22.9C0 23.5 0.5 24 1.1 24H20.9C21.5 24 22 23.5 22 22.9V1.1C22 0.5 21.5 0 20.9 0ZM6 1H16V4H6V1ZM6 5H16C16.6 5 17 4.6 17.1 4H18V20H4V4H4.9C5 4.6 5.4 5 6 5ZM20 22H2V2H5V3H3V21H19V3H17V2H20V22Z"
            fill="black"
          />
          <path
            d="M9.4 15.6999C9.8 16.0999 10.4 16.0999 10.8 15.6999L14.9 11.5999C15.3 11.1999 15.3 10.5999 14.9 10.1999C14.5 9.7999 13.9 9.7999 13.5 10.1999L10.1 13.6999L8.7 12.2999C8.3 11.8999 7.7 11.8999 7.3 12.2999C6.9 12.6999 6.9 13.2999 7.3 13.6999L9.4 15.6999Z"
            fill="black"
          />
        </svg>
      ),
      url: "/grade5/view-assessment",
    },
    {
      NavName: "Lesson Resources",
      id: 16,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_270_682)">
            <path
              d="M4 4V24H24V4H4ZM22 22H6V9H22V22ZM19 19H9V18H19V19ZM19 16H9V15H19V16ZM19 13H9V12H19V13ZM22 3H3V22H2V2H22V3ZM20 1H1V20H0V0H20V1Z"
              fill="#DBDCDE"
            />
          </g>
          <defs>
            <clipPath id="clip0_270_682">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "/grade5/view-lessonresources",
    },
  ];

  const [dataToBeRendered, setDataToBeRendered] = useState(DashboardMenu);

  const MenuClose = () => {
    props.setMenuClose(!props.menuclose);
  };

  // useEffect(() => {
  //   if (window.location.pathname.toLowerCase().includes("/grade5")) {
  //     setDataToBeRendered(DashboardMenu);
  //   } else if (window.location.pathname.includes("view")) {
  //     setDataToBeRendered(LessonMenu);
  //   } else {
  //     setDataToBeRendered(ModuleMenu);
  //   }
  //   setIsActive(window.location.pathname);
  // }, [location]);

  useEffect(() => {
    if (window.location.pathname.toLowerCase().includes("grade5")) {
      setDataToBeRendered(DashboardMenu);
    }
    if (window.location.pathname.toLowerCase().includes("grade5/")) {
      setDataToBeRendered(ModuleMenu);
    }
    if (window.location.pathname.toLowerCase().includes("grade5/view")) {
      setDataToBeRendered(LessonMenu);
    }
    setIsActive(window.location.pathname);
  }, [location]);

  return (
    <>
      <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-2 d-md-block bg-body-tertiary sidebar collapse"
      >
        <div className="position-sticky sidebar-sticky">
          <ul
            className={
              props.menuclose === false
                ? "nav flex-column"
                : "nav flex-column nav-hide"
            }
          >
            {dataToBeRendered.map((val, key) =>
              val.id === 14 ? (
                <>
                  <li className="nav-item dropdown-menu-list">
                    <button
                      className={
                        isactive === val.url
                          ? "btn btn-toggle d-inline-flex align-items-center rounded border-0 left-nav-dropdown collapsed active"
                          : "btn btn-toggle d-inline-flex align-items-center rounded border-0 left-nav-dropdown collapsed"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#procedure-guidence-collapse"
                      aria-expanded="false"
                      // onClick={() => {
                      //   navigate(val.url);
                      // }}
                    >
                      {val.icon}
                      {val.NavName}
                    </button>
                    <div
                      key={key}
                      className="collapse"
                      id="procedure-guidence-collapse"
                    >
                      <ul className="btn-toggle-nav list-unstyled fw-normal left-nav-dropdown-menus pl-30 small">
                        <li>
                          <button
                            className={
                              ischildactive === 0
                                ? "btn btn-toggle d-inline-flex align-items-center rounded left-nav-dropdown border-0 collapsed active"
                                : "btn btn-toggle d-inline-flex align-items-center rounded left-nav-dropdown border-0 collapsed"
                            }
                            onClick={() => {
                              navigate("/grade5/view-lessonprocedure#0");
                              setIsChildActive(0);
                            }}
                            data-bs-toggle="collapse"
                            data-bs-target="#lesson3-collapse"
                            aria-expanded="false"
                          >
                            {LessonMenu.map((x) =>
                              x.Parts?.map((y) => y.LessonName)
                            )}
                          </button>
                          <div
                            key={"modal" + val.id}
                            className="collapse"
                            id="lesson3-collapse"
                          >
                            <ul className="ml-15 btn-toggle-nav list-unstyled fw-normal">
                              {LessonMenu.map((x) => {
                                return x.Parts?.map((y) => {
                                  return (
                                    <li
                                      key={"parts" + y.id}
                                      className="parts-list"
                                    >
                                      <button
                                        className={
                                          ischildactive === y.id
                                            ? "btn btn-toggle d-inline-flex align-items-center rounded left-nav-dropdown border-0 collapsed active"
                                            : "btn btn-toggle d-inline-flex align-items-center rounded left-nav-dropdown border-0 collapsed"
                                        }
                                        onClick={() =>
                                          navigate(
                                            "/grade5/view-lessonprocedure#" +
                                              y.Topics[0].PageIndex
                                          )
                                        }
                                        data-bs-toggle="collapse"
                                        data-bs-target={
                                          "#lesson3" + y.id + "-collapse"
                                        }
                                        aria-expanded={
                                          ischildactive === y.id
                                            ? "true"
                                            : "false"
                                        }
                                      >
                                        {y.PartName}
                                      </button>
                                      <div
                                        key={"partsName" + y.id}
                                        className={
                                          ischildactive === y.id
                                            ? "collapse show"
                                            : "collapse"
                                        }
                                        id={"lesson3" + y.id + "-collapse"}
                                      >
                                        <ul className="ml-15 btn-toggle-nav list-unstyled fw-normal parts">
                                          {y.Topics?.map((val) => {
                                            return (
                                              <>
                                                <li key={"Topics" + val.id}>
                                                  <Link
                                                    to={
                                                      "/grade5/view-lessonprocedure#" +
                                                      val.PageIndex
                                                    }
                                                    onClick={() =>
                                                      setISTopicActive(
                                                        props.updatelesson
                                                      )
                                                    }
                                                    className={
                                                      val.PageIndex <=
                                                      props.updatelesson
                                                        ? getNearestValue(
                                                            props.updatelesson,
                                                            y.Topics
                                                          ) === val.PageIndex
                                                          ? "active fw-700"
                                                          : ""
                                                        : ""
                                                    }
                                                  >
                                                    {val.TopicName}
                                                  </Link>
                                                </li>
                                              </>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </li>
                                  );
                                });
                              })}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </>
              ) : (
                <li className="nav-item" key={"smallSree" + val.id}>
                  {window.screen.width > 900 ? (
                    <Link
                      to={val.url}
                      key={"smallSreen" + val.id}
                      className={
                        isactive === val.url ? "nav-link active" : "nav-link"
                      }
                    >
                      {val.icon}
                      {val.NavName}
                    </Link>
                  ) : (
                    <a
                      className={
                        isactive === val.url ? "nav-link active" : "nav-link"
                      }
                      key={"bigScrren" + val.id}
                      onClick={() => {
                        navigate(val.url);
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target="#sidebarMenu"
                    >
                      {val.icon}
                      {val.NavName}
                    </a>
                  )}
                </li>
              )
            )}
          </ul>
          <div className="collapse-icon-box">
            <span
              className={
                props.menuclose === false
                  ? "collapse-icon"
                  : "collapse-icon rotate"
              }
              onClick={() => MenuClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="24"
                viewBox="0 0 13 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.8111 11.715L12.477 20.0969C13.1743 20.8594 13.1743 22.0956 12.477 22.8581C11.7796 23.6206 10.649 23.6206 9.95159 22.8581L1.02302 13.0956C0.325659 12.3331 0.325659 11.0969 1.02302 10.3344L9.95159 0.571874C10.649 -0.190625 11.7796 -0.190625 12.477 0.571874C13.1743 1.33437 13.1743 2.57063 12.477 3.33313L4.8111 11.715Z"
                  fill="#F9F9F9"
                />
              </svg>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}

export default LeftNav;
