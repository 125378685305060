import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P3_Make_Personal() {
  return (
    <IdGeneratingComponent guideLineCount={"P3"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">4.</span>
        <span className="number-text">
          <span className="fw-700">Make Personal Connections:</span> Encourage
          students to make a connection to the video.
        </span>
      </div>
      <p className="pl-20 mb-7">
        <span className="number-text">Ask:</span>
      </p>
      <ul className="pl-45 circle-listing">
        <li>What does the glass on the end of the pole remind you of?</li>
        <li>Where have you seen something like this before?</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P3_Make_Personal;
