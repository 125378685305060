import T0_Title from "./Lessons/Lesson-3/T0_Title";
import P0_Title from "./TeacherProcedure/Lesson-p3/P0_Title";
import G0_Title from "./TeacherGuideLines/Lesson-g3/G0_Title";
import T1_Prior_Knowledge from "./Lessons/Lesson-3/T1_Prior_Knowledge";
import P1_Prior_Knowledge from "./TeacherProcedure/Lesson-p3/P1_Prior_Knowledge";
import G1_Prior_Knowledge from "./TeacherGuideLines/Lesson-g3/G1_Prior_Knowledge";
import T2_Make_Observations_S1 from "./Lessons/Lesson-3/T2_Make_Observations_S1";
import T2_Make_Observations_S2 from "./Lessons/Lesson-3/T2_Make_Observations_S2";
import P2_Make_Observations_S1 from "./TeacherProcedure/Lesson-p3/P2_Make_Observations_S1";
import P2_Make_Observations_S2 from "./TeacherProcedure/Lesson-p3/P2_Make_Observations_S2";
import G2_Make_Observations_S1 from "./TeacherGuideLines/Lesson-g3/G2_Make_Observations_S1";
import G2_Make_Observations_S2 from "./TeacherGuideLines/Lesson-g3/G2_Make_Observations_S2";
import T3_Make_Personal from "./Lessons/Lesson-3/T3_Make_Personal";
import P3_Make_Personal from "./TeacherProcedure/Lesson-p3/P3_Make_Personal";
import G3_Make_Personal from "./TeacherGuideLines/Lesson-g3/G3_Make_Personal";
import T4_Develop_Initial_S1 from "./Lessons/Lesson-3/T4_Develop_Initial_S1";
import T4_Develop_Initial_S2 from "./Lessons/Lesson-3/T4_Develop_Initial_S2";
import T4_Develop_Initial_S3 from "./Lessons/Lesson-3/T4_Develop_Initial_S3";
import T4_Develop_Initial_S4 from "./Lessons/Lesson-3/T4_Develop_Initial_S4";
import T4_Develop_Initial_S5 from "./Lessons/Lesson-3/T4_Develop_Initial_S5";
import P4_Develop_Initial_S1 from "./TeacherProcedure/Lesson-p3/P4_Develop_Initial_S1";
import P4_Develop_Initial_S2 from "./TeacherProcedure/Lesson-p3/P4_Develop_Initial_S2";
import P4_Develop_Initial_S3 from "./TeacherProcedure/Lesson-p3/P4_Develop_Initial_S3";
import P4_Develop_Initial_S4 from "./TeacherProcedure/Lesson-p3/P4_Develop_Initial_S4";
import P4_Develop_Initial_S5 from "./TeacherProcedure/Lesson-p3/P4_Develop_Initial_S5";
import G4_Develop_Initial_S1 from "./TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S1";
import G4_Develop_Initial_S2 from "./TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S2";
import G4_Develop_Initial_S3 from "./TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S3";
import G4_Develop_Initial_S4 from "./TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S4";
import G4_Develop_Initial_S5 from "./TeacherGuideLines/Lesson-g3/G4_Develop_Initial_S5";
import T5_Ask_Questions_S1 from "./Lessons/Lesson-3/T5_Ask_Questions_S1";
import T5_Ask_Questions_S2 from "./Lessons/Lesson-3/T5_Ask_Questions_S2";
import P5_Ask_Questions_S1 from "./TeacherProcedure/Lesson-p3/P5_Ask_Questions_S1";
import P5_Ask_Questions_S2 from "./TeacherProcedure/Lesson-p3/P5_Ask_Questions_S2";
import G5_Ask_Questions_S1 from "./TeacherGuideLines/Lesson-g3/G5_Ask_Questions_S1";
import G5_Ask_Questions_S2 from "./TeacherGuideLines/Lesson-g3/G5_Ask_Questions_S2";
import T6_Navigate from "./Lessons/Lesson-3/T6_Navigate";
import P6_Navigate from "./TeacherProcedure/Lesson-p3/P6_Navigate";
import G6_Navigate from "./TeacherGuideLines/Lesson-g3/G6_Navigate";
import T7_Prior_Knowledge from "./Lessons/Lesson-3/T7_Prior_Knowledge";
import P7_Prior_Knowledge from "./TeacherProcedure/Lesson-p3/P7_Prior_Knowledge";
import G7_Prior_Knowledge from "./TeacherGuideLines/Lesson-g3/G7_Prior_Knowledge";
import T8_Make_Observations_S1 from "./Lessons/Lesson-3/T8_Make_Observations_S1";
import T8_Make_Observations_S2 from "./Lessons/Lesson-3/T8_Make_Observations_S2";
import P8_Make_Observations_S1 from "./TeacherProcedure/Lesson-p3/P8_Make_Observations_S1";
import P8_Make_Observations_S2 from "./TeacherProcedure/Lesson-p3/P8_Make_Observations_S2";
import G8_Make_Observations_S1 from "./TeacherGuideLines/Lesson-g3/G8_Make_Observations_S1";
import G8_Make_Observations_S2 from "./TeacherGuideLines/Lesson-g3/G8_Make_Observations_S2";
import T9_Engage_Sensemaking_S1 from "./Lessons/Lesson-3/T9_Engage_Sensemaking_S1";
import T9_Engage_Sensemaking_S2 from "./Lessons/Lesson-3/T9_Engage_Sensemaking_S2";
import P9_Engage_Sensemaking_S1 from "./TeacherProcedure/Lesson-p3/P9_Engage_Sensemaking_S1";
import P9_Engage_Sensemaking_S2 from "./TeacherProcedure/Lesson-p3/P9_Engage_Sensemaking_S2";
import G9_Engage_Sensemaking_S1 from "./TeacherGuideLines/Lesson-g3/G9_Engage_Sensemaking_S1";
import G9_Engage_Sensemaking_S2 from "./TeacherGuideLines/Lesson-g3/G9_Engage_Sensemaking_S2";
import T10_Plan_Investigation_S1 from "./Lessons/Lesson-3/T10_Plan_Investigation_S1";
import T10_Plan_Investigation_S2 from "./Lessons/Lesson-3/T10_Plan_Investigation_S2";
import T10_Plan_Investigation_S3 from "./Lessons/Lesson-3/T10_Plan_Investigation_S3";
import P10_Plan_Investigation_S1 from "./TeacherProcedure/Lesson-p3/P10_Plan_Investigation_S1";
import P10_Plan_Investigation_S2 from "./TeacherProcedure/Lesson-p3/P10_Plan_Investigation_S2";
import P10_Plan_Investigation_S3 from "./TeacherProcedure/Lesson-p3/P10_Plan_Investigation_S3";
import G10_Plan_Investigation_S1 from "./TeacherGuideLines/Lesson-g3/G10_Plan_Investigation_S1";
import G10_Plan_Investigation_S2 from "./TeacherGuideLines/Lesson-g3/G10_Plan_Investigation_S2";
import G10_Plan_Investigation_S3 from "./TeacherGuideLines/Lesson-g3/G10_Plan_Investigation_S3";
import T11_Checkpoint from "./Lessons/Lesson-3/T11_Checkpoint";
import P11_Checkpoint from "./TeacherProcedure/Lesson-p3/P11_Checkpoint";
import G11_Checkpoint from "./TeacherGuideLines/Lesson-g3/G11_Checkpoint";
import T12_Navigate from "./Lessons/Lesson-3/T12_Navigate";
import P12_Navigate from "./TeacherProcedure/Lesson-p3/P12_Navigate";
import G12_Navigate from "./TeacherGuideLines/Lesson-g3/G12_Navigate";
import T13_Prior_Knowledge from "./Lessons/Lesson-3/T13_Prior_Knowledge";
import P13_Prior_Knowledge from "./TeacherProcedure/Lesson-p3/P13_Prior_Knowledge";
import G13_Prior_Knowledge from "./TeacherGuideLines/Lesson-g3/G13_Prior_Knowledge";
import T14_Make_Observations_S1 from "./Lessons/Lesson-3/T14_Make_Observations_S1";
import P14_Make_Observations_S1 from "./TeacherProcedure/Lesson-p3/P14_Make_Observations_S1";
import G14_Make_Observations_S1 from "./TeacherGuideLines/Lesson-g3/G14_Make_Observations_S1";
import T15_Make_Predictions from "./Lessons/Lesson-3/T15_Make_Predictions";
import P15_Make_Predictions from "./TeacherProcedure/Lesson-p3/P15_Make_Predictions";
import G15_Make_Predictions from "./TeacherGuideLines/Lesson-g3/G15_Make_Predictions";
import T16_Make_Observations from "./Lessons/Lesson-3/T16_Make_Observations";
import P16_Make_Observations from "./TeacherProcedure/Lesson-p3/P16_Make_Observations";
import G16_Make_Observations from "./TeacherGuideLines/Lesson-g3/G16_Make_Observations";
import T17_Engage_Sensemaking from "./Lessons/Lesson-3/T17_Engage_Sensemaking";
import P17_Engage_Sensemaking from "./TeacherProcedure/Lesson-p3/P17_Engage_Sensemaking";
import G17_Engage_Sensemaking from "./TeacherGuideLines/Lesson-g3/G17_Engage_Sensemaking";
import T18_Make_Personal from "./Lessons/Lesson-3/T18_Make_Personal";
import P18_Make_Personal from "./TeacherProcedure/Lesson-p3/P18_Make_Personal";
import G18_Make_Personal from "./TeacherGuideLines/Lesson-g3/G18_Make_Personal";
import T19_Navigate from "./Lessons/Lesson-3/T19_Navigate";
import P19_Navigate from "./TeacherProcedure/Lesson-p3/P19_Navigate";
import G19_Navigate from "./TeacherGuideLines/Lesson-g3/G19_Navigate";
import T20_Prior_Knowledge_S1 from "./Lessons/Lesson-3/T20_Prior_Knowledge_S1";
import T20_Prior_Knowledge_S2 from "./Lessons/Lesson-3/T20_Prior_Knowledge_S2";
import T20_Prior_Knowledge_S3 from "./Lessons/Lesson-3/T20_Prior_Knowledge_S3";
import T20_Prior_Knowledge_S4 from "./Lessons/Lesson-3/T20_Prior_Knowledge_S4";
import P20_Prior_Knowledge_S1 from "./TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S1";
import P20_Prior_Knowledge_S2 from "./TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S2";
import P20_Prior_Knowledge_S3 from "./TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S3";
import P20_Prior_Knowledge_S4 from "./TeacherProcedure/Lesson-p3/P20_Prior_Knowledge_S4";
import G20_Prior_Knowledge_S1 from "./TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S1";
import G20_Prior_Knowledge_S2 from "./TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S2";
import G20_Prior_Knowledge_S3 from "./TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S3";
import G20_Prior_Knowledge_S4 from "./TeacherGuideLines/Lesson-g3/G20_Prior_Knowledge_S4";
import T21_Make_Observations from "./Lessons/Lesson-3/T21_Make_Observations";
import P21_Make_Observations from "./TeacherProcedure/Lesson-p3/P21_Make_Observations";
import G21_Make_Observations from "./TeacherGuideLines/Lesson-g3/G21_Make_Observations";
import T22_Make_Comparisons from "./Lessons/Lesson-3/T22_Make_Comparisons";
import P22_Make_Comparisons from "./TeacherProcedure/Lesson-p3/P22_Make_Comparisons";
import G22_Make_Comparisons from "./TeacherGuideLines/Lesson-g3/G22_Make_Comparisons";
import T23_Analyze_Data from "./Lessons/Lesson-3/T23_Analyze_Data";
import P23_Analyze_Data from "./TeacherProcedure/Lesson-p3/P23_Analyze_Data";
import G23_Analyze_Data from "./TeacherGuideLines/Lesson-g3/G23_Analyze_Data";
import T24_Engage_Sensemaking from "./Lessons/Lesson-3/T24_Engage_Sensemaking";
import P24_Engage_Sensemaking from "./TeacherProcedure/Lesson-p3/P24_Engage_Sensemaking";
import G24_Engage_Sensemaking from "./TeacherGuideLines/Lesson-g3/G24_Engage_Sensemaking";
import T25_Checkpoint from "./Lessons/Lesson-3/T25_Checkpoint";
import P25_Checkpoint from "./TeacherProcedure/Lesson-p3/P25_Checkpoint";
import G25_Checkpoint from "./TeacherGuideLines/Lesson-g3/G25_Checkpoint";
import T26_Navigate from "./Lessons/Lesson-3/T26_Navigate";
import P26_Navigate from "./TeacherProcedure/Lesson-p3/P26_Navigate";
import G26_Navigate from "./TeacherGuideLines/Lesson-g3/G26_Navigate";
import T27_Prior_Knowledge_S1 from "./Lessons/Lesson-3/T27_Prior_Knowledge_S1";
import T27_Prior_Knowledge_S2 from "./Lessons/Lesson-3/T27_Prior_Knowledge_S2";
import P27_Prior_Knowledge_S1 from "./TeacherProcedure/Lesson-p3/P27_Prior_Knowledge_S1";
import P27_Prior_Knowledge_S2 from "./TeacherProcedure/Lesson-p3/P27_Prior_Knowledge_S2";
import G27_Prior_Knowledge_S1 from "./TeacherGuideLines/Lesson-g3/G27_Prior_Knowledge_S1";
import G27_Prior_Knowledge_S2 from "./TeacherGuideLines/Lesson-g3/G27_Prior_Knowledge_S2";
import T28_Connect_Ideas from "./Lessons/Lesson-3/T28_Connect_Ideas";
import P28_Connect_Ideas from "./TeacherProcedure/Lesson-p3/P28_Connect_Ideas";
import G28_Connect_Ideas from "./TeacherGuideLines/Lesson-g3/G28_Connect_Ideas";
import T29_Engage_Sensemaking from "./Lessons/Lesson-3/T29_Engage_Sensemaking";
import P29_Engage_Sensemaking from "./TeacherProcedure/Lesson-p3/P29_Engage_Sensemaking";
import G29_Engage_Sensemaking from "./TeacherGuideLines/Lesson-g3/G29_Engage_Sensemaking";
import T30_Earn_Words_S1 from "./Lessons/Lesson-3/T30_Earn_Words_S1";
import T30_Earn_Words_S2 from "./Lessons/Lesson-3/T30_Earn_Words_S2";
import T30_Earn_Words_S3 from "./Lessons/Lesson-3/T30_Earn_Words_S3";
import P30_Earn_Words_S1 from "./TeacherProcedure/Lesson-p3/P30_Earn_Words_S1";
import P30_Earn_Words_S2 from "./TeacherProcedure/Lesson-p3/P30_Earn_Words_S2";
import P30_Earn_Words_S3 from "./TeacherProcedure/Lesson-p3/P30_Earn_Words_S3";
import G30_Earn_Words_S1 from "./TeacherGuideLines/Lesson-g3/G30_Earn_Words_S1";
import G30_Earn_Words_S2 from "./TeacherGuideLines/Lesson-g3/G30_Earn_Words_S2";
import G30_Earn_Words_S3 from "./TeacherGuideLines/Lesson-g3/G30_Earn_Words_S3";
import T31_Engage_Argument_S1 from "./Lessons/Lesson-3/T31_Engage_Argument_S1";
import T31_Engage_Argument_S2 from "./Lessons/Lesson-3/T31_Engage_Argument_S2";
import P31_Engage_Argument_S1 from "./TeacherProcedure/Lesson-p3/P31_Engage_Argument_S1";
import P31_Engage_Argument_S2 from "./TeacherProcedure/Lesson-p3/P31_Engage_Argument_S2";
import G31_Engage_Argument_S1 from "./TeacherGuideLines/Lesson-g3/G31_Engage_Argument_S1";
import G31_Engage_Argument_S2 from "./TeacherGuideLines/Lesson-g3/G31_Engage_Argument_S2";
import T32_Checkpoint from "./Lessons/Lesson-3/T32_Checkpoint";
import P32_Checkpoint from "./TeacherProcedure/Lesson-p3/P32_Checkpoint";
import G32_Checkpoint from "./TeacherGuideLines/Lesson-g3/G32_Checkpoint";
import T33_Navigate from "./Lessons/Lesson-3/T33_Navigate";
import P33_Navigate from "./TeacherProcedure/Lesson-p3/P33_Navigate";
import G33_Navigate from "./TeacherGuideLines/Lesson-g3/G33_Navigate";
import T34_Prior_Knowledge_S1 from "./Lessons/Lesson-3/T34_Prior_Knowledge_S1";
import T34_Prior_Knowledge_S2 from "./Lessons/Lesson-3/T34_Prior_Knowledge_S2";
import P34_Prior_Knowledge_S1 from "./TeacherProcedure/Lesson-p3/P34_Prior_Knowledge_S1";
import P34_Prior_Knowledge_S2 from "./TeacherProcedure/Lesson-p3/P34_Prior_Knowledge_S2";
import G34_Prior_Knowledge_S1 from "./TeacherGuideLines/Lesson-g3/G34_Prior_Knowledge_S1";
import G34_Prior_Knowledge_S2 from "./TeacherGuideLines/Lesson-g3/G34_Prior_Knowledge_S2";
import T35_Anchoring_Phenomenon from "./Lessons/Lesson-3/T35_Anchoring_Phenomenon";
import P35_Anchoring_Phenomenon from "./TeacherProcedure/Lesson-p3/P35_Anchoring_Phenomenon";
import G35_Anchoring_Phenomenon from "./TeacherGuideLines/Lesson-g3/G35_Anchoring_Phenomenon";
import T36_Initial_Ideas_S1 from "./Lessons/Lesson-3/T36_Initial_Ideas_S1";
import T36_Initial_Ideas_S2 from "./Lessons/Lesson-3/T36_Initial_Ideas_S2";
import P36_Initial_Ideas_S1 from "./TeacherProcedure/Lesson-p3/P36_Initial_Ideas_S1";
import P36_Initial_Ideas_S2 from "./TeacherProcedure/Lesson-p3/P36_Initial_Ideas_S2";
import G36_Initial_Ideas_S1 from "./TeacherGuideLines/Lesson-g3/G36_Initial_Ideas_S1";
import G36_Initial_Ideas_S2 from "./TeacherGuideLines/Lesson-g3/G36_Initial_Ideas_S2";
import T37_Engage_Sensemaking from "./Lessons/Lesson-3/T37_Engage_Sensemaking";
import P37_Engage_Sensemaking from "./TeacherProcedure/Lesson-p3/P37_Engage_Sensemaking";
import G37_Engage_Sensemaking from "./TeacherGuideLines/Lesson-g3/G37_Engage_Sensemaking";
import T38_Navigate from "./Lessons/Lesson-3/T38_Navigate";
import P38_Navigate from "./TeacherProcedure/Lesson-p3/P38_Navigate";
import G38_Navigate from "./TeacherGuideLines/Lesson-g3/G38_Navigate";

const LessonRender = [
  {
    ModuleName: "How does temperature affect other types of matter?",
    LessonName: "Lesson 3",
    Topics: [
      {
        id: 0,
        TopicName: "How does temperature affect other types of matter?",
        Topic: <T0_Title />,
        PTopic: <P0_Title />,
        GTopic: <G0_Title />,
        url: "/view-lessonprocedure#0",
      },
      {
        id: 1,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T1_Prior_Knowledge />,
        PTopic: <P1_Prior_Knowledge />,
        GTopic: <G1_Prior_Knowledge />,
        url: "/view-lessonprocedure#1",
      },
      {
        id: 2,
        TopicName: "Make Observations",
        Topic: <T2_Make_Observations_S1 />,
        PTopic: <P2_Make_Observations_S1 />,
        GTopic: <G2_Make_Observations_S1 />,
        url: "/view-lessonprocedure#2",
      },
      {
        id: 3,
        TopicName: "Make Observations",
        Topic: <T2_Make_Observations_S2 />,
        PTopic: <P2_Make_Observations_S2 />,
        GTopic: <G2_Make_Observations_S2 />,
        url: "/view/LessonProcedure/Lesson2",
      },
      {
        id: 4,
        TopicName: "Make Personal Connections",
        Topic: <T3_Make_Personal />,
        PTopic: <P3_Make_Personal />,
        GTopic: <G3_Make_Personal />,
        url: "/view/LessonProcedure/Lesson3",
      },
      {
        id: 5,
        TopicName: "Develop Initial Ideas",
        Topic: <T4_Develop_Initial_S1 />,
        PTopic: <P4_Develop_Initial_S1 />,
        GTopic: <G4_Develop_Initial_S1 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 6,
        TopicName: "Develop Initial Ideas",
        Topic: <T4_Develop_Initial_S2 />,
        PTopic: <P4_Develop_Initial_S2 />,
        GTopic: <G4_Develop_Initial_S2 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 7,
        TopicName: "Develop Initial Ideas",
        Topic: <T4_Develop_Initial_S3 />,
        PTopic: <P4_Develop_Initial_S3 />,
        GTopic: <G4_Develop_Initial_S3 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 8,
        TopicName: "Develop Initial Ideas",
        Topic: <T4_Develop_Initial_S4 />,
        PTopic: <P4_Develop_Initial_S4 />,
        GTopic: <G4_Develop_Initial_S4 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 9,
        TopicName: "Develop Initial Ideas",
        Topic: <T4_Develop_Initial_S5 />,
        PTopic: <P4_Develop_Initial_S5 />,
        GTopic: <G4_Develop_Initial_S5 />,
        url: "/view/LessonProcedure/Lesson4",
      },
      {
        id: 10,
        TopicName: "Ask Questions",
        Topic: <T5_Ask_Questions_S1 />,
        PTopic: <P5_Ask_Questions_S1 />,
        GTopic: <G5_Ask_Questions_S1 />,
        url: "/view/LessonProcedure/Lesson5",
      },
      {
        id: 11,
        TopicName: "Ask Questions",
        Topic: <T5_Ask_Questions_S2 />,
        PTopic: <P5_Ask_Questions_S2 />,
        GTopic: <G5_Ask_Questions_S2 />,
        url: "/view/LessonProcedure/Lesson5",
      },
      {
        id: 12,
        TopicName: "Navigate to the Next Session",
        Topic: <T6_Navigate />,
        PTopic: <P6_Navigate />,
        GTopic: <G6_Navigate />,
        url: "/view/LessonProcedure/Lesson6",
      },
      {
        id: 13,
        TopicName: "Connect to Prior Knowledge ",
        Topic: <T7_Prior_Knowledge />,
        PTopic: <P7_Prior_Knowledge />,
        GTopic: <G7_Prior_Knowledge />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 14,
        TopicName: "Make Observations",
        Topic: <T8_Make_Observations_S1 />,
        PTopic: <P8_Make_Observations_S1 />,
        GTopic: <G8_Make_Observations_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 15,
        TopicName: "Make Observations",
        Topic: <T8_Make_Observations_S2 />,
        PTopic: <P8_Make_Observations_S2 />,
        GTopic: <G8_Make_Observations_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 16,
        TopicName: "Engage in Sensemaking",
        Topic: <T9_Engage_Sensemaking_S1 />,
        PTopic: <P9_Engage_Sensemaking_S1 />,
        GTopic: <G9_Engage_Sensemaking_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 17,
        TopicName: "Engage in Sensemaking",
        Topic: <T9_Engage_Sensemaking_S2 />,
        PTopic: <P9_Engage_Sensemaking_S2 />,
        GTopic: <G9_Engage_Sensemaking_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 18,
        TopicName: "Plan an Investigation",
        Topic: <T10_Plan_Investigation_S1 />,
        PTopic: <P10_Plan_Investigation_S1 />,
        GTopic: <G10_Plan_Investigation_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 19,
        TopicName: "Plan an Investigation",
        Topic: <T10_Plan_Investigation_S2 />,
        PTopic: <P10_Plan_Investigation_S2 />,
        GTopic: <G10_Plan_Investigation_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 20,
        TopicName: "Plan an Investigation",
        Topic: <T10_Plan_Investigation_S3 />,
        PTopic: <P10_Plan_Investigation_S3 />,
        GTopic: <G10_Plan_Investigation_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 21,
        TopicName: "Checkpoint",
        Topic: <T11_Checkpoint />,
        PTopic: <P11_Checkpoint />,
        GTopic: <G11_Checkpoint />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 22,
        TopicName: "Navigate to the Next Session",
        Topic: <T12_Navigate />,
        PTopic: <P12_Navigate />,
        GTopic: <G12_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 23,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T13_Prior_Knowledge />,
        PTopic: <P13_Prior_Knowledge />,
        GTopic: <G13_Prior_Knowledge />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 24,
        TopicName: "Make Observations",
        Topic: <T14_Make_Observations_S1 />,
        PTopic: <P14_Make_Observations_S1 />,
        GTopic: <G14_Make_Observations_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 25,
        TopicName: "Make Observations",
        Topic: <T14_Make_Observations_S1 />,
        PTopic: <P14_Make_Observations_S1 />,
        GTopic: <G14_Make_Observations_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 26,
        TopicName: "Make Predictions",
        Topic: <T15_Make_Predictions />,
        PTopic: <P15_Make_Predictions />,
        GTopic: <G15_Make_Predictions />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 27,
        TopicName: "Make Observations",
        Topic: <T16_Make_Observations />,
        PTopic: <P16_Make_Observations />,
        GTopic: <G16_Make_Observations />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 28,
        TopicName: "Engage in Sensemaking",
        Topic: <T17_Engage_Sensemaking />,
        PTopic: <P17_Engage_Sensemaking />,
        GTopic: <G17_Engage_Sensemaking />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 29,
        TopicName: "Make Personal Connections",
        Topic: <T18_Make_Personal />,
        PTopic: <P18_Make_Personal />,
        GTopic: <G18_Make_Personal />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 30,
        TopicName: "Navigate to the Next Session",
        Topic: <T19_Navigate />,
        PTopic: <P19_Navigate />,
        GTopic: <G19_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 31,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T20_Prior_Knowledge_S1 />,
        PTopic: <P20_Prior_Knowledge_S1 />,
        GTopic: <G20_Prior_Knowledge_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 32,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T20_Prior_Knowledge_S2 />,
        PTopic: <P20_Prior_Knowledge_S2 />,
        GTopic: <G20_Prior_Knowledge_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 33,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T20_Prior_Knowledge_S3 />,
        PTopic: <P20_Prior_Knowledge_S3 />,
        GTopic: <G20_Prior_Knowledge_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 34,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T20_Prior_Knowledge_S4 />,
        PTopic: <P20_Prior_Knowledge_S4 />,
        GTopic: <G20_Prior_Knowledge_S4 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 35,
        TopicName: "Make Observations",
        Topic: <T21_Make_Observations />,
        PTopic: <P21_Make_Observations />,
        GTopic: <G21_Make_Observations />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 36,
        TopicName: "Make Comparisons",
        Topic: <T22_Make_Comparisons />,
        PTopic: <P22_Make_Comparisons />,
        GTopic: <G22_Make_Comparisons />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 37,
        TopicName: "Analyze Data",
        Topic: <T23_Analyze_Data />,
        PTopic: <P23_Analyze_Data />,
        GTopic: <G23_Analyze_Data />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 38,
        TopicName: "Engage in Sensemaking",
        Topic: <T24_Engage_Sensemaking />,
        PTopic: <P24_Engage_Sensemaking />,
        GTopic: <G24_Engage_Sensemaking />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 39,
        TopicName: "Checkpoint",
        Topic: <T25_Checkpoint />,
        PTopic: <P25_Checkpoint />,
        GTopic: <G25_Checkpoint />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 40,
        TopicName: "Navigate to the Next Session",
        Topic: <T26_Navigate />,
        PTopic: <P26_Navigate />,
        GTopic: <G26_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 41,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T27_Prior_Knowledge_S1 />,
        PTopic: <P27_Prior_Knowledge_S1 />,
        GTopic: <G27_Prior_Knowledge_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 42,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T27_Prior_Knowledge_S2 />,
        PTopic: <P27_Prior_Knowledge_S2 />,
        GTopic: <G27_Prior_Knowledge_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 43,
        TopicName: "Connect Ideas",
        Topic: <T28_Connect_Ideas />,
        PTopic: <P28_Connect_Ideas />,
        GTopic: <G28_Connect_Ideas />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 44,
        TopicName: "Engage in Sensemaking",
        Topic: <T29_Engage_Sensemaking />,
        PTopic: <P29_Engage_Sensemaking />,
        GTopic: <G29_Engage_Sensemaking />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 45,
        TopicName: "Earn Words",
        Topic: <T30_Earn_Words_S1 />,
        PTopic: <P30_Earn_Words_S1 />,
        GTopic: <G30_Earn_Words_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 46,
        TopicName: "Earn Words",
        Topic: <T30_Earn_Words_S2 />,
        PTopic: <P30_Earn_Words_S2 />,
        GTopic: <G30_Earn_Words_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 47,
        TopicName: "Earn Words",
        Topic: <T30_Earn_Words_S3 />,
        PTopic: <P30_Earn_Words_S3 />,
        GTopic: <G30_Earn_Words_S3 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 48,
        TopicName: "Engage in Argument",
        Topic: <T31_Engage_Argument_S1 />,
        PTopic: <P31_Engage_Argument_S1 />,
        GTopic: <G31_Engage_Argument_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 49,
        TopicName: "Engage in Argument",
        Topic: <T31_Engage_Argument_S2 />,
        PTopic: <P31_Engage_Argument_S2 />,
        GTopic: <G31_Engage_Argument_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 50,
        TopicName: "Checkpoint",
        Topic: <T32_Checkpoint />,
        PTopic: <P32_Checkpoint />,
        GTopic: <G32_Checkpoint />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 51,
        TopicName: "Navigate to the Next Session",
        Topic: <T33_Navigate />,
        PTopic: <P33_Navigate />,
        GTopic: <G33_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 52,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T34_Prior_Knowledge_S1 />,
        PTopic: <P34_Prior_Knowledge_S1 />,
        GTopic: <G34_Prior_Knowledge_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 53,
        TopicName: "Connect to Prior Knowledge",
        Topic: <T34_Prior_Knowledge_S2 />,
        PTopic: <P34_Prior_Knowledge_S2 />,
        GTopic: <G34_Prior_Knowledge_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 54,
        TopicName: "Connect to the Anchoring Phenomenon",
        Topic: <T35_Anchoring_Phenomenon />,
        PTopic: <P35_Anchoring_Phenomenon />,
        GTopic: <G35_Anchoring_Phenomenon />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 55,
        TopicName: "Reflect on Initial Ideas",
        Topic: <T36_Initial_Ideas_S1 />,
        PTopic: <P36_Initial_Ideas_S1 />,
        GTopic: <G36_Initial_Ideas_S1 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 56,
        TopicName: "Reflect on Initial Ideas",
        Topic: <T36_Initial_Ideas_S2 />,
        PTopic: <P36_Initial_Ideas_S2 />,
        GTopic: <G36_Initial_Ideas_S2 />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 57,
        TopicName: "Engage in Sensemaking",
        Topic: <T37_Engage_Sensemaking />,
        PTopic: <P37_Engage_Sensemaking />,
        GTopic: <G37_Engage_Sensemaking />,
        url: "/view/LessonProcedure/Lesson7",
      },
      {
        id: 58,
        TopicName: "Navigate to the Next Lesson",
        Topic: <T38_Navigate />,
        PTopic: <P38_Navigate />,
        GTopic: <G38_Navigate />,
        url: "/view/LessonProcedure/Lesson7",
      },
    ],
  },
];

export default LessonRender;
