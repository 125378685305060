import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G4_Investigate_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S3"}>
        <section className="text-wrapping-right pb-7">
          <div className="icon">
            <img
              src="/image/lesson-images/crosscutting-concepts.svg"
              alt="crosscutting-concepts"
              title="crosscutting-concepts"
            ></img>
          </div>
          <span className="fw-700">NGSS Element:</span>{" "}
          <span className="text-nexus-green fw-700">Patterns:</span> As students
          discuss their ideas, they use the patterns they identify to help them
          explain which objects move in the solar system. Ideas may include:
        </section>
        <ul className="pl-30 circle-listing">
          <li>The Sun is always in the middle, so it probably doesn’t move.</li>
          <li>
            The round objects (planets) appear in different places in the
            models. They probably move.
          </li>
          <li>
            The round objects are in different places around the Sun, so they
            probably move around it.
          </li>
          <li>
            Earth is always the third from the Sun, so it probably does not move
            back and forth.
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G4_Investigate_S3;
