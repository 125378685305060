import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P5_Gather_Evidence() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P5"}>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">11.</span>
          <span className="number-text">
            <span className="">
              <section className="align-items-center icon-text-group">
                <div className="">
                  <div className="text-wrapping-left">
                    <p>
                      <span className="fw-700">Gather Evidence:</span> Ask
                      students to read the{" "}
                      <span className="text-i">
                        Movement in the Solar System
                      </span>
                      <div className="pt-5 pr-5 icon">
                        <img
                          src="/image/lesson-images/notebook.svg"
                          alt="notebook"
                          title="notebook"
                        ></img>
                      </div>{" "}
                      {""}
                      infographic (pages 31–32). When they have finished
                      reading, they should complete the Gather Evidence and
                      Reflect sections of their science notebooks on their own
                      (pages 33–34).
                    </p>
                  </div>
                </div>
              </section>
            </span>
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P5_Gather_Evidence;
