import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.js";
import { Tooltip } from "@mui/material";
import BreadcumbComponent from "../../Common/BreadcrumbComponent_2";
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

function Module5() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // const [changematstate, setChangeMatState] = useState(0);
  // const [prematerial, setPreMaterial] = useState(0);
  // const [altmaterial, setAltMaterial] = useState(1);

  // const PreMaterial = (event) => {
  //   setChangeMatState(0);
  //   setPreMaterial(0);
  //   setAltMaterial(1);
  // };
  // const AltMaterial = (event) => {
  //   setChangeMatState(1);
  //   setPreMaterial(1);
  //   setAltMaterial(0);
  // };
  return (
    <>
      <div className="page-title">
        <h1>
          <BreadcumbComponent
            Parent={{
              title: "Earth and Space Science | Unit 1",
              path: "/grade5/glassblowing",
            }}
            Child={{
              title: "Materials",
            }}
          />
        </h1>
      </div>
      <div className="mb-20 d-flex justify-content-end tab">
        <div className="tab-box">
          <Tooltip title="Add to shopping cart">
            <button
              className="btn mr-5 active shopping"
              style={{ cursor: "auto" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm9.804-16.5l-3.431 12h-2.102l2.542-9h-5.993c.113.482.18.983.18 1.5 0 3.59-2.91 6.5-6.5 6.5-.407 0-.805-.042-1.191-.114l1.306 3.114h13.239l3.474-12h1.929l.743-2h-4.196zm-6.304 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-4.5-10.5c0 2.485-2.018 4.5-4.5 4.5-2.484 0-4.5-2.015-4.5-4.5s2.016-4.5 4.5-4.5c2.482 0 4.5 2.015 4.5 4.5zm-2-.5h-2v-2h-1v2h-2v1h2v2h1v-2h2v-1z" />
              </svg>
            </button>
          </Tooltip>
          {/* <button
            onClick={() => AltMaterial()}
            className={altmaterial === 0 ? "btn active" : "btn ml-5"}
          >
            Alternate Materials
          </button> */}
        </div>
      </div>
      {/* 
      {changematstate === 0 ? (
        <div className="lesson-module-list materials-list">
          <div className="row g-3 row-cols-1 row-cols-md-4 row-cols-lg-5">
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Prescribed Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="lesson-module-list materials-list">
          <div className="row g-3 row-cols-1 row-cols-md-4 row-cols-lg-5">
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 lesson-module material-module">
                <div className="card-body p-3 lesson-module-short-text material-module-short-text">
                  <h6 className="fw-700 lesson-module-title material-module-title mb-7">
                    Alternate Materials 1
                  </h6>
                  <p className="ordering-info">Ordering info</p>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="fw-700 p-2 lesson-module-link material-module-link"
                >
                  Read more ...
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Materials
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">...</div>
        
          </div>
        </div>
      </div> */}
      <div className="bordered-box">
        <Document
          file="/pdf/Nexus_Space_UnitMaterialsList_POC_6-28-23.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              height={
                document.getElementsByClassName("bordered-box")[0]
                  ?.clientWidth * 1.0 ?? 150
              }
            />
          ))}{" "}
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
    </>
  );
}

export default Module5;
