import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G24_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"G24"}>
      <section className="text-wrapping-right pb-7">
        <div className="icon">
          <img
            src="/image/lesson-images/disciplinary_core_idea.svg"
            alt="disciplinary_core_idea"
            title="disciplinary_core_idea"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.B:</span> The purpose of
        this discussion is for students to make sense of the following science
        ideas related to the effects of temperature on the observable properties
        of matter:
      </section>

      <ul className="pl-30 pb-10 circle-listing">
        <li>
          Heating and cooling a substance may cause changes that can be
          observed. Some changes can be undone while others cannot.
        </li>
        <li>
          Different kinds of materials exist and many of them can be either
          solid or liquid, depending on the temperature.
        </li>
      </ul>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/scaffolding.svg"
            alt="scaffolding"
            title="scaffolding"
          ></img>
        </div>
        <p className="text">
          <span className="fw-700">Scaffolding:</span> Use the simulation as a
          visual to help students understand that many changes caused by heating
          and cooling can be undone while others cannot.
        </p>
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          Choose plastic. Heat and cool the plastic over and over, watching it
          change between liquid and solid. Students should notice that no matter
          how many times they heat and cool the plastic, it never goes back to
          the shape of the plane. Be sure that they understand that the
          observable properties remain the same and the matter remains plastic
          regardless of its shape.
        </li>
        <li>
          Repeat the process for glass and metal to allow students to observe
          these same patterns.
        </li>
        <li>
          Next, repeat the process for egg and wood. Students will observe that
          the changes to the observable properties of these types of matter
          cannot be undone regardless of how many times they are heated and
          cooled.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G24_Engage_Sensemaking;
