import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P9_Engage_Sensemaking_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P9"} seriesCount={"S1"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">4.</span>
        <span className="number-text">
          <span className="fw-700">Engage in Sensemaking:</span> Bring the class
          together. Ask students to identify how the items were grouped
          together. For each group of materials, ask:
        </span>
      </div>
      <ul className="pl-45 circle-listing">
        <li>
          Why do you think the items at this station were grouped together?
        </li>
        <li>Why do you think this?</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P9_Engage_Sensemaking_S1;
