import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G29_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"G29"}>
      <section className="pb-20 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/disciplinary_core_idea.svg"
            alt="disciplinary_core_idea"
            title="disciplinary_core_idea"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.B:</span> The purpose of
        this discussion is to deepen students' understanding of changes that can
        and cannot be undone. Actions like melting, boiling, cooling,
        stretching, and folding are usually reversible changes because they can
        be undone without a complete change to the object. Irreversible changes
        cannot be undone. Examples of such changes include tearing or breaking
        an object into pieces, rusting, cooking, and bursting a balloon.
      </section>
      <section className="guidelines-blue-section">
        <div className="title">
          <section className="right-icon-text-group">
            <div className="text">
              <p className="fw-700">Teacher Tip</p>
            </div>
            <div className="icon">
              <img
                src="/image/lesson-images/Pencil.svg"
                alt="Pencil"
                title="Pencil"
              ></img>
            </div>
          </section>
        </div>
        <div className="p-2 text">
          <p>
            Some changes to items like glass can be a little confusing. When
            glass breaks, it is considered an irreversible change because those
            pieces cannot be put back together into their original form. On the
            other hand, heating glass is usually considered a reversible change
            because it can be heated and melted again and again and shaped into
            any object.
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G29_Engage_Sensemaking;
