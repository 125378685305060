import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P34_Prior_Knowledge_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P34"} seriesCount={"S1"}>
      <div className="d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">1.</span>
        <span className="number-text">
          <span className="fw-700">Connect to Prior Knowledge: </span>
          Encourage students to connect back to the prior session by briefly
          reviewing their argument about how temperature affects different types
          of matter with a partner.
        </span>
      </div>
    </IdGeneratingComponent>
  );
}

export default P34_Prior_Knowledge_S1;
