import React from "react";

function T28_Connect_Ideas() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-10 mt-5 font-16">
              Describe how the observable properties of each type of matter
              changed.
            </p>
            <p className="mb-10">
              Next, use the number line as you discuss the following questions
              with the class:
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  What happened to most types of matter when they were heated?
                </li>
                <li>
                  What happened to most types of matter when they were cooled?
                </li>
                <li>
                  How were the egg and wood different from the other types of
                  matter you tested?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T28_Connect_Ideas;
