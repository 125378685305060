import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G4_Investigate_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G4"} seriesCount={"S1"}>
        <section className="text-wrapping-right pb-10">
          <div className="icon">
            <img
              src="/image/lesson-images/science_and_engineerning_practices.svg"
              alt="science_and_engineerning_practices"
              title="science_and_engineerning_practices"
            ></img>
          </div>
          <span className="fw-700">NGSS Element:</span>{" "}
          <span className="fw-700 text-nexus-blue">
            Developing and Using Models:
          </span>{" "}
          Students engage in this practice as they use models of the solar
          system to identify patterns.
        </section>
        <section className="pb-10 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/crosscutting-concepts.svg"
              alt="crosscutting-concepts"
              title="crosscutting-concepts"
            ></img>
          </div>
          <span className="fw-700">NGSS Element:</span>{" "}
          <span className="fw-700 text-nexus-green">Patterns:</span> Students
          use this concept to make sense of the objects in the solar system and
          the ways they move.
        </section>
        <p className="mb-7">
          Students may identify some or all of the following patterns:
        </p>
        <ul className="pl-30 circle-listing">
          <li>Each object is round/ spherical.</li>
          <li>
            There are the same number of objects, but they are in different
            places.
          </li>
          <li>The Sun is always in the middle.</li>
          <li>The Sun is always bigger than other objects.</li>
          <li>The Moon is always near Earth.</li>
          <li>Earth is always the third object from the Sun.</li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default G4_Investigate_S1;
