import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G14_Make_Observations_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G14"} seriesCount={"S1"}>
      {/*    <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G14_Make_Observations_S1;
