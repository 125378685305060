import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P31_Engage_Argument_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P31"} seriesCount={"S2"}>
      <div className="d-flex guidence-count pb-10">
        <span className="fw-700 pr-5 text-nexus-medium-blue">17.</span>
        <span className="font-16">
          <span className="fw-700">Engage in Argument:</span> Tell students that
          they seem to have a good understanding of how temperature can affect
          different types of matter.
        </span>
      </div>
      <p className="pl-25 mb-10">
        Draw student’s attention to the My Argument section of their science
        notebooks (page 16).
      </p>
      <p className="pl-25 mb-10">
        Encourage students to make a claim to answer the lesson question and
        then support their claim with the observations and data they collected
        (evidence) from their investigations.
      </p>
      <p className="pl-25">
        Explain that students will have a chance to share them during the next
        session.
      </p>
    </IdGeneratingComponent>
  );
}

export default P31_Engage_Argument_S2;
