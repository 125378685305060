import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G36_Initial_Ideas() {
  return (
    <IdGeneratingComponent guideLineCount={"G36"} seriesCount={"S2"}>
      <p className="mb-20">
        <span className="fw-700">Scaffolding:</span> Use the number line created
        in the previous session as a resource to help explain ideas related to
        the pole and why the glassblower kept putting the glass back into the
        furnace.
      </p>
      <section className="guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            ELA Connection <span className="text-nexus-lightgrey">(A,B,C)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p>
            Students recount and describe key ideas from their investigations to
            support their explanation of the pole and why the glassblower kept
            returning the glass to the furnace. (SL.2.2)
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G36_Initial_Ideas;
