import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function T18_Collect_Data_S1() {
  const [lgShow, setLgShow] = useState(false);
  return (
    <>
      <div className="lesson-brief">
        <div className="p-3 dashed-border-nexus-blue light-blue-section">
          <h5 className="text-center fw-700 mb-20">
            Stars Demonstration Model
          </h5>
          <img src="/image/lesson-images/star-demostration-video.png" />
        </div>
        <div className="d-flex justify-content-end mt-10">
          <Tooltip title="Video Alternative" placement="bottom">
            <span className="cursor-pointer" onClick={() => setLgShow(true)}>
              <img src="/image/lesson-images/lab_video_option.svg" />
            </span>
          </Tooltip>
        </div>
      </div>
      <Modal
        size="lg modal-dialog-centered"
        className="modal-dialog-centered modal-dialog-scrollable"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Stars Demonstration Model
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-20 video-section">
            <video controls>
              <source
                src="/videos/lesson3-videos/gr5_l3_space_stars_demonstration_model_video.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default T18_Collect_Data_S1;
