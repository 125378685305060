import React from "react";

function T20_Prior_Knowledge_S3() {
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-20">
          Find the Observations at Each Temperature data table in your science
          notebook.
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_10.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T20_Prior_Knowledge_S3;
