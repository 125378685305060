import React from "react";
import { Link } from "react-router-dom";
import ModuleData from "./ModuleData";

function Dashboard(props) {
  return (
    <>
      <div className="list mt-10">
        <div className="home-modules-list">
          <div className="row g-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            {ModuleData.map((x, key) => {
              return (
                <div className="col" key={key}>
                  {x.url === "" ? (
                    <>
                      <div className="card module h-100 opacity-50">
                        <img
                          src={x.ImagePath}
                          className="card-img-top"
                          alt="..."
                        />

                        <div className="card-body">
                          <p className="fw-700">{x.UnitName}</p>
                          <p className="text-i fw-400">{x.ModuleName}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Link to={x.url} className="cursor-pointer">
                        <div className="card module h-100">
                          <img
                            src={x.ImagePath}
                            className="card-img-top"
                            alt="..."
                          />
                          <div className="card-body">
                            <p className="fw-700">{x.UnitName}</p>
                            <p className="text-i fw-400">{x.ModuleName}</p>
                          </div>
                        </div>
                      </Link>
                    </>
                  )}
                </div>
              );
            })}

            {/* <div className="col">
              <div className="card module">
                <img
                  src="/image/nex_gr2_plants_modtile.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  What do plants need to live and grow?
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card module">
                <img
                  src="/image/nex_gr2_trash_modtile.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  How do we help solve the trash problems?
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card module">
                <img
                  src="/image/nex_gr2_water_modtile.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  Where can we find water on Earth?
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card module">
                <img
                  src="/image/nex_gr2_land_modtile.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  Why does land change over time?
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card module">
                <img
                  src="./image/card-img.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  Module 6
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card module">
                <img
                  src="./image/card-img.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  Module 7
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card module">
                <img
                  src="./image/card-img.png"
                  className="card-img-top"
                  alt="..."
                />
                <Link to={"/Module1"} className="card-body">
                  Module 8
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
