import React from "react";

function generateId(guideLineCount, seriesCount) {
  if (guideLineCount && seriesCount) {
    return guideLineCount + "S" + seriesCount;
  } else if (guideLineCount) {
    return guideLineCount;
  }
}

function IdGeneratingComponent(props) {
  let uniqueId = generateId(props.guideLineCount, props.seriesCount);

  return <div id={uniqueId}>{props.children}</div>;
}

export default IdGeneratingComponent;
