import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G16_Navigate() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G16"}>
        {/* <p className="text-i">Intentionally blank</p> */}
      </IdGeneratingComponent>
    </>
  );
}

export default G16_Navigate;
