import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P4_Develop_Initial_S4() {
  return (
    <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S4"}>
      <div className="pb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">7.</span>
        <span className="number-text">(continued)</span>
      </div>
      <p className="pl-20 mb-10">
        Next, move on to students’ ideas about the pole.
      </p>
      <p className="pl-20 mb-7">Ask:</p>
      <ul className="pl-45 pb-10 circle-listing">
        <li>What ideas do you have about pole?</li>
        <li>
          Why do you think the pole did not change while it was in the hot
          furnace?
        </li>
      </ul>
      <p className="pl-20">
        Record these ideas in the “Our Ideas About the Pole” column of the
        chart.
      </p>
    </IdGeneratingComponent>
  );
}

export default P4_Develop_Initial_S4;
