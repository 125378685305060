import React from "react";

function T8_Make_Observations_S1() {
  return (
    <>
      <div className="lesson-brief">
        <ul className="pl-20 circle-listing">
          <li>Go to your assigned station.</li>
          <li>Observe the items at your station.</li>
          <li>Discuss their observable properties with your group.</li>
          <li>Go to the next station when your teacher tells you to</li>
          <li>Repeat this process until you have visited all five stations.</li>
        </ul>
      </div>
    </>
  );
}

export default T8_Make_Observations_S1;
