import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G34_Prior_Knowledge_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"G34"} seriesCount={"S1"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G34_Prior_Knowledge_S1;
