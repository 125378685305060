import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P18_Collect_Data_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P18"} seriesCount={"S1"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">3.</span>
          <span className="number-text">
            <span className="fw-700">Collect Data:</span> Say, “To help us
            gather evidence to better explain the differences we see in the
            stars in those images, I found a model I want to show you.” Gather
            the class around the Stars Demonstration Model. Explain that:
          </span>
        </div>
        <ul className="pl-45 mb-10 circle-listing">
          <li>
            The printed stars around the model represent the stars in space.
          </li>
          <li>The large styrene ball represents the Sun.</li>
          <li>The small styrene ball represents Earth.</li>
          <li>
            The dot on the model Earth will help students know where on Earth
            they are viewing stars from in the context of the model.
          </li>
        </ul>
        <p className="mb-7 pl-20">Follow these steps to use the model:</p>
        <ul className="pl-45 numeric-listing">
          <li>
            Ask students where you should place Earth. (Modeling can begin with
            Earth in any location.)
          </li>
          <li>
            Place Earth on the agreed-upon starting point. Make sure the dot is
            facing the stars and not the Sun.
          </li>
          <li>
            Point out that the stars that are visible from Earth are the stars
            that the dot is facing. Ask:
            <ul className="pb-5 pl-30 mt-5 circle-listing ">
              <li>
                From this position in space, which stars are visible from our
                spot on Earth? (Point at the dot you made as you say “our
                spot.”)
              </li>
            </ul>
          </li>
          <li>
            Move Earth counterclockwise to model how it revolves around the Sun.
            Stop at a different position, about one-quarter of the way around
            the model. Ensure the dot you marked continues to face the stars
            opposite to the Sun. Ask:
            <ul className="pl-30 mt-10 mb-5 circle-listing ">
              <li>
                From this new position in space, which stars are visible from
                our spot on Earth?
              </li>
              <li>
                Are these the same stars that were visible to us from the
                previous position? How do you know?
              </li>
            </ul>
          </li>
          <li>
            Move Earth counterclockwise two more times, until Earth has
            completed a full revolution around the Sun. At each position, ask
            the same questions you asked in Step D.
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P18_Collect_Data_S1;
