import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G6_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"G6"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G6_Navigate;
