import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P15_Make_Predictions() {
  return (
    <IdGeneratingComponent guideLineCount={"P15"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">14.</span>
        <span className="number-text">
          <span className="fw-700">Make Predictions:</span> As a class, briefly
          discuss the changes students observed to the objects that were heated
          in the video. Next, prompt students to consider what might happen to
          the different types of matter once they are removed from the heat.
          Ask:
        </span>
      </p>
      <ul className="pl-50 pb-10 circle-listing">
        <li>
          What do you think will happen to these types of matter once they are
          removed from the heat and cool?
        </li>
        <li>
          What do you predict will happen if these types of matter are placed in
          colder temperatures?
        </li>
      </ul>
      <p className="pl-25">
        Prompt students to locate the Cooling Matter data table in their science
        notebooks and individually fill in the “My Prediction” column (page 9).
        Their predictions should describe what they think will happen to each
        type of matter when the object cools, or changes from a high temperature
        to a lower temperature.
      </p>
    </IdGeneratingComponent>
  );
}

export default P15_Make_Predictions;
