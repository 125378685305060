import React from "react";

function T14_Earn_Words() {
  return (
    <>
      <div className="lesson-brief">
        <div className="container d-flex justify-content-center">
          <div className="row">
            <input type="checkbox" id="card-1" />

            <label className="col-md-2 flash-card-container" for="card-1">
              <div className="card-flip">
                <div className="flash-card front d-flex align-items-center justify-content-center">
                  <div className="card-block">
                    <p className="fw-700">?</p>
                  </div>
                </div>

                <div className="flash-card back d-flex align-items-center justify-content-center">
                  <div className="card-block">
                    <p className="fw-700">axis</p>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default T14_Earn_Words;
