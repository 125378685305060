import React from "react";

function T3_Make_Personal() {
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-20">
          Think about the Revisiting the Furnace video.
        </p>
        <div className="p-3 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>What does the glass on the end of the pole remind you of?</li>
            <li>Where have you seen something like this before?</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default T3_Make_Personal;
