import React from "react";

function T18_Make_Personal() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-20 mt-5 font-16">Tell about a time when:</p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>Something melted into liquid when heated.</li>
                <li>Something changed to a solid when cooled.</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T18_Make_Personal;
