import React from "react";

function T26_Navigate() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  How does our new understanding about temperature help us
                  answer our unit-driving question, How do objects change into
                  other objects?
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-40 lesson-break w-75 mx-auto">
          <img
            src="/image/lesson-images/session_break.svg"
            alt="session_break"
            title="session_break"
          ></img>
        </section>
      </div>
    </>
  );
}

export default T26_Navigate;
