import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P33_Navigate() {
  return (
    <IdGeneratingComponent guideLineCount={"P33"}>
      {/* <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default P33_Navigate;
