import React, { useEffect, useState } from "react";
import PartA from "../../../TeacherPreparation/grade5/Lesson-3/PartA";
import PartB from "../../../TeacherPreparation/grade5/Lesson-3/PartB";
import PartC from "../../../TeacherPreparation/grade5/Lesson-3/PartC";

import P1_Connect_to_Prior_Knowledge from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P1_Connect_to_Prior_Knowledge";
import P2_Make_Observations_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P2_Make_Observations_S1";
import P2_Make_Observations_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P2_Make_Observations_S2";
import P2_Make_Observations_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P2_Make_Observations_S3";
import P3_Develop_Initial_Ideas_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P3_Develop_Initial_Ideas_S1";
import P3_Develop_Initial_Ideas_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P3_Develop_Initial_Ideas_S2";
import P3_Develop_Initial_Ideas_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P3_Develop_Initial_Ideas_S3";
import P4_Investigate_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P4_Investigate_S1";
import P4_Investigate_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P4_Investigate_S2";
import P4_Investigate_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P4_Investigate_S3";
import P4_Investigate_S4 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P4_Investigate_S4";
import P5_Gather_Evidence from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P5_Gather_Evidence";
import P6_Engage_in_Sensemaking from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P6_Engage_in_Sensemaking";
import P7_Earn_Words_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P7_Earn_Words_S1";
import P7_Earn_Words_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P7_Earn_Words_S2";
import P8_Checkpoint from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P8_Checkpoint";
import P9_Navigation from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P9_Navigation";
import P10_Connect_to_Prior_Knowledge from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P10_Connect_to_Prior_Knowledge";
import P11_Develop_Initial_Ideas_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P11_Develop_Initial_Ideas_S1";
import P11_Develop_Initial_Ideas_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P11_Develop_Initial_Ideas_S2";
import P11_Develop_Initial_Ideas_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P11_Develop_Initial_Ideas_S3";
import P12_Collect_Represent_and_Analyze_Data_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S1";
import P12_Collect_Represent_and_Analyze_Data_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S2";
import P12_Collect_Represent_and_Analyze_Data_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S3";
import P12_Collect_Represent_and_Analyze_Data_S4 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P12_Collect_Represent_and_Analyze_Data_S4";
import P13_Engage_in_Sensemaking from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P13_Engage_in_Sensemaking";
import P14_Earn_Words from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P14_Earn_Words";
import P15_Checkpoint from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P15_Checkpoint";
import P16_Navigate from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P16_Navigate";
import P17_Connect_to_Prior_Knowledge_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P17_Connect_to_Prior_Knowledge_S1";
import P17_Connect_to_Prior_Knowledge_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P17_Connect_to_Prior_Knowledge_S2";
import P18_Collect_Data_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P18_Collect_Data_S1";
import P18_Collect_Data_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P18_Collect_Data_S2";
import P18_Collect_Data_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P18_Collect_Data_S3";
import P19_Engage_in_Sensemaking_S1 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P19_Engage_in_Sensemaking_S1";
import P19_Engage_in_Sensemaking_S2 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P19_Engage_in_Sensemaking_S2";
import P19_Engage_in_Sensemaking_S3 from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P19_Engage_in_Sensemaking_S3";
import P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon";
import P21_Navigate from "../../../LessonLevel/grade5/TeacherProcedure/Lesson-p3/P21_Navigate";
import G1_Connect_to_Prior_Knowledge from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G1_Connect_to_Prior_Knowledge";
import G2_Make_Observations_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G2_Make_Observations_S1";
import G2_Make_Observations_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G2_Make_Observations_S2";
import G2_Make_Observations_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G2_Make_Observations_S3";
import G3_Develop_Initial_Ideas_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G3_Develop_Initial_Ideas_S1";
import G3_Develop_Initial_Ideas_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G3_Develop_Initial_Ideas_S2";
import G3_Develop_Initial_Ideas_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G3_Develop_Initial_Ideas_S3";
import G4_Investigate_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G4_Investigate_S1";
import G4_Investigate_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G4_Investigate_S2";
import G4_Investigate_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G4_Investigate_S3";
import G4_Investigate_S4 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G4_Investigate_S4";
import G5_Gather_Evidence from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G5_Gather_Evidence";
import G6_Engage_in_Sensemaking from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G6_Engage_in_Sensemaking";
import G7_Earn_Words_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G7_Earn_Words_S1";
import G7_Earn_Words_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G7_Earn_Words_S2";
import G8_Checkpoint from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G8_Checkpoint";
import G9_Navigation from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G9_Navigation";
import G10_Connect_to_Prior_Knowledge from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G10_Connect_to_Prior_Knowledge";
import G11_Develop_Initial_Ideas_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G11_Develop_Initial_Ideas_S1";
import G11_Develop_Initial_Ideas_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G11_Develop_Initial_Ideas_S2";
import G11_Develop_Initial_Ideas_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G11_Develop_Initial_Ideas_S3";
import G12_Collect_Represent_and_Analyze_Data_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S1";
import G12_Collect_Represent_and_Analyze_Data_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S2";
import G12_Collect_Represent_and_Analyze_Data_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S3";
import G12_Collect_Represent_and_Analyze_Data_S4 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G12_Collect_Represent_and_Analyze_Data_S4";
import G13_Engage_in_Sensemaking from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G13_Engage_in_Sensemaking";
import G14_Earn_Words from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G14_Earn_Words";
import G15_Checkpoint from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G15_Checkpoint";
import G16_Navigate from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G16_Navigate";
import G17_Connect_to_Prior_Knowledge_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G17_Connect_to_Prior_Knowledge_S1";
import G17_Connect_to_Prior_Knowledge_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G17_Connect_to_Prior_Knowledge_S2";
import G18_Collect_Data_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G18_Collect_Data_S1";
import G18_Collect_Data_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G18_Collect_Data_S2";
import G18_Collect_Data_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G18_Collect_Data_S3";
import G19_Engage_in_Sensemaking_S1 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G19_Engage_in_Sensemaking_S1";
import G19_Engage_in_Sensemaking_S2 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G19_Engage_in_Sensemaking_S2";
import G19_Engage_in_Sensemaking_S3 from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G19_Engage_in_Sensemaking_S3";
import G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon";
import G21_Navigate from "../../../LessonLevel/grade5/TeacherGuideLines/Lesson-g3/G21_Navigate";

function ContainerGuide({ sections }) {
  const [isActiveScroll, setIsActiveScroll] = useState(0);
  const [scrollhide, setScrollHide] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const sections = document.querySelectorAll("section[id]");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight * 0.1 &&
          scrollPosition < sectionTop + sectionHeight * 0.1
        ) {
          setIsActiveScroll(section.id);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="two-column-content">
        <aside className="table-of-content-menu">
          <nav className="sidebar card accordion-menu-listings">
            <ul className="nav flex-column" id="nav_accordion">
              <li className="level-one opacity-75">
                <a
                  className="nav-link"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Lesson 1 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
              </li>
              <li className="level-one opacity-75">
                <a
                  className="nav-link"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Lesson 2 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
              </li>
              <li className="level-one">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse3"
                  aria-expanded="true"
                  onClick={() => setScrollHide(4)}
                >
                  Lesson 3 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse show" id="home-collapse3">
                  <ul className="mt-15 nav flex-column submenu btn-toggle-navl">
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          scrollhide === 0 ? "nav-link active" : "nav-link"
                        }
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse"
                        aria-expanded={scrollhide === 0 ? "true" : "false"}
                      >
                        Why do we see different stars in the summer than in the
                        winter?
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 0 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse"
                      >
                        <ul className=" nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "performance"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#performance"
                            >
                              Performance Expectations
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "elements"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#elements"
                            >
                              Elements Developed in This Lesson
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "lessonsummary"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#lessonsummary"
                            >
                              Lesson Summary
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "teacherbackground"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#teacherbackground"
                            >
                              Teacher Background
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "priorconceptions"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#priorconceptions"
                            >
                              Prior Conceptions
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "earnedwords"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#earnedwords"
                            >
                              Earned Words
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "materials"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#materials"
                            >
                              Materials List
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        className={
                          scrollhide === 1 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setScrollHide(1)}
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded={scrollhide === 1 ? "true" : "false"}
                      >
                        Procedure & Guidance{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 1 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse1"
                      >
                        <ul className=" nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "parta"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#parta"
                            >
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partb"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partb"
                            >
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partc"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partc"
                            >
                              Part C
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(2)}
                        className={
                          isActiveScroll === "supporting"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#supporting"
                      >
                        Supporting All Learners
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        className={
                          scrollhide === 3 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setScrollHide(3)}
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse3"
                        aria-expanded={scrollhide === 3 ? "true" : "false"}
                      >
                        Teacher Resources
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 3 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse3"
                      >
                        <ul className=" nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "Lesson_3_Part_A"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#Lesson_3_Part_A"
                            >
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              A
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "Lesson_3_Part_B"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#Lesson_3_Part_B"
                            >
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              B
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "Lesson_3_Part_C"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#Lesson_3_Part_C"
                            >
                              Science Notebook Teacher’s Version: Lesson 3, Part
                              C
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="level-one opacity-75">
                <a
                  className="nav-link"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Lesson 4 <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
              </li>
            </ul>
          </nav>
        </aside>
        <div className="pr-15 pt-15 table-of-content">
          <section className="mb-20 lesson-banner">
            <img
              src="/image/g5-lesson3-image1.jpg"
              alt="WHY DO WE SEE DIFFERENT STARS IN THE SUMMER"
              title="WHY DO WE SEE DIFFERENT STARS IN THE SUMMER"
            ></img>
          </section>
          <h5 className="fw-700 text-nexus-white mb-20">
            <span className="gradient-title p-2 pr-40">
              3 sessions of 30 minutes
            </span>
          </h5>
          <section id="performance">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PERFORMANCE</span>{" "}
              <span className="fw-500">EXPECTATIONS</span>
            </h5>
            <p className="fw-700 mb-10">
              Lesson-Level Performance Expectations
            </p>
            <p className="mb-7">In this lesson, students will:</p>
            <ul className="mb-10 pl-30 circle-listing">
              <li>
                <span className="text-nexus-dark-blue">
                  Represent data in graphical displays
                </span>{" "}
                that reveals{" "}
                <span className="text-nexus-green">
                  patterns that can be used as evidence
                </span>{" "}
                to explain the seasonal changes in the number of daylight hours
                that result from{" "}
                <span className="text-nexus-orange">
                  Earth's revolution around the Sun.
                </span>{" "}
                (Part B)
              </li>
              <li>
                <span className="text-nexus-dark-blue">
                  Use models to describe and predict
                </span>{" "}
                <span className="text-nexus-green">patterns</span> caused by{" "}
                <span className="text-nexus-orange">Earth’s revolution</span>{" "}
                that are related to the{" "}
                <span className="text-nexus-orange">
                  different positions of the stars throughout the year.
                </span>{" "}
                (Part C)
              </li>
            </ul>
            <p className="fw-700 mb-7">
              Building Toward NGSS Performance Expectations
            </p>
            <ul className="pb-20 pl-30 circle-listing">
              <li>
                <span className="fw-700">5-ESS1-2: </span>Represent data in
                graphical displays to reveal patterns of daily changes in length
                and direction of shadows, day and night, and the seasonal
                appearance of some stars in the night sky.
              </li>
            </ul>
            <section className="lesson-banner">
              <img src="/image/g5-lesson3-image2.jpg" alt="" title=""></img>
            </section>
          </section>
          <hr></hr>
          <section id="elements">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">ELEMENTS</span>{" "}
              <span className="fw-500">DEVELOPED IN THIS LESSON</span>
            </h5>
            <p className="mb-10">
              When developing an element, students build on their previous
              knowledge and experience to make progress toward grade-band
              expectations for the element. These elements are what make up the
              Lesson-Level Performance Expectations.
            </p>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle background-nexus-orange w-25">
                      <p>Disciplinary Core Ideas</p>
                      <p className="fw-500">
                        Fundamental scientific ideas that form content
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-darkblue w-25">
                      <p>Science and Engineering Practices</p>
                      <p className="fw-500">
                        What students do to make sense of phenomena
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-green w-25">
                      <p>Crosscutting Concepts</p>
                      <p className="fw-500">
                        A way of linking the different domains of science
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nexus-orange">
                      <span className="fw-700">
                        ESS1.B: Earth and the Solar System:
                      </span>{" "}
                      The orbits of Earth around the sun{" "}
                      <span className="text-decoration-line-through">
                        and of the moon around Earth, together with the rotation
                        of Earth about an axis between its North and South
                        poles,
                      </span>{" "}
                      cause observable patterns. These include{" "}
                      <span className="text-decoration-line-through">
                        day and night; daily changes in the length and direction
                        of shadows;
                      </span>{" "}
                      and different positions of{" "}
                      <span className="text-decoration-line-through">
                        the sun, moon, and
                      </span>{" "}
                      stars at different times of the{" "}
                      <span className="text-decoration-line-through">day,</span>{" "}
                      month, and year.
                    </td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Developing and Using Models:
                      </span>{" "}
                      Develop and/or use models to describe and/or predict
                      phenomena.
                    </td>
                    <td className="text-nexus-green">
                      <span className="fw-700">Patterns:</span> Patterns of
                      change can be used to make predictions.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nexus-orange"></td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Analyzing and Interpreting Data:
                      </span>{" "}
                      Represent data in graphical displays (bar graphs,
                      pictographs, and/ or pie charts) to reveal patterns that
                      indicate relationships.
                    </td>
                    <td className="text-nexus-green">
                      <span className="fw-700">Patterns:</span> Patterns can be
                      used as evidence to support an explanation.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">
              * The parts of each element that are crossed out are not addressed
              in the lesson.
            </p>
          </section>
          <hr></hr>
          <section id="lessonsummary">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">LESSON</span>{" "}
              <span className="fw-500">SUMMARY</span>
            </h5>
            <p>
              In this lesson students conduct a series of investigations to
              determine the role of{" "}
              <span className="text-nexus-orange">Earth’s revolution</span>{" "}
              around the Sun in the seasonal{" "}
              <span className="text-nexus-green">patterns</span> that we observe
              in the sky. Students{" "}
              <span className="text-nexus-dark-blue">
                analyze and represent data in graphical displays
              </span>{" "}
              to identify <span className="text-nexus-green">patterns</span> in
              the{" "}
              <span className="text-nexus-orange">
                number of daylight hours at different times of the year.
              </span>{" "}
              In addition, students{" "}
              <span className="text-nexus-dark-blue">use models</span> to
              determine why we see{" "}
              <span className="text-nexus-orange">
                different stars in the summer than in the winter.
              </span>
            </p>
          </section>
          <hr></hr>
          <section id="teacherbackground">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">TEACHER</span>{" "}
              <span className="fw-500">BACKGROUND</span>
            </h5>
            <p className="mb-10">
              The movement of one space body around another is called
              revolution. Earth’s revolution around the Sun takes about 365
              days. Earth (and all other planets) revolve around the Sun in a
              counterclockwise direction. Earth’s revolution causes observable
              patterns related to seasonal changes such as the number of
              daylight hours, and the different stars and constellations that
              can be seen in the night sky throughout the year. This unit does
              not address the concepts of seasons and other seasonal changes
              because these science ideas are above grade band.
            </p>
            <p className="mb-10">
              Because of the constant pull of the Sun’s gravity on the planets,
              comets, asteroids, and other objects in the solar system, these
              objects move in orbit around the Sun. An orbit is a path that one
              object in space takes around another object. The concept of
              gravity in space is presented to help you better understand the
              concepts related to this lesson, but understanding the concept of
              gravity is above grade band and is not expected of students.
            </p>
            <p className="mb-20">
              Earth’s axis is an imaginary line that runs from top to bottom,
              from the North Pole to the South Pole. Earth is tilted at its
              axis, and this tilt doesn’t change. As Earth revolves around the
              Sun, different parts of Earth are tilted toward or away from the
              Sun. When one part is tilted toward the Sun, it experiences more
              hours of daylight (longer days). When the same part is tilted away
              from the Sun, it experiences fewer hours of daylight (shorter
              days).
            </p>
            <div className="row justify-content-center">
              <div className="col-12 col-md-7">
                <img
                  src="/image/g5-lesson3-image4.jpg"
                  alt="TEACHER BACKGROUND"
                  title="TEACHER BACKGROUND"
                ></img>
              </div>
            </div>
          </section>
          <hr></hr>
          <section id="priorconceptions">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PRIOR</span>{" "}
              <span className="fw-500">CONCEPTIONS</span>
            </h5>
            <p className="mb-10">
              Students may come to this lesson with preconceptions or
              misconceptions around the science ideas presented in this unit.
              Such ideas will be addressed as part of the student sensemaking
              process throughout this unit. Listed below are misconceptions you
              are likely to hear from your students in this lesson. The correct
              science idea follows in parenthesis.
            </p>
            <p className="mb-7">
              This lesson will address the following misconception:
            </p>
            <ul className="pl-30 circle-listing">
              <li>
                Stars and constellations appear in the same place in the sky
                throughout the year.{" "}
                <span className="text-i">
                  (Due to Earth’s revolution around the Sun, stars and
                  constellations appear in different places in the sky
                  throughout the year.)
                </span>
              </li>
            </ul>
          </section>
          <hr></hr>
          <section id="earnedwords">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">EARNED</span>{" "}
              <span className="fw-500">WORDS</span>
            </h5>
            <p className="mb-10">
              A word is earned on the Word Wall when the class demonstrates a
              strong understanding of that key term. Until a word is earned,
              encourage students to use everyday language in discussions and
              work products. Opportunities to earn words are called out in the
              lesson procedure.
            </p>
            <p className="mb-7">
              By the end of this lesson, students will earn the following key
              terms for the Word Wall:
            </p>
            <div className="mb-20 d-flex justify-content-center">
              <p className="font-20 mr-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Axis
              </p>
              <p className="font-20 fw-700 mr-20">
                <span className="text-nexus-blue pr-10">✓</span> Orbit
              </p>
              <p className="font-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Revolution
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <img src="/image/g5-lesson3-image3.jpg" alt="" title=""></img>
              </div>
            </div>
          </section>
          <hr></hr>
          <section id="materials">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">MATERIALS</span>{" "}
              <span className="fw-500">LIST</span>
            </h5>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part A</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Summer and Winter Night Skies image</li>
                            <li>
                              Initial Ideas: Different Stars in the Winter and
                              the Summer Class Chart
                            </li>
                            <li>What Is In Our Solar System? interactive</li>
                          </ul>
                        </li>
                        <li>32 Devices (tablets, laptops, etc.)</li>
                        <li>32 Science Notebooks*</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part B</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3* :
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Earth’s Revolution animation</li>
                          </ul>
                        </li>
                        <li>32 Science Notebooks*</li>
                        {/* <li>32 Science Notebooks*</li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part C</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3* :
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Summer and Winter Night Skies image</li>
                            <li>
                              Initial Ideas: Different Stars in the Winter and
                              the Summer Class Chart (from Part A)
                            </li>
                            <li>
                              Notice and Wonder Class Chart (from Lesson 1)
                            </li>
                            <li>
                              Alternative Digital Investigation (optional)
                            </li>
                          </ul>
                        </li>
                        <li>1 Large styrene ball</li>
                        <li>1 Small styrene ball</li>
                        <li>
                          1 Stars Demonstration Model†
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>1 Night Sky Card Set</li>
                            <li>Tape‡</li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">*Accessible online</p>
            <p className="font-14">† Prepared by the teacher</p>
            <p className="font-14">‡ Needed but not supplied</p>
          </section>
          <hr></hr>
          <section className="mb-20 lesson-banner">
            <img
              src="/image/lesson3_image1.png"
              alt="PROCEDURE AND TEACHER GUIDANCE"
              title="PROCEDURE AND TEACHER GUIDANCE"
            ></img>
          </section>
          <section id="parta">
            <PartA />
          </section>
          <div className="table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P1_Connect_to_Prior_Knowledge />
                  </td>
                  <td>
                    <G1_Connect_to_Prior_Knowledge />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P2_Make_Observations_S1 />
                  </td>
                  <td>
                    <G2_Make_Observations_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P2_Make_Observations_S2 />
                  </td>
                  <td>
                    <G2_Make_Observations_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P2_Make_Observations_S3 />
                  </td>
                  <td>
                    <G2_Make_Observations_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P3_Develop_Initial_Ideas_S1 />
                  </td>
                  <td>
                    <G3_Develop_Initial_Ideas_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P3_Develop_Initial_Ideas_S2 />
                  </td>
                  <td>
                    <G3_Develop_Initial_Ideas_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P3_Develop_Initial_Ideas_S3 />
                  </td>
                  <td>
                    <G3_Develop_Initial_Ideas_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Investigate_S1 />
                  </td>
                  <td>
                    <G4_Investigate_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Investigate_S2 />
                  </td>
                  <td>
                    <G4_Investigate_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Investigate_S3 />
                  </td>
                  <td>
                    <G4_Investigate_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P4_Investigate_S4 />
                  </td>
                  <td>
                    <G4_Investigate_S4 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P5_Gather_Evidence />
                  </td>
                  <td>
                    <G5_Gather_Evidence />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P6_Engage_in_Sensemaking />
                  </td>
                  <td>
                    <G6_Engage_in_Sensemaking />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P7_Earn_Words_S1 />
                  </td>
                  <td>
                    <G7_Earn_Words_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P7_Earn_Words_S2 />
                  </td>
                  <td>
                    <G7_Earn_Words_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P8_Checkpoint />
                  </td>
                  <td>
                    <G8_Checkpoint />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P9_Navigation />
                  </td>
                  <td>
                    <G9_Navigation />
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="partb">
            <PartB />
          </section>
          <div className="table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P10_Connect_to_Prior_Knowledge />
                  </td>
                  <td>
                    <G10_Connect_to_Prior_Knowledge />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P11_Develop_Initial_Ideas_S1 />
                  </td>
                  <td>
                    <G11_Develop_Initial_Ideas_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P11_Develop_Initial_Ideas_S2 />
                  </td>
                  <td>
                    <G11_Develop_Initial_Ideas_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P11_Develop_Initial_Ideas_S3 />
                  </td>
                  <td>
                    <G11_Develop_Initial_Ideas_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P12_Collect_Represent_and_Analyze_Data_S1 />
                  </td>
                  <td>
                    <G12_Collect_Represent_and_Analyze_Data_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P12_Collect_Represent_and_Analyze_Data_S2 />
                  </td>
                  <td>
                    <G12_Collect_Represent_and_Analyze_Data_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P12_Collect_Represent_and_Analyze_Data_S3 />
                  </td>
                  <td>
                    <G12_Collect_Represent_and_Analyze_Data_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P12_Collect_Represent_and_Analyze_Data_S4 />
                  </td>
                  <td>
                    <G12_Collect_Represent_and_Analyze_Data_S4 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P13_Engage_in_Sensemaking />
                  </td>
                  <td>
                    <G13_Engage_in_Sensemaking />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P14_Earn_Words />
                  </td>
                  <td>
                    <G14_Earn_Words />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P15_Checkpoint />
                  </td>
                  <td>
                    <G15_Checkpoint />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P16_Navigate />
                  </td>
                  <td>
                    <G16_Navigate />
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="partc">
            <PartC />
          </section>
          <div className="table-responsive mt-20">
            <table className="table table-bordered border-secondary m-0">
              <thead className="table-dark">
                <tr>
                  <th className="text-center align-middle w-50">Procedure</th>
                  <th className="text-center align-middle w-50">
                    Teacher Guidance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <P17_Connect_to_Prior_Knowledge_S1 />
                  </td>
                  <td>
                    <G17_Connect_to_Prior_Knowledge_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P17_Connect_to_Prior_Knowledge_S2 />
                  </td>
                  <td>
                    <G17_Connect_to_Prior_Knowledge_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P18_Collect_Data_S1 />
                  </td>
                  <td>
                    <G18_Collect_Data_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P18_Collect_Data_S2 />
                  </td>
                  <td>
                    <G18_Collect_Data_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P18_Collect_Data_S3 />
                  </td>
                  <td>
                    <G18_Collect_Data_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P19_Engage_in_Sensemaking_S1 />
                  </td>
                  <td>
                    <G19_Engage_in_Sensemaking_S1 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P19_Engage_in_Sensemaking_S2 />
                  </td>
                  <td>
                    <G19_Engage_in_Sensemaking_S2 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P19_Engage_in_Sensemaking_S3 />
                  </td>
                  <td>
                    <G19_Engage_in_Sensemaking_S3 />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P20_Advance_Sensemaking_of_the_Anchoring_Phenomenon />
                  </td>
                  <td>
                    <G20_Advance_Sensemaking_of_the_Anchoring_Phenomenon />
                  </td>
                </tr>
                <tr>
                  <td>
                    <P21_Navigate />
                  </td>
                  <td>
                    <G21_Navigate />
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="pt-40 pb-40 lesson-break w-50 mx-auto">
              <img
                src="/image/lesson-images/session_break.svg"
                alt="session_break"
                title="session_break"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="supporting">
            <p className="fw-700 p-2 background-nexus-blue mb-20">
              SUPPORTING ALL LEARNERS
            </p>
            <p className="mb-10">
              After completing this lesson, consider the following options for
              remediation or extension:
            </p>
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="background-nexus-blue align-middle w-50">
                      REMEDIATION
                    </th>
                    <th className="background-nexus-blue align-middle w-50">
                      EXTENSION
                    </th>
                  </tr>
                </thead>
                <tbody className="light-blue-section">
                  <tr>
                    <td>
                      <p className="mb-10 fw-700">
                        Represent Data in Graphical Displays and Identify
                        Patterns in the Data
                      </p>
                      <p className="mb-7">
                        If students struggle to independently represent the data
                        in a graph, use the following activity:
                      </p>
                      <ul className="mb-10 pl-30 circle-listing number-listing">
                        <li>
                          Make a list of the total hours of daylight for day 15
                          of each month for your specific location. The site
                          gml.noaa.gov/grad/solcalc/sunrise.html provides
                          sunrise and sunset times you can use to calculate
                          this.
                        </li>
                        <li>
                          Have students identify the months with the most and
                          least daylight hours.
                        </li>
                        <li>
                          Walk students through the process of creating a graph
                          of the total hours of daylight for your location.
                        </li>
                        <li>
                          Ask students to identify a pattern they notice in the
                          graph they created.
                        </li>
                        <li>
                          Have students explain how Earth’s revolution around
                          the Sun can explain this pattern.
                        </li>
                      </ul>
                      <p className="mb-10 fw-700">
                        Earth’s Revolution Around the Sun
                      </p>
                      <p className="mb-7">
                        If students struggle to understand the idea that Earth
                        revolves around the Sun, use the following activity:
                      </p>
                      <ul className="pl-30 circle-listing number-listing">
                        <li>
                          Show students the styrene ball (or inflatable globe)
                          and a flashlight from the kit.
                        </li>
                        <li>
                          Ask students to identify what the ball and the
                          flashlight represent.
                        </li>
                        <li>
                          Explain that Earth revolves around the Sun, which
                          means that is moves in a path, or orbit, around the
                          Sun.
                        </li>
                        <li>
                          Hold the flashlight. Give each student a chance to
                          model Earth’s revolution using the ball.
                        </li>
                        <li>
                          Ask students to describe Earth’s movement as they
                          model it.
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p className="mb-10">
                        If students express high interest in or strong
                        understanding of the lesson’s concepts, consider
                        providing the following activity:
                      </p>
                      <p className="mb-7 fw-700">Constellations</p>

                      <ul className="pl-30 circle-listing number-listing">
                        <li>
                          Find a print or digital resource that shows the
                          different constellations that can be viewed throughout
                          the year from your specific location.
                        </li>
                        <li>
                          Have students choose one constellation that they are
                          interested in and research it.
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <hr></hr>
          <section id="Lesson_3_Part_A">
            <p className="fw-700 p-2 background-nexus-blue mb-20">
              TEACHER RESOURCES
            </p>
            <h5 className="fw-700 mb-10">
              Science Notebook Teacher’s Version: Lesson 3, Part A
            </h5>
            <h5 className="fw-700 mb-10">
              Why Do We See Different Stars in the Winter Than in the Summer?
            </h5>
            <p className="mb-20 text-nexus-blue fw-700">OBSERVE</p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered  m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle w-50">
                      Observations
                    </th>
                    <th className="text-center align-middle w-50">Questions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-i text-nexus-grey">
                      <p className="mb-7">
                        Student observations should include:
                      </p>
                      <ul className="mb-10 pl-30 circle-listing">
                        <li>
                          There are different stars in the summer picture than
                          in the winter picture.
                        </li>
                      </ul>
                      <p className="mb-7">They may also include:</p>
                      <ul className="pl-30 circle-listing">
                        <li>
                          There are some brighter stars in the winter picture.
                        </li>
                        <li>You can see more light in the summer picture.</li>
                        <li>
                          The stars appear in different positions in each
                          picture.
                        </li>
                      </ul>
                    </td>
                    <td className="text-i text-nexus-grey">
                      <p className="mb-7">Student questions may include:</p>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Why are there different stars in the summer than in
                          the winter?
                        </li>
                        <li>
                          What is that band of light in the middle of the summer
                          picture?
                        </li>
                        <li>Why are there different colors in the sky?</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="mb-20 text-nexus-blue fw-700">INITIAL IDEAS</p>
            <div className="mb-20">
              <img src="/image/g5-lesson3-image5.jpg"></img>
            </div>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Record your initial ideas about the different stars you see in
                the Summer and Winter Night Skies image.
              </span>
            </div>
            <p className="text-i pl-15 text-nexus-grey mb-20">
              Students’ initial ideas will vary depending on their prior
              knowledge and experiences.
            </p>
            <h5 className="fw-700 mb-10 text-center">
              What Is Moving in Space?
            </h5>
            <p className="mb-20 text-nexus-blue fw-700">INVESTIGATE</p>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Analyze the models in the What Is In Our Solar System?
                interactive resource. Describe any patterns you observe using
                words, labels, and/or drawings.
              </span>
            </div>
            <p className="mb-7 pl-15 text-i text-nexus-grey">
              Students’ words, labels, or drawings should reflect the following
              patterns:
            </p>
            <ul className="mb-10 pl-45 text-i text-nexus-grey circle-listing">
              <li>The Sun is at the center of the solar system.</li>
              <li>The planets are in different places around the Sun.</li>
              <li>There are stars in all the models.</li>
              <li>
                There are circular/elliptical lines around the Sun in all of the
                models.
              </li>
            </ul>
            <div className="mb-20 fw-700 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                Add arrows and labels to the diagram to show which objects in
                the solar system move and how they move.
              </span>
            </div>
            <div className="mb-10">
              <img src="/image/g5-lesson3-image6.jpg"></img>
            </div>
            <p className="mb-7 pl-20 text-i text-nexus-grey">
              Answers will vary. Students are likely to have some prior
              knowledge about the way objects move in space. For example, they
              may know that planets revolve around the Sun and draw arrows to
              indicate this. Do not be concerned about misconceptions students
              may have at this point.
            </p>
            <div className="mb-7 fw-700 d-flex guidence-count">
              <span className="number pr-5">3.</span>
              <span className="number-text">Explain why you think this.</span>
            </div>
            <p className="mb-20 text-i pl-20 text-nexus-grey">
              Answers will vary based on what students draw and label in their
              diagrams. Look for students’ ideas to be consistent with what they
              draw and label.
            </p>
            <p className="mb-20 text-nexus-blue fw-700">
              MOVEMENT IN THE SOLAR SYSTEM
            </p>
            <div className="mb-20">
              <img src="/image/g5-lesson3-image7.jpg"></img>
            </div>
            <p className="mb-10 text-nexus-blue fw-700">GATHER EVIDENCE</p>
            <p className="mb-10">
              Record four important ideas from the infographic related to how
              objects move in the solar system.
            </p>
            <p className="mb-7 text-i pl-20 text-nexus-grey">
              Students may record any four of the following ideas:
            </p>
            <ul className="mb-10 pl-40 text-i text-nexus-grey circle-listing">
              <li>The Sun is at the center of the solar system.</li>
              <li>Planets, comets, and asteroids revolve around the Sun.</li>
              <li>Objects move around the Sun in a path called an orbit.</li>
              <li>
                Planets revolve around the Sun in a counterclockwise direction.
              </li>
              <li>
                Earth takes about one year to complete one revolution around the
                Sun.
              </li>
            </ul>
            <p className="mb-10 text-nexus-blue fw-700">REFLECT</p>
            <p className="mb-10">
              Compare the information you gathered from the infographic to your
              initial ideas in Part A. How have your ideas changed or grown? Use
              words, labels, and/or drawings to express your ideas.
            </p>
            <p className="mb-10 text-i pl-20 text-nexus-grey">
              Answers will vary depending on students’ initial ideas. Look for
              thoughts that describe self-reflection, such as “I used to think
              ______ and now I think ________,” or “I thought that __________.”
            </p>
            <p className="fw-700 mb-10">
              Science Notebook Teacher’s Version: Lesson 3, Part A
            </p>
            <section className="mb-10 right-icon-text-group align-items-center">
              <div className="text flex-initial">
                <p className="fw-700 pr-10 text-nexus-blue">CHECKPOINT</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/formative_assessment_opportunity.svg"
                  alt="formative-assessment-opportunity"
                  title="formative-assessment-opportunity"
                ></img>
              </div>
            </section>
            <div className="mb-7 d-flex guidence-count">
              <span className="number pr-5 fw-700">1.</span>
              <span className="number-text">What is revolution?</span>
            </div>
            <div className="ml-15 mb-7 d-inline-flex guidence-count px-3 py-2 text-nexus-grey border rounded-pill">
              <span className="number pr-5 fw-700">a.</span>
              <span className="number-text">
                The movement of a smaller object around a larger object.
              </span>
            </div>
            <div className="ml-15 mb-7 d-flex guidence-count">
              <span className="number pr-5 fw-700">b.</span>
              <span className="number-text">A circle around the Sun.</span>
            </div>
            <div className="ml-15 mb-20 d-flex guidence-count">
              <span className="number pr-5 fw-700">c.</span>
              <span className="number-text">
                Any movement an object makes in any direction.
              </span>
            </div>
            <div className="mb-7 d-flex guidence-count">
              <span className="number pr-5 fw-700">2.</span>
              <span className="number-text">
                True or False? An orbit is the path an object follows in any
                direction.
              </span>
            </div>
            <div className="mb-10 d-flex">
              <div className="px-4 py-2 mr-10">True</div>
              <div className="px-4 py-2 text-nexus-grey border rounded-pill">
                False
              </div>
            </div>
            <p className="text-nexus-grey">
              (An orbit is a path an object follows around another object.)
            </p>
          </section>
          <hr></hr>
          <section id="Lesson_3_Part_B">
            <h5 className="fw-700 mb-10">
              Science Notebook Teacher’s Version: Lesson 3, Part B
            </h5>
            <h5 className="fw-700 mb-10">
              What Causes the Change in Hours of Daylight Throughout the Year?
            </h5>
            <p className="mb-10 text-nexus-blue fw-700">COLLECT DATA</p>
            <p className="mb-20">
              Calculate the total number of hours of daylight for the 15th day
              of each month.
            </p>
            <p className="text-center p-2 background-nexus-blue">
              Approximate Hours of Daylight on the 15th Day of Each Month for
              Pittsburgh, Pennsylvania
            </p>
            <div className="mb-20 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-light">
                  <tr>
                    <th className="text-center align-middle">Month</th>
                    <th className="text-center align-middle">
                      Sunrise Time*
                      <p className="fw-500">(Hours: Minutes)</p>
                    </th>
                    <th className="text-center align-middle">
                      Sunset Time*
                      <p className="fw-500">(Hours: Minutes)</p>
                    </th>
                    <th className="text-center align-middle">
                      Total Hours of Daylight
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>January</td>
                    <td>7:40 a.m.</td>
                    <td>5:20 p.m.</td>
                    <td className="text-i">9 hours 40 minutes</td>
                  </tr>
                  <tr>
                    <td>February</td>
                    <td>7:10 a.m.</td>
                    <td>6:00 p.m.</td>
                    <td className="text-i">10 hours 50 minutes</td>
                  </tr>
                  <tr>
                    <td>March</td>
                    <td>6:30 a.m.</td>
                    <td>6:30 p.m.</td>
                    <td className="text-i">12 hours</td>
                  </tr>
                  <tr>
                    <td>April</td>
                    <td>5:40 a.m.</td>
                    <td>7:00 p.m.</td>
                    <td className="text-i">13 hours 20 minutes</td>
                  </tr>
                  <tr>
                    <td>May</td>
                    <td>5:00 a.m.</td>
                    <td>7:30 p.m.</td>
                    <td className="text-i">14 hours 30 minutes</td>
                  </tr>
                  <tr>
                    <td>June</td>
                    <td>4:50 a.m.</td>
                    <td>7:50 p.m.</td>
                    <td className="text-i">15 hours</td>
                  </tr>
                  <tr>
                    <td>July</td>
                    <td>5:10 a.m.</td>
                    <td>7:40 p.m.</td>
                    <td className="text-i">14 hours 30 minutes</td>
                  </tr>
                  <tr>
                    <td>August</td>
                    <td>5:30 a.m.</td>
                    <td>7:20 p.m.</td>
                    <td className="text-i">13 hours 50 minutes</td>
                  </tr>
                  <tr>
                    <td>September</td>
                    <td>6:00 a.m.</td>
                    <td>6:30 p.m.</td>
                    <td className="text-i">12 hours 30 minutes</td>
                  </tr>
                  <tr>
                    <td>October</td>
                    <td>6:30 a.m.</td>
                    <td>5:40 p.m.</td>
                    <td className="text-i">11 hours 10 minutes</td>
                  </tr>
                  <tr>
                    <td>November</td>
                    <td>7:00 a.m.</td>
                    <td>5:00 p.m.</td>
                    <td className="text-i">10 hours</td>
                  </tr>
                  <tr>
                    <td>December</td>
                    <td>7:30 a.m.</td>
                    <td>4:50 p.m.</td>
                    <td className="text-i">9 hours 20 minutes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14 mb-20">
              *Sunrise and sunset times (rounded to the nearest ten minutes)
              from gml.noaa.gov/grad/solcalc/sunrise.html
            </p>
            <p className="mb-10 text-nexus-blue fw-700">REPRESENT DATA</p>
            <p className="mb-20">
              Graph the total hours of daylight you calculated. Title your graph
              and label the axes.
            </p>
            <div className="mb-20 row justify-content-center">
              <div className="col-12 col-md-6">
                <img src="/image/g5-lesson3-image8.jpg"></img>
              </div>
            </div>
            <p className="mb-10 text-nexus-blue fw-700">ANALYZE DATA </p>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Which month had the most hours of daylight on the 15
                <sup>th</sup>? Which month had the fewest hours of daylight on
                the 15<sup>th</sup>?
              </span>
            </div>
            <p className="pl-15 text-i text-nexus-grey mb-20">
              July 15<sup>th</sup> had the most. December 15<sup>th</sup> had
              the fewest.
            </p>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                What patterns can you see in your data?
              </span>
            </div>
            <p className="mb-10 pl-15 text-i text-nexus-grey mb-20">
              From January to June the total hours of daylight increases. From
              June to December, it decreases.
            </p>
            <section className="mb-10 right-icon-text-group align-items-center">
              <div className="text flex-initial">
                <p className="fw-700 pr-10 text-nexus-blue">CHECKPOINT</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/formative_assessment_opportunity.svg"
                  alt="formative-assessment-opportunity"
                  title="formative-assessment-opportunity"
                ></img>
              </div>
            </section>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Based on what you learned, predict which month will have the
                most hours of daylight next year.
              </span>
            </div>
            <div className="d-flex ml-20 mb-10">
              <div className="mr-10 text-center">
                <p className="fw-700">January</p>
                <p className="fw-700">July</p>
              </div>
              <div className="mr-10 text-center">
                <p className="fw-700">February</p>
                <p className="fw-700">August</p>
              </div>
              <div className="mr-10 text-center">
                <p className="fw-700">March</p>
                <p className="fw-700">September</p>
              </div>
              <div className="mr-10 text-center">
                <p className="fw-700">April</p>
                <p className="fw-700">October</p>
              </div>
              <div className="mr-10 text-center">
                <p className="fw-700">May</p>
                <p className="fw-700">November</p>
              </div>
              <div className="text-center">
                <p className="text-i text-nexus-grey border rounded-pill fw-700">
                  June
                </p>
                <p className="fw-700">December</p>
              </div>
            </div>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                What causes the amount of daylight to change throughout the
                year?
              </span>
            </div>
            <div className="ml-15 mb-7 d-flex guidence-count">
              <span className="number pr-5 fw-700">a.</span>
              <span className="number-text">
                Earth sways back and forth on its axis as it revolves around the
                Sun.
              </span>
            </div>
            <div className="ml-15 mb-7 d-flex guidence-count">
              <span className="number pr-5 fw-700">b.</span>
              <span className="number-text">
                Earth revolves around the Sun in unpredictable ways. Scientists
                do not know what causes the change, they only know that it
                happens.
              </span>
            </div>
            <div className="ml-15 d-inline-flex guidence-count px-3 py-2 text-nexus-grey border rounded-pill">
              <span className="number pr-5 fw-700">c.</span>
              <span className="number-text">
                Earth is tilted at an angle that does not change. As Earth
                revolves around the Sun, the tilt causes part of Earth to lean
                toward or away from the Sun. The part of Earth that is tilted
                toward the Sun receives more hours of daylight.
              </span>
            </div>
          </section>
          <hr></hr>
          <section id="Lesson_3_Part_C">
            <p className="fw-700 mb-10">
              Science Notebook Teacher’s Version: Lesson 3, Part C
            </p>
            <p className="mb-10 text-nexus-blue fw-700">OBSERVE</p>
            <p className="mb-10 fw-700">
              Record any important observations you made during the
              demonstration.
            </p>
            <p className="mb-7 text-i text-nexus-grey">
              Students may record any of the following observations:
            </p>
            <ul className="mb-10 pl-30 text-nexus-grey text-i circle-listing">
              <li>There are different stars in space all around Earth.</li>
              <li>
                The stars do not move, Earth moves. It revolves around the Sun.
              </li>
              <li>
                When Earth was in one position, I could see the stars that are
                in front of us, away from the Sun.
              </li>
              <li>
                When Earth moved to a different position, I could no longer see
                those stars, but I could see the different stars that were in
                front of me.
              </li>
            </ul>
            <p className="mb-10 text-i text-nexus-grey">
              Note that in this lesson students have had experience only with
              stars that are opposite the Sun, so they may not draw any stars
              between Earth and the Sun. They will elaborate on this in Lesson
              4.
            </p>
            <p className="mb-10 text-nexus-blue fw-700">EXPLAIN</p>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">1.</span>
              <span className="number-text">
                Draw a model and use it to explain why we see different stars
                during the summer and the winter.
              </span>
            </div>
            <p className="mb-7 pl-15 text-i text-nexus-grey">
              Student models should include:
            </p>
            <ul className="mb-20 pl-45 text-nexus-grey text-i circle-listing">
              <li>The Sun</li>
              <li>
                Earth as it revolves around the Sun, most likely illustrated as
                four pictures and arrows that show its movement.
              </li>
              <li>
                Stars throughout the model, surrounding Earth in all directions.
              </li>
              <li>
                A reference to the stars that are visible at different positions
                in Earth’s revolution, likely illustrated as a colored area, an
                area divided by lines, or pointed out with labels.
              </li>
            </ul>
            <div className="row justify-content-center mb-15">
              <div className="col-12 col-md-7">
                <img src="/image/g5-lesson3-image9.jpg"></img>
              </div>
            </div>
            <p className="pl-20 mb-10 text-i text-nexus-grey">
              Look for students to demonstrate an understanding of the effect of
              Earth’s revolution on the position of the stars from a particular
              viewpoint. Students should write something like: Stars are all
              over in space, all around Earth. Stars do not move, but Earth
              does. It revolves around the Sun. As Earth revolves around the
              Sun, we see the stars that are within our viewpoint. This means we
              do not always see the same stars. We see different stars at
              different times in the year.
            </p>
            <div className="mb-10 fw-700 d-flex guidence-count">
              <span className="number pr-5">2.</span>
              <span className="number-text">
                Do we see different stars during the spring and fall? Use your
                model to explain your answer and why you think that.
              </span>
            </div>
            <p className="pl-20 text-i text-nexus-grey">
              Look for responses to apply all the science ideas students have
              learned in this lesson. Students should realize that we do see
              different stars during spring and fall. Their responses should
              include the idea that Earth is at different positions around the
              Sun in spring and fall, so different stars are in our viewpoint at
              each position.
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContainerGuide;
