import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P1_Prior_Knowledge() {
  return (
    <IdGeneratingComponent guideLineCount={"P1"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="number pr-5 fw-700 text-nexus-medium-blue">1.</span>
        <span className="number-text">
          <span className="fw-700">Connect to Prior Knowledge:</span> Connect
          back to Lesson 2 by asking:
        </span>
      </div>
      <ul className="pl-45 circle-listing">
        <li>What did we figure out about objects made of different pieces?</li>
        <li>What have we learned about the furnace? How do we know this?</li>
        <li>
          How would you describe what happens to the observable properties of
          glass once the pieces are placed in the oven? Can you use some of our
          earned words in your description?
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P1_Prior_Knowledge;
