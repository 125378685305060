import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P20_Prior_Knowledge_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P20"} seriesCount={"S2"}>
      <p className="mb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">2.</span>
        <span className="number-text">
          Display the Temperature Simulation. Click on the glass option. Say, “I
          thought about your ideas from last session, and I found this
          simulation that acts like the furnace from the anchoring phenomenon
          video.” Ask:
        </span>
      </p>
      <ul className="pl-45 circle-listing">
        <li>
          Could we use a simulation like this one to safely test the effects of
          higher temperatures?
        </li>
        <li>
          Does this simulation also let us test lower temperatures? How can you
          tell?
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default P20_Prior_Knowledge_S2;
