import React from "react";

function T1_Prior_Knowledge() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/formative_assessment_opportunity.svg"
              alt="formative-assessment-opportunity"
              title="formative-assessment-opportunity"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Think about what you learned in Lesson 2.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>
                  What did we figure out about objects made of different pieces?
                </li>
                <li>
                  What have we learned about the furnace? How do we know this?
                </li>
                <li>
                  How would you describe what happens to the observable
                  properties of glass once the pieces are placed in the oven?
                  Can you use some of our earned words in your description?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T1_Prior_Knowledge;
