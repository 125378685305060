import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G13_Engage_in_Sensemaking() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G13"} seriesCount={"S2"}>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/disciplinary_core_idea.svg"
              alt="disciplinary_core_idea"
              title="disciplinary_core_idea"
            ></img>
          </div>
          <span className="fw-700">NGSS Element:</span>{" "}
          <span className="text-nexus-orange fw-700">ESS1.B:</span> {""}
          This discussion helps students deepen their understanding of the
          following science ideas:
        </section>
        <ul className="pl-30 mb-10 circle-listing">
          <li>
            The length of the day gradually changes throughout the year as Earth
            orbits the Sun.
          </li>
          <li>
            We experience longer days in the summer (when Earth is tilted toward
            the Sun).
          </li>
          <li>
            We experience shorter days in the winter (when Earth is tilted away
            from the Sun).
          </li>
        </ul>
        <p>
          In grade 5, students analyze data from the Northern Hemisphere only.
          Understanding differences between the Northern and Southern
          Hemispheres is above grade band.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default G13_Engage_in_Sensemaking;
