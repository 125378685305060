import React from "react";

function T17_Connect_to_Prior_Knowledge_S1() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What have we discovered about Earth so far?</li>
                <li>How does that affect us?</li>
                <li>What did we decide to investigate next?</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T17_Connect_to_Prior_Knowledge_S1;
