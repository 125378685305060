import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P19_Engage_in_Sensemaking_S1() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P19"} seriesCount={"S1"}>
        <div className="mb-7 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">5.</span>
          <span className="number-text">
            <span className="fw-700">Engage in Sensemaking:</span> Encourage
            students to share their observations and explanations with the
            class. Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>
            What did you observe as we used our model?{" "}
            <span className="text-i">(Earth revolving around the Sun.)</span>
          </li>
          <li>
            How does Earth’s revolution affect which stars we can see throughout
            the year?{" "}
            <span className="text-i">
              (As Earth revolves around the Sun, different stars move in and out
              of view.)
            </span>
          </li>
          <li>
            How can we answer our lesson question, “Why do we see different
            stars in the summer than in the winter?”{" "}
            <span className="text-i">
              (The stars don’t move, but Earth does. As, Earth revolves around
              the Sun, different stars come into view.)
            </span>
          </li>
          <li>
            Do you think we also see different stars in the spring and fall?{" "}
            <span className="text-i">
              (Answers should reflect an understanding that we see different
              stars at different times of the year, or at every different point
              of Earth’s revolution.)
            </span>
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P19_Engage_in_Sensemaking_S1;
