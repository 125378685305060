import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P12_Collect_Represent_and_Analyze_Data_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P12"} seriesCount={"S3"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">6.</span>
          <span className="number-text">
            Invite groups to share their data with the class. Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>
            Which month had the most hours of daylight?{" "}
            <span className="text-i">(June)</span>
          </li>
          <li>
            Which month had the fewest?{" "}
            <span className="text-i">(December)</span>
          </li>
          <li>
            What patterns did you notice when you graphed the hours of daylight
            for the year?{" "}
            <span className="text-i">
              (From January to June the daylight hours increase, and then from
              July to December, they decrease.)
            </span>
          </li>
          <li>
            Why do you think that is?{" "}
            <span className="text-i">(Accept all answers at this time.)</span>
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P12_Collect_Represent_and_Analyze_Data_S3;
