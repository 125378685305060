import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P5_Ask_Questions_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"P5"} seriesCount={"S2"}>
      <p className="mb-10 d-flex guidence-count">
        <span className="number fw-700 pr-5 text-nexus-medium-blue">10.</span>
        <span className="number-text">
          Guide students to brainstorm ways they could test their initial ideas
          about what the pole is made of.
        </span>
      </p>
      <p className="pl-25 mb-7">Ask:</p>
      <ul className="pl-50 mb-10 circle-listing">
        <li>What are some types of matter other than glass?</li>
        <li>
          Do we know how different temperatures affect other types of matter?
        </li>
        <li>
          How could we investigate to gather evidence about how other types of
          matter are affected by temperature?
        </li>
      </ul>
      <p className="pl-25">
        Record students’ ideas on the Testing Our Ideas Class Chart.
      </p>
    </IdGeneratingComponent>
  );
}

export default P5_Ask_Questions_S2;
