import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G18_Make_Personal() {
  return (
    <IdGeneratingComponent guideLineCount={"G18"}>
      {/*  <p className="text-i">Intentionally blank</p> */}
    </IdGeneratingComponent>
  );
}

export default G18_Make_Personal;
