import React from "react";

function T5_Ask_Questions_S1() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 mb-20 font-16">
              Develop a question to investigate your initial ideas.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>What are we trying to figure out?</li>
                <li>
                  What question could we investigate to figure out how different
                  types of matter are affected by temperature?
                </li>
                <li>
                  How will this help us figure out what type of matter the pole
                  is made from and why it didn't melt?”
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T5_Ask_Questions_S1;
