import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function T2_Make_Observations_S1() {
  const [lgShow, setLgShow] = useState(false);
  const [updatestate, setUpdatedState] = useState(0);

  const setSelectedPage = (index) => {
    setUpdatedState(index);
  };

  const ImageURl = [
    {
      original: "/image/summer-and-winter-night-skies.png",
      originalTitle: "summer-and-winter-night-skies",
    },
  ];
  return (
    <>
      <div className="lesson-brief">
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <p>What do you notice when you compare these pictures?</p>
        </div>
        <div className="row justify-content-center mb-20 p-2">
          {/* <div className="col-12 col-md-9">
            <div className="mb-20 shadow">
              <img
                src="/image/summer-and-winter-night-skies.png"
                alt="summer-and-winter-night-skies"
                title="summer-and-winter-night-skies"
              ></img>
            </div>
          </div> */}
          <div className="carousel-img position-relative">
            <div>
              <img
                src="/image/summer-and-winter-night-skies.png"
                alt="summer-and-winter-night-skies"
                title="summer-and-winter-night-skies"
              ></img>
            </div>
            <Tooltip title="Enlarge View" placement="top">
              <span
                onClick={() => setLgShow(true)}
                className="plus-icon position-absolute"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_27_2)">
                    <path
                      d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_27_2">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </Tooltip>
            <Modal
              size="xl modal-dialog-centered"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={true}
                  useBrowserFullscreen={false}
                  items={ImageURl}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <section className="icon-text-group mb-20">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Record your observations and questions in your notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img
                src="/image/g5_space_notebookL3_forpoc_6-15-234_page_06.png"
                alt="g5_space_notebookL3_forpoc_6-15-234_page_06"
                title="g5_space_notebookL3_forpoc_6-15-234_page_06"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T2_Make_Observations_S1;
