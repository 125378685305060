import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P17_Connect_to_Prior_Knowledge_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P17"} seriesCount={"S2"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">2.</span>
          <span className="number-text">
            Project the Summer and Winter Night Skies image. Say, “When we saw
            these pictures at the beginning of this lesson, we had some ideas
            about what was happening.” Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>
            Do you think what we have learned so far helps us better understand
            what is happening in these pictures? Why or why not?{" "}
            <span className="text-i">(Accept all answers at this time.)</span>
          </li>
          <li>
            Do we have enough evidence to confidently and accurately explain
            what we see? <span className="text-i">(No)</span>
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P17_Connect_to_Prior_Knowledge_S2;
