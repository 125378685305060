import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P12_Collect_Represent_and_Analyze_Data_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P12"} seriesCount={"S2"}>
        <div className="mb-10 d-flex guidence-count">
          <span className="number pr-5 fw-700 text-nexus-medium-blue">5.</span>
          <span className="number-text">(continued)</span>
        </div>
        <p className="pl-20 mb-10">
          <span className="fw-700">Collect, Represent and Analyze Data:</span>{" "}
          Next, students should answer the questions in the Analyze Data section
          of their notebooks (page 37) independently.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P12_Collect_Represent_and_Analyze_Data_S2;
