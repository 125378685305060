import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function T4_Investigate_S1() {
  const [numbercount, setnumber] = useState(0);
  const [lgShow, setLgShow] = useState(false);

  const ImageURl = [
    {
      original: "/image/solar-img1.png",
    },
  ];
  const ImageURl1 = [
    {
      original: "/image/solar-img2.png",
    },
  ];
  const ImageURl2 = [
    {
      original: "/image/solar-img3.png",
    },
  ];
  const ImageURl3 = [
    {
      original: "/image/solar-img4.png",
    },
  ];

  return (
    <>
      <div className="lesson-brief">
        <p className="mb-20">Explore the interactive.</p>
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <p className="text-center mb-20 fw-700 ">
            What Is In Our Solar System?
          </p>
          <div className="container text-center">
            <div className="row row-cols-2">
              <div className="col p-2 position-relative solar-img">
                <img src="/image/solar-img1.png"></img>
                <Tooltip title="Enlarge View" placement="top">
                  <span
                    onClick={() => {
                      setnumber(1);
                      setLgShow(true);
                    }}
                    className="plus-icon-1 position-absolute"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_27_2)">
                        <path
                          d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_27_2">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Tooltip>
              </div>
              <div className="col p-2 position-relative solar-img">
                <img src="/image/solar-img2.png"></img>
                <Tooltip title="Enlarge View" placement="top">
                  <span
                    onClick={() => {
                      setnumber(2);
                      setLgShow(true);
                    }}
                    className="plus-icon-2 position-absolute"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_27_2)">
                        <path
                          d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_27_2">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Tooltip>
              </div>
              <div className="col p-2 position-relative solar-img">
                <img src="/image/solar-img3.png"></img>
                <Tooltip title="Enlarge View" placement="top">
                  <span
                    onClick={() => {
                      setnumber(3);
                      setLgShow(true);
                    }}
                    className="plus-icon-1 position-absolute"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_27_2)">
                        <path
                          d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_27_2">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Tooltip>
              </div>
              <div className="col p-2 position-relative solar-img">
                <img src="/image/solar-img4.png"></img>
                <Tooltip title="Enlarge View" placement="top">
                  <span
                    onClick={() => {
                      setnumber(4);
                      setLgShow(true);
                    }}
                    className="plus-icon-2 position-absolute"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_27_2)">
                        <path
                          d="M24 10H14V0H10V10H0V14H10V24H14V14H24V10Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_27_2">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Complete Step 1 of the Investigate section of your notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="shadow">
              <img src="/image/g5_space_notebookl3_forpoc_6-15-234_page_08.png"></img>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg modal-dialog-centered"
        className="modal-dialog-centered "
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {numbercount === 1 ? (
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={false}
              items={ImageURl}
            />
          ) : null}
          {numbercount === 2 ? (
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={false}
              items={ImageURl1}
            />
          ) : null}
          {numbercount === 3 ? (
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={false}
              items={ImageURl2}
            />
          ) : null}
          {numbercount === 4 ? (
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={false}
              items={ImageURl3}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default T4_Investigate_S1;
