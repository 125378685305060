import React from "react";
import CanvasDraw from "../../../../Common/CanvasComponent";
function T4_Investigate_S3() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Share your observations and ideas with the class.
            </p>
          </div>
        </section>
        <div className="drawing-section mb-20">
          <CanvasDraw
            canvasImageFileId={"canvasstr_6_1"}
            seq_nbr={1}
            img_seq_nbr={1}
          />
        </div>
      </div>
    </>
  );
}

export default T4_Investigate_S3;
