import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P14_Make_Observations_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P14"} seriesCount={"S1"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">11.</span>
          <span className="font-16">
            Explain that students will watch a video of the investigation they
            planned to see what happens to different types of matter when they
            are heated by a clamp lamp. Explain that one object has been
            selected to represent the plastic, metal, food, wax, and wood
            objects they originally chose. Have students locate the Heating
            Matter data table in their science notebooks (page 7). Explain that
            they will record their observations of each object before and after
            it is heated.
          </span>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default P14_Make_Observations_S1;
