import React from "react";

function T23_Analyze_Data() {
  return (
    <>
      <div className="lesson-brief">
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/notebook.svg"
              alt="notebook"
              title="notebook"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              With a partner, complete the Make Connections table and the Final
              Ideas prompts in your science notebook.
            </p>
          </div>
        </section>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_11.png" />
            </div>
          </div>
          <div className="col-6">
            <div className="shadow">
              <img src="/image/lesson-images/G2_Glassblowing_Notebook_POC_Page_12.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default T23_Analyze_Data;
