import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G31_Engage_Argument_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G31"} seriesCount={"S2"}>
      <section className="text-wrapping-right mb-20">
        <div className="icon icon-big">
          <img
            src="/image/lesson-images/settings.svg"
            alt="settings"
            title="settings"
          ></img>
        </div>
        <span className="fw-700">NGSS Elements:</span>{" "}
        <span className="fw-700 text-nexus-blue">
          Engaging in Argument from Evidence,
        </span>{" "}
        <span className="fw-700 text-nexus-orange">PS1.B,</span>{" "}
        <span className="fw-700 text-nexus-green">Cause and Effect:</span> As
        students complete the sentence stems, they construct an argument with
        the evidence they collected during their investigations. Look for
        arguments to describe how changes in temperature affect different types
        of matter.
      </section>
      <section className="mb-20 guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            ELA Connection <span className="text-nexus-lightgrey">(A,B,C)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p className="">
            As students construct their arguments, they recall and gather
            information from their investigations to answer the question, “How
            does temperature affect matter?” (W.2.8)
          </p>
        </div>
      </section>
      <section className="right-icon-text-group">
        <div className="text">
          <p>
            <span className="fw-700">Summative Assessment: </span>
            The My Argument: How Does Temperature Affect Matter? section of the
            science notebook (page 16) can be used as a summative assessment of
            performance expectation 2-PS-1-4. Review students' evidence-based
            arguments to determine if they understand that some changes to
            matter caused by heating or cooling can be reversed and some cannot.
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G31_Engage_Argument_S2;
