import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G37_Engage_Sensemaking() {
  return (
    <IdGeneratingComponent guideLineCount={"G37"}>
      <section className="pb-7 text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/disciplinary_core_idea.svg"
            alt="disciplinary_core_idea"
            title="disciplinary_core_idea"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-orange fw-700">PS1.B:</span> The purpose of
        this discussion is for students to connect the science ideas they
        uncovered during this lesson to the investigative phenomenon. Listen for
        students to determine that:
      </section>
      <ul className="pl-30 circle-listing">
        <li>
          The pole is made of metal since metal melts at a higher temperature
          than glass.
        </li>
        <li>
          The glass begins to cool down and change back into an inflexible solid
          when it is removed from the furnace. To keep it flexible, the
          glassblower must return the glass to the furnace again and again.
        </li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G37_Engage_Sensemaking;
