import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P16_Make_Observations() {
  return (
    <IdGeneratingComponent guideLineCount={"P16"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/notebook.svg"
            alt="notebook"
            title="notebook"
          ></img>
        </div>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">15.</span>
          <span className="font-16">
            <span className="fw-700">Make Observations:</span> Select and play
            the Cooling video. Encourage students to focus on the observable
            changes to each type of matter. Play the video a second time, if
            needed.
          </span>
        </div>
      </section>
      <p className="pl-25 mb-10">
        Prompt students to record their observations in the Observations After
        Cooling column of the table (page 9).
      </p>
      <p className="pl-25">
        Next, have them answer the questions in the Analyzing My Data section
        individually (page 10).
      </p>
    </IdGeneratingComponent>
  );
}

export default P16_Make_Observations;
