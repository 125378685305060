const LessonResourcesData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How do objects change into other objects?",
    Resources: [
      {
        ResourcesName: "Lesson Resource 3.1",
        type: "pdf",
        ImageUrl: "/image/lesson_resource_tile.png",
        PdfUrl: "/pdf/g2_glass_l3_lesson_resource_3_1.pdf",
      },
      {
        ResourcesName: "Lesson 3: Revisiting the Furnace",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/nex_gr2_glassblowing_revisit_furnace_video.mp4",
      },
      {
        ResourcesName: "Lesson 3: Cooling Matter",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/nex_gr2_glassblowing_coolingmatter_video.mp4",
      },
      {
        ResourcesName: "Lesson 3: Heating Matter",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/nex_gr2_glassblowing_heatingmatter_video.mp4",
      },
      {
        ResourcesName: "Lesson 3, Part A – Initial Ideas",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_partA_initialideas.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Our Plan",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_partB_ourplan.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Checkpoint",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_parta_checkpoint.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part B – Heating Matter",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_partb_heatingmatter.pdf",
      },
    ],
  },
];

export default LessonResourcesData;
