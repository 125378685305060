import React, { useEffect, useState } from "react";
import FormikQuill from "../../../../Common/FormikQuill";

function T13_Prior_Knowledge() {
  const LessonData = {
    stt_13_1: "",
  };

  const [data, setData] = useState(LessonData);
  const onBlur = () => {};

  useEffect(() => {
    var fetch_data = localStorage.getItem("Lesson_Data");

    if (fetch_data != null || undefined) {
      const Lesson_Data = JSON.parse(localStorage.getItem("Lesson_Data"));
      setData(Lesson_Data);
    } else {
      localStorage.setItem("Lesson_Data", JSON.stringify(data));
    }
  }, []);

  const UpdateText = (name, val) => {
    name == "stt_13_1" && setData({ ...data, ["stt_13_1"]: val });
  };

  useEffect(() => {
    localStorage.setItem("Lesson_Data", JSON.stringify(data));
  }, [data]);
  return (
    <>
      <div className="lesson-brief">
        <p className="mb-20">
          Review the investigation plan from last session.
        </p>
        <div className="table-responsive">
          <table className="table table-bordered m-0">
            <thead className="table-light">
              <tr>
                <th className="p-0   text-center align-middle">
                  <img
                    src="/image/lesson-images/Our_Plan_Class_Chart.svg"
                    alt="Our Plan Class Chart"
                    title="Our Plan Class Chart"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-50">
                  <div className="text-to-speech-section text-to-speech-section-full">
                    <div className="text-to-speech-box">
                      <div className="text-to-speech-section-box left border-0">
                        <div className="text-to-speech-box-content p-0">
                          <div className="notepad-outer">
                            <div className="notepad-content table-break-line">
                              {["stt_13_1"].map((name) => (
                                <FormikQuill
                                  displayTextBarLocation={"top"}
                                  id={"stt_13_1"}
                                  name={name}
                                  onChange={UpdateText}
                                  onBlur={onBlur}
                                  value={data["stt_13_1"]}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default T13_Prior_Knowledge;
