import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P7_Earn_Words_S2() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P7"} seriesCount={"S2"}>
        <div className="d-flex guidence-count mb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">14.</span>
          <span className="number-text">
            Prompt students to stand beside their desks. Ask:
          </span>
        </div>
        <ul className="pl-55 mb-10 circle-listing">
          <li>
            If your desk was the Sun and you were Earth, what motion could you
            make to represent the ideas of orbit and revolution?{" "}
            <span className="text-i">
              (Students should walk in a circle around their desks.)
            </span>
          </li>
          <li>
            Can you describe your movement using the words “orbit” and
            “revolution”?
          </li>
          <li>How are these two words different? How are they related?</li>
        </ul>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">15.</span>
          <span className="number-text">
            <span className="fw-700">Earn Words:</span> After students come up
            with a motion, add the terms “orbit” and “revolution” to the Word
            Wall. Students have now earned these words and can begin to
            integrate them into their everyday language.
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P7_Earn_Words_S2;
