import React from "react";

function T11_Develop_Initial_Ideas_S2() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mb-15 mt-5 font-16">
              Share your ideas with a partner.
            </p>
            <div className="p-3 dashed-border-nexus-blue light-blue-section">
              <ul className="pl-20 circle-listing">
                <li>How do you experience the Sun on Earth every day?</li>
                <li>What effects does the Sun have on Earth every day?</li>
                <li>
                  Do any of these effects seem to change throughout the year?
                  Why do you think that?
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default T11_Develop_Initial_Ideas_S2;
