import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G1_Connect_to_Prior_Knowledge() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G1"}>
        <section className="text-wrapping-right">
          <span className="mb-10 fw-700">
            Formative Assessment Opportunity:
          </span>{" "}
          Students should mention that the stars are at different distances from
          Earth, which is one reason why some stars look bigger or brighter than
          other stars.
        </section>
      </IdGeneratingComponent>
    </>
  );
}

export default G1_Connect_to_Prior_Knowledge;
