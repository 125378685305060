import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function P20_Prior_Knowledge_S1() {
  return (
    <IdGeneratingComponent guideLineCount={"P20"} seriesCount={"S1"}>
      <div className="pb-7 d-flex guidence-count">
        <span className="fw-700 number pr-5 text-nexus-medium-blue">1.</span>
        <span className="number-text">
          <span className="fw-700">Connect to Prior Knowledge:</span> As a
          class, review what students figured out during the last session. Ask:
        </span>
      </div>
      <ul className="pl-45 pb-10 circle-listing">
        <li>
          What did we figure out so far about how temperature affects different
          types of matter?
        </li>
        <li>What did we decide we still need to figure out?</li>
      </ul>
      <p className="pl-20">
        Say, “It sounds like we know that temperature can affect some types of
        matter by changing them into a solid or liquid. We are not sure if
        temperature can affect plastic, wood, or metal. We need to gather more
        information about the effects of very high and low temperatures on these
        types of matter.”
      </p>
    </IdGeneratingComponent>
  );
}

export default P20_Prior_Knowledge_S1;
