import React, { useState, useEffect } from "react";
import PartA from "./PartA";
import PartB from "./PartB";

function Containerprep() {
  const [isActiveScroll, setIsActiveScroll] = useState(0);
  const [scrollhide, setScrollHide] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const sections = document.querySelectorAll("section[id]");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight * 0.2 &&
          scrollPosition < sectionTop + sectionHeight * 0.2
        ) {
          setIsActiveScroll(section.id);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div className="two-column-content">
        <aside className="table-of-content-menu">
          <nav className="sidebar card accordion-menu-listings">
            <ul className="nav flex-column" id="nav_accordion">
              <li className="level-one">
                <a
                  onClick={() => setScrollHide(2)}
                  className="nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse3"
                  aria-expanded="true"
                >
                  Lesson 3: How does temperature affect other types of matter?
                  <i className="bi small bi-caret-down-fill"></i>{" "}
                </a>
                <div className="collapse show" id="home-collapse3">
                  <ul className="mt-15 nav flex-column submenu btn-toggle-navl">
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "performance"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#performance"
                      >
                        Performance Expectations
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "elements"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#elements"
                      >
                        Elements Developed in This Lesson
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "lessonsummary"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#lessonsummary"
                      >
                        Lesson Summary
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "teacherbackground"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#teacherbackground"
                      >
                        Teacher Background
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "priorconceptions"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#priorconceptions"
                      >
                        Prior Conceptions
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "earnedwords"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#earnedwords"
                      >
                        Earned Words
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        onClick={() => setScrollHide(0)}
                        className={
                          isActiveScroll === "materiallist"
                            ? "nav-link active fw-700"
                            : "nav-link"
                        }
                        href="#materiallist"
                      >
                        Materials List
                      </a>
                    </li>
                    <li className="level-two">
                      <a
                        className={
                          scrollhide === 1 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setScrollHide(1)}
                        data-bs-toggle="collapse"
                        data-bs-target="#dashboard-collapse1"
                        aria-expanded={scrollhide === 1 ? "true" : "false"}
                      >
                        Materials and Preparation{" "}
                        <i className="bi small bi-caret-down-fill"></i>
                      </a>
                      <div
                        className={
                          scrollhide === 1 ? "collapse show" : "collapse"
                        }
                        id="dashboard-collapse1"
                      >
                        <ul className="nav flex-column submenu btn-toggle-nav">
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "parta"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#parta"
                            >
                              Part A
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partb"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partb"
                            >
                              Part B
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partc"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partc"
                            >
                              Part C
                            </a>
                          </li>
                          <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "partd"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#partd"
                            >
                              Part D
                            </a>
                          </li>
                          {/*   <li className="level-three">
                            <a
                              className={
                                isActiveScroll === "supporting"
                                  ? "nav-link active fw-700"
                                  : "nav-link"
                              }
                              href="#supporting"
                            >
                              Supporting All Learners
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </aside>
        <div className="pr-15 pt-15 table-of-content">
          <section className="mb-20 lesson-banner">
            <img
              src="/image/lesson3_image2.jpg"
              alt="HOW DOES TEMPERATURE AFFECT OTHER TYPES OF MATTER?"
              title="HOW DOES TEMPERATURE AFFECT OTHER TYPES OF MATTER?"
            ></img>
          </section>
          <h5 className="fw-700 text-nexus-white mb-20">
            <span className="gradient-title p-2 pr-40">
              6 sessions of 30 minutes
            </span>
          </h5>
          <section id="performance">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PERFORMANCE</span>{" "}
              <span className="fw-500">EXPECTATIONS</span>
            </h5>
            <p className="fw-700 mb-10">
              Lesson-Level Performance Expectations
            </p>
            <p className="mb-7">In this lesson, students will:</p>
            <ul className="pl-30 circle-listing">
              <li>
                <span className="text-nexus-dark-blue">
                  Plan and conduct an investigation to produce data
                </span>{" "}
                on how a{" "}
                <span className="text-nexus-orange">change in temperature</span>{" "}
                can <span className="text-nexus-green">cause</span> the{" "}
                <span className="text-nexus-orange">
                  observable properties of some types of matter to change.
                </span>{" "}
                (Part B)
              </li>
              <li>
                <span className="text-nexus-dark-blue">
                  Make observations to collect and compare data to describe the
                </span>{" "}
                <span className="text-nexus-green">effect</span>{" "}
                <span className="text-nexus-dark-blue">
                  {" "}
                  of temperature on the
                </span>{" "}
                <span className="text-nexus-orange">
                  observable properties of different types of matter.
                </span>{" "}
                (Part C)
              </li>
              <li>
                <span className="text-nexus-dark-blue">
                  Construct an evidence-based argument
                </span>{" "}
                that describes how the{" "}
                <span className="text-nexus-orange">changes to matter</span>{" "}
                <span className="text-nexus-green">caused</span>{" "}
                <span className="text-nexus-orange">
                  by heating and cooling can sometimes be reversed.
                </span>{" "}
                (Part C)
              </li>
            </ul>
          </section>
          <hr></hr>
          <div id="towards">
            <p className="fw-700 mb-10">
              Building Toward NGSS Performance Expectations
            </p>
            <p className="mb-7">
              The NGSS Performance Expectations set learning goals for what
              students should be able to do by the end of a grade band. This
              lesson builds toward the following Performance Expectations:
            </p>
            <ul className="mb-20 pl-30 circle-listing">
              <li>
                <span className="fw-700">2-PS1-1:</span> Plan and conduct an
                investigation to describe and classify different kinds of
                materials by their observable properties.
              </li>
              <li>
                <span className="fw-700">2-PS1-4:</span> Construct an argument
                with evidence that some changes caused by heating or cooling can
                be reversed and some cannot.
              </li>
            </ul>
            <section className="lesson-banner">
              <img
                src="/image/lesson3_image3.png"
                alt="Building Toward NGSS Performance Expectations"
                title="Building Toward NGSS Performance Expectations"
              ></img>
            </section>
          </div>
          <hr></hr>
          <section id="elements" className="">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">ELEMENTS</span>{" "}
              <span className="fw-500">DEVELOPED IN THIS LESSON</span>
            </h5>
            <p className="mb-10">
              When developing an element, students build on their previous
              knowledge and experience to make progress toward grade-band
              expectations for the element. These elements are what make up the
              Lesson-Level Performance Expectations.
            </p>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle background-nexus-orange w-25">
                      <p>Disciplinary Core Ideas</p>
                      <p className="fw-500">
                        Fundamental scientific ideas that form content
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-darkblue w-25">
                      <p>Science and Engineering Practices</p>
                      <p className="fw-500">
                        What students do to make sense of phenomena
                      </p>
                    </th>
                    <th className="text-center align-middle background-nexus-green w-25">
                      <p>Crosscutting Concepts</p>
                      <p className="fw-500">
                        A way of linking the different domains of science
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nexus-orange">
                      <span className="fw-700">
                        PS1.A: Structure and Properties of Matter:
                      </span>{" "}
                      Different kinds of matter exist and many of them can be
                      either solid or liquid, depending on temperature. Matter
                      can be described and classified by its observable
                      properties.
                    </td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Planning and Carrying Out Investigations:
                      </span>{" "}
                      Plan and conduct an investigation collaboratively to
                      produce data to serve as the basis for evidence to answer
                      a question.
                    </td>
                    <td className="text-nexus-green">
                      <span className="fw-700">Cause and Effect:</span> Events
                      have causes that generate observable patterns.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nexus-orange">
                      <span className="fw-700">PS1.B: Chemical Reactions:</span>{" "}
                      Heating or cooling a substance may cause changes that can
                      be observed. Sometimes these changes are reversible and
                      sometimes they are not.
                    </td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Planning and Carrying Out Investigations:
                      </span>{" "}
                      Make observations (firsthand or from media){" "}
                      <span className="text-decoration-line-through">
                        and/or make measurements
                      </span>{" "}
                      to collect data that can be used to make comparisons.
                    </td>
                    <td className="text-nexus-green"></td>
                  </tr>
                  <tr>
                    <td className="text-nexus-orange"></td>
                    <td className="text-nexus-dark-blue">
                      <span className="fw-700">
                        Engaging in Argument from Evidence:
                      </span>{" "}
                      Construct an argument with evidence to support a claim.
                    </td>
                    <td className="text-nexus-green"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">
              * The parts of each element that are crossed out are not addressed
              in the lesson.
            </p>
          </section>
          <hr></hr>
          <section id="lessonsummary">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">LESSON</span>{" "}
              <span className="fw-500">SUMMARY</span>
            </h5>
            <p>
              This lesson is driven by students’ questions related to their
              ideas about the furnace, including why the glassblower keeps
              putting the glass back into the furnace and why the pole doesn’t
              melt like the pieces of glass do. Students gather evidence to help
              answer these questions when they engage in investigations that
              focus on why{" "}
              <span className="text-nexus-orange">
                some types of matter change when heated or cooled.
              </span>{" "}
              They{" "}
              <span className="text-nexus-dark-blue">
                use their observations as evidence to construct an argument
              </span>{" "}
              to explain the <span className="text-nexus-green">effect</span> of
              temperature on objects made of different types of matter and
              whether or not these{" "}
              <span className="text-nexus-orange">changes can be undone.</span>
            </p>
          </section>
          <hr></hr>
          <section id="teacherbackground">
            <div className="row">
              <div className="col-12 col-md-10 mb-10">
                <h5 className="fw-700 mb-10 text-decoration-underline">
                  <span className="text-nexus-blue">TEACHER</span>{" "}
                  <span className="fw-500">BACKGROUND</span>
                </h5>
                <p className="mb-10">
                  Nearly all matter has a temperature at which it changes. The
                  most common change is a change in state. (Students are not
                  expected to use or learn the term “state” in this unit.) Many
                  types of matter can be solid, liquid, or gas at different
                  temperatures. In many instances, matter at high temperatures
                  is liquid or gas, and matter at cooler temperatures is solid.
                  In some instances, a higher temperature may result in a more
                  flexible solid, such as glass and melted cheese. Students will
                  explore and identify only liquids and solids in this unit.
                  They will progress in their understanding of matter in grade 5
                  when they learn about gases.
                </p>
              </div>
              <div className="col-12 col-md-2 mb-10">
                <img
                  src="/image/lesson3_image4.jpg"
                  alt="TEACHER BACKGROUND"
                  title="TEACHER BACKGROUND"
                ></img>
              </div>
            </div>
            <p className="mb-10">
              Some of the changes that occur can be reversed. Think of water,
              for example. It can be cooled from a liquid into solid ice, warmed
              back up into a liquid, and cooled again into ice. Many examples of
              melting are reversible, including melting chocolate, butter, gold,
              and ice cream, although these substances may not return to their
              original form or shape when the change is reversed. Other changes
              caused by temperature are irreversible, including burning a
              marshmallow, cooking an egg, or popping popcorn.
            </p>
            <p>
              Changes to some items, including glass, can be confusing. When
              glass breaks, it is considered an irreversible change because
              those pieces cannot be put back together into their original form.
              On the other hand, the heating of glass is usually considered a
              reversible process because the pieces can be melted together again
              and again and shaped into any object.
            </p>
          </section>
          <hr></hr>
          <section id="priorconceptions">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">PRIOR</span>{" "}
              <span className="fw-500">CONCEPTIONS</span>
            </h5>
            <p className="mb-10">
              Students may come to this lesson with preconceptions or
              misconceptions around the science ideas presented in this unit.
              Such ideas will be addressed as part of the student sensemaking
              process throughout this unit. Listed below are misconceptions you
              are likely to hear from your students in this lesson. The correct
              science idea follows in parenthesis.
            </p>
            <p className="mb-7">
              This lesson will address the following misconceptions:
            </p>
            <ul className="pl-30 circle-listing">
              <li>
                Solids are matter, but liquids are not.{" "}
                <span className="text-i">
                  (All things, big and small, are made of matter. This includes
                  all solids and liquids. Some students may know that gas is
                  also made of matter, but this is a grade 5 idea.)
                </span>
              </li>
              <li>
                When matter changes from solid to liquid, it becomes a new type
                of matter.{" "}
                <span className="text-i">
                  (When matter changes from solid to liquid or liquid to solid,
                  its observable properties change, but the type of matter
                  remains the same [e.g., When solid ice warms and changes to
                  liquid water, it is still water].)
                </span>
              </li>
              <li>
                All changes to matter are permanent.{" "}
                <span className="text-i">
                  (Most matter can undergo change. Some changes cannot be
                  undone, like frying an egg or burning a piece of paper. Other
                  changes can be undone, such as melting an ice cube and
                  refreezing it or melting gold and reshaping it into a new
                  piece of jewelry.)
                </span>
              </li>
              <li>
                All changes to matter can be undone.{" "}
                <span className="text-i">
                  (Most matter can undergo change. Some changes cannot be
                  undone, like frying an egg or burning a piece of paper. Other
                  changes can be undone, such as melting an ice cube and
                  refreezing it or melting gold and reshaping it into a new
                  piece of jewelry.)
                </span>
              </li>
            </ul>
          </section>
          <hr></hr>
          <section id="earnedwords">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">EARNED</span>{" "}
              <span className="fw-500">WORDS</span>
            </h5>
            <p className="mb-10">
              A word is earned on the Word Wall when the class demonstrates a
              strong understanding of that key term. Until a word is earned,
              encourage students to use everyday language in discussions and
              work products. Opportunities to earn words are called out in the
              lesson procedure.
            </p>
            <p className="mb-10">
              By the end of this lesson, students will earn the following key
              terms for the Word Wall:
            </p>
            <div className="d-flex justify-content-center">
              <p className="font-20 mr-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Irreversible
              </p>
              <p className="font-20 fw-700">
                <span className="text-nexus-blue pr-10">✓</span> Reversible
              </p>
            </div>
          </section>
          <hr></hr>
          <section id="materiallist">
            <h5 className="fw-700 mb-10 text-decoration-underline">
              <span className="text-nexus-blue">MATERIALS</span>{" "}
              <span className="fw-500">LIST</span>
            </h5>
            <div className="mb-10 table-responsive">
              <table className="table table-bordered border-secondary tab m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part A</th>
                    <th className="text-center align-middle w-50">Part B</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="mb-20 pl-30 circle-listing">
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Revisiting the Furnace video</li>
                            <li>Initial Ideas Class Chart</li>
                            <li>Testing Our Ideas Class Chart</li>
                          </ul>
                        </li>
                        <li>32 Science Notebooks*</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="mb-20 pl-30 circle-listing">
                        <li>
                          5 Lesson Resource 3.1:{" "}
                          <span className="text-i">
                            Observable Properties Checklist<sup>*</sup>
                          </span>
                        </li>
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              Heating and Cooling Different Types of Matter
                              videos
                            </li>
                            <li>Our Plan Class Chart</li>
                          </ul>
                        </li>
                        <li>
                          Food objects:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              1 TBSP Butter<sup>‡</sup>
                            </li>
                            <li>
                              5 Chocolate chips<sup>‡</sup>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Metal objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              3 Coins<sup>‡</sup>
                            </li>
                            <li>
                              3 Paper clips<sup>‡</sup>
                            </li>
                            <li>2 Washers</li>
                          </ul>
                        </li>
                        <li>
                          Plastic objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>
                              3 Marker caps<sup>‡</sup>
                            </li>
                            <li>
                              2 Plastic forks<sup>‡</sup>
                            </li>
                          </ul>
                        </li>
                        <li>32 Science notebooks*</li>
                        <li>
                          Wax objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>2 Candles</li>
                            <li>
                              5 Crayons<sup>‡</sup>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Wood objects
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>2 Craft sticks</li>
                            <li>
                              2 Pencils<sup>‡</sup>
                            </li>
                            <li>2 Wood blocks</li>
                          </ul>
                        </li>
                        {/* <li>32 Science Notebooks*</li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-secondary m-0">
                <thead className="table-dark">
                  <tr>
                    <th className="text-center align-middle w-50">Part C</th>
                    <th className="text-center align-middle w-50">Part D</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          32 Devices (tablets, laptops, etc.)<sup>‡</sup>
                        </li>
                        <li>
                          Chart paper<sup>‡</sup>
                        </li>
                        <li>
                          Number Line<sup>†</sup>
                        </li>
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Temperature Simulation</li>
                          </ul>
                        </li>
                        <li>
                          Markers<sup>‡</sup>
                        </li>
                        <li>32 Science notebooks*</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="pl-30 circle-listing">
                        <li>
                          Digital Lesson 3*:
                          <ul className="mt-5 pl-30 circle-listing">
                            <li>Initial Ideas Class Chart (from Part A)</li>
                          </ul>
                        </li>
                        <li>
                          1 Pair of scissors<sup>‡</sup>
                        </li>
                        <li>
                          1 Rubber band<sup>‡</sup>
                        </li>
                        <li>32 Science notebooks*</li>
                        <li>
                          1 Sheet of paper<sup>‡</sup>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="font-14">*Accessible online</p>
            <p className="font-14">† Prepared by the teacher</p>
            <p className="font-14">‡ Needed but not supplied</p>
          </section>
          <hr></hr>
          <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
            MATERIALS AND PREPARATION
          </p>
          <section id="parta">
            <PartA />
          </section>
          <hr></hr>
          <section id="partb">
            <PartB />
          </section>
          <hr></hr>
          <section id="partc">
            <div className="lesson3-parts parta">
              <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
                PART C: INVESTIGATE HIGHER AND LOWER TEMPERATURES
              </p>
              <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
                <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
                  MATERIALS FOR PART C
                </h5>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                      TEACHER
                    </p>
                    <ul className="pl-20 circle-listing">
                      <li>
                        Chart paper<sup>‡</sup>
                      </li>
                      <li>
                        Digital Lesson 3*:
                        <ul className="pt-5 pl-20 circle-listing">
                          <li>Temperature Simulation</li>
                        </ul>
                      </li>
                      <li>
                        Markers<sup>‡</sup>
                      </li>
                      <li>
                        1 Pair of scissors<sup>‡</sup>
                      </li>
                      <li>
                        1 Rubber band<sup>‡</sup>
                      </li>
                      <li>
                        1 Sheet of paper<sup>‡</sup>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                      CLASS
                    </p>
                    <ul className="mb-20 pl-20 circle-listing">
                      <li>
                        Number Line<sup>†</sup>
                      </li>
                    </ul>

                    <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                      STUDENT
                    </p>
                    <ul className="mb-20 pl-20 circle-listing">
                      <li>1 Science Notebook*</li>
                      <li>
                        1 Device (tablet, computer, etc.)<sup>‡</sup>
                      </li>
                    </ul>
                    <p className="font-13">*Accessible online</p>
                    <p className="font-13">† Prepared by the teacher</p>
                    <p className="font-13">‡ Needed but not supplied</p>
                  </div>
                </div>
              </section>
              <h5 className="fw-700 mb-10 text-decoration-underline">
                PREPARATION
              </h5>
              <p className="fw-700 mb-7">
                Part <span className="text-nexus-blue">C</span>{" "}
                <span className="fw-400">(10 minutes total)</span>
              </p>
              <p className="mb-7">
                <span className="fw-700">Session 1</span> (5 minutes)
              </p>
              <ul className="mb-20 pl-20 number-listing">
                <li>Prepare to present Digital Lesson 3.</li>
                <li>
                  If you are not using the digital experience, students will
                  still need access to the Temperature Simulation used in this
                  lesson. Obtain enough devices for students to access the
                  simulation individually.
                </li>
                <li>
                  Ensure that students have access to their science notebooks.
                </li>
              </ul>
              <p className="mb-7">
                <span className="fw-700">Session 2</span> (5 minutes)
              </p>
            </div>
          </section>
          <section id="partd">
            <ul className="pl-20 number-listing">
              <li>Prepare to present Digital Lesson 3.</li>
              <li>
                <p className="mb-10">
                  Create a number line on chart paper with 27 evenly spaced hash
                  marks. Label the first hash mark “0.” Have markers available
                  to add to the number line during the lesson.
                </p>
                <div className="row">
                  <div className="col-12">
                    <img src="./image/lesson3_image5.png" alt="" title="" />
                  </div>
                </div>
              </li>
              <li>
                Have a pair of scissors, sheet of paper, and rubber band for a
                demonstration during the lesson.
              </li>
              <li>
                Ensure that students have access to their science notebooks.
              </li>
            </ul>
            <hr></hr>
            <div className="lesson3-parts parta">
              <p className="fw-700 mb-10 text-center p-2 dashed-border-nexus-blue border-bottom-0 radious-0 background-nexus-blue">
                PART D: ADVANCE SENSEMAKING OF THE ANCHOR
              </p>
              <section className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
                <h5 className="fw-700 text-uppercase mb-10 text-nexus-blue">
                  MATERIALS FOR PART D
                </h5>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                      TEACHER
                    </p>
                    <ul className="pl-20 circle-listing">
                      <li>
                        Digital Lesson 3*:
                        <ul className="pt-5 pl-20 circle-listing">
                          <li>Initial Ideas Class Chart</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="fw-700 text-uppercase mb-7 text-nexus-blue">
                      STUDENT
                    </p>
                    <ul className="mb-20 pl-20 circle-listing">
                      <li>1 Science Notebook*</li>
                    </ul>
                    <p className="font-13">*Accessible online</p>
                    <p className="font-13">† Prepared by the teacher</p>
                    <p className="font-13">‡ Needed but not supplied</p>
                  </div>
                </div>
              </section>
              <h5 className="fw-700 mb-10 text-decoration-underline">
                PREPARATION
              </h5>
              <p className="fw-700 mb-7">
                Part <span className="text-nexus-blue">D</span>{" "}
                <span className="fw-400">(5 minutes)</span>
              </p>
              <ul className="pl-20 number-listing">
                <li>Prepare to present Digital Lesson 3.</li>
                <li>
                  Ensure that students have access to their science notebooks.
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Containerprep;
