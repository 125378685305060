import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P9_Navigation() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P9"}>
        <div className="d-flex guidence-count">
          <span className="fw-700 pr-5 text-nexus-medium-blue">17.</span>
          <span className="number-text">
            <span className="fw-700">Navigate to the Next Session:</span> Say,
            “I wonder if there are any effects of Earth’s revolution that we
            experience? What do you think? Should we investigate what patterns
            may be caused by Earth’s revolution?”
          </span>
        </div>
      </IdGeneratingComponent>
    </>
  );
}

export default P9_Navigation;
