import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G2_Make_Observations_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G2"} seriesCount={"S3"}>
        <section className="text-wrapping-right mb-5">
          <div className="icon">
            <img
              src="/image/lesson-images/scaffolding.svg"
              alt="scaffolding"
              title="scaffolding"
            ></img>
          </div>
          <span className="fw-700">Scaffolding:</span> Students should arrive at
          a lesson question similar to, “Why do we see different stars in the
          summer than in the winter?”
        </section>
        <p className="mb-7">
          Use the following guiding questions if students need support to
          develop a lesson question:
        </p>
        <ul className="pl-30 mb-10 circle-listing">
          <li>What do we see?</li>
          <li>What are we trying to figure out?</li>
        </ul>
        <p>
          If needed, say, "Could we ask, 'Why do we see different stars in the
          summer than in the winter?'”
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default G2_Make_Observations_S3;
