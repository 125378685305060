const LessonResourcesData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How can we explain the patterns we notice in the sky?",
    Resources: [
      {
        ResourcesName: "Stars Demonstration Model",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/gr5_l3_space_stars_demonstration_model_video.mp4",
      },
      {
        ResourcesName: "Lesson 3, Part A – Observe",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_observe.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Initial Ideas",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_initial_ideas.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Gather Evidence",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_gather_evidence.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Reflect",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_reflect.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Investigate",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_parta_investigate.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part B – Observe",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_partb_observe.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part B – Analyze Data",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_analyze_data.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part B – Check Point",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/Lessonspdf/g5_space_l3_checkpoint.pdf",
      },
      {
        ResourcesName: "Lesson 3, Part A – Movement in the Solar System ",
        type: "image",
        ImageUrl: "/image/POC_tiles_image.png",
        PdfUrl: "/image/gr5_space_l3_image_solarsystem_infographic.png",
      },
      {
        ResourcesName: "Lesson 3 – Solar System Model",
        type: "image",
        ImageUrl: "/image/POC_tiles_image.png",
        PdfUrl: "/image/g5_space_l3_image_solarsystem.png",
      },
    ],
  },
];

export default LessonResourcesData;
