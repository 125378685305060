const UnitResourcesData = [
  {
    LessonName: "Lesson 3",
    ModuleName: "How do objects change into other objects?",
    UnitResources: [
      {
        UnitResourcesName: "Lesson Resource 3.1",
        type: "pdf",
        ImageUrl: "/image/lesson_resource_tile.png",
        PdfUrl: "/pdf/g2_glass_l3_lesson_resource_3_1.pdf",
      },
      {
        UnitResourcesName: "Lesson 3: Revisiting the Furnace",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/nex_gr2_glassblowing_revisit_furnace_video.mp4",
      },
      {
        UnitResourcesName: "Lesson 3: Cooling Matter",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/nex_gr2_glassblowing_coolingmatter_video.mp4",
      },
      {
        UnitResourcesName: "Lesson 3: Heating Matter",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl:
          "/videos/lesson3-videos/nex_gr2_glassblowing_heatingmatter_video.mp4",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Initial Ideas",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_partA_initialideas.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Our Plan",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_partB_ourplan.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part A – Checkpoint",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_parta_checkpoint.pdf",
      },
      {
        UnitResourcesName: "Lesson 3, Part B – Heating Matter",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "/pdf/g2_glass_notebook_partb_heatingmatter.pdf",
      },
      {
        UnitResourcesName: "Lesson 3 Notebook: Teacher Version",
        type: "pdf",
        ImageUrl: "/image/notebook_tv.png",
        PdfUrl: "/pdf/g2_glass_l3_answer_key.pdf",
      },
      {
        UnitResourcesName: "Lesson 3: Supporting All Learners",
        type: "pdf",
        ImageUrl: "/image/rem_ext.png",
        PdfUrl: "/pdf/g2_glass_l3_rem_ext.pdf",
      },
      {
        UnitResourcesName:
          "Lesson 3, Summative Assessment: Heating and Cooling Matter",
        type: "pdf",
        ImageUrl: "/image/summ_asses.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName:
          "Lesson 3, Assessment Rubric: Heating and Cooling Matter",
        type: "pdf",
        ImageUrl: "/image/assess_rubric.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName:
          "Lesson 2, Summative Assessment: Building New Things",
        type: "pdf",
        ImageUrl: "/image/summ_asses.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 2, Assessment Rubric: Building New Things",
        type: "pdf",
        ImageUrl: "/image/assess_rubric.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 1, Part A – Develop Ideas",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 5, Part C – Make Observations",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Lesson 2, Part A – Sensemaking",
        type: "pdf",
        ImageUrl: "/image/notebook_tile.png",
        PdfUrl: "",
      },
      {
        UnitResourcesName: "Solid or Liquid",
        type: "video",
        ImageUrl: "/image/video_tile.png",
        PdfUrl: "",
      },
    ],
  },
];

export default UnitResourcesData;
