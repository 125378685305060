import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P11_Develop_Initial_Ideas_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P11"} seriesCount={"S3"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">4.</span>
          <span className="number-text">
            Say, “Someone mentioned that some days seem shorter and other days
            seem longer during the year. Has anyone else noticed this?” Ask:
          </span>
        </div>
        <ul className="pl-45 circle-listing">
          <li>
            What are some ways we could gather evidence to determine if this
            really happens?
          </li>
          <li>
            Why might it be important for us to know if the amount of daylight
            changes throughout the year?
          </li>
        </ul>
      </IdGeneratingComponent>
    </>
  );
}

export default P11_Develop_Initial_Ideas_S3;
