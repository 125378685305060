import React from "react";

function T20_Advance_Sensemaking_of_the_Anchoring_Phenomenon() {
  return (
    <>
      <div className="lesson-brief">
        <div className="p-3 mb-20 dashed-border-nexus-blue light-blue-section">
          <h5 className="fw-700 mb-10 text-center">Anchoring Phenomenon</h5>
          <p className="text-i text-center">
            During the course of a day, the Sun, the Moon, and the stars move
            across the sky.
          </p>
        </div>
        <section className="mb-20 icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Share your ideas with a partner and then with the class.
            </p>
          </div>
        </section>
        <div className="p-3 dashed-border-nexus-blue light-blue-section">
          <ul className="pl-20 circle-listing">
            <li>What did we learn in this lesson?</li>
            <li>How can these ideas help us explain this phenomenon?</li>
            <li>Can we fully explain it? Why not?</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default T20_Advance_Sensemaking_of_the_Anchoring_Phenomenon;
