import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G27_Prior_Knowledge_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G27"} seriesCount={"S2"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/scaffolding.svg"
            alt="scaffolding"
            title="scaffolding"
          ></img>
        </div>
        <span className="fw-700">Scaffolding:</span> This visual scaffold
        supports sensemaking as students develop their understanding of the
        patterns associated with temperature and changes to matter.
      </section>
    </IdGeneratingComponent>
  );
}

export default G27_Prior_Knowledge_S2;
