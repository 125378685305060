import React from "react";

function T3_Develop_Initial_Ideas_S3() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">What should we investigate?</p>
          </div>
        </section>
      </div>
    </>
  );
}

export default T3_Develop_Initial_Ideas_S3;
