import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function G6_Engage_in_Sensemaking() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G6"}>
        <p>
          <span className="fw-700">Formative Assessment Opportunity:</span>{" "}
          Listen for students to use evidence from the literacy resource to
          describe the idea that planets move in a path around the Sun.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default G6_Engage_in_Sensemaking;
