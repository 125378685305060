import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G2_Make_Observations_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G2"} seriesCount={"S2"}>
      <section className="guidelines-blue-section">
        <div className="title">
          <p className="fw-700">
            ELA Connection <span className="text-nexus-lightgrey">(A,B,C)</span>
          </p>
        </div>
        <div className="p-2 text">
          <p>
            Listen for students to describe key details about the pole and glass
            blob they observe in the video and to construct questions related to
            what they saw. (SL.2.2)
          </p>
        </div>
      </section>
    </IdGeneratingComponent>
  );
}

export default G2_Make_Observations_S2;
