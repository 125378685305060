import React from "react";

function T31_Engage_Argument_S1() {
  return (
    <>
      <div className="lesson-brief">
        <section className="icon-text-group">
          <div className="icon">
            <img
              src="/image/lesson-images/class_discussion.svg"
              alt="class_discussion"
              title="class_discussion"
            ></img>
          </div>
          <div className="text">
            <p className="mt-5 font-16">
              Share and discuss your matches with a partner.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

export default T31_Engage_Argument_S1;
