import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G5_Ask_Questions_S2() {
  return (
    <IdGeneratingComponent guideLineCount={"G5"} seriesCount={"S2"}>
      <section className="text-wrapping-right">
        <div className="icon">
          <img
            src="/image/lesson-images/science_and_engineerning_practices.svg"
            alt="science_and_engineerning_practices"
            title="science_and_engineerning_practices"
          ></img>
        </div>
        <span className="fw-700">NGSS Element:</span>{" "}
        <span className="text-nexus-blue fw-700">
          {" "}
          Planning and Carrying Out Investigations:
        </span>{" "}
        Students begin to engage in this practice when they brainstorm ways to
        test their initial ideas about how other types of matter are affected by
        temperature.
      </section>
    </IdGeneratingComponent>
  );
}

export default G5_Ask_Questions_S2;
