import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";

function G3_Make_Personal() {
  return (
    <IdGeneratingComponent guideLineCount={"G3"}>
      <p className="pb-7">Some examples include:</p>
      <ul className="pl-30 circle-listing">
        <li>Using a stick to roast marshmallows or hotdogs in a campfire.</li>
        <li>Using a stick/poker to move wood in a firepit or fireplace.</li>
        <li>Using tongs to cook food on a grill, pan, or fryer.</li>
      </ul>
    </IdGeneratingComponent>
  );
}

export default G3_Make_Personal;
