import React from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
function P4_Investigate_S3() {
  return (
    <>
      <IdGeneratingComponent guideLineCount={"P4"} seriesCount={"S3"}>
        <div className="d-flex guidence-count pb-7">
          <span className="fw-700 pr-5 text-nexus-medium-blue">9.</span>
          <span className="number-text">
            Project the Solar System Model. Invite students to share their
            observations and ideas about which objects move in the solar system
            with the class. Encourage them to come to the front of the room and
            use the diagram from the Investigate section of their notebook as
            they share. Ask:
          </span>
        </div>
        <ul className="pl-45 mb-10 circle-listing">
          <li>
            What patterns did you notice when you analyzed the models of the
            solar system?
          </li>
          <li>How could these patterns help us figure out what is moving?</li>
          <li>
            What ideas do you have about which objects are moving? How did you
            come to that conclusion?
          </li>
          <li>
            How do you think the objects might be moving? Why do you think that?
          </li>
        </ul>
        <p className="pl-20">
          As students share their ideas, record them (or allow students to
          record them) on the projected model.
        </p>
      </IdGeneratingComponent>
    </>
  );
}

export default P4_Investigate_S3;
