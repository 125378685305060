import React, { useState } from "react";
import IdGeneratingComponent from "../../../../Common/IdGeneratingComponent";
import Modal from "react-bootstrap/Modal";
import { Toolbar, Tooltip } from "@mui/material";

function G18_Collect_Data_S1() {
  const [lgShow, setLgShow] = useState(false);
  return (
    <>
      <IdGeneratingComponent guideLineCount={"G18"} seriesCount={"S1"}>
        <section className="mb-30 text-wrapping-right">
          <div onClick={() => setLgShow(true)} className="icon cursor-pointer">
            <Tooltip title="Video Alternative" placement="bottom">
              <img
                src="/image/lab_video_option.png"
                alt="lab video"
                title="lab video"
              ></img>
            </Tooltip>
          </div>
          <span className="fw-700">
            Alternative Digital Investigation Available
          </span>
        </section>
        <section className="mb-20 guidelines-blue-section">
          <div className="title">
            <section className="right-icon-text-group">
              <div className="text">
                <p className="fw-700">Teacher Tip</p>
              </div>
              <div className="icon">
                <img
                  src="/image/lesson-images/Pencil.svg"
                  alt="Pencil"
                  title="Pencil"
                ></img>
              </div>
            </section>
          </div>
          <div className="p-2 text">
            <p>
              To make sure all students can see the demonstration, consider
              using a document camera to project the model or asking small
              groups to rotate through to abserve the model.
            </p>
          </div>
        </section>
        <section className="mb-10 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/settings.svg"
              alt="settings"
              title="settings"
            ></img>
          </div>
          <span className="fw-700">NGSS Elements:</span>{" "}
          <span className="fw-700 text-nexus-blue">
            Developing and Using Models,
          </span>{" "}
          <span className="fw-700 text-nexus-green">Patterns,</span>{" "}
          <span className="fw-700 text-nexus-orange">ESS1.A:</span> Students use
          the model to make sense of the role Earth’s revolution plays in the
          patterns related to the stars we see in the sky. They first solidify
          the science idea that it is Earth, not the stars, that moves in space.
          Next, students put these ideas together to understand that we see
          different stars because the stars stay in place as Earth revolves
          around the Sun.
        </section>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/misconceptions.svg"
              alt="misconceptions"
              title="misconceptions"
            ></img>
          </div>
          <span className="fw-700">Misconceptions:</span> In this lesson
          students learn that:
        </section>
        <ul className="pl-30 mb-10 circle-listing">
          <li>Stars do not move.</li>
          <li>
            Earth revolves around the Sun. As it does, it is in different places
            in space.
          </li>
        </ul>
        <p className="mb-10">
          In this step, students put these ideas together to understand that as
          Earth revolves around the Sun, we see different stars in our night
          sky. This addresses the misconception that stars and constellations
          appear in the same place in the sky. If any student continues to hold
          this misconception, guide them to use the Stars Demonstration Model
          like you did and notice that different stars can be viewed from each
          different position of Earth as it moves around the model.
        </p>
        <section className="mb-7 text-wrapping-right">
          <div className="icon">
            <img
              src="/image/lesson-images/scaffolding.svg"
              alt="scaffolding"
              title="scaffolding"
            ></img>
          </div>
          <span className="fw-700">Scaffolding:</span> As you use the Stars
          Demonstration Model, your students may have questions similar to the
          following:
        </section>
        <ul className="pl-30 mb-10 circle-listing">
          <li>
            Why is the spot always looking toward the stars and never toward the
            Sun?
          </li>
          <li>
            Why does Earth seem like it is spinning around a bit as you make the
            spot look to the stars?
          </li>
        </ul>
        <p>
          Acknowledge their questions and that the class doesn’t have enough
          information to answer them at this time. Suggest that you can record
          their questions so they can return to them later.
        </p>
        <Modal
          size="lg modal-dialog-centered"
          className="modal-dialog-centered modal-dialog-scrollable"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Stars Demonstration Model
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-20 video-section">
              <video controls>
                <source
                  src="/videos/lesson3-videos/gr5_l3_space_stars_demonstration_model_video.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </Modal.Body>
        </Modal>
      </IdGeneratingComponent>
    </>
  );
}

export default G18_Collect_Data_S1;
